import React, { useCallback, useContext, useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  invoiceDeleteAction,
  returnBillTableAction,
  storeDetailsAction,
  returnBillViewAction,
} from "../../redux/actions/invoices-action";
import DynamicTable from "../../components/tables/dynamic-table1";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import moment from "moment";
import StatusIcons from "../../assets/icons/check-icon";
import InvoiceFilter from "../../components/tables/table-filter/invoice-filter";
import { DataContext } from "../../components/layouts/main-layout";
import {
  formatString,
  handlea4Print,
} from "../../components/helper-components/helper-function";
import { useHotkeys } from "react-hotkeys-hook";
import { handleReturnBillPrint } from "../../components/return-bill/invoice-thermal-printer";

const ReturnBillTable = () => {
  const { permissionFn } = useContext(DataContext);
  const [storeDetails, setStoreDetails] = useState({});
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  const [orders, setOrders] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
    const [pageSize, setPageSize] = useState(10);;
  const [filterData, setFilterData] = useState({
    sort_by: "",
    sort_order: "",
    selectColumn: "icn_invoice_number",
    from: "",
    to: "",
    inputValue: "",
    order_status: "",
    payment_status: "",
  });

  useEffect(() => {
    dispatch(storeDetailsAction(onSuccessStoreDetails, onErrorStoreDetails));
  }, []);

  const onSuccessStoreDetails = (data) => {
    setStoreDetails(data?.data);
  };
  const onErrorStoreDetails = () => {};

  useEffect(() => {
    dispatch(
      returnBillTableAction(
        {
          pageNumber: currentPage,
          pageSize: pageSize,
          filterData,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, pageSize, filterData, reload]);
  const onSuccess = (data) => {
    setError(false);
    setLoading(false);
    setOrders(data?.data?.orderList ?? []);
    setDataCount(data?.data?.totalCount);
  };
  const onError = (data) => {
    setError(true);
    setLoading(false);
  };

  const navigate = useNavigate();

  const tableBodyData = orders?.map((item) => {
    return {
      id: item?.icn_id,

      icn_invoice_number:
        (
          <span className={`cursor-pointer text-secondary fw_500`}>
            {item?.icn_invoice_number ?? "--"}
          </span>
        ) ?? "--",
      cmr_phone_number: item?.cmr_phone_number,
      cmr_first_name: item?.cmr_first_name ?? "",
      order_status: (
        <span
          className={`${
            item?.is_draft_order
              ? "border-344054 border text-344054"
              : "text-027A48 border-027A48 border "
          } px-2 py-1 rounded-full text-xs whitespace-nowrap`}
        >
          {item?.is_draft_order ? "Saved as Draft" : "Completed"}
        </span>
      ),

      icn_payment_status: (
        <span
          style={{
            width: "fit-content",
            backgroundColor:
              item?.icn_payment_status === "paid"
                ? "var(--success-color-opacity)" // Fully opaque success color
                : "var(--danger-color-opacity)", // F51D00 with approximately 15% opacity
          }}
          className={` ${
            item?.icn_payment_status === "paid"
              ? "text-027A48 "
              : "text-F51D00 "
          } fw_500 flex items-center gap-2 text-xs px-3 py-1 rounded-full`}
        >
          <StatusIcons status={item?.icn_payment_status} />
          {formatString(item?.icn_payment_status)}
        </span>
      ),
      update_date: moment(item?.update_date).format("MMM D, YYYY"),

      icn_total_amount: (
        <span className="flex justify-end pe-5">
          {"₹ " + item?.icn_total_amount}
        </span>
      ),
      // is_draft_order: item?.is_draft_order,
    };
  });
  const paginationProps = {
    pageSize: pageSize,
    setPageSize: setPageSize,
    dataCount: dataCount,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };
  const handleFilterClick = useCallback(() => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  }, []);

  const filterby = showFilter ? (
    <InvoiceFilter
      setCurrentPage={setCurrentPage}
      filterData={filterData}
      setFilterData={setFilterData}
    />
  ) : null;

  const handleVoidAction = (id) => {
    setLoading(true);
    dispatch(
      invoiceDeleteAction(
        {
          icn_id: id,
        },
        onSuccessDel,
        onErrorDel
      )
    );
  };

  const onSuccessDel = () => {
    setReload(!reload);
    setLoading(false);
  };
  const onErrorDel = () => {};

  useHotkeys("escape", () => {
    setShowFilter(false);
  });

  function viewFn(id) {
    dispatch(returnBillViewAction(id, onSuccessView, onErrorView));
  }
  const onSuccessView = (data) => {
    let invoice = data?.data?.[0];
   
    handleReturnBillPrint(
      {
        items: invoice?.itemData,
        orderData: [invoice?.orderData],
      },
      storeDetails
    );
  };
  const onErrorView = (data) => {};

  return (
    <InnerLayout
      permissionFn={permissionFn}
      module="invoice"
      list={true}
      title="Return Bill"
    >
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <></>
      ) : (
        <>
          <DynamicTable
            is_checkbox_req={false}
            invoicePrntFn={(id) => {
              // viewFn(id);
              handlea4Print(id, "invoice_credit_note", dispatch);
            }}
            newBtnTxt="New Bill"
            onClickItemFn={(id) => navigate(`/view-return-bill/${id}`)}
            list={false}
            permissionFn={permissionFn}
            module="invoice"
            transaction="Returned Bill"
            transaction_type="invoice_credit_note"
            voidFn={handleVoidAction}
            showFilter={showFilter}
            filterFn={handleFilterClick}
            setFilterData={setFilterData}
            filterData={filterData}
            paginationProps={paginationProps}
            tableHeading={tableHeading}
            tableBodyData={tableBodyData}
            placeholder="Search"
            filterBy={filterby}
            initialFilter={{
              sort_by: "",
              sort_order: "",
              selectColumn: "icn_invoice_number",
              from: "",
              to: "",
              inputValue: "",
              order_status: "",
              payment_status: "",
            }}
          />
        </>
      )}
    </InnerLayout>
  );
};

export default ReturnBillTable;
const tableHeading = [
  {
    label: "RETURN BILL NUMBER",
    value: "icn_invoice_number",
    display: true,
    column: true,
  },
  {
    label: "PHONE NUMBER",
    value: "cmr_phone_number",
    display: true,
    column: true,
  },
  {
    label: "CUSTOMER NAME",
    value: "cmr_first_name",
    display: true,
    column: true,
  },
  {
    label: "ORDER STATUS",
    value: "order_status",
    display: false,
    column: false,
  },
  {
    label: "PAYMENT STATUS",
    value: "icn_payment_status",
    display: false,
    column: false,
  },
  {
    label: "DATE",
    value: "update_date",
    display: true,
    column: false,
  },

  {
    label: <span className="flex justify-end">AMOUNT</span>,
    value: "icn_total_amount",
    display: true,
    column: false,
    className: "justify-end pe-5",
  },
  {
    label: "",
    value: "",
    column: false,
  },
  // {
  //   label: "",
  //   value: "",
  //   column: false,
  // },
];
