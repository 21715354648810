import React, { useContext, useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import emptyInvoice from "../../assets/images/empty-invoice.png";
import AddButton from "../../components/buttons/button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DynamicTable from "../../components/tables/dynamic-table1";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import {
  doctorDeleteAction,
  doctorTableAction,
} from "../../redux/actions/doctor-action";
import { DataContext } from "../../components/layouts/main-layout";

const DoctorTable = () => {
  const { permissionFn } = useContext(DataContext);

  const dispatch = useDispatch();
  const [doctors, setDoctors] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

    const [pageSize, setPageSize] = useState(10);;
  const [filterData, setFilterData] = useState({
    sort_by: "",
    sort_order: "",
    selectColumn: "dr_name",
    inputValue: "",
  });
  
  useEffect(() => {
    dispatch(
      doctorTableAction(
        {
          pageNumber: currentPage,
          pageSize: pageSize,
          filterData,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, pageSize, filterData, reload]);
  const onSuccess = (data) => {
    setError(false);
    setLoading(false);
    setDoctors(data?.data?.DoctorList);
    setDataCount(data?.data?.totalCount);
  };
  const onError = (data) => {
    setError(true);
    setLoading(false);
  };

  const navigate = useNavigate();

  const tableBodyData = doctors?.map((item) => {
    return {
      id: item?.dr_id,
      dr_name:
        (
          <span className="cursor-pointer text-secondary fw_500">
            {item?.dr_name ?? "--"}
          </span>
        ) ?? "--",
      dr_registration_num: item?.dr_registration_num
        ? item?.dr_registration_num
        : "--",
      dr_qualification: item?.dr_qualification ? item?.dr_qualification : "--",
      dr_specialization: item?.dr_specialization
        ? item?.dr_specialization
        : "--",
    };
  });
  const paginationProps = {
    pageSize: pageSize,
    setPageSize: setPageSize,
    dataCount: dataCount,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };

  return (
    <InnerLayout
      list={true}
      onclkFn={() => navigate("/add-doctor")}
      title="Doctors"
      module="doctor"
      permissionFn={permissionFn}
    >
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <></>
      ) : doctors?.length === 0 ? (
        <div className="flex flex-col justify-center items-center h-full">
          <div>
            <img src={emptyInvoice} alt="empty-invoice" />
            <h3 className="flex justify-center text-000000 fw_600 fs_18">
              No doctors found{" "}
            </h3>
            <p className="flex justify-center text-667085 fw_400 mt-4 text-sm">
              Let’s start by adding your first doctor.{" "}
            </p>
            <div className="flex justify-center mt-3">
              <AddButton
                text="Add Customer"
                onclkFn={() => navigate("/add-doctor")}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <DynamicTable
            id_name="dr_id"
            filter={false}
            date={false}
            onClickItemFn={(id) => navigate(`/view-doctor/${id}`)}
            list={true}
            onclkFn={() => {
              navigate("/add-doctor");
            }}
            permissionFn={permissionFn}
            module={"invoice"}
            path="doctor"
            reload={reload}
            setReload={setReload}
            loading={loading}
            setLoading={setLoading}
            deleteAction={doctorDeleteAction}
            label="Doctor"
            showFilter={false}
            filterFn={() => {}}
            setFilterData={setFilterData}
            filterData={filterData}
            paginationProps={paginationProps}
            tableHeading={tableHeading}
            tableBodyData={tableBodyData}
            placeholder="Search"
            filterBy={false}
            initialFilter={{
              sort_by: "",
              sort_order: "",
              selectColumn: "dr_name",
              inputValue: "",
            }}
          />
        </>
      )}
    </InnerLayout>
  );
};

export default DoctorTable;
const tableHeading = [
  {
    label: "DOCTOR NAME",
    value: "dr_name",
    display: true,
    column: true,
  },
  {
    label: "DOCTOR REG NO.",
    value: "dr_registration_num",
    display: true,
    column: true,
  },
  {
    label: "DOCTOR QUALIFICATION",
    value: "dr_qualification",
    display: false,
    column: false,
  },
  {
    label: "DOCTOR SPECIALIZATION",
    value: "dr_specialization",
    display: true,
    column: false,
  },

  {
    label: "",
    value: "",
    column: false,
  },
];
