import React, { useState } from "react";
import ItemCategoryTable from "./item-category-table";
import ItemGroupTable from "./item-group-table";
import ItemTypeTable from "./item-type-table";
import InnerLayout from "../../../layouts/inner-layout";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import search from "../../../../assets/icons/searchIcon.svg";
import TabsComponent from "../../../helper-components/tabs-component";

const ItemSetup = () => {
  const [currentTab, setCurrentTab] = useState("Item Type");
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  return (
    <InnerLayout
      list={true}
      title="Settings"
      module="item-setup"
      // permissionFn={permissionFn}
    >
      <div className="my-2 pl-2">
        <div className="flex justify-between pt-3">
          <div className="pl-2 pb-4 flex items-center gap-2 text-sm">
            <button
              className="flex items-center gap-2"
              onClick={() => {
                navigate("/settings");
              }}
            >
              <ChevronLeftIcon className="h-5 w-5 inline-block " />
              Settings /
            </button>
            <span className="cursor-pointer">{" " + "Item Setup"}</span>
          </div>
          <div
            style={{ minWidth: "18rem", height: "2.5rem" }}
            className={`input_border flex items-center rounded-md pb-1 w-10 mx-6`}
          >
            <span className="px-2">
              {" "}
              <img width={"16px"} src={search} alt="search icon" />
            </span>
            <input
              placeholder={`Search here`}
              className=" table_search_input w-full rounded-md text-xs placeholder:text-xs"
              type="text"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e?.target?.value);
              }}
            />
            <span                                                
              className="text-sm mx-3 cursor-pointer text-primary"
              onClick={() => setSearchValue("")}
            >
              Clear
            </span>
          </div>
        </div>
        <TabsComponent
          handleClickfn={(item) => {
            setCurrentTab(item);
          }}
          itemTabs={itemTabs}
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
        />
        {currentTab === "Item Type" && (
          <div>
            <ItemTypeTable searchValue={searchValue} />
          </div>
        )}
        {currentTab === "Item Category" && (
          <div>
            <ItemCategoryTable searchValue={searchValue} />
          </div>
        )}
        {currentTab === "Item Groups" && (
          <div>
            {" "}
            <ItemGroupTable searchValue={searchValue} />
          </div>
        )}
      </div>
    </InnerLayout>
  );
};

export default ItemSetup;

const itemTabs = ["Item Type", "Item Category", "Item Groups"];
