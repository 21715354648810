import { all } from "redux-saga/effects";

import {
  PaymentInCreateWatcher,
  glAccListWatcher,
  paymentInTableWatcher,
  paymentInViewWatcher,
  salesCreateWatcher,
  salesCreditEditWatcher,
  salesCreditReceiptCreateWatcher,
  salesCreditTableWatcher,
  salesCreditViewWatcher,
  salesCreditVoidWatcher,
  salesDeliveryEditWatcher,
  salesDeliveryItemsGetWatcher,
  salesDeliveryReceiptCreateWatcher,
  salesDeliveryTableWatcher,
  salesDeliveryViewWatcher,
  salesDeliveryVoidWatcher,
  salesEditWatcher,
  salesInvoiceEditWatcher,
  salesInvoiceItemsGetWatcher,
  salesInvoiceReceiptCreateWatcher,
  salesInvoiceTableWatcher,
  salesInvoiceViewWatcher,
  salesInvoiceVoidWatcher,
  salesOrderItemsGetWatcher,
  salesPaymentInCusWatcher,
  salesProductDetailsWatcher,
  salesProductListWatcher,
  salesReturnEditWatcher,
  salesReturnItemsGetWatcher,
  salesReturnReceiptCreateWatcher,
  salesReturnTableWatcher,
  salesReturnViewWatcher,
  salesReturnVoidWatcher,
  salesTableWatcher,
  salesViewWatcher,
  salesVoidWatcher,
} from "../sales-saga";

export default function* salesRootSaga() {
  yield all([
    salesCreateWatcher(),
    salesTableWatcher(),
    salesProductListWatcher(),
    salesViewWatcher(),
    salesDeliveryReceiptCreateWatcher(),
    salesOrderItemsGetWatcher(),
    salesDeliveryTableWatcher(),
    salesDeliveryViewWatcher(),
    salesDeliveryEditWatcher(),
    salesDeliveryVoidWatcher(),
    salesDeliveryItemsGetWatcher(),
    salesInvoiceItemsGetWatcher(),
    salesInvoiceViewWatcher(),
    salesInvoiceEditWatcher(),
    salesInvoiceVoidWatcher(),
    salesInvoiceReceiptCreateWatcher(),
    salesInvoiceTableWatcher(),
    salesReturnItemsGetWatcher(),
    salesReturnViewWatcher(),
    salesReturnEditWatcher(),
    salesReturnVoidWatcher(),
    salesReturnReceiptCreateWatcher(),
    salesReturnTableWatcher(),
    salesCreditTableWatcher(),
    salesCreditReceiptCreateWatcher(),
    salesCreditViewWatcher(),
    salesCreditEditWatcher(),
    salesCreditVoidWatcher(),
    salesEditWatcher(),
    salesVoidWatcher(),
    salesPaymentInCusWatcher(),
    paymentInTableWatcher(),
    PaymentInCreateWatcher(),
    glAccListWatcher(),
    paymentInViewWatcher(),
    salesProductDetailsWatcher(),
  ]);
}
