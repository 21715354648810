import React from "react";

const TimerIcon = ({ fill = "#64758B", width = "14px", height = "14px" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_11021_37470)">
        <path
          d="M8.09998 14.6667C11.7819 14.6667 14.7666 11.6819 14.7666 8.00004C14.7666 4.31814 11.7819 1.33337 8.09998 1.33337C4.41808 1.33337 1.43331 4.31814 1.43331 8.00004C1.43331 11.6819 4.41808 14.6667 8.09998 14.6667Z"
          stroke={fill}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.09998 4V8L10.7666 9.33333"
          stroke={fill}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11021_37470">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.0999756)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TimerIcon;
