import { api } from "./api";

export const inventoryCreateApi = (params) => {
  return api.post(`/warehouse/create`, JSON.stringify(params));
};

export const binlocationAddApi = (params) => {
  return api.post(`/warehouse/addBinLocation`, JSON.stringify(params));
};

export const warehouseListApi = (params) => {
  return api.get(
    `/warehouse/getByWareHouseCode?warehouse_code=${params?.code}`
  );
};

export const stockTransferCreateApi = (params) => {
  return api.post(`/stock/transfer/create`, JSON.stringify(params));
};
export const stockTransferEditApi = (params) => {
  return api.post(`/stock/transfer/update`, JSON.stringify(params));
};

export const stockTransferTableApi = (params) => {
  return api.get(
    `/stock/transfer/list?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${params?.filterData?.to}${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }&filterValue=${params?.filterData?.filterValue}`,
    JSON.stringify(params)
  );
};

export const stockTransferViewApi = (params) => {
  return api.get(
    `/stock/transfer/getById?itt_id=${params}`,
    JSON.stringify(params)
  );
};
export const stockTransferBatchApi = (params) => {
  return api.get(`/product/batchnumber/get?item_id=${params}`);
};
export const voidStockTransferApi = (params) => {
  return api.post(`/stock/transfer/void`, JSON.stringify(params));
};
export const voidStockTransferItemApi = (params) => {
  return api.post(`/stock/transfer/void/item`, JSON.stringify(params));
};
