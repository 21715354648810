// import React, { useEffect, useState } from "react";
// import { EditButton } from "../buttons/button";
// import "./layout.css";
// import { PagLeftIcon } from "../../assets/icons/arrow-pagination-icons";
// import { useNavigate } from "react-router-dom";
// import { formatString } from "../helper-components/helper-function";

// const InnerLayout = (props) => {
//   const navigate = useNavigate();
//   const defaultPermissionFn = () => {};
//   const [currentDate, setCurrentDate] = useState(
//     new Date().toLocaleString("en-US", {
//       weekday: "short",
//       month: "short",
//       day: "2-digit",
//       hour12: true,
//       hour: "2-digit",
//       minute: "2-digit",
//       second: "2-digit",
//     })
//   );
//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setCurrentDate(
//         new Date().toLocaleString("en-US", {
//           weekday: "short",
//           month: "short",
//           day: "2-digit",
//           hour12: true,
//           hour: "2-digit",
//           minute: "2-digit",
//           second: "2-digit",
//         })
//       );
//     }, 1000);

//     return () => clearInterval(intervalId);
//   }, []);

//   const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
//   useEffect(() => {
//     const updateHeight = () => {
//       const firstDivHeight = document.querySelector(".main-div")?.clientHeight;
//       setHeightOfFirstDiv(firstDivHeight);
//     };
//     updateHeight();
//     window.addEventListener("resize", updateHeight);
//     return () => {
//       window.removeEventListener("resize", updateHeight);
//     };
//   }, []);

//   return (
//     <div className="flex flex-col h-full w-full overflow-y-hidden">
//       <div
//         // style={{
//         //   position: "fixed",
//         //   top: "0",
//         //   zIndex: "40",
//         //   height: "3.7rem",
//         // }}
//         className="inner_layout_header main-div  flex justify-between w-[-webkit-fill-available]"
//       >
//         <div className="flex items-center gap-2">
//           {props?.backBtn && (
//             <button
//               onClick={() => {
//                 if (props?.title === "Past Orders") {
//                   navigate(`/add-invoice?phoneNumber=${props?.phoneNumber}`);
//                 } else {
//                   navigate(-1);
//                 }
//               }}
//             >
//               <PagLeftIcon width="25" height="25" fill="var(--heading-color)" />
//             </button>
//           )}
//           <span
//             className="text-000000 text-lg font-weight-600"
//             style={{ fontWeight: 600 }}
//           >
//             {props?.title}
//           </span>
//         </div>
//         <div className="flex gap-3 text-sm items-center">
//           <span className="text-FFBA08 text-sm">{props?.title1}</span>
//           <div
//             style={{
//               width: "12rem",
//             }}
//             className="text-787878 text-sm whitespace-nowrap"
//           >
//             {currentDate}
//           </div>
//           {/* {
//             // (props?.permissionFn || defaultPermissionFn)(props?.module)
//             //   ?.create &&
//             props.list && <AddButton onclkFn={props?.onclkFn} text="New" />} */}
//           {/* <PowerIcon
//             onClick={() => {
//               localStorage.clear("accessToken");
//               navigate("/login");
//               successToast("Logged Out Successfully");
//             }}
//             className="w-6"
//           /> */}
//           {props.view &&
//             (props?.permissionFn || defaultPermissionFn)(props?.module)
//               ?.update && (
//               <EditButton onclkFn={props?.onclkEditFn} text="Edit" />
//             )}
//           Logged in User : {formatString(localStorage.getItem("user_name"))}
//         </div>
//       </div>
//       <div
//         style={{
//           // maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
//           height: `calc(100% - ${heightOfFirstDiv}px)`,
//         }}
//         className={`innerLayoutScroller ${
//           props?.list ? "" : "overflow-y-auto"
//         }`}
//       >
//         {props?.children}
//       </div>
//     </div>
//   );
// };

// export default InnerLayout;

import React, { useEffect, useState } from "react";
import { EditButton } from "../buttons/button";
import "./layout.css";
import { PagLeftIcon } from "../../assets/icons/arrow-pagination-icons";
import { useNavigate } from "react-router-dom";
import { formatString } from "../helper-components/helper-function";
import CalendorIcon from "../../assets/icons/calendor-icon";
import TimerIcon from "../../assets/icons/timer-icon";
import PersonIcon from "../../assets/icons/person-icon";

const InnerLayout = (props) => {
  const navigate = useNavigate();
  const defaultPermissionFn = () => {};
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      setCurrentDate(                      
        now.toLocaleDateString("en-US", {
          weekday: "short",
          month: "short",
          day: "2-digit",
        })
      );
      setCurrentTime(
        now.toLocaleTimeString("en-US", {
          hour12: true,
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
      );
    };

    updateDateTime(); // Initialize immediately
    const intervalId = setInterval(updateDateTime, 1000); // Update time every second

    return () => clearInterval(intervalId);
  }, []);

  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight = document.querySelector(".main-div")?.clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <div className="flex flex-col h-full w-full overflow-y-hidden">
      <div
        // style={{
        //   position: "fixed",
        //   top: "0",
        //   zIndex: "40",
        //   height: "3.7rem",
        // }}
        className="inner_layout_header main-div  flex justify-between w-[-webkit-fill-available]"
      >
        <div className="flex items-center gap-2">
          {props?.backBtn && (
            <button
              onClick={() => {
                if (props?.title === "Past Orders") {
                  navigate(`/add-invoice?phoneNumber=${props?.phoneNumber}`);
                } else {
                  navigate(-1);
                }
              }}
            >
              <PagLeftIcon width="20px" height="20px" fill="var(--heading-color)" />
            </button>
          )}
          <span className="text-[var(--top-nav-heading-color)] text-[length:--top-nav-heading-size] font-[var(--top-nav-heading-weight)]">
            {props?.title}
          </span>
        </div>
        <div className="flex gap-3 text-sm items-center">
          <span className="text-FFBA08 text-sm">{props?.title1}</span>
          <div
            style={{
              width: "15rem",
            }}
            className="text-[var(--top-nav-text-color)] text-[length:--top-nav-text-size] font-[var(--top-nav-text-weight)] whitespace-nowrap flex gap-5 items-center  border-r-[var(--top-nav-border-color)] border-r"
          >
            <span className="flex gap-2 items-center">
              <CalendorIcon /> {currentDate}
            </span>{" "}
            <span className="flex gap-2 items-center">
              <TimerIcon />
              {currentTime}
            </span>
          </div>
          {/* {
            // (props?.permissionFn || defaultPermissionFn)(props?.module)
            //   ?.create &&
            props.list && <AddButton onclkFn={props?.onclkFn} text="New" />} */}
          {/* <PowerIcon
            onClick={() => {
              localStorage.clear("accessToken");
              navigate("/login");
              successToast("Logged Out Successfully");
            }}
            className="w-6"
          /> */}
          {props.view &&
            (props?.permissionFn || defaultPermissionFn)(props?.module)
              ?.update && (
              <EditButton onclkFn={props?.onclkEditFn} text="Edit" />
            )}
         <span className="text-[var(--top-nav-heading-color)] text-[length:--top-nav-text-size] font-[var(--top-nav-text-weight)] flex items-center gap-2"> <PersonIcon  /> {formatString(localStorage.getItem("user_name"))}</span>
        </div>
      </div>
      <div
        style={{
          // maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
          height: `calc(100% - ${heightOfFirstDiv}px)`,
        }}
        className={`innerLayoutScroller ${
          props?.list ? "" : "overflow-y-auto"
        }`}
      >
        {props?.children}
      </div>
    </div>
  );
};

export default InnerLayout;
