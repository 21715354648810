import React, { useEffect, useState } from "react";
import InnerLayout from "../../../layouts/inner-layout";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AddButton from "../../../buttons/button";
import DeleteIcon from "../../../../assets/icons/delete-icon";
import {
  deleteAlternativeTransAction,
  templateDeleteAction,
  templateTableAction,
} from "../../../../redux/actions/settings-action";
import successToast from "../../../helper-components/success-toast";
import errorToast from "../../../helper-components/error-toast";
import SortingIcons from "../../../../assets/icons/arrowup-down";
import LoadingSpinner from "../../../helper-components/loading-spinner";
import search from "../../../../assets/icons/searchIcon.svg";
import TemplateUploadModal from "./template-upload-modal";
import EditIcon from "../../../../assets/icons/action-icons";
import moment from "moment";
const ImportTemplates = () => {
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mapping_id, setMapping_id] = useState("");
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight = document.querySelector(".first-div")?.clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);
  const navigate = useNavigate();
  const [templateRows, setTemplateRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);

  const [filterData, setfilterData] = useState({
    sort_order: "",
    sort_by: "",
  });

  useEffect(() => {
    setLoading(true);
    dispatch(
      templateTableAction({ searchValue }, onFetchSuccess, onFetchError)
    );
  }, [reload, searchValue]);
  const onFetchSuccess = (data) => {
    console.log(data, "dsfdssdfsdfsdfsdfasd");
    setError(false);
    setLoading(false);
    setTemplateRows(data?.data ?? []);
  };
  const onFetchError = (data) => {
    setLoading(false);
  };

  const tableBodyData = templateRows?.map((item) => {
    return {
      cmr_vendor_name: item?.cmr_vendor_name,
      module_name: item?.module_name,
      mapping_id: item?.mapping_id,
      update_date: item?.update_date,
    };
  });

  function deleteAltItemFn(id) {
    dispatch(
      templateDeleteAction(
        {
          mapping_id: id,
        },
        onDeleteSuccess,
        onDeleteError
      )
    );
  }
  const onDeleteSuccess = (data) => {
    successToast(data?.data?.message);
    setReload(!reload);
  };
  const onDeleteError = (data) => {
    errorToast(data?.data?.message);
  };
  return (
    <InnerLayout
      list={true}
      title="Settings"
      module="item-setup"
      // permissionFn={permissionFn}
    >
      <div className="my-2  w-full h-full">
        <div className="flex justify-between pt-3 h-[4rem]">
          <div className="pl-2 pb-4 flex items-center gap-2 text-sm">
            <button
              className="flex items-center gap-2"
              onClick={() => {
                navigate("/settings");
              }}
            >
              <ChevronLeftIcon className="h-5 w-5 inline-block" />
              Settings /
            </button>
            <span className="cursor-pointer">
              {" " + "Import Templates Settings"}
            </span>
          </div>
          <div></div>
        </div>

        <div
          style={{
            height: `calc(100% - 4rem)`,
          }}
        >
          <div className="flex gap-5 items-center justify-between  first-div px-5">
            <div
              style={{ minWidth: "25rem", height: "2.5rem" }}
              className={`input_border flex items-center rounded-md  w-10 `}
            >
              <span className="px-2">
                {" "}
                <img width={"16px"} src={search} alt="search icon" />
              </span>
              <input
                placeholder={`Search here`}
                className=" table_search_input w-full rounded-md text-xs placeholder:text-xs"
                type="text"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e?.target?.value);
                }}
              />
              <span
                className="text-sm mx-3 cursor-pointer text-primary"
                onClick={() => setSearchValue("")}
              >
                Clear
              </span>
            </div>
            <AddButton
              onclkFn={() => {
                setIsModalOpen(true);
                setMapping_id("");
              }}
              prefixIcon={false}
              className="bg-primary text-white px-4 py-[7px]  rounded-md flex justify-center fw_500 text-sm"
              text="Create Template"
              type="button"
            />
          </div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div
              style={{
                height: `calc(100% - ${heightOfFirstDiv}px)`,
              }}
              className="flex flex-col justify-between overflow-auto pt-5"
            >
              <table className="my-tab">
                <thead>
                  <tr>
                    {tableHeading.map((heading, index) => (
                      <th
                        key={index}
                        className={`${"tab-header"} whitespace-nowrap text-xs fw_700 text-475467 bg-F2F4F5`}
                      >
                        <span
                          className={`flex items-center gap-2 ${
                            index === 0 ? "pl-7" : ""
                          }`}
                        >
                          {heading?.label}
                          {heading?.display && (
                            <button
                            // onClick={() => sortFn(heading?.value)}
                            >
                              {" "}
                              <SortingIcons
                                inc={
                                  filterData?.sort_order === "ASC" &&
                                  heading?.value === filterData?.sort_by
                                    ? "var(--table-heading-color)"
                                    : "#9A9FA6"
                                }
                                dec={
                                  filterData?.sort_order === "DESC" &&
                                  heading?.value === filterData?.sort_by
                                    ? "var(--table-heading-color)"
                                    : "#9A9FA6"
                                }
                              />
                            </button>
                          )}
                        </span>
                      </th>
                    ))}
                    <th
                      className={`${"tab-header"} whitespace-nowrap text-right text-sm fw_700 text-475467 bg-F2F4F5`}
                    ></th>
                  </tr>
                </thead>
                <tbody className="h-full">
                  {tableBodyData?.length > 0 && (
                    <>
                      {tableBodyData.map((rowData, rowIndex) => (
                        <>
                          <tr className={`hover:bg-f3f8ff`} key={rowIndex}>
                            <td
                              className={`text-sm tab-cell  fw_400 text-secondary`}
                            >
                              <span className="pl-7">
                                {" "}
                                {rowData?.cmr_vendor_name}
                              </span>
                            </td>
                            <td
                              className={`text-sm tab-cell fw_400 text-secondary`}
                            >
                              {rowData?.module_name}
                            </td>
                            <td
                              className={`text-sm tab-cell fw_400 text-secondary`}
                            >
                              {moment(rowData?.update_date).format(
                                "DD-MM-YYYY"
                              )}
                            </td>

                            <td
                              className={`text-sm tab-cell text-center fw_400 text-secondary  flex gap-10 justify-end `}
                            >
                              <button
                                onClick={() => {
                                  setMapping_id(rowData?.mapping_id);
                                  setIsModalOpen(true);
                                }}
                              >
                                <EditIcon />
                              </button>
                              <button
                                className="pr-10"
                                type="button"
                                onClick={() => {
                                  deleteAltItemFn(rowData?.mapping_id);
                                }}
                              >
                                <DeleteIcon />
                              </button>
                            </td>
                          </tr>
                        </>
                      ))}
                      <tr style={{ height: "10rem" }}></tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <TemplateUploadModal
        reload={reload}
        setReload={setReload}
        setMapping_id={setMapping_id}
        mapping_id={mapping_id}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </InnerLayout>
  );
};

export default ImportTemplates;

// const tablist = ["Item Type", "Item Category", "Item Groups"];

const tableHeading = [
  {
    label: "Template Name",
    value: "item_name",
    display: false,
  },
  {
    label: "Type",
    value: "item_name",
    display: false,
  },
  {
    label: "Last Modified",
    value: "item_name",
    display: false,
  },

  // {
  //   label: "Alternative Item Code",
  //   value: "alternative_item_code",
  //   display: true,
  // },
  // {
  //   label: "Alternative Item Name",
  //   value: "alternative_item_name",
  //   display: true,
  // },
];
