import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import AddButton from "../../components/buttons/button";
import { useLocation, useNavigate } from "react-router-dom";

import outstockimg from "../../assets/images/out-stock.png";
import SelectField from "../../components/forms/select-field";
import SearchAddFeild from "../../components/forms/search-add-field";
import { fetchData } from "../../components/helper-components/helper-function";
import CloseIcon from "../../assets/icons/close-icon";
import errorToast from "../../components/helper-components/error-toast";
import { url } from "../../redux/config";
import { api } from "../../redux/apis/api";

const PurchaseOrderCsv = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const suggestedProducts = location.state?.suggestedProducts || [];
  const initialValue = {
    data: suggestedProducts || [],
  };
  const validationSchema = Yup.object().shape({
    // data: Yup.array().of(
    //   Yup.object().shape({
    //     item_name_suggested: Yup.string().required(
    //       "Suggested Item is required"
    //     ),
    //   })
    // ),           
  });
  const handleSubmit = (values, actions) => {
    const missingSuggestedItem = values?.data?.some(
      (item) => !item.item_name_suggested
    );
    const missingBaseUOM = values?.data?.some((item) => !item.item_sheet_uom);
    if (missingSuggestedItem) {
      errorToast("Suggested Item is required!");
      actions.setSubmitting(false); // Stop the form submission if the validation fails
      return;
    }
    if (missingBaseUOM) {
      errorToast("Base UOM is required!");
      actions.setSubmitting(false); // Stop the form submission if the validation fails
      return;
    }
    const finalValueFromSheet = values?.data?.map((item) => ({
      item_name: item?.item_name_suggested,
      item_generic_name: "",
      item_code: item?.item_code,
      item_uom: item?.item_sheet_uom,
      item_unit_price: item?.item_unit_price ?? 0,
      sales_order_qty: 0,
      item_quantity: item?.item_quantity ?? 0,
      item_total_amount: item?.item_total_amount ?? 0,
      item_id: item?.item_id,
      item_tax_amount: item?.item_tax_amount ?? 0,
      instock: false,
      item_gst: item?.item_tax_amount ?? 0,
      item_cgst: item?.item_cgst ?? 0,
      item_sgst: item?.item_sgst ?? 0,
      item_price_wiithout_tax: 0,
      uom_dropdown: item?.uom_dropdown,
      item_uom_id: item?.item_sheet_uom_id,
      item_minimun_purchasing: item?.item_minimun_purchasing ?? "",
      item_maximum_purchasing: item?.item_maximum_purchasing ?? "",
    }));
    navigate(
      `/add-purchase/${encodeURIComponent(JSON.stringify([]))}/purchase`,
      {
        state: { orderDetail: finalValueFromSheet },
      }
    );
  };
  const handleQuantityChange = async (
    event,
    rowIndex,
    values,
    setFieldValue,
    selectedOption
  ) => {
    const updatedData = [...values.data];
    
    const totalAmount =
      parseFloat(values?.data?.[rowIndex]?.item_unit_price) * parseFloat(event);

    if (parseFloat(event) > 0) {
      try {
        const response = await api.get(
          `${url}/discount/get?totalAmountOfItem=${totalAmount}&item_quantity=${event}&item_id=${selectedOption?.item_id}&cmr_phone_number=${values?.cmr_phone_number}`,
          JSON.stringify(totalAmount)
        );

        const tax =
          response.data?.code === 200 ? response.data.data.item_tax_amount : 0;
        const updatedDataRow = {
          ...updatedData[rowIndex],
          item_gst:
            response.data?.code === 200
              ? response.data.data.item_total_tax_percentage
              : 0,
          item_sgst:
            response.data?.code === 200
              ? (
                  parseInt(event) *
                  parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
                  (response.data.data.sgstRate / 100)
                )?.toFixed(2)
              : 0,
          item_cgst:
            response.data?.code === 200
              ? (
                  parseInt(event) *
                  parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
                  (response.data.data.cgstRate / 100)
                )?.toFixed(2)
              : 0,
          item_igst:
            response.data?.code === 200
              ? (
                  parseInt(event) *
                  parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
                  (response.data.data.igstRate / 100)
                )?.toFixed(2)
              : 0,
          item_total_amount:
            parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
            parseFloat(parseInt(event)),
          item_tax_amount: parseFloat(tax),
          item_price_wiithout_tax: (
            parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
              parseFloat(parseInt(event)) -
              // parseFloat(discount) -
              parseFloat(tax) || 0
          ).toFixed(2),
          item_name_suggested: selectedOption.item_name,
          item_id: selectedOption.item_id,
          item_code: selectedOption.item_code,
          item_sheet_uom_id: selectedOption.item_sheet_uom_id,
          item_sheet_uom: selectedOption.item_sheet_uom,
          uom_dropdown: selectedOption.uom_dropdown,
        };
        updatedData[rowIndex] = updatedDataRow;

        setFieldValue("data", [...updatedData]);
      } catch (error) {}
    }
  };

  return (
    <InnerLayout backBtn={true} title="Purchase Order CSV Import">
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form className="h-full">
              <div
                className="flex gap-3 justify-between"
                style={{
                  maxHeight: `calc(100% - 4.5rem)`,
                  height: `calc(100% - 4.5rem)`,
                }}
              >
                <div className="min-w-[68%] border-EAECF0 border overflow-auto mb-2 h-fit     scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                  <table className="my-tab">
                    <thead className="sticky top-0 z-10">
                      <tr className="bg-F2F4F5">
                        {tableHeading.map((heading, index) => (
                          <th
                            key={index}
                            className={`${"border-b-EAECF0 border-b h-[3rem] text-left px-2"} whitespace-nowrap text-sm fw_700 text-475467 bg-F2F4F5
                            ${
                              index === 0
                                ? "w-[2rem]"
                                : index === 1
                                ? "w-[2rem]"
                                : index === 2
                                ? "min-w-[4rem] w-[7rem] text-center"
                                : index === 3
                                ? "w-[2rem]"
                                : index === 4
                                ? "min-w-[8rem] w-[10rem]"
                                : index === 5
                                ? ""
                                : ""
                            }`}
                          >
                            {heading?.label}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {values?.data?.map((item, index) => (
                        <tr>
                          <td className="text-sm h-[4rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary ">
                            {index + 1}
                          </td>
                          <td className="text-sm h-[4rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary text-nowrap">
                            {item?.item_name || "N/A"}
                          </td>
                          <td className="text-sm h-[4rem] px-3 text-center border-b-EAECF0 border-b  fw_400 text-secondary ">
                            {item?.item_quantity || "N/A"}
                          </td>
                          <td className="text-sm h-[4rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary ">
                            {item?.item_unit_price || "N/A"}
                          </td>
                          <td
                            className={`text-sm h-[4rem] px-3  fw_400 text-secondary border-b-EAECF0 border-b `}
                          >
                            <SelectField
                              handleSelected={(sel) => {
                                setFieldValue(
                                  `data[${index}].item_sheet_uom`,
                                  sel?.label
                                );
                              }}
                              borderclass={
                                item?.item_sheet_uom
                                  ? ""                      
                                  : "border border-F51D00"
                              }
                              defaultSelectClass={
                                item?.item_sheet_uom
                                  ? "border-0"
                                  : "border border-F51D00 rounded-md"
                              }
                              selectClass="w-full"
                              defaultValue={
                                item?.item_sheet_uom
                                  ? {
                                      label: item?.item_sheet_uom ?? "",
                                    }
                                  : null
                              }
                              errMsgContainerClass="hidden"
                              id={`data[${index}].item_sheet_uom_id`}
                              isContainer={false}
                              options={
                                item?.uom_dropdown?.map((opt) => ({
                                  label: opt?.alt_uom,
                                  value: opt?.item_id,
                                })) || []
                              }
                              name={`data[${index}].item_sheet_uom_id`}
                              placeholder={"Select UOM"}
                            />
                          </td>
                          <td className="text-sm h-[4rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary">
                            <div className="relative w-full">
                              <SearchAddFeild
                                errorClass="hidden"
                                isRequired={false}
                                value={{
                                  label: item?.item_name_suggested,
                                  value: "",
                                }}
                                name={`data[${index}].item_id`}
                                field={`item_name`}
                                isLabelReq={false}
                                defaultSelectClass=""
                                placeholder=""
                                fetchData={fetchData}
                                onChange={async (selectedOption) => {
                             
                                  if (
                                    selectedOption?.value?.text === "new_item"
                                  ) {
                                    navigate(
                                      `/add-product?itemText=${selectedOption?.value?.itemText}&itemIndex=${index}`,
                                      {
                                        state: {
                                          suggestedProducts: values?.data,
                                        },
                                      }
                                    );
                                  } else {
                                    handleQuantityChange(
                                      item.item_quantity,
                                      index,
                                      values,
                                      setFieldValue,
                                      selectedOption
                                    );
                                    const updatedData = [...values.data];
                                    const updatedItem = updatedData[index];
                                    updatedData[index] = {
                                      ...updatedItem,
                                      item_name_suggested:
                                        selectedOption.item_name,
                                      item_id: selectedOption.item_id,
                                      item_code: selectedOption.item_code,
                                      item_sheet_uom_id:
                                        selectedOption.item_sheet_uom_id,
                                      item_sheet_uom:
                                        selectedOption.item_sheet_uom,
                                      uom_dropdown: selectedOption.uom_dropdown,
                                    };
                                    setFieldValue("data", updatedData);
                                  }
                                }}
                              />

                              <div
                                onClick={() => {
                                  setFieldValue(`data[${index}].item_id`, "");
                                  setFieldValue(
                                    `data[${index}].item_name_suggested`,
                                    ""
                                  );
                                  setFieldValue(
                                    `data[${index}].item_sheet_uom_id`,
                                    ""
                                  );
                                  setFieldValue(
                                    `data[${index}].item_sheet_uom`,
                                    ""
                                  );
                                  setFieldValue(
                                    `data[${index}].uom_dropdown`,
                                    []
                                  );
                                  // setFieldValue(
                                  //   `data[${index}].item_price_wiithout_tax`,
                                  //   0
                                  // );
                                  // setFieldValue(
                                  //   `data[${index}].item_tax_amount`,
                                  //   0
                                  // );
                                  // setFieldValue(
                                  //   `data[${index}].item_total_amount`,
                                  //   0
                                  // );
                                  // setFieldValue(`data[${index}].item_igst`, 0);
                                  // setFieldValue(`data[${index}].item_cgst`, 0);
                                  // setFieldValue(`data[${index}].item_sgst`, 0);
                                  // setFieldValue(`data[${index}].item_gst`, 0);
                                }}
                                className="absolute cursor-pointer z-15 right-1 top-1/2 transform -translate-y-1/2"
                              >
                                {item?.item_name_suggested && (
                                  <CloseIcon width={15} height={15} />
                                )}
                              </div>
                            </div>
                            {/* {item?.item_name_suggested || "N/A"} */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="min-w-[28%] border-EAECF0 border mb-2 h-fit scrollbar-thin scrollbar-thumb scrollbar-track flex flex-col">
                  <div
                    className="whitespace-nowrap text-sm fw_700 text-475467 bg-F2F4F5
                            border-b-EAECF0 border-b h-[3rem] text-left px-2 flex items-center w-full"
                  >
                    More Suggestions
                  </div>
                  <div className="w-full">
                    {values?.data?.map((item, index) => (
                      <div
                        key={index}
                        className="text-sm h-[4rem] px-3 border-b-EAECF0 overflow-auto border-b  fw_400 text-secondary flex items-center gap-5 scrollbar-rounded cursor-pointer"
                      >
                        {item?.moreSuggestedItemsArray?.length > 0 ? (
                          <>
                            {item?.moreSuggestedItemsArray?.map(
                              (suggestedItem, idx) => (
                                <div
                                  onClick={() => {
                                    const updatedData = [...values.data];
                                    const updatedItem = updatedData[index];
                                    updatedData[index] = {
                                      ...updatedItem,
                                      item_name_suggested:
                                        suggestedItem.item_name,
                                      item_id: suggestedItem.item_id,
                                      item_code: suggestedItem.item_code,
                                      item_sheet_uom_id:
                                        suggestedItem.item_sheet_uom_id,
                                      item_sheet_uom:
                                        suggestedItem.item_sheet_uom,
                                      uom_dropdown: suggestedItem.uom_dropdown,
                                    };
                                    setFieldValue("data", updatedData);
                                  }}
                                  key={`${index}-${idx}`}
                                  className={`text-nowrap cursor-pointer ${
                                    suggestedItem?.item_id === item?.item_id
                                      ? "text-primary border-primary border-[2px]"
                                      : "text-secondary  border-EAECF0 border-[2px]"
                                  } w-fit px-2 flex gap-2 py-[5px] rounded-2xl `}
                                >
                                  {suggestedItem?.item_name}

                                  {parseInt(suggestedItem?.item_quantity) <
                                    1 && (
                                    <img
                                      style={{
                                        objectFit: "cover",
                                        height: 20,
                                        width: 20,
                                      }}
                                      src={outstockimg}
                                      alt="Out of stock"
                                    />
                                  )}
                                </div>
                              )
                            )}
                          </>
                        ) : (
                          <span className="text-000000">
                            No more suggestions
                          </span>
                        )}
                      </div>
                    ))}
                  </div>

                  {/* <table className="my-tab w-full">
                    <thead className="sticky top-0 z-10">
                      <tr className="bg-F2F4F5">
                        <th className="border-b-EAECF0 border-b h-[3rem] text-left px-2 whitespace-nowrap text-sm fw_700 text-475467 bg-F2F4F5">
                          More Suggestions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {values?.data?.map((item, index) => (
                        <tr c key={index}>
                          <td className="text-sm h-[4rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary flex items-center gap-5">
                            {item?.moreSuggestedItemsArray?.length > 0 ? (
                              <>
                                {" "}
                                {item?.moreSuggestedItemsArray?.map(
                                  (suggestedItem, idx) => (
                                    <div
                                      onClick={() => {
                                        const updatedData = [...values.data];
                                        const updatedItem = updatedData[index];
                                        updatedData[index] = {
                                          ...updatedItem,
                                          item_name_suggested:
                                            suggestedItem.item_name,
                                          item_id: suggestedItem.item_id,
                                          item_code: suggestedItem.item_code,
                                          item_sheet_uom_id:
                                            suggestedItem.item_sheet_uom_id,
                                          item_sheet_uom:
                                            suggestedItem.item_sheet_uom,
                                          uom_dropdown:
                                            suggestedItem.uom_dropdown,
                                        };
                                        setFieldValue("data", updatedData);
                                      }}
                                      key={`${index}-${idx}`}
                                      className={`text-nowrap cursor-pointer ${
                                        suggestedItem?.item_id === item?.item_id
                                          ? "text-primary border-primary border-[2px]"
                                          : "text-secondary  border-EAECF0 border-[2px]"
                                      } w-fit px-2 flex gap-2 py-[5px] rounded-2xl mb-2`}
                                    >
                                      {suggestedItem?.item_name}

                                      {parseInt(suggestedItem?.item_quantity) <
                                        1 && (
                                        <img
                                          style={{
                                            objectFit: "cover",
                                            height: 20,
                                            width: 20,
                                          }}
                                          src={outstockimg}
                                          alt="Out of stock"
                                        />
                                      )}
                                    </div>
                                  )
                                )}
                              </>
                            ) : (
                              <span className="text-000000">
                                No more suggestions
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                </div>
              </div>
              <div className="flex justify-between invoice-bottom-container w-full py-4 px-4 items-center h-[4rem] ">
                <div className="flex gap-3 fw_600"></div>
                <AddButton prefixIcon={false} text="Continue" type="submit" />
              </div>
            </Form>
          );
        }}
      </Formik>
    </InnerLayout>
  );
};

export default PurchaseOrderCsv;
const tableHeading = [
  { label: "SR No", value: "sl_no" },
  { label: "Items From  CSV", value: "item_product_name" },
  { label: "Qty ", value: "item_generic_name" },
  { label: "MRP ", value: "item_unit_price" },
  { label: "UOM", value: "item_code" },
  { label: "Suggested Item ", value: "item_pack_size" },
];
