import React from "react";
import Select from "react-select";
import AddButton from "../buttons/button";
import PaginationLeftIcon, {
  PaginationRightIcon,
} from "../../assets/icons/pagination-rigth-left-icon";

const Pagination = ({ paginationProps }) => {
  const { pageSize, dataCount, setCurrentPage, currentPage, setPageSize } =
    paginationProps;

  const totalPages = Math.ceil(dataCount / pageSize);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const getPaginationText = (currentPage, pageSize, dataCount) => {
    if (dataCount === 0) return "0-0 of 0"; // Handle empty data case
    const start = (currentPage - 1) * pageSize + 1;
    const end = Math.min(currentPage * pageSize, dataCount);
    return `Showing  ${start} - ${end}  of  ${dataCount}`;
  };

  return (
    <div
      style={{
        bottom: "0",
      }}
      className="flex justify-between sticky  items-center py-[16px]"
    >
      <p className="text-[var(--list-pagination-text)] text-[length:--list-pagination-font-size] font-[var(--list-pagination-font-weight)]">
        {getPaginationText(currentPage, pageSize, dataCount)}
      </p>

      <div className="flex gap-[15px] items-center">
        <div className="flex items-center gap-[8px]">
          <Select
            menuPlacement="auto"
            className="border border-[var(--list-pagination-select-border)] border-radius bg-[var(--list-pagination-select-bg)] text-[var(--list-pagination-select-fg)] text-[length:--list-pagination-font-size] font-[var(--list-pagination-font-weight)] "
            menuPortalTarget={document.body}
            styles={customStyles}
            value={{ label: pageSize, value: pageSize }} // Fix: Controlled value
            onChange={(sel) => {
              setPageSize(sel.value);
              setCurrentPage(1); // Fix: Reset to first page when changing page size
            }}
            options={[
              { label: 10, value: 10 },
              { label: 20, value: 20 },
              { label: 30, value: 30 },
              { label: 40, value: 40 },
              { label: 50, value: 50 },
              { label: 60, value: 60 },
              { label: 70, value: 70 },
              { label: 80, value: 80 },
            ]}
            placeholder="Rows per page"
            isSearchable={false}
          />
          Rows / Page
        </div>
        <div className="flex gap-[8px]">
          <AddButton
            isDisabled={currentPage === 1}
            className="flex items-center px-[12px] h-[32px] w-[32px] whitespace-nowrap border-radius  gap-[8px] bg-[var(--list-pagination-button-bg)]  cursor-pointer  hover:bg-[var(--list-pagination-button-hover-bg)]"
            icon={<PaginationLeftIcon />}
            onclkFn={() => handlePageChange(currentPage - 1)}
            text={""}
          />
          <AddButton
            icon={<PaginationRightIcon />}
            isDisabled={currentPage === totalPages}
            className="flex items-center px-[12px] h-[32px] w-[32px] whitespace-nowrap border-radius  gap-[8px] bg-[var(--list-pagination-button-bg)]  cursor-pointer  hover:bg-[var(--list-pagination-button-hover-bg)]"
            onclkFn={() => handlePageChange(currentPage + 1)}
            text={""}
          />
        </div>
      </div>
    </div>
  );
};

export default Pagination;

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontWeight: "600",
    
    // backgroundColor: "var(--list-pagination-select-bg)",
    padding: "6px 10px",
    // width: "5rem",
    minHeight: "25px",
    boxShadow: "none", // Remove box shadow
    border: "none",
    borderRadius: "6px",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide indicator separator
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "400",
    color: "var(--list-pagination-select-fg)",
    // fontSize: "12px",
  }),
  option: (provided, state) => {
    return {
      ...provided,
      ...(state.data.color ? { color: state.data.color } : { color: "black" }),
      backgroundColor: state.isSelected ? "#e5ecffb2" : null,
      borderBottom: "1px solid #F5F5F5",
      fontSize: "12px",
      fontFamily: "Inter,sans-serif",
      "&:hover": {
        backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
      },
    };
  },

  indicatorContainer: (provided) => ({
    ...provided,
    padding: 0, // Remove padding
  }),
  dropdownIndicator: (provided) => ({
    ...provided,

    fontSize: "14px",
    padding: 0, // Removes padding from the dropdown icon
    fontWeight: "400",
  }),
};
