import React from "react";

const PersonIcon = ({ fill = "black", height = "14px", width = "14px" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_11021_37479)">
        <path
          d="M8.09998 14.6667C11.7819 14.6667 14.7666 11.6819 14.7666 8.00004C14.7666 4.31814 11.7819 1.33337 8.09998 1.33337C4.41808 1.33337 1.43331 4.31814 1.43331 8.00004C1.43331 11.6819 4.41808 14.6667 8.09998 14.6667Z"
          stroke={fill}
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.09998 8.66663C9.20455 8.66663 10.1 7.7712 10.1 6.66663C10.1 5.56206 9.20455 4.66663 8.09998 4.66663C6.99541 4.66663 6.09998 5.56206 6.09998 6.66663C6.09998 7.7712 6.99541 8.66663 8.09998 8.66663Z"
          stroke={fill}
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.76664 13.7747V12.6667C4.76664 12.3131 4.90712 11.9739 5.15716 11.7239C5.40721 11.4739 5.74635 11.3334 6.09997 11.3334H10.1C10.4536 11.3334 10.7927 11.4739 11.0428 11.7239C11.2928 11.9739 11.4333 12.3131 11.4333 12.6667V13.7747"
          stroke={fill}
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11021_37479">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.0999756)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PersonIcon;
