import { all } from "redux-saga/effects";
import {
  bounceReportWatcher,
  branchWiseSalesReportWatcher,
  customerPurchaseBehaviorReportWatcher,
  customerPurchaseFreqReportWatcher,
  dailySalesReportWatcher,
  discountAnalysisReportWatcher,
  doctorWiseSalesReportWatcher,
  expiryLossReportWatcher,
  gstR1ReportWatcher,
  gstr2AReportWatcher,
  gstr3bReportWatcher,
  inventoryReportWatcher,
  inventorySalesReportWatcher,
  overallSalesReportWatcher,
  partyWiseProfitReportWatcher,
  partyWiseSalesReportWatcher,
  paymentModeWiseSalesReportWatcher,
  productWiseSalesReportWatcher,
  salesReturnReportWatcher,
  scheduledHDrugRegReportWatcher,
  slowAndFastMovingProductsReportWatcher,
  productPurchaseReturnReportWatcher,
  SavedReportsReportWorkertWatcher,
  SavedReportsReportDataWorkertWatcher,
  B2bSalesSummaryReportWatcher,
  StorePurchaseReportWatcher,
  GetFavWorkertWatcher,
  SetFavWorkertWatcher,
  ProductInfoWatcher,
  purchaseOrderReportWatcher,
  ProfitReportWatcher,
  VendorMasterReportWatcher,
  StockLedgerBatchReportWatcher,
  vendorPayableReportWatcher,
  dayWiseReportWatcher,
  ReOrderReportWatcher
} from "../report-saga";

export default function* reportRootSaga() {
  yield all([
    overallSalesReportWatcher(),
    dailySalesReportWatcher(),
    branchWiseSalesReportWatcher(),
    productWiseSalesReportWatcher(),
    productPurchaseReturnReportWatcher(),
    SavedReportsReportWorkertWatcher(),
    SavedReportsReportDataWorkertWatcher(),
    doctorWiseSalesReportWatcher(),
    paymentModeWiseSalesReportWatcher(),
    partyWiseSalesReportWatcher(),

    partyWiseProfitReportWatcher(),
    discountAnalysisReportWatcher(),

    salesReturnReportWatcher(),

    customerPurchaseFreqReportWatcher(),
    customerPurchaseBehaviorReportWatcher(),

    bounceReportWatcher(),
    inventorySalesReportWatcher(),
    inventoryReportWatcher(),
    scheduledHDrugRegReportWatcher(),
    expiryLossReportWatcher(),
    slowAndFastMovingProductsReportWatcher(),
    GetFavWorkertWatcher(),
    SetFavWorkertWatcher(),
    ProductInfoWatcher(),
    ProfitReportWatcher(),
    StockLedgerBatchReportWatcher(),
    purchaseOrderReportWatcher(),
    //gst report
    gstR1ReportWatcher(),
    gstr2AReportWatcher(),
    gstr3bReportWatcher(),
    B2bSalesSummaryReportWatcher(),
    StorePurchaseReportWatcher(),
    VendorMasterReportWatcher(),
    vendorPayableReportWatcher(),
    dayWiseReportWatcher(),
    ReOrderReportWatcher()
  ]);
}
