import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import {
  binLocationListAction,
  storeIdAction,
} from "../../../redux/actions/general-action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { storeDetailsAction } from "../../../redux/actions/invoices-action";
import successToast from "../../helper-components/success-toast";
import errorToast from "../../helper-components/error-toast";
import {
  purchaseCreditEditAction,
  purchaseCreditReceiptCreateAction,
} from "../../../redux/actions/purchase-action";
import moment from "moment";
import SalesPurchaseFinalBillTable, {
  calculateFinalTotal,
  calculateTotal,
  calculateTotalDis,
  calculateTotalTax,
  calculatedTcsTdsAmount,
} from "../../sales-section/sales-purchase-finalBill-table";
import SalesPurchaseHeader from "../../sales-section/sales-purchase-header";
import { useHotkeys } from "react-hotkeys-hook";
import PurchaseItemsTable from "../purchase-items-table";
import PurchaseBatchesPopover from "../purchase-batches-popover1";
import { handlea4Print } from "../../helper-components/helper-function";

const CreateEditViewPurchaseCredit = ({
  orderDetail = [],
  vendorDetails = [],
  disabled = false,
  type = "",
  page = "",
  data,
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [binLocationList, setBinLocationList] = useState([]);
  const rowData = useSelector((state) => state.invoiceRowReducer);
  const searchRef = useRef(null);
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  const [tcsTdsAmount, setTcsTdsAmount] = useState("");
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight = document.querySelector(".first-div").clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);
  const initialData = {
    sl_no: 1,
    item_name: "",
    item_generic_name: "",
    item_code: "",
    item_uom: "",
    item_hsn: "",
    item_manufacturer_name: "",
    item_batches: "",
    // item_unit_price: 0,
    item_credit_quanity_remaining: 0,
    quantity: 0,
    item_pack_size: 0,

    item_free_quantity: 0,
    item_discount_amount: 0,
    item_tax_amount: 0,
    item_total_amount: 0,
    item_id: "",
    item_gst: 0,
    item_sgst: 0,
    item_cgst: 0,
    item_tax_amount: 0,
    pot_id: null,
    item_uom_id: "",
    item_manufacturer_id: "",
  };
  const initialFormValues =
    rowData?.data?.length >= 1
      ? {
          ...rowData,
          data: [...rowData.data, initialData],
        }
      : {
          cmr_code: vendorDetails?.[0]?.cmr_id
            ? {
                label: vendorDetails?.[0]?.cmr_code ?? "",
                value: vendorDetails?.[0]?.cmr_id ?? "",
              }
            : "",
          cmr_name: vendorDetails?.[0]?.cmr_id
            ? {
                label: vendorDetails?.[0]?.cmr_name,
                value: vendorDetails?.[0]?.cmr_id,
              }
            : "",
          cmr_id: vendorDetails?.[0]?.cmr_id,
          remarks: vendorDetails?.[0]?.remarks ?? "",
          pct_total_discount: vendorDetails?.[0]?.pct_total_discount,
          // pct_total_discount:
          //   type === "return" || type === "invoice"
          //     ? orderDetail.reduce(
          //         (sum, item) =>
          //           sum + (parseFloat(item.item_discount_amount) || 0),
          //         0
          //       )
          //     : vendorDetails?.[0]?.pct_total_discount ?? 0,
          ...(vendorDetails?.[0]?.pct_id
            ? { pct_id: vendorDetails?.[0]?.pct_id }
            : {}),
          pct_order_date: vendorDetails?.[0]?.pct_order_date
            ? moment(vendorDetails?.[0]?.pct_order_date).format("YYYY-MM-DD")
            : moment(new Date()).format("YYYY-MM-DD"),
          pct_document_date: vendorDetails?.[0]?.pct_document_date
            ? moment(vendorDetails?.[0]?.pct_document_date).format("YYYY-MM-DD")
            : moment(new Date()).format("YYYY-MM-DD"),
          pct_delivery_date: vendorDetails?.[0]?.pct_delivery_date
            ? moment(vendorDetails?.[0]?.pct_delivery_date).format("YYYY-MM-DD")
            : moment(new Date()).format("YYYY-MM-DD"),
          pct_round_off_value: vendorDetails?.[0]?.pct_round_off_value ?? 0,
          cmr_tcs: "",
          cmr_tds: "",
          pct_tcs_or_tds_percentage_id:
            vendorDetails?.[0]?.tcs_or_tds_percentage_id ?? "",
          pct_tcs_or_tds_percentage:
            vendorDetails?.[0]?.tcs_or_tds_percentage ?? 0,
          pct_tds_tcs_type: vendorDetails?.[0]?.tds_tcs_type ?? "",
          pct_tcs_or_tds_amount: vendorDetails?.[0]?.tcs_or_tds_amount ?? 0,

          data: [
            ...orderDetail?.map((item, index) => ({
              sl_no: index + 1,
              item_name: item?.item_name,
              item_generic_name: item?.item_generic_name,
              item_code: item?.item_code,
              item_hsn: item?.item_hsn,
              item_manufacturer_name: item?.item_manufacturer_name,
              item_uom: item?.item_uom,
              item_batches:
                item?.itemBatchData?.map((batch) => ({
                  // item_sellable_quantity: batch?.item_sellable_quantity,
                  // item_batch_number: batch?.item_batch_number,
                  // item_exp_date: moment(batch?.item_exp_date).format(
                  //   "YYYY-MM-DD"
                  // ),
                  // item_id: batch?.item_id,

                  item_batch_number: batch?.item_batch_number,
                  item_sellable_quantity: parseInt(
                    batch?.item_sellable_quantity
                  ),
                  item_batch_free_quantity:
                    parseInt(batch?.item_batch_free_quantity) ?? 0,

                  item_batch_purchase_rate:
                    parseFloat(batch?.item_batch_purchase_rate) ?? 0,
                  item_batch_discount_percentage: parseFloat(
                    batch?.item_batch_discount_percentage
                  ),
                  item_batch_discount_amount: parseFloat(
                    batch?.item_batch_discount_amount
                  ),
                  item_batch_tax_percentage: parseFloat(
                    batch?.item_batch_tax_percentage
                  ),
                  item_batch_tax_amount: parseFloat(
                    batch?.item_batch_tax_amount
                  ),
                  item_batch_final_purchase_rate: parseFloat(
                    batch?.item_batch_final_purchase_rate
                  ),
                  item_batch_margin_percentage:
                    parseFloat(batch?.item_batch_margin_percentage) || 0,
                  item_batch_total_purchase_rate: parseFloat(
                    batch?.item_batch_total_purchase_rate
                  ),
                  item_batch_sgst: parseFloat(batch?.item_batch_tax_amount) / 2,
                  item_batch_cgst: parseFloat(batch?.item_batch_tax_amount) / 2,

                  item_batch_unit_price:
                    parseFloat(batch?.item_batch_unit_price) ?? 0,
                  item_exp_date: batch?.item_exp_date,
                  item_id: batch?.item_id,
                  from_bin_location: batch?.from_bin_location,
                  to_bin_location: batch?.to_bin_location,
                  from_bin_id: batch?.from_bin_id,
                  to_bin_id: batch?.to_bin_id,
                })) ?? [],
              // item_unit_price: item?.item_unit_price,
              item_credit_quanity_remaining:
                item?.item_credit_quanity_remaining ?? 0,
              quantity:
                page === "view" || page === "edit"
                  ? item?.item_quantity
                  : item?.item_credit_quanity_remaining,
              item_pack_size: item?.item_pack_size || 0,
              item_free_quantity: parseInt(item?.item_free_quantity) ?? 0,
              item_discount_amount: item?.item_discount_amount ?? 0,
              item_tax_amount: item?.item_tax_amount ?? 0,
              item_total_amount: parseFloat(item?.item_total_amount),
              // item_total_amount: itemTotalAmt({
              //   ...item,
              //   item_open_quantity: item?.item_credit_quanity_remaining,
              // }),
              item_id: item?.item_id,
              poit_id: item?.poit_id ?? null,
              prt_id: item?.prt_id ?? null,
              // item_tax_amount: itemTotalTax({
              //   ...item,
              //   item_open_quantity: item?.item_credit_quanity_remaining,
              // }),
              item_sgst: item?.item_sgst,
              item_cgst: item?.item_cgst,
              // item_sgst:
              //   itemTotalTax({
              //     ...item,
              //     item_open_quantity: item?.item_credit_quanity_remaining,
              //   }) / 2,
              // item_cgst:
              //   itemTotalTax({
              //     ...item,
              //     item_open_quantity: item?.item_credit_quanity_remaining,
              //   }) / 2,

              item_uom_id: item?.item_uom_id ?? "",
              item_uom: item?.item_uom ?? "",
              item_manufacturer_id: item?.item_manufacturer_id ?? "",
            })),
            initialData,
          ],
        };

  const [initialValue, setInitialValue] = useState(initialFormValues);
  const [reload, setReload] = useState(false);
  const [addbatch, setAddbatch] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [storeId, setStoreId] = useState("");
  const [storeDetails, setStoreDetails] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    cmr_code: Yup.object().required("Customer code number is required"),
    cmr_name: Yup.object().required("Customer name is required"),
    data: Yup.array().of(
      Yup.object().shape({
        item_batches: Yup.array().of(
          Yup.object().shape({
            item_batch_purchase_rate: Yup.number()
              .typeError("Must be a number")
              .required("Purchase rate is required")
              .moreThan(0, "Purchase rate must be greater than 0"),

            item_batch_unit_price: Yup.number()
              .typeError("Must be a number")
              .required("MRP is required")
              .moreThan(0, "MRP must be greater than 0")
              .test(
                "MRP-greater-than-final-purchase-rate",
                "MRP must be greater than the final purchase rate",
                function (value) {
                  const { item_batch_final_purchase_rate } = this?.parent;
                  return value > item_batch_final_purchase_rate;
                }
              ),

            item_sellable_quantity: Yup.number()
              .typeError("Must be a number")
              .integer("Quantity must be an integer")
              .required("Sellable quantity is required")
              .moreThan(0, "Sellable quantity must be greater than 0"),
            item_batch_free_quantity: Yup.number()
              .transform((value) =>
                typeof value === "string" ? parseInt(value, 10) : value
              ) // Transform string to number
              .typeError("Must be a number")
              .integer("Quantity must be an integer"),
            item_batch_number: Yup.string().required(
              "Batch number is required"
            ),
            // to_bin_id: Yup.string().required("Bin ID is required"),
            item_exp_date: Yup.date().required("Expiration date is required"),
          })
        ),
      })
    ),
  });
  useEffect(() => {
    dispatch(storeIdAction(onSuccessStore, onErrorStore));
    dispatch(storeDetailsAction(onSuccessStoreDetails, onErrorStoreDetails));
    dispatch(binLocationListAction(onSuccessBin, onerrorBin));
  }, []);
  const onSuccessBin = (data) => {
    setBinLocationList(
      data?.data?.map((item) => ({
        label: (
          <span
            className={`${
              item?.is_expired_bin_location ||
              item?.is_non_sellable_bin_location
                ? "text-F51D00"
                : ""
            }`}
          >
            {item?.bin_location_no}
            {item?.is_expired_bin_location}
            {item?.is_non_sellable_bin_location}
          </span>
        ),
        value: item?.bin_no_id,
        actualLabel: item?.bin_location_no,
      }))
    );
  };
  const onerrorBin = () => {};
  const onSuccessStore = (data) => {
    setStoreId(data?.data?.store_id);
    setReload(!reload);
  };
  const onErrorStore = () => {};
  const onSuccessStoreDetails = (data) => {
    setStoreDetails(data?.data);
  };
  const onErrorStoreDetails = () => {};
  const handleSubmit = (values, actions) => {
    const missingUOM = values?.data
      ?.slice(0, -1)
      .some((item) => !item.item_uom);
    if (page === "edit") {
      setButtonLoading(true);

      dispatch(
        purchaseCreditEditAction(
          {
            pct_id: values?.pct_id,
            remarks: values?.remarks,
          },
          onSuccessOrder,
          onErrorOrder
        )
      );
    } else {
      if (
        values.data
          ?.slice(0, -1)
          ?.some((item) => item.item_batches.length === 0)
      ) {
        errorToast("Please add batches for all items");
      } else if (
        values?.data
          ?.slice(0, -1)
          .some((item) => parseInt(item.quantity) === 0 || item.quantity === "")
      ) {
        errorToast("Quantity cannot be zero");
      } else if (missingUOM) {
        errorToast("Please add UOM for all items");
      } else {
        if (values?.data?.length > 1) {
          setButtonLoading(true);

          dispatch(
            purchaseCreditReceiptCreateAction(
              {
                orderData: {
                  store_id: storeId,
                  ...(values?.pct_id ? { pct_id: values?.pct_id } : {}),
                  pct_total_gst: parseFloat(
                    values.data
                      ?.slice(0, -1)
                      .reduce(
                        (total, item) =>
                          total + parseFloat(item?.item_tax_amount),
                        0
                      )
                  ),
                  pct_total_discount: calculateTotalDis(values?.data)?.toFixed(
                    2
                  ),
                  pct_payment_status: "paid",
                  pct_transaction_id: "",
                  pct_billing_address: "",
                  pct_total_amount: Math.round(
                    calculateFinalTotal(values, tcsTdsAmount, "pct")
                  )?.toFixed(2),
                  pct_sub_total: (
                    parseFloat(calculateTotal(values?.data)) -
                    parseFloat(calculateTotalTax(values?.data)) -
                    calculateTotalDis(values?.data)
                  ).toFixed(2),
                  cmr_code: values?.cmr_code?.label,
                  cmr_id: values?.cmr_id,
                  cmr_name: values?.cmr_name?.label,
                  remarks: values?.remarks,
                  // pct_order_date: values?.pct_order_date,
                  pct_document_date: values?.pct_document_date,
                  pct_delivery_date: values?.pct_delivery_date,
                  pct_round_off_value: (
                    Math.round(
                      calculateFinalTotal(values, tcsTdsAmount, "pct")
                    ) - calculateFinalTotal(values, tcsTdsAmount, "pct")
                  )?.toFixed(2),
                  pct_tcs_or_tds_percentage_id:
                    values?.pct_tcs_or_tds_percentage_id ?? "",
                  pct_tcs_or_tds_percentage:
                    values?.pct_tcs_or_tds_percentage ?? 0,
                  pct_tds_tcs_type: values?.pct_tds_tcs_type ?? "",
                  pct_tcs_or_tds_amount:
                    calculatedTcsTdsAmount(values, tcsTdsAmount, "pct") ?? 0,
                },
                itemData: values.data?.slice(0, -1).map((item, index) => ({
                  item_code: item?.item_code,
                  item_id: item?.item_id,
                  item_generic_name: item?.item_generic_name,
                  item_name: item?.item_name,
                  item_hsn: item?.item_hsn,
                  item_uom: item?.item_uom,
                  // item_unit_price: parseFloat(item?.item_unit_price),
                  item_quantity: parseInt(item?.quantity),
                  item_pack_size: parseFloat(item.item_pack_size),

                  // item_received_quantity: parseInt(item?.quantity),
                  item_free_quantity: item?.item_batches?.reduce(
                    (sum, batch) =>
                      sum + (parseInt(batch.item_batch_free_quantity, 10) || 0),
                    0
                  ),
                  item_total_amount: parseFloat(item.item_total_amount),
                  item_tax_amount: item?.item_tax_amount ?? 0,
                  item_discount_amount: item?.item_discount_amount ?? 0,
                  item_gst: item?.item_gst,
                  item_cgst: item?.item_cgst,
                  item_sgst: item?.item_sgst,
                  ...(item?.poit_id && type === "invoice"
                    ? { poit_id: item?.poit_id }
                    : { poit_id: null }),
                  ...(item?.prt_id && type === "return"
                    ? { prt_id: item?.prt_id }
                    : { prt_id: null }),
                  item_uom_id: item?.item_uom_id,
                  item_uom: item?.item_uom,
                  item_manufacturer_id: item?.item_manufacturer_id ?? "",
                  item_manufacturer_name: item?.item_manufacturer_name ?? "",
                })),
                itemBatchData: values.data
                  ?.slice(0, -1)
                  .map((item) => item.item_batches)
                  .flat()
                  .map((batch) => ({
                    item_batch_number: batch?.item_batch_number,
                    item_sellable_quantity: parseInt(
                      batch?.item_sellable_quantity
                    ),
                    item_batch_free_quantity: batch?.item_batch_free_quantity,
                    item_batch_purchase_rate: batch?.item_batch_purchase_rate,
                    item_batch_discount_percentage:
                      batch?.item_batch_discount_percentage,
                    item_batch_discount_amount:
                      batch?.item_batch_discount_amount,
                    item_batch_tax_percentage: batch?.item_batch_tax_percentage,
                    item_batch_tax_amount: batch?.item_batch_tax_amount,
                    item_batch_final_purchase_rate:
                      batch?.item_batch_final_purchase_rate,
                    item_batch_margin_percentage:
                      parseFloat(batch?.item_batch_margin_percentage) ?? 0,
                    item_batch_total_purchase_rate:
                      batch?.item_batch_total_purchase_rate,
                    item_batch_unit_price: batch?.item_batch_unit_price,
                    item_exp_date: batch?.item_exp_date,
                    item_id: batch?.item_id,
                    from_bin_location: batch?.from_bin_location,
                    to_bin_location: batch?.to_bin_location,
                    from_bin_id: batch?.from_bin_id,
                    to_bin_id: batch?.to_bin_id,
                  })),
              },
              onSuccessOrder,
              onErrorOrder
            )
          );
        }
      }
    }
  };
  const onSuccessOrder = (data) => {
    setButtonLoading(false);

    navigate("/purchase-credit");
    if (formValues?.submittedButton === "print") {
      handlea4Print(
        data?.data?.orderData?.pct_id,
        "purchase_credit_note",
        dispatch
      );
    }
    successToast(data?.message);
    setInitialValue({
      cmr_code: "",
      cmr_name: "",
      remarks: "",
      pct_total_discount: 0,
      pct_order_date: null,
      pct_document_date: null,
      pct_delivery_date: null,
      data: [initialData],
    });
  };
  const onErrorOrder = (data) => {
    setButtonLoading(false);

    errorToast(data?.data?.message);
  };

  useHotkeys("escape", () => {
    setAddbatch(false);
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleSubmit(values, actions); // Pass values and actions if needed
            actions.setSubmitting(false); // Notify Formik that submission is done
          }, 500);
        }}
      >
        {({ values, setFieldValue }) => {
          setFormValues(values);

          return (
            <Form className="h-full">
              <div className="flex flex-col h-full ">
                <div class="flex flex-wrap justify-between first-div px-3">
                  <SalesPurchaseHeader
                    section="purchase"
                    name="pct"
                    disabled={disabled}
                    searchRef={searchRef}
                  />
                </div>
                {addbatch && (
                  // <BatchQuantityExpiryPopoverPurINS
                  <PurchaseBatchesPopover
                    disabled={disabled}
                    disName="pct_total_discount"
                    binLocationList={binLocationList}
                    setItemId={setItemId}
                    itemId={itemId}
                    setAddbatch={setAddbatch}
                    itemValues={values}
                  />
                )}
                <div
                  className="flex flex-col "
                  style={{
                    maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
                    height: `calc(100% - ${heightOfFirstDiv}px)`,
                  }}
                >
                  {/* <PurchaseCreditBillingTable */}
                  <PurchaseItemsTable
                    disabled={disabled}
                    addBatchFn={(id) => {
                      setAddbatch(true);
                      setItemId(id);
                    }}
                    searchRef={searchRef}
                    initialData={initialData}
                  />
                  <div className="h-[18rem] mt-auto">
                    <SalesPurchaseFinalBillTable
                      transaction="Purchase Credit Note"
                      transaction_type="purchase_credit_note"
                      buttonLoading={buttonLoading}
                      name="pct_total_discount"
                      page={page}
                      disabled={disabled}
                      prefixName="pct"
                      tcsTdsAmount={tcsTdsAmount}
                      setTcsTdsAmount={setTcsTdsAmount}
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateEditViewPurchaseCredit;
