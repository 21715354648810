import {
  ADD_ITEM_CATEGORY,
  ADD_ITEM_GROUP,
  ADD_ITEM_TYPE,
  ADD_MANUFACTURER,
  ADD_SHIPPING_TYPE,
  ADD_TAX_COMBINATION,
  ADD_TAX_TYPE,
  ADD_UOM,
  ADD_UOM_GROUP,
  ALTERNATIVE_ITEMS_LIST,
  CURRENT_PRINTPREFERENCE,
  DELETE_ALTERNATIVE_TRANSACTION,
  DELETE_ITEM_CATEGORY,
  DELETE_ITEM_GROUP,
  DELETE_ITEM_TYPE,
  DELETE_MANUFACTURER,
  DELETE_SHIPPING_TYPE,
  DELETE_TAX_COMBINATION,
  DELETE_TAX_COMBINATION_ITEM,
  DELETE_TAX_TYPE,
  DELETE_UOM_GROUP,
  DELETE_UOM_GROUP_ITEM,
  DOCUMENT_NUMBERING_LIST,
  DOCUMENT_NUMBERING_SERIES_ADD,
  DOCUMENT_NUMBERING_SERIES_LIST,
  DOCUMENT_NUMBERING_SERIES_UPDATE,
  DOCUMENT_NUMBERING_UPDATE,
  ITEM_CATEGORY_LIST,
  ITEM_GROUP_TABLE_LIST,
  ITEM_TYPE_LIST,
  LIST_UOM,
  MANUFACTURERS_TABLE_LIST,
  MAP_ALTERNATIVE_ITEM,
  PAYTM_ADD,
  PAYTM_UPDATE,
  PAYTM_VIEW,
  PHONEPE_ADD,
  PHONEPE_UPDATE,
  PHONEPE_VIEW,
  PINE_LABS_ADD,
  PINE_LABS_UPDATE,
  PINE_LABS_VIEW,
  PRINTPREFERENCE_LIST,
  PRINTPREFERENCE_UPDATE,
  RAZORPAY_ADD,
  RAZORPAY_UPDATE,
  RAZORPAY_VIEW,
  SHIPPING_TYPE_TABLE__LIST,
  SMS_ADD,
  SMS_UPDATE,
  SMS_VIEW,
  SMTP_ADD,
  SMTP_UPDATE,
  SMTP_VIEW,
  STORE_GET,
  STORE_UPDATE,
  STRIPE_ADD,
  STRIPE_UPDATE,
  STRIPE_VIEW,
  TAX_COMBINATION_LIST,
  TAX_TYPE_DROPDOWN,
  TAX_TYPE_LIST,
  TCS_TAX_ADD,
  TCS_TAX_DELETE,
  TCS_TAX_LIST,
  TDS_TAX_ADD,
  TDS_TAX_DELETE,
  TDS_TAX_LIST,
  TEMPLATE_CREATE,
  TEMPLATE_DELETE,
  TEMPLATE_EDIT,
  TEMPLATE_TABLE,
  TEMPLATE_VIEW,
  UOM_DROPDOWN,
  UOM_GROUP_TABLE_LIST,
  UPDATE_ITEM_CATEGORY,
  UPDATE_ITEM_GROUP,
  UPDATE_ITEM_TYPE,
  UPDATE_MANUFACTURER,
  UPDATE_SHIPPING_TYPE,
  UPDATE_TAX_COMBINATION,
  UPDATE_TAX_TYPE,
  UPDATE_UOM,
  UPDATE_UOM_GROUP,
  WHATSAPP_ADD,
  WHATSAPP_UPDATE,
  WHATSAPP_VIEW,
} from "../types";

/**UOM Setup */
export const uomCreateAction = (data, onSuccess, onError) => {
  return {
    type: ADD_UOM,
    data,
    onSuccess,
    onError,
  };
};

export const uomUpdateAction = (data, onSuccess, onError) => {
  return {
    type: UPDATE_UOM,
    data,
    onSuccess,
    onError,
  };
};

export const uomListAction = (data, onSuccess, onError) => {
  return {
    type: LIST_UOM,
    data,
    onSuccess,
    onError,
  };
};

export const uomGroupTableListAction = (data, onSuccess, onError) => {
  return {
    type: UOM_GROUP_TABLE_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const uomGroupAddAction = (data, onSuccess, onError) => {
  return {
    type: ADD_UOM_GROUP,
    data,
    onSuccess,
    onError,
  };
};

export const uomGroupUpdateAction = (data, onSuccess, onError) => {
  return {
    type: UPDATE_UOM_GROUP,
    data,
    onSuccess,
    onError,
  };
};

export const uomGroupDeleteAction = (data, onSuccess, onError) => {
  return {
    type: DELETE_UOM_GROUP,
    data,
    onSuccess,
    onError,
  };
};

export const deleteUomGroupItemAction = (data, onSuccess, onError) => {
  return {
    type: DELETE_UOM_GROUP_ITEM,
    data,
    onSuccess,
    onError,
  };
};

export const uomDropDownAction = (data, onSuccess, onError) => {
  return {
    type: UOM_DROPDOWN,
    data,
    onSuccess,
    onError,
  };
};

/**Item Setup */

export const itemTypeListAction = (data, onSuccess, onError) => {
  return {
    type: ITEM_TYPE_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const addItemTypeAction = (data, onSuccess, onError) => {
  return {
    type: ADD_ITEM_TYPE,
    data,
    onSuccess,
    onError,
  };
};

export const updateItemTypeAction = (data, onSuccess, onError) => {
  return {
    type: UPDATE_ITEM_TYPE,
    data,
    onSuccess,
    onError,
  };
};

export const deleteItemTypeAction = (data, onSuccess, onError) => {
  return {
    type: DELETE_ITEM_TYPE,
    data,
    onSuccess,
    onError,
  };
};

export const itemCategoryListAction = (data, onSuccess, onError) => {
  return {
    type: ITEM_CATEGORY_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const addItemCategoryAction = (data, onSuccess, onError) => {
  return {
    type: ADD_ITEM_CATEGORY,
    data,
    onSuccess,
    onError,
  };
};

export const updateItemCategoryAction = (data, onSuccess, onError) => {
  return {
    type: UPDATE_ITEM_CATEGORY,
    data,
    onSuccess,
    onError,
  };
};

export const deleteItemCategoryAction = (data, onSuccess, onError) => {
  return {
    type: DELETE_ITEM_CATEGORY,
    data,
    onSuccess,
    onError,
  };
};

export const itemGroupTableListAction = (data, onSuccess, onError) => {
  return {
    type: ITEM_GROUP_TABLE_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const addItemGroupAction = (data, onSuccess, onError) => {
  return {
    type: ADD_ITEM_GROUP,
    data,
    onSuccess,
    onError,
  };
};

export const updateItemGroupAction = (data, onSuccess, onError) => {
  return {
    type: UPDATE_ITEM_GROUP,
    data,
    onSuccess,
    onError,
  };
};

export const deleteItemGroupAction = (data, onSuccess, onError) => {
  return {
    type: DELETE_ITEM_GROUP,
    data,
    onSuccess,
    onError,
  };
};

/**Manufacturer setup */
export const manufacturerTableListAction = (data, onSuccess, onError) => {
  return {
    type: MANUFACTURERS_TABLE_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const addManufacturerAction = (data, onSuccess, onError) => {
  return {
    type: ADD_MANUFACTURER,
    data,
    onSuccess,
    onError,
  };
};

export const updateManufacturerAction = (data, onSuccess, onError) => {
  return {
    type: UPDATE_MANUFACTURER,
    data,
    onSuccess,
    onError,
  };
};

export const deleteManufacturerAction = (data, onSuccess, onError) => {
  return {
    type: DELETE_MANUFACTURER,
    data,
    onSuccess,
    onError,
  };
};

/***Tax Setup */

export const taxTypeListAction = (data, onSuccess, onError) => {
  return {
    type: TAX_TYPE_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const addTaxTypeAction = (data, onSuccess, onError) => {
  return {
    type: ADD_TAX_TYPE,
    data,
    onSuccess,
    onError,
  };
};

export const updateTaxTypeAction = (data, onSuccess, onError) => {
  return {
    type: UPDATE_TAX_TYPE,
    data,
    onSuccess,
    onError,
  };
};

export const deleteTaxTypeAction = (data, onSuccess, onError) => {
  return {
    type: DELETE_TAX_TYPE,
    data,
    onSuccess,
    onError,
  };
};

export const taxCombinationListAction = (data, onSuccess, onError) => {
  return {
    type: TAX_COMBINATION_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const addTaxCombinationAction = (data, onSuccess, onError) => {
  return {
    type: ADD_TAX_COMBINATION,
    data,
    onSuccess,
    onError,
  };
};

export const updateTaxCombinationAction = (data, onSuccess, onError) => {
  return {
    type: UPDATE_TAX_COMBINATION,
    data,
    onSuccess,
    onError,
  };
};

export const deleteTaxCombinationAction = (data, onSuccess, onError) => {
  return {
    type: DELETE_TAX_COMBINATION,
    data,
    onSuccess,
    onError,
  };
};

export const deleteTaxCombinationItemAction = (data, onSuccess, onError) => {
  return {
    type: DELETE_TAX_COMBINATION_ITEM,
    data,
    onSuccess,
    onError,
  };
};

export const taxTypeDropDownAction = (data, onSuccess, onError) => {
  return {
    type: TAX_TYPE_DROPDOWN,
    data,
    onSuccess,
    onError,
  };
};

/**Shipping Type */

export const ShippingTypeTableListAction = (data, onSuccess, onError) => {
  return {
    type: SHIPPING_TYPE_TABLE__LIST,
    data,
    onSuccess,
    onError,
  };
};

export const addShippingTypeAction = (data, onSuccess, onError) => {
  return {
    type: ADD_SHIPPING_TYPE,
    data,
    onSuccess,
    onError,
  };
};

export const updateShippingTypeAction = (data, onSuccess, onError) => {
  return {
    type: UPDATE_SHIPPING_TYPE,
    data,
    onSuccess,
    onError,
  };
};

export const deleteShippingTypeAction = (data, onSuccess, onError) => {
  return {
    type: DELETE_SHIPPING_TYPE,
    data,
    onSuccess,
    onError,
  };
};

/**Document Numbering */
export const documentNumberingListAction = (data, onSuccess, onError) => {
  return {
    type: DOCUMENT_NUMBERING_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const documentNumberingUpdateAction = (data, onSuccess, onError) => {
  return {
    type: DOCUMENT_NUMBERING_UPDATE,
    data,
    onSuccess,
    onError,
  };
};

export const documentNumberingSeriesListAction = (data, onSuccess, onError) => {
  return {
    type: DOCUMENT_NUMBERING_SERIES_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const documentNumberingSeriesAddAction = (data, onSuccess, onError) => {
  return {
    type: DOCUMENT_NUMBERING_SERIES_ADD,
    data,
    onSuccess,
    onError,
  };
};

export const documentNumberingSeriesUpdateAction = (
  data,
  onSuccess,
  onError
) => {
  return {
    type: DOCUMENT_NUMBERING_SERIES_UPDATE,
    data,
    onSuccess,
    onError,
  };
};

/**Communication Setup */

/**SMTP */
export const smtpViewAction = (data, onSuccess, onError) => {
  return {
    type: SMTP_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const smtpAddAction = (data, onSuccess, onError) => {
  return {
    type: SMTP_ADD,
    data,
    onSuccess,
    onError,
  };
};

export const smtpUpdateAction = (data, onSuccess, onError) => {
  return {
    type: SMTP_UPDATE,
    data,
    onSuccess,
    onError,
  };
};

/**Whatsapp */
export const whatsappViewAction = (data, onSuccess, onError) => {
  return {
    type: WHATSAPP_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const whatsappAddAction = (data, onSuccess, onError) => {
  return {
    type: WHATSAPP_ADD,
    data,
    onSuccess,
    onError,
  };
};

export const whatsappUpdateAction = (data, onSuccess, onError) => {
  return {
    type: WHATSAPP_UPDATE,
    data,
    onSuccess,
    onError,
  };
};

export const smsViewAction = (data, onSuccess, onError) => {
  return {
    type: SMS_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const smsAddAction = (data, onSuccess, onError) => {
  return {
    type: SMS_ADD,
    data,
    onSuccess,
    onError,
  };
};

export const smsUpdateAction = (data, onSuccess, onError) => {
  return {
    type: SMS_UPDATE,
    data,
    onSuccess,
    onError,
  };
};

/**Payment COnfig */

/**Razor Pay */
export const razorpayViewAction = (data, onSuccess, onError) => {
  return {
    type: RAZORPAY_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const razorpayAddAction = (data, onSuccess, onError) => {
  return {
    type: RAZORPAY_ADD,
    data,
    onSuccess,
    onError,
  };
};

export const razorpayUpdateAction = (data, onSuccess, onError) => {
  return {
    type: RAZORPAY_UPDATE,
    data,
    onSuccess,
    onError,
  };
};

/**Stripe */
export const stripeViewAction = (data, onSuccess, onError) => {
  return {
    type: STRIPE_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const stripeAddAction = (data, onSuccess, onError) => {
  return {
    type: STRIPE_ADD,
    data,
    onSuccess,
    onError,
  };
};

export const stripeUpdateAction = (data, onSuccess, onError) => {
  return {
    type: STRIPE_UPDATE,
    data,
    onSuccess,
    onError,
  };
};

/**Phonepe */
export const phonepeViewAction = (data, onSuccess, onError) => {
  return {
    type: PHONEPE_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const phonepeAddAction = (data, onSuccess, onError) => {
  return {
    type: PHONEPE_ADD,
    data,
    onSuccess,
    onError,
  };
};

export const phonepeUpdateAction = (data, onSuccess, onError) => {
  return {
    type: PHONEPE_UPDATE,
    data,
    onSuccess,
    onError,
  };
};

/**Paytm */
export const paytmViewAction = (data, onSuccess, onError) => {
  return {
    type: PAYTM_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const paytmAddAction = (data, onSuccess, onError) => {
  return {
    type: PAYTM_ADD,
    data,
    onSuccess,
    onError,
  };
};

export const paytmUpdateAction = (data, onSuccess, onError) => {
  return {
    type: PAYTM_UPDATE,
    data,
    onSuccess,
    onError,
  };
};

/**Pine Labs */
export const pineLabsViewAction = (data, onSuccess, onError) => {
  return {
    type: PINE_LABS_VIEW,
    data,
    onSuccess,
    onError,
  };
};

export const pineLabsAddAction = (data, onSuccess, onError) => {
  return {
    type: PINE_LABS_ADD,
    data,
    onSuccess,
    onError,
  };
};

export const pineLabsUpdateAction = (data, onSuccess, onError) => {
  return {
    type: PINE_LABS_UPDATE,
    data,
    onSuccess,
    onError,
  };
};

/**Print Preferences */
export const printPreferenceListAction = (data, onSuccess, onError) => {
  return {
    type: PRINTPREFERENCE_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const printPreferenceUpdateAction = (data, onSuccess, onError) => {
  return {
    type: PRINTPREFERENCE_UPDATE,
    data,
    onSuccess,
    onError,
  };
};

export const currentPrintPreferenceAction = (data, onSuccess, onError) => {
  return {
    type: CURRENT_PRINTPREFERENCE,
    data,
    onSuccess,
    onError,
  };
};

export const storeDetailsUpdateAction = (data, onSuccess, onError) => {
  return {
    type: STORE_UPDATE,
    data,
    onSuccess,
    onError,
  };
};
export const storeDetailsGetAction = (data, onSuccess, onError) => {
  return {
    type: STORE_GET,
    data,
    onSuccess,
    onError,
  };
};

export const mapAlternativerItemAction = (data, onSuccess, onError) => {
  return {
    type: MAP_ALTERNATIVE_ITEM,
    data,
    onSuccess,
    onError,
  };
};
export const alternativeItemsListAction = (data, onSuccess, onError) => {
  return {
    type: ALTERNATIVE_ITEMS_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const deleteAlternativeTransAction = (data, onSuccess, onError) => {
  return {
    type: DELETE_ALTERNATIVE_TRANSACTION,
    data,
    onSuccess,
    onError,
  };
};

/* ===============================================================================================*/
/* TCS TAX */

export const tcsTaxListAction = (data, onSuccess, onError) => {
  return {
    type: TCS_TAX_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const tcsTaxCreateAction = (data, onSuccess, onError) => {
  return {
    type: TCS_TAX_ADD,
    data,
    onSuccess,
    onError,
  };
};

export const tcsTaxDeleteAction = (data, onSuccess, onError) => {
  return {
    type: TCS_TAX_DELETE,
    data,
    onSuccess,
    onError,
  };
};
export const tdsTaxListAction = (data, onSuccess, onError) => {
  return {
    type: TDS_TAX_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const tdsTaxCreateAction = (data, onSuccess, onError) => {
  return {
    type: TDS_TAX_ADD,
    data,
    onSuccess,
    onError,
  };
};

export const tdsTaxDeleteAction = (data, onSuccess, onError) => {
  return {
    type: TDS_TAX_DELETE,
    data,
    onSuccess,
    onError,
  };
};

export const templateCreateAction = (data, onSuccess, onError) => {
  return {
    type: TEMPLATE_CREATE,
    data,
    onSuccess,
    onError,
  };
};

export const templateTableAction = (data, onSuccess, onError) => {
  return {
    type: TEMPLATE_TABLE,
    data,
    onSuccess,
    onError,
  };
};

export const templateViewAction = (data, onSuccess, onError) => {
  return {
    type: TEMPLATE_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const templateEditAction = (data, onSuccess, onError) => {
  return {
    type: TEMPLATE_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const templateDeleteAction = (data, onSuccess, onError) => {
  return {
    type: TEMPLATE_DELETE,
    data,
    onSuccess,
    onError,
  };
};
