import { Field, useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";
import TextAreaField from "../forms/textarea-field";
import SelectField from "../forms/select-field";
import { url } from "../../redux/config";
import { api } from "../../redux/apis/api";
import { getBackgroundColor } from "../helper-components/helper-function";
import { useNavigate } from "react-router-dom";

const BillingFinalTable = ({
  disabled = false,
  payModesList,
  formValuesRef,
  handleBilling,
}) => {
  const { values, setFieldValue, handleSubmit, setValues } = useFormikContext();
  const navigate = useNavigate();
  const inputRef1 = useRef(null);
  // const totalInvoiceAmount = isRoundOffReq(values?.paymentData)
  //   ? Math.round(calculateTotal(values))
  //   : calculateTotal(values);

  const totalInvoiceAmount = (paymentMethod, indexToUpdate) => {
    // Clone existing payment data and update the payment method at the given index
    const updatedPaymentData = values?.paymentData?.map((pay, index) =>
      index === indexToUpdate && paymentMethod
        ? { ...pay, payment_mode_name: paymentMethod }
        : pay
    );

    return isRoundOffReq(updatedPaymentData)
      ? Math.round(calculateTotal(values))
      : calculateTotal(values);
  };

  const getAvailablePaymentModes = (index) => {
    // If cmr_credit_balance is greater than 0 and it's the first entry, show only "Credit"
    if (values?.cmr_credit_balance > 0 && index === 0) {
      return payModesList?.filter((mode) => mode.label === "Credit");
    }

    // Exclude "Credit" from the list if balance is 0 or less
    const filteredPayModes = payModesList?.filter(
      (mode) => !(mode.label === "Credit" && values?.cmr_credit_balance <= 0) // Exclude Credit if balance is 0 or less
    );

    // Filter out already selected payment modes
    const selectedPaymentModes = values.paymentData
      ?.filter((_, i) => i !== index)
      .map((item) => item.payment_mode_name);

    return filteredPayModes?.filter(
      (mode) => !selectedPaymentModes.includes(mode.label)
    );
  };

  const totalPaymentAmount =
    parseFloat(values.paymentData[0]?.payment_amount ?? 0) +
    parseFloat(values.paymentData[1]?.payment_amount ?? 0) +
    parseFloat(values.paymentData[2]?.payment_amount ?? 0);

  const initPayment = (data, amount, index) => {
    const options = {
      key: "rzp_test_0tpemkHKm5K1Bc",
      currency: data.currency,
      amount: data.amount,
      name: "ZOTA POS",
      handler: async function (response) {
        response.order_id = data.id;
        const id = response?.razorpay_payment_id;
        setFieldValue(`paymentData[${index}].transaction`, id);
      },
      prefill: {
        name: "Rajat",
        email: "rajat@rajat.com",
        phone_number: "9899999999",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handlePayment = async (amount, index) => {
    try {
      const orderUrl = `${url}/order/payment`;
      const response = await api.post(orderUrl, {
        amount: amount,
      });
      initPayment(response?.data?.order, amount, index);
    } catch (error) {}
  };

  useEffect(() => {
    return () => {
      const nextPath = window.location.hash; // Use hash for hash-based routing
      if (
        !nextPath.includes("/past-orders") &&
        formValuesRef?.current &&
        !formValuesRef?.current?.submittedButton &&
        !disabled
      ) {
        handleBilling({
          ...formValuesRef.current,
          submittedButton: "draft",
          save: "auto",
        });
      }
    };
  }, [navigate]);

  console.log(values, "fgdgdgdggdgdfgdgdgdg");

  return (
    <div className="table-container flex">
      <div
        style={{
          minWidth: `calc(100% - 17rem)`,
        }}
        className="bg-foot-tab-head-bg  flex "
      >
        <div className="w-[40%] border-r-EAECF0 border-r-2 px-4 py-3 ">
          <span className="text-foot-tab-head-color fw_700 text-sm">
            Remarks
          </span>
          <TextAreaField
            defaultInputClass="textAreaField text-secondary focus_outline__none"
            autoFocus={false}
            errMsgContainerClass=""
            isDisabled={disabled || true}
            containerClass="mt-2 text-secondary"
            placeholder="Enter Remarks"
            name="remarks"
            id={"remarks"}
            isRequired={false}
            labelWidth="7rem"
            // feildHeight="5.6rem"
          />
        </div>

        <div className="w-[40%] border-r-EAECF0 border-r-2 px-2 py-3">
          <span className="text-foot-tab-head-color fw_700 text-sm">
            Payment method
          </span>
          <div className="mt-2 flex flex-col gap-2 w-full">
            {values?.paymentData?.map((payment, index) => {
              const totalInvoice = totalInvoiceAmount("");

              // Compute the cumulative sum of previous payments
              const cumulativeAmount = values?.paymentData
                ?.slice(0, index)
                ?.reduce(
                  (sum, p) => sum + (parseFloat(p?.payment_amount) || 0),
                  0
                );

              // Determine whether to show the current index
              const shouldShowIndex =
                index === 0 ||
                (cumulativeAmount < totalInvoice &&
                  payment?.payment_amount !== null);

              if (!shouldShowIndex) return null;

              return (
                <div key={index} className="flex flex-col gap-2 w-full ">
                  <div className="flex gap-2 w-full items-center">
                    {/* Payment Method Selection */}
                    <div style={{ width: "30%" }}>
                      <SelectField
                        general_color={false}
                        handleSelected={(sel) => {
                          if (
                            index === 0 &&
                            sel?.label !== "Credit" &&
                            !values.paymentData.some(
                              (item, i) =>
                                i > 0 && parseFloat(item?.payment_amount) > 0
                            )
                          ) {
                            setFieldValue(
                              `paymentData[0].payment_amount`,
                              totalInvoiceAmount(sel?.value, 0)
                            );
                          }

                          setFieldValue(
                            `paymentData[${index}].payment_mode_name`,
                            sel?.label
                          );
                          setFieldValue(
                            `paymentData[${index}].tender_check`,
                            false
                          );
                        }}
                        defaultSelectClass="text-sm fw_500 fm_inter text-030304 h-[2.5rem] focus_outline__none"
                        isDisabled={disabled || totalInvoice <= 0}
                        defaultValue={
                          payment.payment_mode_name && {
                            label: payment.payment_mode_name,
                          }
                        }
                        errMsgContainerClass="flex justify-start hidden"
                        id={`paymentData[${index}].payment_mode_name`}
                        isContainer={false}
                        options={getAvailablePaymentModes(index)}
                        name={`paymentData[${index}].payment_mode_name`}
                        placeholder="Select"
                        style={{
                          control: (base, state) => ({
                            ...base,
                            color: "#0D111A",
                            borderRadius: "10px",
                            backgroundColor: state.isDisabled
                              ? "#EAECF0"
                              : base.backgroundColor,
                            borderColor: state.isFocused
                              ? "#277dfe"
                              : "transparent",
                          }),
                          input: (base) => ({
                            ...base,
                            margin: "0",
                            padding: "0",
                          }),
                        }}
                      />
                    </div>

                    {/* Payment Amount Input */}
                    <div
                      style={{ height: "2.5rem" }}
                      className="w-[40%] relative"
                    >
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        ₹
                      </span>
                      <Field name={`paymentData[${index}].payment_amount`}>
                        {({ field, form }) => (
                          <input
                            {...field}
                            value={field.value || ""}
                            disabled={
                              disabled ||
                              totalInvoice <= 0 ||
                              !payment.payment_mode_name
                            }
                            {...(payment?.payment_mode_name === "Credit"
                              ? { max: payment?.payment_amount }
                              : {})}
                            type="number"
                            className="w-full h-full pl-7 fw_600 focus_outline__none input-field-no-spin-buttons rounded-lg border-D0D5DD border focus:border-focus-color bg-white disabled:bg-EAECF0"
                            onChange={(e) => {
                              const value = e.target.value;
                              const enteredAmount = parseFloat(value) || 0;
                              form.setFieldValue(field.name, value);

                              // Calculate new cumulative amount including the entered amount
                              const newCumulativeAmount =
                                cumulativeAmount + enteredAmount;

                              // If the total entered amount exceeds the invoice total, mark tender
                              // if (newCumulativeAmount > totalInvoice) {
                              //   setFieldValue(
                              //     `paymentData[${index}].tender_check`,
                              //     true
                              //   );
                              //   setFieldValue(
                              //     `paymentData[${index}].payment_amount`,
                              //     totalInvoice - cumulativeAmount
                              //   );
                              // } else {
                              //   setFieldValue(
                              //     `paymentData[${index}].tender_check`,
                              //     false
                              //   );
                              // }
                              if (payment?.payment_mode_name === "Credit") {
                                const maxCreditAmount =
                                  values?.cmr_credit_balance || 0;

                                if (enteredAmount > maxCreditAmount) {
                                  setFieldValue(
                                    `paymentData[${index}].payment_amount`,
                                    maxCreditAmount
                                  );
                                }
                              }

                              // Dynamically update next index amount if needed
                              if (newCumulativeAmount < totalInvoice) {
                                setFieldValue(
                                  `paymentData[${index + 1}].payment_amount`,
                                  (totalInvoice - newCumulativeAmount).toFixed(
                                    2
                                  )
                                );
                              } else {
                                setFieldValue(
                                  `paymentData[${index + 1}].payment_amount`,
                                  null
                                );
                                setFieldValue(
                                  `paymentData[${index + 1}].payment_mode_name`,
                                  ""
                                );
                              }
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    {payment.payment_mode_name === "Cash" &&
                      (totalInvoiceAmount("") < totalPaymentAmount ||
                        payment.tender_check === true) && (
                        <div
                          style={{
                            backgroundColor: getBackgroundColor(
                              payment.tender_check
                            ),
                          }}
                          className={`px-2 flex gap-2 items-center rounded-lg text-nowrap ${
                            payment.tender_check
                              ? "border border-primary"
                              : "border border-D0D5DD"
                          }`}
                        >
                          <Field name={`paymentData[${index}].tender_check`}>
                            {({ field }) => (
                              <input
                                {...field}
                                checked={field.value}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  setFieldValue(
                                    `paymentData[${index}].tender_check`,
                                    isChecked
                                  );
                                  if (isChecked) {
                                    setFieldValue(
                                      `paymentData[${index}].payment_amount`,
                                      (
                                        parseFloat(payment.payment_amount) -
                                        (parseFloat(totalPaymentAmount) -
                                          parseFloat(totalInvoiceAmount("")))
                                      ).toFixed(2)
                                    );
                                  }
                                }}
                                type="checkbox"
                              />
                            )}
                          </Field>

                          <span
                            className={`${
                              payment.tender_check
                                ? "text-primary"
                                : "text-F51D00"
                            }`}
                          >
                            -{" "}
                            {(
                              parseFloat(totalPaymentAmount) -
                              parseFloat(totalInvoiceAmount(""))
                            ).toFixed(2)}
                          </span>
                        </div>
                      )}

                    {payment.payment_mode_name === "Credit" && (
                      <span className="text-000000 fw_500 text-sm">
                        {parseFloat(values?.cmr_credit_balance) > 0
                          ? (
                              (values?.cmr_credit_balance || 0) -
                              (values?.paymentData?.find(
                                (pay) => pay.payment_mode_name === "Credit"
                              )?.payment_amount || 0)
                            ).toFixed(2)
                          : 0}
                        (cb/db)
                      </span>
                    )}
                  </div>

                  {/* Tender Checkbox */}
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex flex-col justify-between xl:px-5 w-[20%] text-sm border-r-EAECF0 border-r-2 px-4 py-3 fw_500 text-secondary whitespace-nowrap">
          <span className="text-foot-tab-head-color  fw_700 text-sm">
            GST Information
          </span>
          <div className="w-full flex justify-between ">
            <span>CGST (₹)</span>{" "}
            <span className="text-000000">
              {calculateTotalCGST(values?.data).toFixed(2)}
            </span>
          </div>
          <div className="w-full flex justify-between ">
            <span>SGST (₹)</span>{" "}
            <span className="text-000000">
              {" "}
              {calculateTotalSGST(values?.data).toFixed(2)}
            </span>
          </div>
          <div className="w-full flex justify-between ">
            <span>IGST (₹)</span>{" "}
            <span className="text-000000">
              {calculateTotalIGST(values?.data).toFixed(2)}
            </span>
          </div>
        </div>
      </div>
      <div
        className="bg-foot-tab-head-bg text-sm fw_500 text-secondary"
        style={{
          minWidth: "17rem",
        }}
      >
        <div className="pt-2 pb-1 px-4 flex justify-between items-center">
          <span>Sub Total (₹)</span>
          <span>
            {" "}
            <input
              disabled={true}
              className="text-end w-[8rem] px-2 py-1 border border-D0D5DD rounded-md text-000000"
              type="text"
              value={`${calculateSubtotal(values?.data).toFixed(2)}`}
            />
          </span>
        </div>
        <div className="py-1 px-4 flex justify-between items-center">
          <span>Tax (₹)</span>
          <span>
            <input
              disabled={true}
              className="text-end w-[8rem] px-2 py-1 border border-D0D5DD rounded-md text-000000"
              type="text"
              value={`${calculateTotalTax(values?.data).toFixed(2)}`}
            />
          </span>
        </div>
        <div className="py-1 px-4 flex justify-between items-center">
          <span>Discount (₹)</span>
          <span>
            <input
              value={calculateTotalDiscount(values?.data).toFixed(2)}
              disabled={true}
              className="text-end w-[8rem] px-2 py-1 rounded-md border focus:placeholder-transparent focus:outline-none border-D0D5DD input-field-no-spin-buttons text-000000"
              type="text"
            />
          </span>
        </div>
        {isRoundOffReq(values?.paymentData) && (
          <div className="py-1 px-4 flex justify-between items-center">
            <span>Round off (+/-)</span>
            <span>
              <input
                value={(
                  Math.round(calculateTotal(values)) - calculateTotal(values)
                )?.toFixed(2)}
                disabled={true}
                className="text-end w-[8rem] px-2 py-1 rounded-md border focus:placeholder-transparent focus:outline-none border-D0D5DD input-field-no-spin-buttons text-000000"
                type="text"
              />
            </span>
          </div>
        )}

        <div className="py-1 px-4 flex justify-between fw_700 text-sm items-center">
          <span>Total (₹)</span>
          <span>
            <input
              disabled={true}
              className="text-end w-[8rem] px-2 py-1 border rounded-md border-D0D5DD text-000000"
              type="text"
              value={
                isRoundOffReq(values?.paymentData)
                  ? Math.round(calculateTotal(values))?.toFixed(2)
                  : calculateTotal(values).toFixed(2)
              }
              // value={calculateTotal(values)?.toFixed(2)}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default BillingFinalTable;

export const calculateTotal = (values) => {
  return values.data?.reduce(
    (total, item) => total + parseFloat(item.item_total_amount || 0),
    0
  );
};
export const calculateTotalTax = (data) => {
  let totalTax = 0;

  data?.forEach((rowData) => {
    totalTax += parseFloat(rowData.item_tax_amount) || 0;
  });

  return totalTax;
};

export const calculateSubtotal = (data) => {
  return data.reduce((subtotal, item) => {
    return subtotal + parseFloat(item.item_price_wiithout_tax);
  }, 0);
};

const calculateTotalDiscount = (data) => {
  return data.reduce((totalDiscount, item) => {
    const item_discount_amount = parseFloat(item.item_discount_amount || 0);
    return totalDiscount + item_discount_amount;
  }, 0);
};

export const calculateTotalCGST = (data) => {
  let totalCGST = 0;

  data?.forEach((rowData) => {
    totalCGST += parseFloat(rowData.item_cgst) || 0;
  });

  return totalCGST;
};

export const calculateTotalSGST = (data) => {
  let totalSGST = 0;

  data?.forEach((rowData) => {
    totalSGST += parseFloat(rowData.item_sgst) || 0;
  });

  return totalSGST;
};
export const calculateTotalIGST = (data) => {
  let totalIGST = 0;

  data?.forEach((rowData) => {
    totalIGST += parseFloat(rowData.item_igst) || 0;
  });

  return totalIGST;
};
export const calculateTotalPayment = (values) => {
  return values.paymentData?.reduce((acc, item) => {
    return acc + parseFloat(item.payment_amount || 0);
  }, 0);
};

// export function validatePaymentData(paymentData) {
//   let isValid = true;

//   paymentData.forEach((payment) => {
//     if (
//       (payment.payment_mode_name === "UPI" ||
//         payment.payment_mode_name === "Card") &&
//       !payment.transaction
//     ) {
//       isValid = false;
//     }
//   });
//   if (isValid) {
//     return false;
//   } else {
//     return true;
//   }
// }

export function validatePaymentmethod(paymentData) {
  let isValid = true;

  paymentData.forEach((payment) => {
    const isPaymentAmountValid =
      payment.payment_amount && parseFloat(payment.payment_amount) > 0;

    if (isPaymentAmountValid && !payment.payment_mode_name) {
      isValid = false;
    }
  });

  return !isValid;
}

export function isRoundOffReq(paymentData) {
  return !paymentData.some(
    (pay) =>
      pay?.payment_mode_name === "UPI" || pay?.payment_mode_name === "Card"
  );
}
