import React from "react";
import FilterRadioButtons from "../../forms/filter-radio-buttons";
import { customerStatusoptions } from "../../helper-components/data";

const CustomerFilter = ({ setFilterData, filterData }) => {
  const handlecustomerStatusChange = (e) => {
    setFilterData({
      ...filterData,
      customer_status: e.target.value,
    });
  };

  return (
    <div
      menuPortalTarget={document.body}
      style={{
        minWidth: "13rem",
        zIndex: 999,
        top: "40px",
        right: "-5px",
        boxShadow: `0px 1px 3px 0px var(--popover-shadow-color)`,
      }}
      className="absolute  border border-[var(--popover-border-color)] border-solid border-1   py-3 px-2  bg-[var(--popover-bg-color)]  rounded  z-10 flex flex-col"
    >
      <span className=" text-[length:--popover-heading-font-size] font-[var(--popover-heading-font-weight)] text-[var(--popover-heading-fg-color)] whitespace-nowrap cursor-pointer border-radius px-[8px] py-[12px]">
        FILTER BY
      </span>
      <h className="border border-EAECF0" />
      <div className="px-3">
        <FilterRadioButtons
          name="customer_status"
          label={"Customer Type"}
          options={customerStatusoptions}
          handleChange={handlecustomerStatusChange}
          value={filterData?.customer_status}
        />
      </div>
    </div>
  );
};

export default CustomerFilter;
