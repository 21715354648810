import { all } from "redux-saga/effects";
import {
  goodsItemsGetWatcher,
  goodsReceiptCreateWatcher,
  goodsTableWatcher,
  goodsViewWatcher,
  purchaseCreateWatcher,
  purchaseProductListWatcher,
  purchaseTableWatcher,
  purchaseViewWatcher,
  purchaseInvoiceItemsGetWatcher,
  purchaseInvoiceViewWatcher,
  purchaseInvoiceReceiptCreateWatcher,
  purchaseInvoiceTableWatcher,
  purchaseReturnItemsGetWatcher,
  purchaseReturnViewWatcher,
  purchaseReturnReceiptCreateWatcher,
  purchaseReturnTableWatcher,
  purchaseCreditTableWatcher,
  purchaseCreditReceiptCreateWatcher,
  purchaseCreditViewWatcher,
  purchaseEditWatcher,
  purchaseItemsGetWatcher,
  goodsVoidWatcher,
  purchaseVoidWatcher,
  goodsEditWatcher,
  purchaseInvoiceEditWatcher,
  purchaseInvoiceVoidWatcher,
  purchaseReturnEditWatcher,
  purchaseReturnVoidWatcher,
  purchaseCreditVoidWatcher,
  purchaseCreditEditWatcher,
  salesPaymentOutCusWatcher,
  paymentOutTableWatcher,
  PaymentOutCreateWatcher,
  paymentOutViewWatcher,
  loadMinQtyItemsWatcher,
  bulkPurchaseCreateWatcher,
  uploadPoCsvWatcher,
  purchaseTaxCalWatcher,
  goodsReceiptCreateBulkWatcher,
  purchaseProductDetailsWatcher,
  goodsTempProImpWatcher,
  goodsTempProVeriWatcher,
} from "../purchase-saga";
export default function* purchaseRootSaga() {
  yield all([
    purchaseCreateWatcher(),
    purchaseTableWatcher(),
    purchaseProductListWatcher(),
    purchaseViewWatcher(),
    goodsReceiptCreateWatcher(),
    goodsReceiptCreateBulkWatcher(),
    goodsItemsGetWatcher(),
    goodsTableWatcher(),
    goodsViewWatcher(),
    purchaseInvoiceItemsGetWatcher(),
    purchaseInvoiceViewWatcher(),
    purchaseInvoiceReceiptCreateWatcher(),
    purchaseInvoiceTableWatcher(),
    purchaseReturnItemsGetWatcher(),
    purchaseReturnViewWatcher(),
    purchaseReturnReceiptCreateWatcher(),
    purchaseReturnTableWatcher(),
    purchaseCreditTableWatcher(),
    purchaseCreditReceiptCreateWatcher(),
    purchaseCreditViewWatcher(),
    purchaseEditWatcher(),
    purchaseItemsGetWatcher(),
    purchaseCreditEditWatcher(),
    purchaseCreditVoidWatcher(),
    purchaseReturnVoidWatcher(),
    purchaseReturnEditWatcher(),
    purchaseInvoiceVoidWatcher(),
    purchaseInvoiceEditWatcher(),
    goodsEditWatcher(),
    goodsVoidWatcher(),
    purchaseVoidWatcher(),
    salesPaymentOutCusWatcher(),
    paymentOutTableWatcher(),
    PaymentOutCreateWatcher(),
    paymentOutViewWatcher(),
    loadMinQtyItemsWatcher(),
    bulkPurchaseCreateWatcher(),
    uploadPoCsvWatcher(),
    purchaseTaxCalWatcher(),
    purchaseProductDetailsWatcher(),
    goodsTempProImpWatcher(),
    goodsTempProVeriWatcher(),
  ]);
}
