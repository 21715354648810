import React from "react";

const CloseIcon = ({
  onClickFn = () => {},
  width = "14px",
  height = "14px",
  fill = "black",
}) => {
  return (
    <svg
      onClick={onClickFn}
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.875 1.125L1.125 10.875M1.125 1.125L10.875 10.875"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseIcon;
