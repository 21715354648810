import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import CloseIcon from "../../../../assets/icons/close-icon";

const TemplateFormatPreview = ({ isModalOpen = false, setIsModalOpen, values }) => {
  console.log(values, "Preview CSV Data");

  // Extract grnColumns from values
  const grnColumns = values?.grnColumns || [];

  // Map grnColumns label to the preview columns
  const previewColumns = Object.entries(values?.mapped_columns || {})
    .filter(([_, mappedKey]) => mappedKey) // Exclude null mappings
    .map(([name, mappedKey]) => {
      const grnColumn = grnColumns.find(col => col.name === name); // Match with grnColumns
      return { label: grnColumn ? grnColumn.label : name, mappedKey };
    });

  return (
    <Transition show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => setIsModalOpen(false)}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
          </Transition.Child>

          {/* Modal Container */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-0"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div className="mx-auto h-[80vh] w-[80%] p-5 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
              <div className="flex justify-between items-start h-[3rem]">
                <span className="text-000000 text-lg fw_600">Preview CSV</span>
                <button
                  type="button"
                  className="close-button bg-transparent border-none p-0 hover:bg-787878"
                  onClick={() => setIsModalOpen(false)}
                >
                  <CloseIcon />
                </button>
              </div>

              {/* Table Container */}
              <div
                style={{ height: `calc(100% - 3rem)` }}
                className="w-full overflow-auto"
              >
                <table className="w-full">
                  {/* Table Headings */}
                  <thead className="sticky top-0 z-9 bg-F2F4F5">
                    <tr>
                      <th className="tab-header whitespace-nowrap text-xs fw_700 text-475467 bg-F2F4F5 text-start">
                        #
                      </th>
                      {previewColumns.map(({ label }) => (
                        <th
                          key={label}
                          className="tab-header whitespace-nowrap text-xs fw_700 text-475467 bg-F2F4F5 text-start"
                        >
                          {label}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  {/* Table Body */}
                  <tbody>
                    {values?.excel_rows?.map((row, index) => (
                      <tr key={index}>
                        {/* Row Index */}
                        <td className="tab-cell text-sm fw_400 text-secondary w-20">
                          {index + 1}
                        </td>

                        {/* Dynamically Render Table Cells */}
                        {previewColumns.map(({ mappedKey }) => (
                          <td
                            key={mappedKey}
                            className="tab-cell text-sm fw_400 text-secondary"
                          >
                            {row[mappedKey] || "-"}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default TemplateFormatPreview;
