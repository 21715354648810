import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import KibabMenu from "../../../../../assets/icons/kibab-menu";
import { useDispatch } from "react-redux";
import DeleteIcon from "../../../../../assets/icons/delete-icon";
import successToast from "../../../../helper-components/success-toast";
import errorToast from "../../../../helper-components/error-toast";
import AddButton from "../../../../buttons/button";
import LoadingSpinner from "../../../../helper-components/loading-spinner";
import { decimalNumberFn } from "../../../../helper-components/helper-function";

const TcsTdsTable = ({
  searchValue,
  newLine,
  currentTab,
  listAction,
  createAction,
  deleteAction,
}) => {
  const [reloadData, setReloadData] = useState(false);
  const tableHeading = ["Tax Name", "Rate (%)", "Tax Type", ""];
  const [selectedDOtIndex, setSelectedDOtIndex] = useState(null);
  const [initialData, setInitialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleFieldChange = (index, field, value, setFieldValue) => {
    setFieldValue(`${index}.${field}`, value);
  };
  useEffect(() => {
    dispatch(
      listAction({ searchValue }, onFetchTaxTypeSuccess, onFetchTaxTypeError)
    );
    setLoading(true);
  }, [currentTab, searchValue, reloadData]);

  const onFetchTaxTypeSuccess = (data) => {
    setInitialData([...data?.data, newLine]);
    setLoading(false);
  };

  const onFetchTaxTypeError = (data) => {
    errorToast(data?.message);
    setInitialData([newLine]);
    setLoading(false);
  };

  const initialValues = {
    data: initialData,
  };

  const onCreateSuccess = (data) => {
    setSelectedDOtIndex(null);
    successToast(data?.message);
    setLoading(false);
    setReloadData(!reloadData);
  };
  const onCreateError = (data) => {
    setSelectedDOtIndex(null);
    errorToast(data?.message);
    setLoading(false);
  };

  const onClickDelete = (id) => {
    dispatch(deleteAction({ id }, onDeletSuccess, onError));
  };
  const onDeletSuccess = (data) => {
    setSelectedDOtIndex(null);
    successToast(data?.message);
    setReloadData(!reloadData);
  };

  const onError = (data) => {
    setSelectedDOtIndex(null);

    errorToast(data?.message);
  };

  const componentRef = useRef(null);

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setSelectedDOtIndex(null);
    }
  };

  const onSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    const key = currentTab === "TCS setup" ? "tcs" : "tds"; // Determine key dynamically

    let updatedData = values?.data || [];

    // Check if last element has tds_name or tcs_name
    const lastItem = updatedData[updatedData.length - 1];
    const hasRequiredField =
      lastItem && (lastItem.tds_name || lastItem.tcs_name);

    // If lastItem doesn't have the required field, remove it
    if (!hasRequiredField) {
      updatedData = updatedData.slice(0, -1);
    }

    if (updatedData.length) {
      dispatch(
        createAction({ [key]: updatedData }, onCreateSuccess, onCreateError)
      );
    }

    setSubmitting(false);
  };

  return (
    <div className="h-full">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, dirty, handleSubmit }) => {
          console.log(values, "dfsfsdffsdfsdfsdfsd");
          return (
            <Form className="h-full">
              <div
                onClick={handleClickOutside}
                className="flex flex-col justify-between"
                style={{
                  height: "calc(100% - 4rem)",
                }}
              >
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <div className="overflow-auto h-full">
                    <table className="mt-1 w-full">
                      <thead className="sticky top-0 z-10 bg-F2F4F5">
                        <tr>
                          {tableHeading.map((heading, index) => (
                            <th
                              className={`tab-header whitespace-nowrap text-xs fw_700 text-475467 bg-F2F4F5 text-start`}
                              key={heading}
                            >
                              {heading}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody style={{ position: "relative" }}>
                        {values.data.map((item, index) => (
                          <tr key={index}>
                            <td
                              className={`tab-cell text-sm fw_400 text-secondary w-20`}
                            >
                              <Field
                                name={`data[${index}].${
                                  Object.keys(newLine)[0]
                                }`}
                                placeholder="Name"
                                onChange={(e) =>
                                  handleFieldChange(
                                    `data[${index}]`,
                                    Object.keys(newLine)[0], // Dynamically get the first key of newLine (tcs_name/tds_name)
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded py-2"
                              />
                            </td>
                            <td
                              className={`tab-cell text-sm fw_400 text-secondary`}
                            >
                              <Field
                                min={0}
                                onKeyDown={decimalNumberFn}
                                type="number"
                                name={`data[${index}].${
                                  Object.keys(newLine)[1]
                                }`}
                                placeholder="Percentage"
                                onChange={(e) =>
                                  handleFieldChange(
                                    `data[${index}]`,
                                    Object.keys(newLine)[1], // Dynamically get the second key of newLine (tcs_percentage/tds_percentage)
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2 input-field-no-spin-buttons"
                              />
                            </td>
                            <td
                              className={`tab-cell text-sm fw_400 text-secondary `}
                            >
                              <Field
                                name={`data[${index}].${
                                  Object.keys(newLine)[2]
                                }`}
                                placeholder="Type"
                                onChange={(e) =>
                                  handleFieldChange(
                                    `data[${index}]`,
                                    Object.keys(newLine)[2], // Dynamically get the third key of newLine (tcs_type/tds_type)
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                                className="border-0 focus:outline-none focus:ring focus:ring-blue-500 rounded px-1 py-2"
                              />
                            </td>

                            <td className="tab-cell w-[5rem] text-sm fw_400 text-secondary p-8">
                              <button
                                type="button"
                                onClick={() => setSelectedDOtIndex(index)}
                              >
                                <KibabMenu />
                              </button>
                              {selectedDOtIndex === index && (
                                <div
                                  ref={componentRef}
                                  style={{
                                    position: "absolute",
                                    right: "4rem",
                                    boxShadow:
                                      "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                  }}
                                  className="bg-white rounded w-38"
                                >
                                  <div className="w-full p-3">
                                    <button
                                      type="button"
                                      className="w-full flex align-middle gap-2 px-2 py-1"
                                      onClick={() => onClickDelete(item?.id)}
                                    >
                                      <div className="mt-1">
                                        <DeleteIcon />
                                      </div>
                                      <span className="text-sm">Delete</span>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className="flex justify-end w-full bg-white py-3  px-6 invoice-bottom-container h-[4rem]">
                <AddButton
                  onclkFn={handleSubmit}
                  prefixIcon={false}
                  text="Save"
                  type="submit"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TcsTdsTable;
