import React from "react";

const KibabMenu = ({ width = "14px", height = "14px" }) => {
  return (
    // <svg
    //   width="20"
    //   height="20"
    //   viewBox="0 0 20 20"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M4.16634 10C4.16634 10.4602 4.53944 10.8333 5.00067 10.8333C5.4609 10.8333 5.83401 10.4602 5.83401 10C5.83401 9.53978 5.4609 9.16667 5.00067 9.16667C4.53944 9.16667 4.16634 9.53978 4.16634 10Z"
    //     stroke="#98A2B3"
    //     strokeWidth="1.66667"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    //   <path
    //     d="M9.16634 10C9.16634 10.4602 9.53944 10.8333 10.0007 10.8333C10.4609 10.8333 10.834 10.4602 10.834 10C10.834 9.53978 10.4609 9.16667 10.0007 9.16667C9.53944 9.16667 9.16634 9.53978 9.16634 10Z"
    //     stroke="#98A2B3"
    //     strokeWidth="1.66667"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    //   <path
    //     d="M15.833 10C15.833 10.4602 16.2061 10.8333 16.6663 10.8333C17.1266 10.8333 17.4997 10.4602 17.4997 10C17.4997 9.53978 17.1266 9.16667 16.6663 9.16667C16.2061 9.16667 15.833 9.53978 15.833 10Z"
    //     stroke="#98A2B3"
    //     strokeWidth="1.66667"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    // </svg>
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4.16667C10.4602 4.16667 10.8333 4.53978 10.8333 5C10.8333 5.46022 10.4602 5.83333 10 5.83333C9.53978 5.83333 9.16667 5.46022 9.16667 5C9.16667 4.53978 9.53978 4.16667 10 4.16667Z"
        stroke="#98A2B3"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 9.16667C10.4602 9.16667 10.8333 9.53978 10.8333 10C10.8333 10.4602 10.4602 10.8333 10 10.8333C9.53978 10.8333 9.16667 10.4602 9.16667 10C9.16667 9.53978 9.53978 9.16667 10 9.16667Z"
        stroke="#98A2B3"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 15.8333C10.4602 15.8333 10.8333 16.2064 10.8333 16.6667C10.8333 17.1269 10.4602 17.5 10 17.5C9.53978 17.5 9.16667 17.1269 9.16667 16.6667C9.16667 16.2064 9.53978 15.8333 10 15.8333Z"
        stroke="#98A2B3"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default KibabMenu;
