import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditIcon, { VoidIcon } from "../../assets/icons/action-icons";
import KibabMenu from "../../assets/icons/kibab-menu";
import FilterSection from "./filtering-section2";
import Pagination from "./pagination1";
import "./table.css";
import ShareTransactionModal from "../helper-components/share-popup";
import SortingIcons1 from "../../assets/icons/arrowup-down";
const DynamicTable = ({
  tableHeading,
  tableBodyData = [],
  paginationProps,
  filterData,
  setFilterData,
  placeholder,
  filterFn = () => {},
  filterBy,
  showFilter,
  label = "Invoice",
  initialFilter,
  reload,
  setReload,
  setLoading,
  deleteAction = () => {},
  id_name = "cmr_id",
  path = "invoice",
  filterText,
  date,
  module,
  permissionFn = () => {},
  filterSec = true,
  menu = true,
  filter = true,
  onclkFn,
  list,
  edit = true,
  onClickItemFn = () => {},
  usePassedComp = false,
  passedCompFn = () => {},
  newBtnTxt = "New",
  transaction = "",
  transaction_type = "",
  is_checkbox_req = true,
}) => {
  const defaultPermissionFn = () => {};
  const [isModalOpen, setIsModalOpen] = useState("");
  const [checkedId, setCheckedId] = useState([]);
  const navigate = useNavigate();
  const [action, setAction] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const dispatch = useDispatch();
  function sortFn(item) {
    let direction = "ASC";
    if (
      filterData != null &&
      filterData?.sort_by === item &&
      filterData?.sort_order === "ASC"
    ) {
      direction = "DESC";
    }
    setFilterData({ ...filterData, sort_by: item, sort_order: direction });
  }

  function checkboxChecker(e, all) {
    if (all === "all" && checkedId.length === e.length) {
      setCheckedId([]);
    } else if (all === "all") {
      setCheckedId(e);
    } else {
      if (checkedId.includes(e?.target?.id)) {
        const checkedVAlues = [...checkedId];
        const findIndex = checkedVAlues.findIndex(
          (item) => item === e.target?.id
        );
        checkedVAlues.splice(findIndex, 1);
        setCheckedId(checkedVAlues);
      } else {
        setCheckedId((previous) => {
          return [...previous, e.target.id];
        });
      }
    }
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        action &&
        currentIndex != null &&
        !event.target.closest(".relative")
      ) {
        setAction(false);
        setCurrentIndex(null);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [action, currentIndex]);

  const handleDeleteAction = (id) => {
    setLoading(true);
    dispatch(                            
      deleteAction(
        {
          [id_name]: id,
        },
        onSuccessDel,
        onErrorDel
      )
    );
  };

  const onSuccessDel = () => {
    setReload(!reload);
    setLoading(false);
  };
  const onErrorDel = () => {
    setLoading(false);
  };
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight = document.querySelector(".head-div")?.clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);
  return (
    <>
      <div className="head-div">
        <FilterSection
          newBtnTxt={newBtnTxt}
          list={list}
          filterSec={filterSec}
          onclkFn={onclkFn}
          module={module}
          permissionFn={permissionFn}
          filter={filter}
          date={date}
          filterText={filterText}
          paginationProps={paginationProps}
          showFilter={showFilter}
          placeholder={placeholder}
          filterFn={filterFn}
          tableHeading={
            module === "stock_transfer"
              ? [
                  ...tableHeading,
                  {
                    label: "ITEM NAME",
                    value: "item_name",
                    display: false,
                    column: true,
                  },
                ]
              : tableHeading
          }
          filterData={filterData}
          setFilterData={setFilterData}
          filterBy={filterBy}
          initialFilter={initialFilter}
        />
      </div>

      <div
        style={{
          height: `calc(100% - ${heightOfFirstDiv}px)`,
        }}
        className="flex flex-col justify-between  px-[24px]"
      >
        <div
          style={{
            height: `calc(100% - 50px)`,
          }}
          className="tab-container outline outline-[var(--list-table-border-color)] outline-1 border-radius"
        >
          <table className="w-full border-collapse mt-0">
            <thead className="sticky top-0 bg-[var(--list-table-heading-bg)] z-[10]">
              <tr className="relative h-[50px]">
                {is_checkbox_req && (
                  <th
                    className={`whitespace-nowrap text-xs fw_700 text-475467  tab-header `}
                  >
                    <input
                      checked={
                        checkedId?.length ===
                          tableBodyData?.map((item) => item?.id).length &&
                        checkedId.length > 0
                      }
                      onChange={() =>
                        checkboxChecker(
                          tableBodyData.map((item) => item?.id),
                          "all"
                        )
                      }
                      style={{ transform: "scale(1.1)" }}
                      type="checkbox"
                      id="myCheckbox"
                      className="custom-checkbox form-check-input cursor-pointer"
                    />
                  </th>
                )}

                {tableHeading.map((heading, index) => (
                  <th
                    key={index}
                    className={`${"tab-header"} whitespace-nowrap text-[var(--list-table-heading-fg-color)]  text-[length:--list-table-heading-font-size] font-[var(--list-table-heading-font-weight)]`}
                  >
                    <span
                      className={`flex items-center gap-2 ${heading?.className}`}
                    >
                      {heading?.label}
                      {heading?.display && (
                        <button onClick={() => sortFn(heading?.value)}>
                          {" "}
                          <SortingIcons1
                            inc={
                              filterData?.sort_order === "ASC" &&
                              heading?.value === filterData?.sort_by
                                ? "var(--list-table-heading-fg-color)"
                                : "var(--list-table-heading-fg-op-50-color)"
                            }
                            dec={
                              filterData?.sort_order === "DESC" &&
                              heading?.value === filterData?.sort_by
                                ? "var(--list-table-heading-fg-color)"
                                : "var(--list-table-heading-fg-op-50-color)"
                            }
                          />
                        </button>
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="h-full">
              {tableBodyData?.length > 0 ? (
                <>
                  {tableBodyData.map((rowData, rowIndex) => (
                    <tr
                      className={`hover:bg-[var(--list-table-row-hover-bg)] bg-[var(--list-table-row-bg)] ${
                        checkedId.includes(rowData?.id) &&
                        "bg-D5E6Ff hover:bg-[var(--list-table-row-hover-bg)]"
                      } h-[50px] `}
                      key={rowIndex}
                    >
                      {is_checkbox_req && (
                        <td className="text-[length: --list-table-row-icon-size]">
                          <input
                            checked={checkedId.includes(rowData?.id)}
                            id={rowData?.id}
                            onChange={(e) => checkboxChecker(e)}
                            style={{ transform: "scale(1.2)" }}
                            type="checkbox"
                            className="custom-checkbox form-check-input cursor-pointer "
                          />
                        </td>
                      )}

                      {Object.entries(rowData).map(
                        ([key, cellData], cellIndex) => (
                          <Fragment key={cellIndex}>
                            {cellIndex !== 0 && (
                              <td
                                onClick={() => onClickItemFn(rowData?.id)}
                                className={`tab-cell h-[55px] text-[length:--list-table-row-font-size] font-[var(--list-table-row-font-weight)] text-[var(--list-table-row-fg-color)] whitespace-nowrap cursor-pointer`}
                              >
                                { cellData}
                              </td>
                            )}
                          </Fragment>
                        )
                      )}
                      {menu && (
                        <td
                          className={`tab-cell h-[55px] text-[length:--list-table-row-font-size] font-[var(--list-table-row-font-weight)] text-[var(--list-table-row-fg-color)] whitespace-nowrap cursor-pointer flex justify-center items-center`}
                        >
                          {((permissionFn || defaultPermissionFn)(module)
                            ?.update ||
                            (permissionFn || defaultPermissionFn)(module)
                              ?.delete ||
                            ((permissionFn || defaultPermissionFn)(module)
                              ?.print &&
                              module === "invoice") ||
                            ((permissionFn || defaultPermissionFn)(module)
                              ?.send &&
                              module === "invoice")) && (
                            <>
                              <div className="relative">
                                <button
                                  onClick={() => {
                                    setAction(!action);
                                    setCurrentIndex(rowIndex);
                                  }}
                                >
                                  <KibabMenu /> 
                                </button>
                                {action &&
                                currentIndex === rowIndex &&
                                usePassedComp ? (
                                  <>{passedCompFn(rowData?.id)}</>
                                ) : (
                                  action &&
                                  currentIndex === rowIndex && (
                                    <div
                                    style={{
                                      minWidth: "11rem",
                                      zIndex: 999,
                                      top: "25px",
                                      right: "15px",
                                      boxShadow: `0px 1px 3px 0px var(--popover-shadow-color)`,
                                    }}
                                    className="absolute  border border-[var(--popover-border-color)] border-solid border-1 top-5 right-0  py-2 px-2  bg-[var(--popover-bg-color)]  rounded  z-10 flex flex-col"
                                    >
                                      {label !== "Invoice" &&
                                        edit &&
                                        (permissionFn || defaultPermissionFn)(
                                          module
                                        )?.update && (
                                          <button
                                            onClick={() => {
                                              navigate(
                                                `/edit-${path}/${rowData?.id}`
                                              );
                                            }}
                                            className="flex items-center text-nowrap gap-2 text-[length:--popover-font-size] font-[var(--popover-font-weight)] text-[var(--popover-fg-color)] whitespace-nowrap cursor-pointer h-[35px] hover:bg-[var(--popover-hover-color)] border-radius px-[8px]"
                                            >
                                            <EditIcon fill="var(--popover-fg-color)" />
                                            Edit {label}
                                          </button>
                                        )}

                                      {label !== "Invoice" &&
                                        (permissionFn || defaultPermissionFn)(
                                          module
                                        )?.delete &&
                                        (module !== "customer" ||
                                          rowData?.total_sale_amount?.value <
                                            1) &&
                                        rowData?.id !==
                                          "01HS5HJYWZ5DG2CW4EHX2W7A8V" && (
                                          <button
                                            onClick={() => {
                                              handleDeleteAction(rowData?.id);
                                            }}
                                            className="flex items-center text-nowrap gap-2 text-[length:--popover-font-size] font-[var(--popover-font-weight)]  text-[var(--error-color)] whitespace-nowrap cursor-pointer h-[35px] hover:bg-[var(--popover-hover-color)] border-radius px-[8px]"
                                            >
                                            <VoidIcon fill="var(--error-color)" />
                                            Void {label}
                                          </button>
                                        )}
                                    </div>
                                  )
                                )}
                              </div>
                            </>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                  <tr style={{ height: "10rem" }}></tr>
                </>
              ) : (
                <tr
                  style={{
                    height: "20rem",
                  }}
                >
                  <td
                    colSpan={tableHeading.length + 1}
                    className="text-center text-primary"
                  >
                    No {label + "'s"} found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination paginationProps={paginationProps} />
        <ShareTransactionModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          transaction={transaction}
          transaction_type={transaction_type}
        />
      </div>
    </>
  );
};

export default DynamicTable;
