import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { productPlanningCreateAction } from "../../redux/actions/product-action";
import AddButton from "../buttons/button";
import InputField from "../forms/input-field";
import successToast from "../helper-components/success-toast";

const PlanningTab = ({
  productId,
  setFormValues,
  formValues,
  setCurrentTab,
  product,
  disabled = false,
  itemGeneralData = {},
}) => {
  const dispatch = useDispatch();
  const initialValues = {
    item_id: product?.item_id ?? productId,
    item_order_interval: product?.item_order_interval ?? null,
    item_order_multiple: product?.item_order_multiple ?? null,
    item_minimum_order_quantity: product?.item_minimum_order_quantity ?? null,
    item_checking_rule: product?.item_checking_rule ?? "",
  };
  const validationSchema = Yup.object().shape({});
  const innerOnSubmit = (values) => {
    setFormValues({
      ...formValues,
      planning: values,
    });
    dispatch(
      productPlanningCreateAction(
        {
          itemGeneralData: itemGeneralData,
          itemPlanningData: values,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast(data?.message);
    setCurrentTab("Restrictions");
  };
  const onError = (data) => {};

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={formValues?.planning ?? initialValues}
        validationSchema={validationSchema}
        onSubmit={innerOnSubmit}
      >
        {({ values, handleSubmit, errors }) => {
          return (
            <>
              <div className="flex flex-wrap">
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_order_interval"
                    placeholder=""
                    label="Order Interval"
                    isRequired={false}
                    labelWidth="9rem"
                  />

                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_order_multiple"
                    placeholder=""
                    label="Order Multiple "
                    isRequired={false}
                    labelWidth="9rem"
                  />
                </div>
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10 mt-10 lg:mt-0">
                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_minimum_order_quantity"
                    placeholder=""
                    label="Minimum Order Qty"
                    isRequired={false}
                    labelWidth="9rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_checking_rule"
                    placeholder=""
                    label="Checking Rule"
                    isRequired={false}
                    labelWidth="9rem"
                  />
                </div>
              </div>
              {!disabled && (
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <AddButton
                    onclkFn={handleSubmit}
                    prefixIcon={false}
                    text="Save"
                    type="submit"
                  />
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default PlanningTab;
