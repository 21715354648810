import React from 'react';
import { TableRow } from './TableRow';
export const TableSection = ({ title, reports,navigate }) => {
  return (
    <div className='w-full bg-faf9fa ' id={title}>
      <div className="px-4 py-2 border-b  border-D0D5DD bg-F2F4F5">
        <h2 className="font-bold">{title}</h2>
      </div>
      {/* Updated div to cover full width */}
      <div className="w-full ">
      {reports.map((report, index) => (
          <TableRow
            key={`${report.title}-${index}`}
            name={report.title}
            type={"System Generated"}
            report={report}  // Passing down the full report data
            navigate={navigate}  // Passing down the navigate function
          />
        ))}
      </div>
    </div>
  );
};
