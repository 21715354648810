import { Field, useFormikContext } from "formik";
import React, { Fragment, useState } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import DownArrowIcon from "../../assets/icons/down-arrow-icon";
import StarIcon from "../../assets/icons/req-star";
import ErrorMsg from "./error-msg";
export default function SelectField({
  key,
  label,
  isMulti = false,
  isDisabled = false,
  style = {},
  defaultValue = null,
  Icon = <DownArrowIcon />,
  name = "",
  options,
  containerClass = "mb-2",
  placeholder,
  id,
  isContainer = true,
  labelClass = "whitespace-no-wrap flex text-sm",
  handleSelected,
  defaultSelectClass = "selectField h-[2.9rem] focus_outline__none",
  selectClass = "w-full",
  replaceSelectCSS = false,
  errMsgContainerClass = "",
  isClearable = false,
  isSearchable = true,
  isCreatable = false,
  charLimit = 22,
  labelsLimit = 1000,
  children,
  customStyle,
  labelWidth = "10rem",
  borderclass = "selectField_border",
  isRequired = false,
  menuPosition = "fixed",
  autoFocus = false,
  ref,
  general_color = true,
  menuWidth = "",
  onFocus = () => {}
}) {
  const [isFocused, setIsFocused] = useState(false); // State for tracking focus
  const { values: formValues, setFieldValue } = useFormikContext();
  const Container = isContainer ? "div" : Fragment;
  const SelectComponent = isCreatable ? CreatableSelect : Select;
  const finalSelectClass = replaceSelectCSS
    ? selectClass
    : defaultSelectClass + " " + selectClass;
  const formatOptionLabel = (option, { context }) => {
    const index = formValues[name]?.indexOf(option);
    if (context === "menu") {
      return option.label;
    }
    if (index > labelsLimit - 1) {
      return null;
    }
    const label =
      option.label.length > charLimit
        ? `${option.label.slice(0, charLimit)}...`
        : option.label;
    return label;
  };
  const formatCreateLabel = (inputValue) => {
    return (
      <>
        <span className="color_707070 pe-2">Add option</span>"{inputValue}"
      </>
    );
  };
  const handleRemoveAllAfterTwo = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFieldValue(name, formValues[name].slice(0, labelsLimit));
  };

  const customValueContainer = ({ children, ...props }) => {
    const itemsCount = formValues[name].length;
    const newChildren = [...children];
    const hiddenOptions = formValues[name].slice(labelsLimit);
    if (itemsCount > labelsLimit) {
      newChildren.splice(
        newChildren.length - 1,
        0,
        <div
          title={hiddenOptions?.map((opt) => opt?.label).join(", ")}
          key="additional-items"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{
            cursor: "pointer",
            marginLeft: "8px",
            borderRadius: "5px",
            padding: "1px 6px",
            backgroundColor: "#E5ECFF",
            display: "flex",
            fontWeight: "500",
            fontFamily: "Poppins",
            gap: "5px",
            alignItems: "center",
          }}
        >
          {itemsCount - labelsLimit}+
          <components.MultiValueRemove
            data={{ label: "[number]+" }}
            innerProps={{
              onMouseDown: handleRemoveAllAfterTwo,
              onTouchStart: handleRemoveAllAfterTwo,
              onTouchEnd: handleRemoveAllAfterTwo,
            }}
          >
            {smallCloseIcon}
          </components.MultiValueRemove>
        </div>
      );
    }

    return (
      <components.ValueContainer {...props}>
        {newChildren}
      </components.ValueContainer>
    );
  };

  const customMultiValueRemove = (props) => {
    if (formValues[name]?.indexOf(props.data) > labelsLimit - 1) {
      return null;
    }
    return (
      <components.MultiValueRemove {...props}>
        {smallCloseIcon}
      </components.MultiValueRemove>
    );
  };

  const customMultiValue = (props) => {
    if (formValues[name]?.indexOf(props.data) > labelsLimit - 1) {
      return null;
    }
    return (
      <components.MultiValue
        {...props}
        style={{ backgroundColor: "#E5ECFF" }}
      />
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {Icon}
      </components.DropdownIndicator>
    );
  };

  const selectValue = (form) => {
    console.log(form , "sdsfsfsfsfs");
    let result = null;
    if (isMulti) {
      result = form.values[name];
    } else {
      console.log(form.values[`contact_persons[0].contact_person_country`], "sdsfsfsfsfs");
      console.log(name, "sdsfsfsfsfs");
      if (form.values[name]) {
        
        result = options?.find((item) => item.value === form.values[name]) ?? {
          label: form.values[name],
          value: form.values[name],
        };
      }
    }
    return result ?? defaultValue ?? null;
  };
  const customStyles = {
    indicatorSeparator: () => ({ display: "none" }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "transparent" : "transparent",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {},
      fontFamily: "Inter,sans-serif ",
      fontWeight: "400",
      borderRadius: "10px",
      width: "100%",
      backgroundColor: state.isDisabled ? "inherit" : base.backgroundColor,
      borderColor: state.isFocused ? "transparent" : "transparent",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {},
      height: "100%",
    }),
    option: (provided, state) => {
      const defaultBackgroundColor = "white"; // Default background color
      const focusedBackgroundColor = "#0000002E"; // Background color when focused
      const selectedBackgroundColor = "#e5ecffb2"; // Background color when selected

      return {
        ...provided,
        ...(state.data.color
          ? { color: state.data.color }
          : { color: "black" }),
        // backgroundColor: state?.isSelected ? "red" : "red",
        backgroundColor: state.isSelected
          ? selectedBackgroundColor // Use selected background color
          : state.isFocused
          ? focusedBackgroundColor // Use focused background color
          : defaultBackgroundColor, // Use default background color

        fontSize: "14px",
        zIndex: "999",
        borderBottom: "1px solid #F5F5F5",
        fontFamily: "Inter, sans-serif",
        "&:hover": {
          backgroundColor: state.isFocused
            ? focusedBackgroundColor
            : focusedBackgroundColor, // Keeps hover state consistent
        },
      };
    },
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "var(--placeholder-color)",
        fontFamily: "Inter,sans-serif",
        // fontSize: "14px",
        fontWeight: "400",
        opacity: "1",
        // position: "absolute",
        // top: "30%",
        whiteSpace: "nowrap",
      };
    },
    multiValue: (base) => ({
      ...base,
      fontFamily: "Inter,sans-serif ",
      backgroundColor: "#E5ECFF",
      gap: "5px",
      padding: "0px 6px",
    }),
    singleValue: (base) => ({
      ...base,
      fontFamily: "Inter,sans-serif ",
      fontWeight: "500",
      color: general_color ? "var(--value-color)" : "var(--secondary)",
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensures the menu appears above other elements
      minWidth: menuWidth,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      ...(state.selectProps.menuIsOpen
        ? {
            transform: "rotate(180deg)",
          }
        : {}),
    }),
  };

  return (
    <Container
      {...(isContainer && containerClass ? { className: containerClass } : {})}
    >
      {" "}
      <Field name={name}>
        {({ form, meta, Field }) => {
          return (
            <div className={`flex  items-center `}>
              {label && (
                <label
                  style={{
                    minWidth: labelWidth,
                  }}
                  {...(id ? { htmlFor: id } : {})}
                  className={`${labelClass} whitespace-nowrap text-label-color`}
                >
                  {label}
                  {isRequired && (
                    <span className="ml-2">
                      <StarIcon />
                    </span>
                  )}
                </label>
              )}
              {children}

              <SelectComponent
                autoFocus={autoFocus}
                ref={ref}
                menuPosition={menuPosition}
                {...(key ? { key: key } : {})}
                isSearchable={isSearchable}
                isClearable={isClearable}
                isDisabled={isDisabled}
                defaultValue={defaultValue}
                value={selectValue(form)}
                name={name}
                {...(id ? { id: id } : {})}
                {...Field}
                isMulti={isMulti}
                components={{
                  DropdownIndicator,
                  ...(isMulti
                    ? {
                        ValueContainer: customValueContainer,
                        MultiValueRemove: customMultiValueRemove,
                        MultiValue: customMultiValue,
                      }
                    : {}),
                }}
                styles={{ ...customStyles, ...style }}
                className={` text-sm placeholder:text-sm  ${
                  meta.error && meta.touched
                    ? `${finalSelectClass} selectField_border__error w-full`
                    : `${finalSelectClass}  w-full ${
                        isFocused && autoFocus
                          ? "border-focus-color border rounded-[10px]"
                          : `${borderclass}`
                      }`
                }`}
                onFocus={() => {setIsFocused(true); onFocus()}}
                onBlur={() => {
                  setIsFocused(false);

                  form.setFieldTouched(name, true);
                }}
                placeholder={placeholder}
                options={options}
                formatOptionLabel={formatOptionLabel}
                formatCreateLabel={formatCreateLabel}
                onChange={(selectedOption) => {
                  if (isCreatable && isMulti) {
                    const modedSelectedOptions = selectedOption?.map((item) => {
                      if (item.__isNew__)
                        return { label: item?.value, value: item?.label };
                      return item;
                    });
                    form.setFieldValue(
                      name,
                      isMulti ? modedSelectedOptions : selectedOption?.value
                    );
                  } else {
                    form.setFieldValue(
                      name,
                      isMulti ? selectedOption : selectedOption?.value
                    );
                  }
                  handleSelected && handleSelected(selectedOption);
                }}
              />
            </div>
          );
        }}
      </Field>
      <ErrorMsg
        containerClass={errMsgContainerClass}
        name={name}
        customStyle={customStyle}
      />
    </Container>
  );
}

export const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    color: "#0D111A",
    fontFamily: "Inter,sans-serif",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "10px",
    width: "100%",
    borderColor: state.isFocused ? "transparent" : "transparent",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "#1D72DB" : "#F4F8FB",
    },
  }),
  option: (provided, state) => {
    return {
      ...provided,
      ...(state.data.color ? { color: state.data.color } : { color: "black" }),
      backgroundColor: state.isSelected
        ? "#e5ecffb2"
        : state.isFocused
        ? "#0000002E"
        : null,
      borderBottom: "1px solid #F5F5F5",
      fontFamily: "Inter, sans-serif",
      "&:hover": {
        backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
      },
      zIndex: "999",
    };
  },

  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#667085",
      fontFamily: "Inter,sans-serif",
      fontSize: "14px",
      fontWeight: "400",
      opacity: "0.9",
      position: "absolute",
      // top: "0.6rem"
    };
  },
  multiValue: (base) => ({
    ...base,
    backgroundColor: "#E5ECFF",
    gap: "5px",
    padding: "0px 6px",
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "500",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    ...(state.selectProps.menuIsOpen
      ? {
          transform: "rotate(180deg)",
        }
      : {}),
  }),
};

const smallCloseIcon = (
  <svg
    width="6"
    height="6"
    viewBox="0 0 6 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.78026 1.2804L4.06056 3.00009L5.78026 4.71979C6.0735 5.01303 6.0735 5.48702 5.78026 5.78026C5.63401 5.9265 5.44202 6 5.25002 6C5.05803 6 4.86604 5.9265 4.71979 5.78026L3.00009 4.06056L1.2804 5.78026C1.13415 5.9265 0.942158 6 0.750164 6C0.55817 6 0.366176 5.9265 0.219931 5.78026C-0.0733102 5.48702 -0.0733102 5.01303 0.219931 4.71979L1.93963 3.00009L0.219931 1.2804C-0.0733102 0.987157 -0.0733102 0.513171 0.219931 0.219931C0.513171 -0.0733102 0.987157 -0.0733102 1.2804 0.219931L3.00009 1.93963L4.71979 0.219931C5.01303 -0.0733102 5.48702 -0.0733102 5.78026 0.219931C6.0735 0.513171 6.0735 0.987157 5.78026 1.2804Z"
      fill="#2820DA"
    />
  </svg>
);
