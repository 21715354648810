import React from "react";

// const SortingIcons = ({ inc, dec }) => {
//   return (
//     <div className="flex flex-col">
//       <svg
//         width="10"
//         height="9"
//         viewBox="0 0 7 4"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           d="M5.79183 3.58333L3.50016 0.875L1.2085 3.58333"
//           stroke={inc}
//           strokeWidth="1"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//       </svg>
//       <svg
//         width="10"
//         height="9"
//         viewBox="0 0 7 4"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           d="M1.20817 0.416667L3.49984 3.125L5.7915 0.416667"
//           stroke={dec}
//           strokeWidth="1"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//       </svg>

//     </div>
//   );
// };

// export default SortingIcons;

const SortingIcons1 = ({ inc, dec }) => {
  return (
    <div className="flex">
      <svg
        width="7"
        height="13"
        viewBox="0 0 7 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 3.66667L3.66667 1M3.66667 1L6.33333 3.66667M3.66667 1V11.6667"
          stroke={inc}
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <svg
        width="7"
        height="13"
        viewBox="0 0 7 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 9.33333L3.66667 11.6667M3.66667 11.6667L6.33333 9.33333M3.66667 11.6667V1"
          stroke={dec}
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default SortingIcons1;
