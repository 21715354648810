import { Field, useFormikContext } from "formik";
import React, { useState } from "react";
import { components } from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useNavigate } from "react-router-dom";
import DownArrowIcon from "../../assets/icons/down-arrow-icon";
import StarIcon from "../../assets/icons/req-star";
import ErrorMsg from "./error-msg";
import "./form.css";

const AsyncCreateSelect = ({
  name,
  cusType = "Customer",
  errorClass,
  handleCreateOption,
  loadOptions,
  type = "number",
  isRequired = true,
  isLabelReq = true,
  onChange = () => {},
  placeholder = "select and add...",
  Icon = <DownArrowIcon />,
  label = "Customer Phone Number",
  disabled = false,
  autoFocus = false,
  labelClass = "whitespace-nowrap flex text-sm gap-2 ",
  marginRight = "20px",
  minWidth = "",
  isValidNewOption = false,
  className = "selectField_border",
  maxlength = 100,
  menuPosition = "",
}) => {
  const { values: formValues } = useFormikContext();

  const [inputValue, setInputValue] = useState(
    formValues?.phone === null ? "" : ""
  );

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {Icon}
      </components.DropdownIndicator>
    );
  };
  const navigate = useNavigate();
  const NoOptionsMessage = ({ inputValue, ...props }) => (
    <button
      onClick={() => navigate("/add-customer")}
      className="py-1 cursor-pointer"
    >
      <span className="text-primary text-sm px-3">
        <span className="fs_18 pr-1">+</span> New {cusType}
      </span>
    </button>
  );
  // const handleNumberInput = (e) => {
  //   if (/^[A-Za-z]$/.test(e.key)) {
  //     e.preventDefault();
  //   }
  // };

  const handleNumberInput = (e) => {
    // Allow Ctrl+V (or Cmd+V on Mac) for pasting
    if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === "v") {
      return; // Allow pasting
    }

    // Prevent alphabetic characters during typing
    if (/^[A-Za-z]$/.test(e.key)) {
      e.preventDefault();
    }
  };
  const onKeyDownHandler = type === "number" ? handleNumberInput : undefined;
  const handleInputChange = (newValue, { action }) => {
    if (action === "input-change" && newValue.length <= maxlength) {
      setInputValue(newValue);
    }
  };
  const handleChange = (selectedOption, actionMeta) => {
    if (actionMeta?.action === "clear") {
      setInputValue("");
    }
    onChange(selectedOption, actionMeta);
  };
  return (
    <Field name={name}>
      {({ field }) => (
        <>
          <div className="flex items-center ">
            {isLabelReq && (
              <label
                style={{
                  marginRight: marginRight,
                  minWidth: minWidth,
                }}
                className={`${labelClass} text-label-color`}
              >
                {label}
                <StarIcon />
              </label>
            )}
            <AsyncCreatableSelect
              // key={formValues[name]}
              {...(!formValues[name]
                ? { inputValue, onInputChange: handleInputChange }
                : {})}
              menuPlacement="auto"
              isValidNewOption={() => isValidNewOption}
              autoFocus={autoFocus}
              onKeyDown={onKeyDownHandler}
              name={name}
              isClearable
              value={formValues[name]}
              components={{
                ...components,
                DropdownIndicator,
                NoOptionsMessage,
              }}
              placeholder={placeholder}
              type={type}
              menuPosition={menuPosition}
              className={`${``} w-full z-20  ${className}`}
              styles={customStyles}
              {...field}
              onCreateOption={handleCreateOption}
              onChange={handleChange} // Pass the handleChange function
              loadOptions={loadOptions}
              isDisabled={disabled}
            />
          </div>
          <div
            style={{
              height: "1rem",
            }}
            className={`text-right w-full fs_12 ${errorClass}`}
          >
            {isRequired && <ErrorMsg name={name} />}
          </div>
        </>
      )}
    </Field>
  );
};
export default AsyncCreateSelect;
const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    color: "#464646",
    fontFamily: "Inter, sans-serif",
    fontWeight: "400",
    fontSize: "15px",
    borderRadius: "10px",
    width: "100%",
    padding: "0", // Set minimal padding
    margin: "0",
    backgroundColor: state.isDisabled ? "inherit" : base.backgroundColor,
    borderColor: state.isFocused ? "#277DFE" : "transparent",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {},
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "3px",
  }),
  option: (provided, state) => {
    return {
      ...provided,
      ...(state.data.color ? { color: state.data.color } : { color: "black" }),
      backgroundColor: state.isSelected
        ? "#e5ecffb2"
        : state.isFocused
        ? "#0000002E"
        : null,
      borderBottom: "1px solid #F5F5F5",  
      fontFamily: "Inter, sans-serif",
      "&:hover": {
        backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
      },
    };
  },

  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      whiteSpace: "nowrap",
      color: "var(--placeholder-color)",
      fontFamily: "Inter, sans-serif",
      fontSize: "12px",
      fontWeight: "400",
      opacity: "0.9",
      position: "absolute",
      width: "100%",
    };
  },
  multiValue: (base) => ({
    ...base,
    backgroundColor: "#E5ECFF",
    gap: "5px",
    padding: "0px 6px",
  }),
  singleValue: (base) => ({
    ...base,
    whiteSpace: "nowrap", // Add this line
    textOverflow: "ellipsis",
    overflow: "hidden",
    padding: "0 1px",
    color: "var(--value-color)",
  }),
  dropdownIndicator: () => ({ display: "none" }),
};
