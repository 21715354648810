import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { productPurchasingCreateAction } from "../../redux/actions/product-action";
import AddButton from "../buttons/button";
import InputField from "../forms/input-field";
import SelectField from "../forms/select-field";
import successToast from "../helper-components/success-toast";

const PurchasingTab = ({
  productId,
  uomList,
  setFormValues,
  formValues,      
  setCurrentTab,
  product,
  disabled = false,
  itemGeneralData = {},
  vendorList = [],
  uomGroupData = [],
  uomGroupID = "",
}) => {
  
console.log(product, "dfsfdsfsdfsdfsdfsdf");
  const dispatch = useDispatch();
  const initialValues = {
    item_id:
      product?.item_id === null ? productId : product?.item_id ?? productId,
    item_pur_preferred_vendor: product?.item_pur_preferred_vendor ?? "",
    item_pur_preferred_vendor_id: product?.item_pur_preferred_vendor_id ?? "",
    item_pur_mfg_catalog_no: product?.item_pur_mfg_catalog_no ?? "",
    item_pur_purchasing_uom_name: product?.item_pur_purchasing_uom_name ?? "",
    item_pur_per_purchasing_unit: product?.item_pur_per_purchasing_unit ?? null,
    item_pur_packing_uom_name: product?.item_pur_packing_uom_name ?? "",
    item_pur_purchasing_uom_name_id:
      product?.item_pur_purchasing_uom_name_id ?? "",
    item_pur_quantity_per_package:
      product?.item_pur_quantity_per_package ?? null,
    item_pur_measurement_unit: product?.item_pur_measurement_unit ?? "",
    item_pur_length: product?.item_pur_length ?? null,
    item_pur_width: product?.item_pur_width ?? null,
    item_pur_height: product?.item_pur_height ?? null,
    item_pur_volume: product?.item_pur_volume ?? null,
    item_pur_weight: product?.item_pur_weight ?? null,
    item_pur_factor_1: product?.item_pur_factor_1 ?? null,
    item_pur_factor_2: product?.item_pur_factor_2 ?? null,
    item_pur_factor_3: product?.item_pur_factor_3 ?? null,
    item_pur_factor_4: product?.item_pur_factor_4 ?? null,
    item_pur_customs_group: product?.item_pur_customs_group ?? "",
  };

  const validationSchema = Yup.object().shape({
    item_pur_purchasing_uom_name_id: Yup.string().required(
      "Purchasing UOM is required"
    ),
  });
  const innerOnSubmit = (values) => {
    setFormValues({
      ...formValues,
      purchasing: values,
    });

    dispatch(
      productPurchasingCreateAction(
        {
          itemGeneralData: itemGeneralData,
          itemPurchasingData: values,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast(data?.message);
    setCurrentTab("Sales");
  };
  const onError = (data) => {};

  // useEffect(() => {
  //   dispatch(vendorListAction(onVendorSuccess, onVendorError));
  // }, []);

  // const onVendorSuccess = (data) => {
  //   setVendorList(
  //     data?.data?.vendors?.map((item) => {
  //       return { label: item?.vendors, value: item?.id };
  //     })
  //   );
  // };
  // const onVendorError = (data) => {
  //   errorToast(data?.message);
  // };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={formValues?.purchasing ?? initialValues}
        validationSchema={validationSchema}
        onSubmit={innerOnSubmit}
      >
        {({ values, handleSubmit, errors, setFieldValue }) => {
          
          return (
            <>
              <div className="flex flex-wrap">
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                  <SelectField
                    handleSelected={(sel) => {
                      setFieldValue("item_pur_preferred_vendor", sel?.label);
                    }}
                    isDisabled={disabled}
                    placeholder="Select manufacturer"
                    options={vendorList}
                    label="Preferred Vendor"
                    id="item_pur_preferred_vendor_id"
                    name="item_pur_preferred_vendor_id"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={false}
                    labelWidth="11.5rem"
                  />

                  <InputField
                    isDisabled={disabled}
                    type="text"
                    name="item_pur_mfg_catalog_no"
                    placeholder="xxxxxxxxxx"
                    label="Mfr Catalog No."
                    isRequired={false}
                    labelWidth="11.5rem"
                  />
                  <SelectField
                    handleSelected={(sel) => {
                      setFieldValue("item_pur_purchasing_uom_name", sel?.label);
                    }}
                    isDisabled={disabled}
                    placeholder="Select uom"
                    // options={uomList}
                    options={uomGroupData
                      ?.find(
                        (grp) => grp?.group_id === itemGeneralData?.item_uom
                      )
                      ?.group_items?.map((uom) => ({
                        label: uom?.alt_uom,
                        value: uom?.item_id,
                      }))}
                    label="Purchasing UOM Name"
                    id="item_pur_purchasing_uom_name_id"
                    name="item_pur_purchasing_uom_name_id"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={true}
                    labelWidth="11.5rem"
                  />
                </div>
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10 mt-10 lg:mt-0">
                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_pur_per_purchasing_unit"
                    placeholder="xxxxxxx"
                    label="Items per Purchasing Unit"
                    isRequired={false}
                    labelWidth="12rem"
                  />
                  <SelectField
                    isDisabled={disabled}
                    placeholder="Select uom"
                    options={uomList}
                    label="Packaging UoM Name"
                    id="item_pur_packing_uom_name"
                    name="item_pur_packing_uom_name"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={false}
                    labelWidth="12rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_pur_quantity_per_package"
                    placeholder="xxxxxxx"
                    label="Quantity per Package"
                    isRequired={false}
                    labelWidth="12rem"
                  />
                </div>
              </div>
              <div className="flex flex-wrap mt-10">
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                  <SelectField
                    isDisabled={disabled}
                    placeholder="Select measurement unit"
                    options={measurementUnitOptns}
                    label="Measurement Unit"
                    id="item_pur_measurement_unit"
                    name="item_pur_measurement_unit"
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={false}
                    labelWidth="11.5rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_pur_length"
                    placeholder="xxxxxxx"
                    label="Length"
                    isRequired={false}
                    labelWidth="11.5rem"
                  />

                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_pur_width"
                    placeholder="xxxxxxx"
                    label="Width"
                    isRequired={false}
                    labelWidth="11.5rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_pur_height"
                    placeholder="xxxxxxx"
                    label="Height"
                    isRequired={false}
                    labelWidth="11.5rem"
                  />
                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_pur_volume"
                    placeholder="xxxxxxx"
                    label="Volume"
                    isRequired={false}
                    labelWidth="11.5rem"
                  />

                  <InputField
                    isDisabled={disabled}
                    type="number"
                    name="item_pur_weight"
                    placeholder="xxxxxxx"
                    label="Weight"
                    isRequired={false}
                    labelWidth="11.5rem"
                  />
                  <SelectField
                    isDisabled={true}
                    placeholder="select customs group"
                    options={measurementUnitOptns}
                    label="Customs Group"
                    id="item_pur_customs_group "
                    name="item_pur_customs_group "
                    isSearchable={true}
                    errMsgContainerClass="ps-2"
                    isRequired={false}
                    labelWidth="11.5rem"
                  />
                </div>
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10 mt-10 lg:mt-0">
                  <InputField
                    labelClass="whitespace-nowrap flex text-sm fw_400 text-D0D5DD"
                    isDisabled={true}
                    type="number"
                    name="item_pur_factor_1"
                    placeholder="xxxxxxx"
                    label="Factor 1"
                    isRequired={false}
                    labelWidth="12rem"
                  />
                  <InputField
                    labelClass="whitespace-nowrap flex text-sm fw_400 text-D0D5DD"
                    isDisabled={true}
                    type="number"
                    name="item_pur_factor_2"
                    placeholder="xxxxxxx"
                    label="Factor 2"
                    isRequired={false}
                    labelWidth="12rem"
                  />
                  <InputField
                    labelClass="whitespace-nowrap flex text-sm fw_400 text-D0D5DD"
                    isDisabled={true}
                    type="number"
                    name="item_pur_factor_3"
                    placeholder="xxxxxxx"
                    label="Factor 3"
                    isRequired={false}
                    labelWidth="12rem"
                  />
                  <InputField
                    labelClass="whitespace-nowrap flex text-sm fw_400 text-D0D5DD"
                    isDisabled={true}
                    type="number"
                    name="item_pur_factor_4"
                    placeholder="xxxxxxx"
                    label="Factor 4"
                    isRequired={false}
                    labelWidth="12rem"
                  />
                </div>
              </div>
              {!disabled && (
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <AddButton
                    onclkFn={handleSubmit}
                    prefixIcon={false}
                    text="Save"
                    type="submit"
                  />
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default PurchasingTab;

export const serialBatchesOptns = [
  { label: "Batches", value: "batches" },
  { label: "Numbers", value: "numbers" },
  { label: "Batches and Numbers", value: "batches_numbers" },
];
export const measurementUnitOptns = [
  { label: "Meter", value: "meter" },
  { label: "Centimetres", value: "centimetres" },
  { label: "Inches", value: "inches" },
];
