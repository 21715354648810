import React, { useState } from "react";
import TaxTypeTable from "./tcs-tds-table";
import InnerLayout from "../../../../layouts/inner-layout";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import search from "../../../../../assets/icons/searchIcon.svg";
import TabsComponent from "../../../../helper-components/tabs-component";
import TcsTdsTable from "./tcs-tds-table";
import {
  tcsTaxCreateAction,
  tcsTaxDeleteAction,
  tcsTaxListAction,
  tdsTaxCreateAction,
  tdsTaxDeleteAction,
  tdsTaxListAction,
} from "../../../../../redux/actions/settings-action";

const TcsTdsSetup = () => {
  const [currentTab, setCurrentTab] = useState("TCS setup");
  const [searchValue, setSearchValue] = useState("");
  const [showAddEdit, setShowAddEdit] = useState(false);
  const navigate = useNavigate();
  return (
    <InnerLayout
      list={true}
      title="Settings"
      module="tax-setup"
      // permissionFn={permissionFn}
    >
      <div className="pl-2 h-full">
        <div className="flex justify-between py-2 h-[4rem]">
          <div className="pl-2 pb-4 flex items-center gap-2 text-sm">
            <button
              className="flex items-center gap-2"
              onClick={() => {
                navigate("/settings");
              }}
            >
              <ChevronLeftIcon className="h-5 w-5 inline-block" />
              Settings /
            </button>
            <span className="cursor-pointer">{" " + "TCS TDS Setup"}</span>
          </div>
          <div className="flex items-center">
            <div
              style={{ minWidth: "18rem", height: "2.5rem" }}
              className={`input_border flex items-center rounded-md w-10 mx-6`}
            >
              <span className="px-2">
                {" "}
                <img width={"16px"} src={search} alt="search icon" />
              </span>
              <input
                placeholder={`Search here`}
                className=" table_search_input w-full rounded-md text-xs placeholder:text-xs"
                type="text"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e?.target?.value);
                }}
              />
              <span
                className="text-sm mx-3 cursor-pointer text-primary"
                onClick={() => setSearchValue("")}
              >
                Clear
              </span>
            </div>
          </div>
        </div>
        <TabsComponent
          handleClickfn={(item) => {
            setCurrentTab(item);
          }}
          itemTabs={itemTabs}
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
        />
        <div
          style={{
            height: "calc(100% - 8rem)",
          }}
        >
          <TcsTdsTable
            currentTab={currentTab}
            newLine={
              currentTab === "TCS setup"
                ? {
                    tcs_name: "",
                    tcs_percentage: 0,
                    tcs_type: "",
                  }
                : {
                    tds_name: "",
                    tds_percentage: 0,
                    tds_type: "",
                  }
            }
            createAction={
              currentTab === "TCS setup"
                ? tcsTaxCreateAction
                : tdsTaxCreateAction
            }
            listAction={
              currentTab === "TCS setup" ? tcsTaxListAction : tdsTaxListAction
            }
            deleteAction={
              currentTab === "TCS setup"
                ? tcsTaxDeleteAction
                : tdsTaxDeleteAction
            }
            searchValue={searchValue}
          />
        </div>
      </div>
    </InnerLayout>
  );
};

export default TcsTdsSetup;

const itemTabs = ["TCS setup", "TDS setup"];
