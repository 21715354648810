import React from "react";

const FilterRadioButtons = ({
  options,
  handleChange = () => {},
  value,
  label,
  name = "",
  valueclass = "flex gap-2 flex-col ml-1",
  className = "flex gap-2 flex-col mt-3 ml-1",
}) => {
  return (
    <div className={className}>
      <label className=" font-[var(--popover-heading-font-weight)] text-[length:--popover-font-size] text-[var(--popover-heading-fg-color)] pb-2 ">{label}</label>
      <div className={valueclass}>
        {options?.map((option) => (
          <div key={option?.value} className="flex items-center">
            <input
              type="radio"
              id={option.value + name}
              name={name}
              value={option.value}
              checked={value === option?.value}                           
              onChange={handleChange}
            />
            <label
              htmlFor={option.value + name}
              className="ml-2 font-[var(--popover-font-weight)] cursor-pointer  text-[length:--popover-font-size] text-[var(--popover-fg-color)] whitespace-nowrap"
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterRadioButtons;
