import { call, takeLatest } from "redux-saga/effects";
import {
  alternativeItemsListApi,
  currentPrintPreferenceApi,
  deleteAlternativeTransApi,
  documentNumberingList,
  documentNumberingSeriesAddApi,
  documentNumberingSeriesList,
  documentNumberingSeriesUpdateApi,
  documentNumberingUpdateApi,
  itemCategoryAddApi,
  itemCategoryDeleteApi,
  itemCategoryListApi,
  itemCategoryUpdateApi,
  itemGroupAddApi,
  itemGroupDeleteApi,
  itemGroupListApi,
  itemGroupUpdateApi,
  itemTypeAddApi,
  itemTypeDeleteApi,
  itemTypeListApi,
  itemTypeUpdateApi,
  manufacturerAddApi,
  manufacturerDeleteApi,
  manufacturerListApi,
  manufacturerUpdateApi,
  mapAlternativerItemApi,
  paytmAddApi,
  paytmUpdateApi,
  paytmViewApi,
  phonepeAddApi,
  phonepeUpdateApi,
  phonepeViewApi,
  pineLabsAddApi,
  pineLabsUpdateApi,
  pineLabsViewApi,
  printPreferenceListApi,
  printPreferenceUpdateApi,
  razorpayAddApi,
  razorpayUpdateApi,
  razorpayViewApi,
  shippingTypeAddApi,
  shippingTypeDeleteApi,
  shippingTypeListApi,
  shippingTypeUpdateApi,
  smsAddApi,
  smsUpdateApi,
  smsViewApi,
  smtpAddApi,
  smtpUpdateApi,
  smtpViewApi,
  storeDetailsGetApi,
  storeDetailsUpdateApi,
  stripeAddApi,
  stripeUpdateApi,
  stripeViewApi,
  taxCombinationAddApi,
  taxCombinationDeleteApi,
  taxCombinationItemDeleteApi,
  taxCombinationListApi,
  taxCombinationUpdateApi,
  taxTypeAddApi,
  taxTypeDeleteApi,
  taxTypeDropDownApi,
  taxTypeListApi,
  taxTypeUpdateApi,
  tcsTaxCreateApi,
  tcsTaxDeleteApi,
  tcsTaxListApi,
  tdsTaxCreateApi,
  tdsTaxDeleteApi,
  tdsTaxListApi,
  templateCreateApi,
  templateDeleteApi,
  templateEditApi,
  templateTableApi,
  templateViewApi,
  uomCreateApi,
  uomGroupDataAddApi,
  uomGroupDataListApi,
  uomGroupDataUpdateApi,
  uomGroupDeleteApi,
  uomGroupItemDeleteApi,
  uomGroupUomListApi,
  uomListApi,
  uomUpdateApi,
  whatsappAddApi,
  whatsappUpdateApi,
  whatsappViewApi,
} from "../apis/settings-api";
import {
  ADD_ITEM_CATEGORY,
  ADD_ITEM_GROUP,
  ADD_ITEM_TYPE,
  ADD_MANUFACTURER,
  ADD_SHIPPING_TYPE,
  ADD_TAX_COMBINATION,
  ADD_TAX_TYPE,
  ADD_UOM,
  ADD_UOM_GROUP,
  ALTERNATIVE_ITEMS_LIST,
  CURRENT_PRINTPREFERENCE,
  DELETE_ALTERNATIVE_TRANSACTION,
  DELETE_ITEM_CATEGORY,
  DELETE_ITEM_GROUP,
  DELETE_ITEM_TYPE,
  DELETE_MANUFACTURER,
  DELETE_SHIPPING_TYPE,
  DELETE_TAX_COMBINATION,
  DELETE_TAX_COMBINATION_ITEM,
  DELETE_TAX_TYPE,
  DELETE_UOM_GROUP,
  DELETE_UOM_GROUP_ITEM,
  DOCUMENT_NUMBERING_LIST,
  DOCUMENT_NUMBERING_SERIES_ADD,
  DOCUMENT_NUMBERING_SERIES_LIST,
  DOCUMENT_NUMBERING_SERIES_UPDATE,
  DOCUMENT_NUMBERING_UPDATE,
  ITEM_CATEGORY_LIST,
  ITEM_GROUP_TABLE_LIST,
  ITEM_TYPE_LIST,
  LIST_UOM,
  MANUFACTURERS_TABLE_LIST,
  MAP_ALTERNATIVE_ITEM,
  PAYTM_ADD,
  PAYTM_UPDATE,
  PAYTM_VIEW,
  PHONEPE_ADD,
  PHONEPE_UPDATE,
  PHONEPE_VIEW,
  PINE_LABS_ADD,
  PINE_LABS_UPDATE,
  PINE_LABS_VIEW,
  PRINTPREFERENCE_LIST,
  PRINTPREFERENCE_UPDATE,
  RAZORPAY_ADD,
  RAZORPAY_UPDATE,
  RAZORPAY_VIEW,
  SHIPPING_TYPE_TABLE__LIST,
  SMS_ADD,
  SMS_UPDATE,
  SMS_VIEW,
  SMTP_ADD,
  SMTP_UPDATE,
  SMTP_VIEW,
  STORE_GET,
  STORE_UPDATE,
  STRIPE_ADD,
  STRIPE_UPDATE,
  STRIPE_VIEW,
  TAX_COMBINATION_LIST,
  TAX_TYPE_DROPDOWN,
  TAX_TYPE_LIST,
  TCS_TAX_ADD,
  TCS_TAX_DELETE,
  TCS_TAX_LIST,
  TDS_TAX_ADD,
  TDS_TAX_DELETE,
  TDS_TAX_LIST,
  TEMPLATE_CREATE,
  TEMPLATE_DELETE,
  TEMPLATE_EDIT,
  TEMPLATE_TABLE,
  TEMPLATE_VIEW,
  UOM_DROPDOWN,
  UOM_GROUP_TABLE_LIST,
  UPDATE_ITEM_CATEGORY,
  UPDATE_ITEM_GROUP,
  UPDATE_ITEM_TYPE,
  UPDATE_MANUFACTURER,
  UPDATE_SHIPPING_TYPE,
  UPDATE_TAX_COMBINATION,
  UPDATE_TAX_TYPE,
  UPDATE_UOM,
  UPDATE_UOM_GROUP,
  WHATSAPP_ADD,
  WHATSAPP_UPDATE,
  WHATSAPP_VIEW,
} from "../types";

/**UOM */
export function* uomCreateWorker(action) {
  try {
    const res = yield call(uomCreateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* uomUpdateWorker(action) {
  try {
    const res = yield call(uomUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* uomListWorker(action) {
  try {
    const res = yield call(uomListApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

/**UOM GROUPS */
export function* uomGroupDataListWorker(action) {
  try {
    const res = yield call(uomGroupDataListApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* uomGroupDataAddWorker(action) {
  try {
    const res = yield call(uomGroupDataAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* uomGroupDataUpdateWorker(action) {
  try {
    const res = yield call(uomGroupDataUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* uomGroupDeleteWorker(action) {
  try {
    const res = yield call(uomGroupDeleteApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* uomGroupItemDeleteWorker(action) {
  try {
    const res = yield call(uomGroupItemDeleteApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* uomGroupuomListWorker(action) {
  try {
    const res = yield call(uomGroupUomListApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

/**Item Setup */
export function* itemTypeAddWorker(action) {
  try {
    const res = yield call(itemTypeAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* itemTypeUpdateWorker(action) {
  try {
    const res = yield call(itemTypeUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* itemTypeDeleteWorker(action) {
  try {
    const res = yield call(itemTypeDeleteApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* itemTypeListWorker(action) {
  try {
    const res = yield call(itemTypeListApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* itemCategoryAddWorker(action) {
  try {
    const res = yield call(itemCategoryAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* itemCategoryUpdateWorker(action) {
  try {
    const res = yield call(itemCategoryUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* itemCategoryDeleteWorker(action) {
  try {
    const res = yield call(itemCategoryDeleteApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* itemCategoryListWorker(action) {
  try {
    const res = yield call(itemCategoryListApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* itemGroupAddWorker(action) {
  try {
    const res = yield call(itemGroupAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* itemGroupUpdateWorker(action) {
  try {
    const res = yield call(itemGroupUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* itemGroupDeleteWorker(action) {
  try {
    const res = yield call(itemGroupDeleteApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* itemGroupListWorker(action) {
  try {
    const res = yield call(itemGroupListApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

/**Manufacturer Setup */
export function* manufacturerAddWorker(action) {
  try {
    const res = yield call(manufacturerAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* manufacturerUpdateWorker(action) {
  try {
    const res = yield call(manufacturerUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* manufacturerDeleteWorker(action) {
  try {
    const res = yield call(manufacturerDeleteApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* manufacturerTableListWorker(action) {
  try {
    const res = yield call(manufacturerListApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

/**Tax Setup */
export function* taxTypeAddWorker(action) {
  try {
    const res = yield call(taxTypeAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* taxTypeUpdateWorker(action) {
  try {
    const res = yield call(taxTypeUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* taxTypeDeleteWorker(action) {
  try {
    const res = yield call(taxTypeDeleteApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* taxTypeListWorker(action) {
  try {
    const res = yield call(taxTypeListApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* taxCombinationAddWorker(action) {
  try {
    const res = yield call(taxCombinationAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* taxCombinationUpdateWorker(action) {
  try {
    const res = yield call(taxCombinationUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* taxCombinationListWorker(action) {
  try {
    const res = yield call(taxCombinationListApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* taxCombinationDeleteWorker(action) {
  try {
    const res = yield call(taxCombinationDeleteApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* taxCombinationItemDeleteWorker(action) {
  try {
    const res = yield call(taxCombinationItemDeleteApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* taxTypeDropDownWorker(action) {
  try {
    const res = yield call(taxTypeDropDownApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

/**Shipping Type */

export function* shippingTypeAddWorker(action) {
  try {
    const res = yield call(shippingTypeAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* shippingTypeUpdateWorker(action) {
  try {
    const res = yield call(shippingTypeUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* shippingTypeListWorker(action) {
  try {
    const res = yield call(shippingTypeListApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* shippingTypeDeleteWorker(action) {
  try {
    const res = yield call(shippingTypeDeleteApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

/**Document Numbering */
export function* documentNumberListWorker(action) {
  try {
    const res = yield call(documentNumberingList, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* documentNumberUpdateWorker(action) {
  try {
    const res = yield call(documentNumberingUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* documentNumberSeriesListWorker(action) {
  try {
    const res = yield call(documentNumberingSeriesList, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* documentNumberSeriesAddWorker(action) {
  try {
    const res = yield call(documentNumberingSeriesAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* documentNumberSeriesUpdateWorker(action) {
  try {
    const res = yield call(documentNumberingSeriesUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

/**Communication  */
//SMTP
export function* smtpViewWorker(action) {
  try {
    const res = yield call(smtpViewApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* smtpAddWorker(action) {
  try {
    const res = yield call(smtpAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* smtpUpdateWorker(action) {
  try {
    const res = yield call(smtpUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

//Whatsapp
export function* whatsappViewWorker(action) {
  try {
    const res = yield call(whatsappViewApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* whatsappAddWorker(action) {
  try {
    const res = yield call(whatsappAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* whatsappUpdateWorker(action) {
  try {
    const res = yield call(whatsappUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

//SMS
export function* smsViewWorker(action) {
  try {
    const res = yield call(smsViewApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* smsAddWorker(action) {
  try {
    const res = yield call(smsAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* smsUpdateWorker(action) {
  try {
    const res = yield call(smsUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

/**Payment Configuration */
//Razorpay
export function* razorpayViewWorker(action) {
  try {
    const res = yield call(razorpayViewApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* razorpayAddWorker(action) {
  try {
    const res = yield call(razorpayAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* razorpayUpdateWorker(action) {
  try {
    const res = yield call(razorpayUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

//Stripe
export function* stripeViewWorker(action) {
  try {
    const res = yield call(stripeViewApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* stripeAddWorker(action) {
  try {
    const res = yield call(stripeAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* stripeUpdateWorker(action) {
  try {
    const res = yield call(stripeUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

//Phonepe
export function* phonepeViewWorker(action) {
  try {
    const res = yield call(phonepeViewApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* phonepeAddWorker(action) {
  try {
    const res = yield call(phonepeAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* phonepeUpdateWorker(action) {
  try {
    const res = yield call(phonepeUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

//Paytm
export function* paytmViewWorker(action) {
  try {
    const res = yield call(paytmViewApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* paytmAddWorker(action) {
  try {
    const res = yield call(paytmAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* paytmUpdateWorker(action) {
  try {
    const res = yield call(paytmUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

//Pine Labs
export function* pineLabsViewWorker(action) {
  try {
    const res = yield call(pineLabsViewApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* pineLabsAddWorker(action) {
  try {
    const res = yield call(pineLabsAddApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* pineLabsUpdateWorker(action) {
  try {
    const res = yield call(pineLabsUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* printPreferenceListWorker(action) {
  try {
    const res = yield call(printPreferenceListApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* printPreferenceUpdateWorker(action) {
  try {
    const res = yield call(printPreferenceUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* currentPrintPreferenceWorker(action) {
  try {
    const res = yield call(currentPrintPreferenceApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* storeDetailsUpdateWorker(action) {
  try {
    const res = yield call(storeDetailsUpdateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* storeDetailsGetWorker(action) {
  try {
    const res = yield call(storeDetailsGetApi, action.data);
    if (res.data?.code === 200) {
      localStorage.setItem("company", res?.data?.data?.company);
      localStorage.setItem(
        "company_landmark",
        res?.data?.data?.company_landmark
      );
      localStorage.setItem("store_icon", res?.data?.data?.store_icon);
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

// Alternative item setup
export function* mapAlternativerItemWorker(action) {
  try {
    const res = yield call(mapAlternativerItemApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* alternativeItemsListWorker(action) {
  try {
    const res = yield call(alternativeItemsListApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* deleteAlternativeTransWorker(action) {
  try {
    const res = yield call(deleteAlternativeTransApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* tcsTaxListWorker(action) {
  try {
    const res = yield call(tcsTaxListApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* tcsTaxCreateWorker(action) {
  try {
    const res = yield call(tcsTaxCreateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* tcsTaxDeleteWorker(action) {
  try {
    const res = yield call(tcsTaxDeleteApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* tdsTaxListWorker(action) {
  try {
    const res = yield call(tdsTaxListApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* tdsTaxCreateWorker(action) {
  try {
    const res = yield call(tdsTaxCreateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* tdsTaxDeleteWorker(action) {
  try {
    const res = yield call(tdsTaxDeleteApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* templateCreateWorker(action) {
  try {
    const res = yield call(templateCreateApi, action.data);
    
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* templateTableWorker(action) {
  try {
    const res = yield call(templateTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* templateViewWorker(action) {
  try {
    const res = yield call(templateViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* templateDeleteWorker(action) {
  try {
    const res = yield call(templateDeleteApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* templateEditWorker(action) {
  try {
    const res = yield call(templateEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

/**Watcher functions */

/**UOM */
export function* uomCreateWatcher() {
  yield takeLatest(ADD_UOM, uomCreateWorker);
}

export function* uomUpdateWatcher() {
  yield takeLatest(UPDATE_UOM, uomUpdateWorker);
}

export function* uomListWatcher() {
  yield takeLatest(LIST_UOM, uomListWorker);
}

/**UOM GROUPS */
export function* uomGroupDataListWatcher() {
  yield takeLatest(UOM_GROUP_TABLE_LIST, uomGroupDataListWorker);
}

export function* uomGroupDataAddWatcher() {
  yield takeLatest(ADD_UOM_GROUP, uomGroupDataAddWorker);
}

export function* uomGroupDataUpdateWatcher() {
  yield takeLatest(UPDATE_UOM_GROUP, uomGroupDataUpdateWorker);
}

export function* uomGroupDeleteWatcher() {
  yield takeLatest(DELETE_UOM_GROUP, uomGroupDeleteWorker);
}

export function* uomGroupItemDeleteWatcher() {
  yield takeLatest(DELETE_UOM_GROUP_ITEM, uomGroupItemDeleteWorker);
}

export function* uomGroupUomListWatcher() {
  yield takeLatest(UOM_DROPDOWN, uomGroupuomListWorker);
}

/**Item Setup */
export function* itemTypeAddWatcher() {
  yield takeLatest(ADD_ITEM_TYPE, itemTypeAddWorker);
}

export function* itemTypeUpdateWatcher() {
  yield takeLatest(UPDATE_ITEM_TYPE, itemTypeUpdateWorker);
}

export function* itemTypeDeleteWatcher() {
  yield takeLatest(DELETE_ITEM_TYPE, itemTypeDeleteWorker);
}

export function* itemTypeListWatcher() {
  yield takeLatest(ITEM_TYPE_LIST, itemTypeListWorker);
}

export function* itemCategoryAddWatcher() {
  yield takeLatest(ADD_ITEM_CATEGORY, itemCategoryAddWorker);
}

export function* itemCategoryUpdateWatcher() {
  yield takeLatest(UPDATE_ITEM_CATEGORY, itemCategoryUpdateWorker);
}

export function* itemCategoryListWatcher() {
  yield takeLatest(ITEM_CATEGORY_LIST, itemCategoryListWorker);
}

export function* itemCategoryDeleteWatcher() {
  yield takeLatest(DELETE_ITEM_CATEGORY, itemCategoryDeleteWorker);
}

export function* itemGroupAddWatcher() {
  yield takeLatest(ADD_ITEM_GROUP, itemGroupAddWorker);
}

export function* itemGroupUpdateWatcher() {
  yield takeLatest(UPDATE_ITEM_GROUP, itemGroupUpdateWorker);
}

export function* itemGroupDeleteWatcher() {
  yield takeLatest(DELETE_ITEM_GROUP, itemGroupDeleteWorker);
}

export function* itemGroupListWatcher() {
  yield takeLatest(ITEM_GROUP_TABLE_LIST, itemGroupListWorker);
}

/**Manufacturer setup */
export function* manufacturerAddWatcher() {
  yield takeLatest(ADD_MANUFACTURER, manufacturerAddWorker);
}

export function* manufacturerUpdateWatcher() {
  yield takeLatest(UPDATE_MANUFACTURER, manufacturerUpdateWorker);
}

export function* manufacturerDeleteWatcher() {
  yield takeLatest(DELETE_MANUFACTURER, manufacturerDeleteWorker);
}

export function* manufacturerTableListWatcher() {
  yield takeLatest(MANUFACTURERS_TABLE_LIST, manufacturerTableListWorker);
}

/**Tax setup */
export function* taxTypeAddWatcher() {
  yield takeLatest(ADD_TAX_TYPE, taxTypeAddWorker);
}

export function* taxTypeUpdateWatcher() {
  yield takeLatest(UPDATE_TAX_TYPE, taxTypeUpdateWorker);
}

export function* taxTypeDeleteWatcher() {
  yield takeLatest(DELETE_TAX_TYPE, taxTypeDeleteWorker);
}

export function* taxTypeTableListWatcher() {
  yield takeLatest(TAX_TYPE_LIST, taxTypeListWorker);
}

export function* taxCombinationAddWatcher() {
  yield takeLatest(ADD_TAX_COMBINATION, taxCombinationAddWorker);
}

export function* taxCombinationUpdateWatcher() {
  yield takeLatest(UPDATE_TAX_COMBINATION, taxCombinationUpdateWorker);
}

export function* taxCombinationDeleteWatcher() {
  yield takeLatest(DELETE_TAX_COMBINATION, taxCombinationDeleteWorker);
}

export function* taxCombinationItemDeleteWatcher() {
  yield takeLatest(DELETE_TAX_COMBINATION_ITEM, taxCombinationItemDeleteWorker);
}

export function* taxCombinationTableListWatcher() {
  yield takeLatest(TAX_COMBINATION_LIST, taxCombinationListWorker);
}

export function* taxTypeDropDownWatcher() {
  yield takeLatest(TAX_TYPE_DROPDOWN, taxTypeDropDownWorker);
}

/**SHipping Type */
export function* shippingTypeAddWatcher() {
  yield takeLatest(ADD_SHIPPING_TYPE, shippingTypeAddWorker);
}

export function* shippingTypeUpdateWatcher() {
  yield takeLatest(UPDATE_SHIPPING_TYPE, shippingTypeUpdateWorker);
}

export function* shippingTypeDeleteWatcher() {
  yield takeLatest(DELETE_SHIPPING_TYPE, shippingTypeDeleteWorker);
}

export function* shippingTypeTableListWatcher() {
  yield takeLatest(SHIPPING_TYPE_TABLE__LIST, shippingTypeListWorker);
}

export function* documentNumberingListWatcher() {
  yield takeLatest(DOCUMENT_NUMBERING_LIST, documentNumberListWorker);
}

export function* documentNumberingUpdateWatcher() {
  yield takeLatest(DOCUMENT_NUMBERING_UPDATE, documentNumberUpdateWorker);
}

export function* documentNumberingSeriesListWatcher() {
  yield takeLatest(
    DOCUMENT_NUMBERING_SERIES_LIST,
    documentNumberSeriesListWorker
  );
}

export function* documentNumberingSeriesAddWatcher() {
  yield takeLatest(
    DOCUMENT_NUMBERING_SERIES_ADD,
    documentNumberSeriesAddWorker
  );
}

export function* documentNumberingSeriesUpdateWatcher() {
  yield takeLatest(
    DOCUMENT_NUMBERING_SERIES_UPDATE,
    documentNumberSeriesUpdateWorker
  );
}

export function* smtpViewWatcher() {
  yield takeLatest(SMTP_VIEW, smtpViewWorker);
}

export function* smtpAddWatcher() {
  yield takeLatest(SMTP_ADD, smtpAddWorker);
}

export function* smtpUpdateWatcher() {
  yield takeLatest(SMTP_UPDATE, smtpUpdateWorker);
}

export function* whatsappViewWatcher() {
  yield takeLatest(WHATSAPP_VIEW, whatsappViewWorker);
}

export function* whatsappAddWatcher() {
  yield takeLatest(WHATSAPP_ADD, whatsappAddWorker);
}

export function* whatsappUpdateWatcher() {
  yield takeLatest(WHATSAPP_UPDATE, whatsappUpdateWorker);
}

export function* smsViewWatcher() {
  yield takeLatest(SMS_VIEW, smsViewWorker);
}

export function* smsAddWatcher() {
  yield takeLatest(SMS_ADD, smsAddWorker);
}

export function* smsUpdateWatcher() {
  yield takeLatest(SMS_UPDATE, smsUpdateWorker);
}

export function* razorpayViewWatcher() {
  yield takeLatest(RAZORPAY_VIEW, razorpayViewWorker);
}

export function* razorpayAddWatcher() {
  yield takeLatest(RAZORPAY_ADD, razorpayAddWorker);
}

export function* razorpayUpdateWatcher() {
  yield takeLatest(RAZORPAY_UPDATE, razorpayUpdateWorker);
}

export function* stripeViewWatcher() {
  yield takeLatest(STRIPE_VIEW, stripeViewWorker);
}

export function* stripeAddWatcher() {
  yield takeLatest(STRIPE_ADD, stripeAddWorker);
}

export function* stripeUpdateWatcher() {
  yield takeLatest(STRIPE_UPDATE, stripeUpdateWorker);
}

export function* phonepeViewWatcher() {
  yield takeLatest(PHONEPE_VIEW, phonepeViewWorker);
}

export function* phonepeAddWatcher() {
  yield takeLatest(PHONEPE_ADD, phonepeAddWorker);
}

export function* phonepeUpdateWatcher() {
  yield takeLatest(PHONEPE_UPDATE, phonepeUpdateWorker);
}

export function* paytmViewWatcher() {
  yield takeLatest(PAYTM_VIEW, paytmViewWorker);
}

export function* paytmAddWatcher() {
  yield takeLatest(PAYTM_ADD, paytmAddWorker);
}

export function* paytmUpdateWatcher() {
  yield takeLatest(PAYTM_UPDATE, paytmUpdateWorker);
}

export function* pineLabsViewWatcher() {
  yield takeLatest(PINE_LABS_VIEW, pineLabsViewWorker);
}

export function* pineLabsAddWatcher() {
  yield takeLatest(PINE_LABS_ADD, pineLabsAddWorker);
}

export function* pineLabsUpdateWatcher() {
  yield takeLatest(PINE_LABS_UPDATE, pineLabsUpdateWorker);
}

export function* printPreferenceListWatcher() {
  yield takeLatest(PRINTPREFERENCE_LIST, printPreferenceListWorker);
}

export function* printPreferenceUpdateWatcher() {
  yield takeLatest(PRINTPREFERENCE_UPDATE, printPreferenceUpdateWorker);
}

export function* currentPrintPreferenceeWatcher() {
  yield takeLatest(CURRENT_PRINTPREFERENCE, currentPrintPreferenceWorker);
}
export function* storeDetailsUpdateWatcher() {
  yield takeLatest(STORE_UPDATE, storeDetailsUpdateWorker);
}

export function* storeDetailsGetWatcher() {
  yield takeLatest(STORE_GET, storeDetailsGetWorker);
}

export function* mapAlternativerItemWatcher() {
  yield takeLatest(MAP_ALTERNATIVE_ITEM, mapAlternativerItemWorker);
}
export function* alternativeItemsListWatcher() {
  yield takeLatest(ALTERNATIVE_ITEMS_LIST, alternativeItemsListWorker);
}
export function* deleteAlternativeTransWatcher() {
  yield takeLatest(
    DELETE_ALTERNATIVE_TRANSACTION,
    deleteAlternativeTransWorker
  );
}

/**TCS TDS setup */
export function* tcsTaxCreateWatcher() {
  yield takeLatest(TCS_TAX_ADD, tcsTaxCreateWorker);
}

export function* tcsTaxDeleteWatcher() {
  yield takeLatest(TCS_TAX_DELETE, tcsTaxDeleteWorker);
}

export function* tcsTaxListWatcher() {
  yield takeLatest(TCS_TAX_LIST, tcsTaxListWorker);
}
export function* tdsTaxCreateWatcher() {
  yield takeLatest(TDS_TAX_ADD, tdsTaxCreateWorker);
}

export function* tdsTaxDeleteWatcher() {
  yield takeLatest(TDS_TAX_DELETE, tdsTaxDeleteWorker);
}

export function* tdsTaxListWatcher() {
  yield takeLatest(TDS_TAX_LIST, tdsTaxListWorker);
}

export function* templateCreateWatcher() {
  yield takeLatest(TEMPLATE_CREATE, templateCreateWorker);
}
export function* templateTableWatcher() {
  yield takeLatest(TEMPLATE_TABLE, templateTableWorker);
}
export function* templateViewWatcher() {
  yield takeLatest(TEMPLATE_VIEW, templateViewWorker);
}
export function* templateEditWatcher() {
  yield takeLatest(TEMPLATE_EDIT, templateEditWorker);
}
export function* templateDeleteWatcher() {
  yield takeLatest(TEMPLATE_DELETE, templateDeleteWorker);
}

