import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import CloseIcon from "../../../../assets/icons/close-icon";
import UploadIcon from "../../../../assets/icons/upload-icon";
import {
  getBackgroundColor,
  positiveIntegerFn,
} from "../../../helper-components/helper-function";
import Papa from "papaparse";
import errorToast from "../../../helper-components/error-toast";
import AddButton, { DraftButton } from "../../../buttons/button";
import { Field, Form, Formik } from "formik";
import AsyncCreateSelect from "../../../forms/async-create-select-field";
import { customerListAction } from "../../../../redux/actions/general-action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectField from "../../../forms/select-field";
import * as Yup from "yup";

import {
  templateCreateAction,
  templateEditAction,
  templateViewAction,
} from "../../../../redux/actions/settings-action";
import successToast from "../../../helper-components/success-toast";
import LoadingSpinner from "../../../helper-components/loading-spinner";
import TemplateAddOptModal from "./template-additional-option-modal";
import TemplateFormatPreview from "./template-format-preview";

const TemplateUploadModal = ({
  isModalOpen = false,
  setIsModalOpen,
  mapping_id = "",
  setMapping_id,
  reload,
  setReload,
}) => {
  const [templateData, setTemplateData] = useState({});
  const formikRef = useRef(null); // Create ref for Formik
  const [isPreviewOpen, setisPreviewOpen] = useState(false);
  const [format, setFormat] = useState({
    gst_format: templateData?.gst_format || "gst",
    exp_format: templateData?.exp_format || "single",
  });
  const [loading, setLoading] = useState(false);
  const [isAddOptOpen, setIsAddOptOpen] = useState(false);
  const [formValues, setFormValues] = useState({});
  const navigate = useNavigate();
  const [localData, setLocalData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (mapping_id && isModalOpen) {
      setLoading(true);
      dispatch(templateViewAction(mapping_id, onSuccessView, onErrorView));
    }
  }, [mapping_id, isModalOpen]);

  const onSuccessView = (data) => {
    setTemplateData(data?.data?.[0]);
    console.log(data, "dsfdssdfsdfsdfsdfasd");
    setLoading(false);
  };
  const onErrorView = (data) => {
    setLoading(false);
  };

  console.log(getGrnColumns(format), "dffddsfdsfdsfdsfsfsfsfsfs");
  const initial = {
    gst_format: "gst",
    exp_format: "single",
    mapping_id: "",
    filename: "",
    cmr_vendor_name: "",
    cmr_vendor_id: "",
    excel_rows: [],
    excel_columns: [],
    mapped_columns: [],
    module_name: "purchase",
    grnColumns: getGrnColumns(formValues),
  };
  const validationSchema = Yup.object().shape({
    cmr_vendor_id: Yup.string().required("Vendor is required"),
    cmr_vendor_name: Yup.object().required("Customer name is required"),
  });

  const initialValues = {
    mapping_id: mapping_id || "",
    gst_format: templateData?.gst_format || "gst",
    exp_format: templateData?.exp_format || "single",
    filename: "",
    cmr_vendor_name: templateData?.cmr_vendor_id
      ? {
          label: templateData?.cmr_vendor_name,
          value: templateData?.cmr_vendor_id,
        }
      : "",
    cmr_vendor_id: templateData?.cmr_vendor_id ?? "",
    excel_rows: templateData?.excel_rows || [],
    excel_columns: templateData?.excel_columns || [],
    mapped_columns: templateData?.mapped_columns ?? [],
    module_name: templateData?.module_name || "purchase",
    grnColumns: getGrnColumns(mapping_id ? templateData : initial)?.map(
      (col) => {
        const mappedColumns = templateData?.mapped_columns || {}; // Ensure mapped_columns is an object
        const excelColumns = templateData?.excel_columns || []; // Ensure excel_columns is an array

        const supplierIndex = mappedColumns[col.name]
          ? excelColumns.indexOf(mappedColumns[col.name]) + 1
          : null;

        return { ...col, value: supplierIndex };
      }
    ),
  };

  const handleFileUpload = (event, setValues) => {
    const file = event.target.files[0];
    if (!file) return;
    event.target.value = null; // Reset file input
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const parsedData = results.data;
        if (!parsedData || parsedData.length === 0) {
          errorToast(
            "Error: The CSV file is empty or contains no valid records."
          );
          return;
        }
        setValues({
          ...initial,
          excel_rows: parsedData,
          filename: file?.name,
          excel_columns: Object.keys(parsedData?.[0]),
        });
      },
      error: (error) => {
        errorToast("Error parsing file:", error);
      },
    });
  };

  const loadOptions = (inputValue, field) => {
    return new Promise((resolve) => {
      const onSuccess = (data) => {
        const options = [
          ...data.data.map((item) => ({
            value: item.cmr_id,
            label: item?.cmr_code + "-" + item?.cmr_first_name,
          })),
          {
            value: "new_customer",
            label: <span className="text-primary">+ New Vendor</span>,
          },
        ];
        setLocalData(data.data);
        resolve(options);
      };

      const onError = (error) => {
        resolve([
          {
            value: "new_customer",
            label: <span className="text-primary">+ New Vendor</span>,
          },
        ]);
      };

      if (inputValue?.length > 0) {
        dispatch(
          customerListAction(
            {
              field: field,
              value: inputValue,
              isVendor: true,
            },
            onSuccess,
            onError
          )
        );
      } else {
        resolve([]);
      }
    });
  };

  const onSubmit = (values) => {
    console.log(values, "dfgfdgfdgdfgdfgdfg");
    let actionType = templateCreateAction;
    if (values?.mapping_id) {
      actionType = templateEditAction;
    }
    const { grnColumns, filename, cmr_vendor_name, ...rest } = values;
    dispatch(
      actionType(
        {
          ...rest,
          cmr_vendor_name: cmr_vendor_name?.label,
          mapped_columns: grnColumns.reduce((acc, col) => {
            // Get the corresponding supplier column based on value-1 (since value is 1-based index)
            acc[col.name] = col.value
              ? rest?.excel_columns[col.value - 1] || null
              : null;
            return acc;
          }, {}),
        },
        (data) => {
          successToast(data?.data?.message);
          setMapping_id("");
          setReload(!reload);
          formikRef?.current?.resetForm({ values: initial }); // Reset to initial object
          setTemplateData({});
          setIsModalOpen(false);
        },
        onError
      )
    );
  };

  const onError = (data) => {
    errorToast(data?.data?.message);
  };

  return (
    <Transition show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => {
          setIsModalOpen(false);
          setMapping_id("");
          formikRef?.current?.resetForm({ values: initial }); // Reset to initial object
        }}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
          </Transition.Child>

          {/* This is your modal container */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-0"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div
              className={`mx-auto ${
                formValues?.filename || mapping_id ? "h-[90vh] w-[90%]" : ""
              }  p-5  text-left align-middle transition-all transform bg-white shadow-xl rounded-xl`}
            >
              <Formik
                innerRef={formikRef} // Assign ref to Formik
                // key={initialValues}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, setFieldValue, errors, setValues }) => {
                  setFormValues(values);

                  console.log(values, "dsfsdfsdfsdfsfsdfsdfsdfs");
                  return (
                    <Form className="h-full w-full ">
                      <TemplateAddOptModal
                        isAddOptOpen={isAddOptOpen}
                        setIsAddOptOpen={setIsAddOptOpen}
                      />
                      {values?.filename || mapping_id ? (
                        <div
                          style={{
                            height: `calc(100% - 3rem)`,
                          }}
                        >
                          <div className="flex justify-between items-start h-[3rem]">
                            <span className="text-000000 text-lg fw_600">
                              {mapping_id
                                ? "Edit Template"
                                : "Step (2/2) - Mapping and Template details"}
                            </span>{" "}
                            <button
                              type="button"
                              className="close-button bg-transparent border-none p-0 hover:bg-787878"
                              onClick={() => {
                                setIsModalOpen(false);
                                setMapping_id("");
                                setValues(initial);
                              }}
                            >
                              <CloseIcon />
                            </button>
                          </div>
                          {loading ? (
                            <LoadingSpinner />
                          ) : (
                            <>
                              <div className="flex  justify-between items-start w-full  px-3 h-[4rem]">
                                <div className="flex gap-5 items-center">
                                  <div className="w-[30rem] ">
                                    <AsyncCreateSelect
                                      menuPosition=""
                                      menuPortalTarget={document.body}
                                      className={`z-20 ${
                                        errors?.cmr_vendor_name &&
                                        !values?.cmr_vendor_name
                                          ? "selectField_border__error"
                                          : "selectField_border"
                                      }`}
                                      errorClass="hidden"
                                      disabled={false}
                                      minWidth="8.5rem"
                                      marginRight=""
                                      cusType="Vendor"
                                      labelClass="whitespace-nowrap flex text-sm fw_600   gap-2"
                                      type="text"
                                      label="Vendor Name"
                                      autoFocus={true}
                                      name={"cmr_vendor_name"}
                                      placeholder={`Enter Vendor Name`}
                                      onCreateOption={() => {}}
                                      loadOptions={(e) =>
                                        loadOptions(e, "cmr_first_name")
                                      }
                                      onChange={(
                                        selectedOption,
                                        actionMeta
                                      ) => {
                                        if (actionMeta?.action === "clear") {
                                          setFieldValue("cmr_vendor_id", "");
                                          setFieldValue("cmr_vendor_name", "");
                                        } else if (
                                          selectedOption === "new_customer" ||
                                          selectedOption?.value ===
                                            "new_customer"
                                        ) {
                                          navigate("/add-customer");
                                        } else {
                                          const customerData = localData?.find(
                                            (item) =>
                                              item?.cmr_id ===
                                              selectedOption?.value
                                          );

                                          setFieldValue(
                                            "cmr_vendor_id",
                                            customerData?.cmr_id
                                          );
                                          setFieldValue("cmr_vendor_name", {
                                            value: customerData?.cmr_id,
                                            label: customerData?.cmr_first_name,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="w-[10rem]">
                                    <SelectField
                                      containerClass="z-10"
                                      errMsgContainerClass="hidden"
                                      menuPosition=""
                                      isDisabled={false}
                                      placeholder="Select type"
                                      options={TransTypeOptions}
                                      label=""
                                      id="module_name"
                                      name="module_name"
                                      isSearchable={false}
                                      isRequired={false}
                                      labelWidth="8rem"
                                    />
                                  </div>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setIsAddOptOpen(true);
                                    }}
                                    className="text-sm underline text-primary"
                                  >
                                    Additional Options
                                  </button>
                                </div>
                              </div>
                              <div
                                className="flex gap-5"
                                style={{
                                  height: `calc(100% - 7rem)`,
                                }}
                              >
                                <div className="w-[60%] overflow-auto h-full">
                                  <table className="w-full">
                                    <thead className="sticky top-0 z-9 bg-F2F4F5">
                                      <tr>
                                        {tableHeading1.map((heading, index) => (
                                          <th
                                            className={`tab-header whitespace-nowrap text-xs fw_700 text-475467 bg-F2F4F5 text-start`}
                                            key={heading}
                                          >
                                            {heading}
                                          </th>
                                        ))}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {values?.excel_columns?.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <td
                                              className={`tab-cell text-sm fw_400 text-secondary w-20`}
                                            >
                                              {index + 1}
                                            </td>
                                            <td
                                              className={`tab-cell text-sm fw_400 text-secondary`}
                                            >
                                              {item}
                                            </td>
                                            <td
                                              className={`tab-cell text-sm fw_400 text-secondary `}
                                            >
                                              {" "}
                                              {values?.excel_rows?.[0]?.[item]}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="w-[40%] overflow-auto h-full">
                                  <table className="w-full">
                                    <thead className="sticky top-0 z-9 bg-F2F4F5">
                                      <tr>
                                        <th
                                          className={`tab-header whitespace-nowrap text-xs fw_700 text-475467 bg-F2F4F5 text-start`}
                                        >
                                          Mapping Value
                                        </th>
                                        <th
                                          className={`tab-header whitespace-nowrap text-xs fw_700 text-475467 bg-F2F4F5 text-start`}
                                        >
                                          CSV Column Position
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {values?.grnColumns?.map((row, index) => (
                                        <tr key={index}>
                                          <td
                                            className={`tab-cell text-sm fw_400 text-secondary`}
                                          >
                                            {row?.label}{" "}
                                            {row?.required && (
                                              <span className="text-F51D00">
                                                {" "}
                                                *
                                              </span>
                                            )}
                                          </td>
                                          <td
                                            className={`tab-cell text-sm fw_400 text-secondary `}
                                          >
                                            <Field
                                              onWheel={(e) => e.target.blur()}
                                              onKeyDown={positiveIntegerFn}
                                              min={1}
                                              type="number"
                                              name={`grnColumns[${index}].value`}
                                              placeholder=""
                                              className="focus:outline-none focus:ring focus:ring-blue-500 px-1 py-2 input-field-no-spin-buttons border-D0D5DD  border-2 rounded-md"
                                            />
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className=" w-[35rem]">
                          <div className="flex justify-between items-start w-full">
                            <span className="text-000000 text-lg fw_600">
                              Step (1/2) - Upload
                            </span>{" "}
                            <button
                              type="button"
                              className="close-button bg-transparent border-none p-0 hover:bg-787878"
                              onClick={() => {
                                setIsModalOpen(false);
                                setMapping_id("");
                                setValues(initial);
                              }}
                            >
                              <CloseIcon />
                            </button>
                          </div>
                          <label
                            style={{
                              backgroundColor: `${
                                values?.filename || mapping_id
                                  ? getBackgroundColor(true, "--primary")
                                  : ""
                              }`,
                            }}
                            className="min-w-[45%] min-h-[15rem] border-dotted border-primary border-[3px] rounded-md flex justify-center items-center mt-5 mx-5"
                            htmlFor={`csv-upload`}
                          >
                            <div className="flex flex-col justify-center">
                              <div className="flex justify-center">
                                <UploadIcon
                                  width="20px"
                                  height="20px"
                                  fill="var(--primary)"
                                />
                              </div>
                              <span className="text-center text-primary text-sm mt-3">
                                {values?.filename
                                  ? values?.filename
                                  : values?.documentType}
                              </span>
                            </div>
                            <input
                              type="file"
                              accept=".csv"
                              onChange={(e) => {
                                handleFileUpload(e, setValues, values);
                              }}
                              className="hidden"
                              id={`csv-upload`} // Make the ID unique
                            />
                          </label>
                        </div>
                      )}
                      <div className="flex justify-between items-start w-full px-5 mt-5 h-[3rem]">
                        <div>
                          {(() => {
                            const validValues = values?.grnColumns
                              .map((grn) => grn?.value)
                              .filter(
                                (val) =>
                                  val !== "" &&
                                  val !== null &&
                                  val !== undefined
                              ); // Remove empty, null, undefined values

                            return (
                              new Set(validValues).size !== validValues.length
                            ); // Check duplicates only for non-empty values
                          })() ? (
                            <span className="text-F51D00 text-sm">
                              Duplicate column positions found ! Please check
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div>
                          <TemplateFormatPreview
                            values={values}
                            isModalOpen={isPreviewOpen}
                            setIsModalOpen={setisPreviewOpen}
                          />
                          {values?.filename || mapping_id ? (
                            <div className="flex gap-3">
                              <DraftButton
                                className="text-F94B4B border border-F94B4B  border-solid px-10 py-2 items-center rounded-md flex justify-center fw_600 text-sm"
                                onclkFn={() => {
                                  setisPreviewOpen(true);
                                }}
                                text="Preview"
                              />
                              <AddButton
                                className={`${
                                  values?.grnColumns?.some(
                                    (grn) =>
                                      grn?.value > values?.excel_columns?.length
                                  ) ||
                                  (() => {
                                    const validValues = values?.grnColumns
                                      .map((grn) => grn?.value)
                                      .filter(
                                        (val) =>
                                          val !== "" &&
                                          val !== null &&
                                          val !== undefined
                                      ); // Remove empty, null, undefined values

                                    return (
                                      new Set(validValues).size !==
                                      validValues.length
                                    ); // Check duplicates only for non-empty values
                                  })() ||
                                  values?.grnColumns?.some(
                                    (field) => field.required && !field.value
                                  )
                                    ? "bg-disabled-color"
                                    : "bg-primary"
                                } text-white px-8 py-2 rounded-md flex justify-center items-center fw_500 text-sm whitespace-nowrap hover:bg-blue hover:text-white transition duration-300 ease-in-out`}
                                prefixIcon={false}
                                isDisabled={
                                  values?.grnColumns?.some(
                                    (grn) =>
                                      grn?.value > values?.excel_columns?.length
                                  ) ||
                                  (() => {
                                    const validValues = values?.grnColumns
                                      .map((grn) => grn?.value)
                                      .filter(
                                        (val) =>
                                          val !== "" &&
                                          val !== null &&
                                          val !== undefined
                                      ); // Ignore empty values

                                    return (
                                      new Set(validValues).size !==
                                      validValues.length
                                    ); // Disable if duplicates found
                                  })() ||
                                  values?.grnColumns?.some(
                                    (field) => field.required && !field.value
                                  )
                                }
                                text="save"
                                type="submit"
                              />
                            </div>
                          ) : (
                            <DraftButton
                              className="text-F94B4B border border-F94B4B  border-solid px-10 py-2 items-center rounded-md flex justify-center fw_600 text-sm"
                              onclkFn={() => {
                                setIsModalOpen(false);
                                setMapping_id("");
                                setValues(initial);
                              }}
                              text="cancel"
                            />
                          )}
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default TemplateUploadModal;

export const TransTypeOptions = [{ value: "purchase", label: "Purchase" }];

export const tableHeading1 = ["Column no.", "Column Header", "Sample Value"];

export const getGrnColumns = (format = {}) => {
  const defaultColumns = [
    {
      label: "Customer Inv Number",
      name: "customer_invoice_number",
      value: "",
      required: true,
    },
    {
      label: "Customer Code",
      name: "cmr_code",
      value: "",
      required: false,
    },
    {
      label: "Customer Name",
      name: "cmr_name",
      value: "",
      required: true,
    },

    {
      label: "Order Date",
      name: "gort_order_date",
      value: "",
      required: true,
    },
    {
      label: "Document Date",
      name: "gort_document_date",
      value: "",
      required: false,
    },
    {
      label: "Delivery Date",
      name: "gort_delivery_date",
      value: "",
      required: false,
    },

    {
      label: "Payment Status",
      name: "gort_payment_status",
      value: "",
      required: false,
    },
    {
      label: "Transaction ID",
      name: "gort_transaction_id",
      value: "",
      required: false,
    },
    {
      label: "Billing Address",
      name: "gort_billing_address",
      value: "",
      required: false,
    },

    {
      label: "Remarks",
      name: "remarks",
      value: "",
      required: false,
    },

    {
      label: "TCS/TDS Percentage",
      name: "gort_tcs_or_tds_percentage",
      value: "",
      required: false,
    },
    {
      label: "TDS/TCS Type",
      name: "gort_tds_tcs_type",
      value: "",
      required: false,
    },
    {
      label: "TCS/TDS Amount",
      name: "gort_tcs_or_tds_amount",
      value: "",
      required: false,
    },

    {
      label: "Item Name",
      name: "item_name",
      value: "",
      required: true,
    },
    {
      label: "Item Code",
      name: "item_code",
      value: "",
      required: true,
    },
    {
      label: "Item HSN",
      name: "item_hsn",
      value: "",
      required: true,
    },
    {
      label: "Item UOM",
      name: "item_uom",
      value: "",
      required: true,
    },

    {
      label: "Manufacturer Name",
      name: "item_manufacturer_name",
      value: "",
      required: false,
    },
    {
      label: "Batch Number",
      name: "item_batch_number",
      value: "",
      required: true,
    },
    {
      label: "Item Quantity",
      name: "item_sellable_quantity",
      value: "",
      required: true,
    },
    {
      label: "Batch Free Quantity",
      name: "item_batch_free_quantity",
      value: "",
      required: true,
    },

    {
      label: "Batch Purchase Rate",
      name: "item_batch_purchase_rate",
      value: "",
      required: true,
    },
    {
      label: "Batch Total Base Purchase Rate",
      name: "item_batch_total_base_purchase_rate",
      value: "",
      required: false,
    },

    {
      label: "Batch Scheme Discount Percentage",
      name: "item_batch_scheme_discount_percentage",
      value: "",
      required: false,
    },

    {
      label: "Batch Discount Percentage",
      name: "item_batch_discount_percentage",
      value: "",
      required: false,
    },

    {
      label: "Batch Final Purchase Rate",
      name: "item_batch_final_purchase_rate",
      value: "",
      required: false,
    },
    {
      label: "Batch Total Purchase Rate",
      name: "item_batch_total_purchase_rate",
      value: "",
      required: false,
    },
    {
      label: "MRP",
      name: "item_batch_unit_price",
      value: "",
      required: true,
    },
  ];

  const gstColumns =
    format?.gst_format === "gst"
      ? [{ label: "Item GST % *", name: "item_gst", value: "", required: true }]
      : [
          {
            label: "Item CGST %",
            name: "item_cgst_percentage",
            value: "",
            required: true,
          },
          {
            label: "Item SGST %",
            name: "item_sgst_percentage",
            value: "",
            required: true,
          },
        ];

  const expiryColumns =
    format?.exp_format === "single"
      ? [
          {
            label: "Item Expiry Date",
            name: "item_exp_date",
            value: "",
            required: true,
          },
        ]
      : [
          {
            label: "Expiry Month",
            name: "item_exp_month",
            value: "",
            required: true,
          },
          {
            label: "Expiry year",
            name: "item_exp_year",
            value: "",
            required: true,
          },
        ];

  return [...defaultColumns, ...gstColumns, ...expiryColumns];
};
