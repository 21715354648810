import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import GoodsRecComp from "../../../components/purchase-section/goods-receipt/goods-receipt";
import {
  purchaseItemsGetAction,
  purchaseReturnItemsGetAction,
} from "../../../redux/actions/purchase-action";

const GoodsReceipt = () => {
  const location = useLocation();
  const values = location.state?.values || [];
  const { itemData, ...rest } = values;

  console.log(values, "dsfdsfsdfsfsdfsdfsfsf");

  const { itemId, type } = useParams();
  const itemIdArray = JSON.parse(decodeURIComponent(itemId));

  const allitems = itemIdArray.map((item) => {
    const { cmr_id, ...rest } = item;
    return rest;
  });
  const [orderDetail, setOrderDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (type === "return") {
      setLoading(true);
      dispatch(purchaseReturnItemsGetAction(allitems, onSuccPur, onErrPur));
    } else {
      setLoading(true);
      dispatch(purchaseItemsGetAction(allitems, onSuccPur, onErrPur));
    }
  }, []);

  const onSuccPur = (data) => {
    setOrderDetail(data?.data);
    setLoading(false);
  };

  const onErrPur = (data) => {
    setLoading(false);
  };
  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 100);
  // }, [orderDetail]);
  console.log(rest, "dsfsfdsfdsfdsfdsf");

  return (
    <InnerLayout title="Goods Receipt">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <GoodsRecComp    
          setLoading={setLoading}
          setOrderDetail={setOrderDetail}
          type={type}
          orderDetail={
            values?.itemData?.length > 0
              ? values?.itemData
              : orderDetail?.itemData
          }
          vendorDetails={
            values?.itemData?.length > 0 ? [rest] : orderDetail?.vendorDetails
          }
        />
      )}
    </InnerLayout>
  );
};

export default GoodsReceipt;
