import { Field, useFormikContext } from "formik";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "../../../assets/icons/delete-icon";
import { url } from "../../../redux/config";
import "../../forms/form.css";
import SearchAddFeild from "../../forms/search-add-field";
import errorToast from "../../helper-components/error-toast";
import "../sales.css";
import { api } from "../../../redux/apis/api";
import useBarcodeScanner from "../../helper-components/helper-function";
import { invoiceScannedItemAction } from "../../../redux/actions/invoices-action";
import {
  salesProductDetailsAction,
  salesProductListAction,
} from "../../../redux/actions/sales-action";
import SelectField from "../../forms/select-field";

const SalesBillingTable = ({ initialData, disabled = false, searchRef }) => {
  const inputRefs = useRef(null);
  const { values, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const [localData, setLocalData] = useState([]);
  const tableHeading = [
    { label: "S No.", value: "sl_no" },
    { label: "Product Name", value: "item_product_name" },
    { label: "Generic Name", value: "item_generic_name" },
    { label: "Item Code", value: "item_code" },
    { label: "Mfg By", value: "item_manufacturer_name" },
    { label: "Return Avail", value: "item_return_availability" },
    { label: "UOM", value: "item_pack_size" },
    { label: "Avail Qty", value: "item_avail_qty" },
    { label: "Purchase Req Qty", value: "item_request_quantity" },
    { label: "Qty", value: "item_quantity" },
    { label: "Tentative Price (₹)", value: "item_unit_price" },
    { label: "Min-Max", value: "item_unit_price" },
    { label: "Total (₹)", value: "item_total" },
    ...(disabled ? [] : [{ label: "", value: "" }]),
  ];
  const fetchData = async (inputValue, callback, field) => {
    const onSuccess = (data) => {
      const options =
        data?.data === null
          ? []
          : data.data
              .map((item) => ({
                value: item.item_id,
                label: item[field],
              }))
              .filter(
                (option) =>
                  !values?.data?.some((item) => item?.item_id === option.value)
              );
      setLocalData(data.data);
      callback(options);
    };
    const onError = (data) => {};
    if (inputValue?.length > 0) {
      dispatch(
        salesProductListAction(
          {
            field: field,
            value: inputValue,
          },
          onSuccess,
          onError
        )
      );
    }
  };

  const removeRow = (rowIndex) => {
    const updatedData = [...values.data];
    updatedData.splice(rowIndex, 1);
    setFieldValue("data", updatedData);
  };

  const onErrorDis = (data) => {};

  const handleQuantityChange = async (event, rowIndex, item_unit_price) => {
    const updatedData = values.data.map((row, index) =>
      index === rowIndex
        ? {
            ...row,
            quantity: parseFloat(event.target.value),
            item_unit_price: item_unit_price,
          }
        : row
    );
    setFieldValue("data", updatedData);
    const totalAmount =
      parseFloat(item_unit_price) * parseFloat(event.target.value);

    if (parseFloat(event?.target?.value) > 0) {
      try {
        const response = await api.get(
          `${url}/discount/get?totalAmountOfItem=${totalAmount}&item_quantity=${event?.target?.value}&item_id=${values?.data?.[rowIndex]?.item_id}&cmr_phone_number=${values?.cmr_phone_number}`,
          JSON.stringify(totalAmount)
        );

        const tax =
          response.data?.code === 200 ? response.data.data.item_tax_amount : 0;
        const updatedDataRow = {
          ...updatedData[rowIndex],
          item_gst:
            response.data?.code === 200
              ? response.data.data.item_total_tax_percentage
              : 0,
          item_sgst:
            response.data?.code === 200 ? (parseFloat(tax) / 2).toFixed(2) : 0,
          item_cgst:
            response.data?.code === 200 ? (parseFloat(tax) / 2).toFixed(2) : 0,
          item_igst: 0,
          item_total_amount: totalAmount,
          item_tax_amount: parseFloat(tax),
        };
        updatedData[rowIndex] = updatedDataRow;

        setFieldValue("data", [...updatedData]);
      } catch (error) {}
    }
  };

  const hasDuplicateItemId = (itemId, currentIndex) => {
    const itemIdsExceptCurrent = values.data
      .filter((_, index) => index !== currentIndex)
      .map((item) => item.item_id);

    return itemIdsExceptCurrent.includes(itemId);
  };

  useBarcodeScanner((barcode) => {
    if (barcode) {
      dispatch(
        invoiceScannedItemAction(barcode, onSuccessScanned, onErrorScanned)
      );
    }
  });

  const onSuccessScanned = (data) => {
    const itemData = data?.data?.item_information;
    if (
      !values?.data?.some(
        (item) => item?.item_id === data?.data?.item_information?.item_id
      )
    ) {
      const newdata = {
        sl_no: 1,
        item_name: itemData?.item_name,
        item_generic_name: itemData?.item_generic_name,
        item_code: itemData?.item_code,
        item_uom: itemData?.item_uom,
        item_unit_price: itemData?.item_unit_price,
        quantity: 1,
        item_total_amount:
          parseFloat(itemData?.item_unit_price) * 1 -
          data.data.discount_details?.item_discount_amount +
          data.data.discount_details?.item_tax_amount,
        item_id: itemData?.item_id,
        item_tax_amount: data.data.discount_details?.item_tax_amount,
        item_gst: data.data.discount_details?.item_total_tax_percentage,
        item_sgst: (
          (parseFloat(itemData?.item_unit_price) -
            parseFloat(data.data.discount_details?.item_discount_amount)) *
          (data.data.discount_details?.sgstRate / 100)
        )?.toFixed(2),
        item_cgst: (
          (parseFloat(itemData?.item_unit_price) -
            parseFloat(data.data.discount_details?.item_discount_amount)) *
          (data.data.discount_details?.cgstRate / 100)
        )?.toFixed(2),
        item_igst: (
          (parseFloat(itemData?.item_unit_price) -
            parseFloat(data.data.discount_details?.item_discount_amount)) *
          (data.data.discount_details?.igstRate / 100)
        )?.toFixed(2),
      };
      const oldData = [...values?.data];
      oldData.pop();
      setFieldValue("data", [...oldData, newdata, initialData]);
    } else {
      errorToast(
        "Item already added to invoice. Please adjust quantity if you wish to add more."
      );
    }
  };
  const onErrorScanned = (data) => {
    errorToast(data?.data?.message);
  };

  const onSuccessDetails = (data, rowIndex, filteredData) => {
    const oldData = [...values?.data];
    oldData.pop();
    const updatedData = {
      ...filteredData,
      total_sellable_quantity: data?.data?.total_sellable_quantity,
      uom_dropdown: data?.data?.uom_dropdown
        ? data?.data?.uom_dropdown?.map((uom) => ({
            item_uom_id: uom?.item_id,
            label: uom?.alt_uom,
            value: uom?.alt_uom,
          }))
        : [],
    };

    setFieldValue("data", [...oldData, updatedData, initialData]);
    inputRefs.current?.focus();
  };

  const onErrorDetails = (data) => {};
  return (
    <>
      <div
        className="table-container mt-3"
        style={{
          maxHeight: `calc(100% - 15rem)`,
          height: `calc(100% - 15rem)`,
        }}
      >
        <table className="my-table m-0">
          <thead className="sticky top-0 z-10">
            <tr className="bg-F2F4F5">
              {tableHeading.map((heading, index) => (
                <th
                  key={index}
                  className={`${
                    index === tableHeading?.length - 1
                      ? "table-header"
                      : "table-header"
                  } whitespace-nowrap text-sm fw_700 text-475467  ${
                    index === 7 || index === 9
                      ? "text-right w-[7rem]"
                      : "text-left"
                  } 
                  ${index === 0 && "w-[2rem]"}
                  ${index === 4 && "min-w-[8.5rem]"}
                   ${index === 6 && "min-w-[8rem] "}                 
                   ${index === 3 && "w-[8rem]"}                 
                   ${index === 2 && "w-[15rem]"}  
                   ${index === 1 ? "w-full min-w-[15rem]" : ""}               
                  `}
                >
                  {heading?.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {values?.data?.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {Object?.values(rowData)?.map((cellData, colIndex) => {
                  if (colIndex < 13) {
                    return (
                      <td
                        key={colIndex}
                        className={`table-cell text-sm fw_400 text-secondary ${
                          colIndex === 7 ||
                          colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 10 ||
                          colIndex === 12
                            ? "text-end"
                            : ""
                        } ${colIndex === 4 && "w-[7rem] p-0"}
                         ${colIndex === 0 && "w-[2rem]"} 
                      ${colIndex === 6 && "w-[5rem]"}
                      ${colIndex === 5 && "w-[5rem]"}
                      ${colIndex === 11 && "text-center"}
                      `}
                      >
                        {colIndex === 1 ||
                        colIndex === 2 ||
                        colIndex === 3 ||
                        colIndex === 9 ? (
                          colIndex !== 9 ? (
                            <div
                              title={
                                values?.data[rowIndex][
                                  Object.keys(rowData)[colIndex]
                                ]
                              }
                            >
                              <SearchAddFeild
                                searchRef={colIndex === 1 ? searchRef : {}}
                                isDisabled={
                                  values?.data?.length - 1 !== rowIndex ||
                                  disabled
                                    ? true
                                    : false
                                }
                                errorClass="hidden"
                                isRequired={false}
                                value={{
                                  label:
                                    values?.data[rowIndex][
                                      Object.keys(rowData)[colIndex]
                                    ],
                                  value: "",
                                }}
                                name={`data[${rowIndex}].${
                                  Object.keys(rowData)[colIndex]
                                }`}
                                field={Object.keys(rowData)[colIndex]}
                                isLabelReq={false}
                                defaultSelectClass=""
                                placeholder=""
                                fetchData={fetchData}
                                onChange={async (selectedOption) => {
                                  const filteredData = localData
                                    ?.filter(
                                      (item) =>
                                        item?.item_id === selectedOption?.value
                                    )
                                    .map((item) => ({
                                      sl_no: 1,
                                      item_name: item.item_name,
                                      item_generic_name:
                                        item?.item_generic_name,
                                      item_code: item.item_code,
                                      item_manufacturer_name:
                                        item?.item_manufacturer_name,
                                      item_return_availability:
                                        item?.item_sales_returns ? true : false,
                                      item_uom:
                                        item.item_pur_purchasing_uom_name ===
                                        null
                                          ? ""
                                          : item.item_pur_purchasing_uom_name,
                                      total_sellable_quantity: 0,
                                      item_requested_quantity: 0,
                                      quantity: 0,
                                      item_unit_price: item?.item_unit_price,
                                      item_min_max: "",
                                      item_total_amount: 0,
                                      quantity: "",
                                      item_total_amount: 0,
                                      item_id: item?.item_id,
                                      item_uom_id:
                                        item?.item_pur_purchasing_uom_name_id ??
                                        "",
                                      item_price_wiithout_tax: 0,
                                      uom_dropdown: [],
                                      // uom_dropdown: item?.uom_dropdown
                                      //   ? item?.uom_dropdown.map((uom) => ({
                                      //       item_uom_id: uom?.item_id,
                                      //       label: uom?.alt_uom,
                                      //       value: uom?.alt_uom,
                                      //     }))
                                      //   : [],
                                      item_manufacturer_id:
                                        item?.item_manufacturer_id,
                                      item_minimun_purchasing:
                                        item?.item_minimun_purchasing ?? 0,
                                      item_maximum_purchasing:
                                        item?.item_maximum_purchasing ?? 0,
                                      item_hsn: item.item_hsn,
                                      uom_group_id: item?.item_uom || "",
                                    }));
                                  dispatch(
                                    salesProductDetailsAction(
                                      {
                                        item_id: filteredData?.[0]?.item_id,
                                        item_uom:
                                          filteredData?.[0]?.uom_group_id,
                                      },
                                      (data) => {
                                        onSuccessDetails(
                                          data,
                                          rowIndex,
                                          ...filteredData
                                        );
                                      },
                                      onErrorDetails
                                    )
                                  );

                                  // const oldData = [...values?.data];
                                  // oldData.pop();

                                  // setFieldValue("data", [
                                  //   ...oldData,
                                  //   ...filteredData,
                                  //   initialData,
                                  // ]);
                                  // inputRefs.current?.focus();
                                }}
                              />
                            </div>
                          ) : (
                            <div>
                              <Field name={`data[${rowIndex}].quantity`}>
                                {({ field }) => (
                                  <input
                                    min={0}
                                    ref={inputRefs}
                                    {...field}
                                    type="number"
                                    step="1"
                                    placeholder=""
                                    readOnly={
                                      values?.data?.length - 1 === rowIndex ||
                                      hasDuplicateItemId(
                                        rowData.item_id,
                                        rowIndex
                                      ) ||
                                      disabled
                                    }
                                    // onKeyDown={(event) => {
                                    //   if (
                                    //     event.key === "-" ||
                                    //     event.key === "e" ||
                                    //     event.key === "."
                                    //   ) {
                                    //     event.preventDefault();
                                    //   }
                                    //   if (
                                    //     event.key === "Tab" ||
                                    //     event.key === "Enter"
                                    //   ) {
                                    //     handleQuantityChange(event, rowIndex);
                                    //     setFieldValue(
                                    //       `data[${rowIndex}].quantity`,
                                    //       event.target.value
                                    //     );
                                    //   }
                                    // }}

                                    onKeyDown={(event) => {
                                      if (
                                        event.key === "-" ||
                                        event.key === "e" ||
                                        event.key === "."
                                      ) {
                                        event.preventDefault();
                                      }
                                      if (
                                        event.key === "Tab" ||
                                        event.key === "Enter"
                                      ) {
                                        if (searchRef && searchRef.current) {
                                          setTimeout(() => {
                                            searchRef.current.focus();
                                          }, 0);
                                        }
                                      }
                                    }}
                                    onChange={(event) => {
                                      if (
                                        values?.data?.length - 1 === rowIndex ||
                                        hasDuplicateItemId(
                                          rowData.item_id,
                                          rowIndex
                                        ) ||
                                        disabled
                                      ) {
                                        event?.preventDefault();
                                      } else {
                                        handleQuantityChange(
                                          event,
                                          rowIndex,
                                          rowData?.item_unit_price
                                        );
                                      }
                                    }}
                                    className="focus-within:ring-2 text-right min-w-[5rem]   px-2 w-full py-3 rounded focus-within:outline-none focus-within:shadow-[0px_0px_0px_4px_#EBF2FF input-field-no-spin-buttons"
                                  />
                                )}
                              </Field>
                            </div>
                          )
                        ) : colIndex === 0 ? (
                          <div>
                            <input
                              type="radio"
                              name="SerialNumberRadioGroup"
                              disabled={disabled}
                              value={rowIndex}
                              className="peer hidden"
                              id={rowIndex}
                            />
                            <label
                              htmlFor={rowIndex}
                              className="block cursor-pointer select-none rounded-xl p-3 text-center hover:bg-slate-100 peer-checked:bg-blue-100 peer-checked:font-bold peer-checked:outline-none peer-checked:ring-2 peer-checked:ring-blue-500"
                            >
                              {rowIndex + 1}
                            </label>
                          </div>
                        ) : colIndex === 6 ? (
                          <div title={rowData.item_uom ?? ""}>
                            <SelectField
                              general_color={false}
                              handleSelected={(sel) => {
                                setFieldValue(
                                  `data[${rowIndex}].item_uom_id`,
                                  sel?.item_uom_id
                                );
                              }}
                              borderclass=""
                              defaultSelectClass="border-0"
                              selectClass="w-full"
                              defaultValue={
                                values.data[rowIndex]?.item_uom
                                  ? {
                                      label:
                                        values.data[rowIndex]?.item_uom ?? "",
                                    }
                                  : null
                              }
                              isDisabled={disabled}
                              errMsgContainerClass="hidden"
                              id={`data[${rowIndex}].item_uom`}
                              isContainer={false}
                              options={values.data[rowIndex]?.uom_dropdown}
                              name={`data[${rowIndex}].item_uom`}
                              placeholder={"Select UOM"}
                            />
                          </div>
                        ) : colIndex === 10 ? (
                          <div>
                            <Field name={`data[${rowIndex}].item_unit_price`}>
                              {({ field }) => (
                                <input
                                  min={0}
                                  {...field}
                                  type="number"
                                  step="any"
                                  placeholder=""
                                  readOnly={
                                    values?.data?.length - 1 === rowIndex ||
                                    hasDuplicateItemId(
                                      rowData.item_id,
                                      rowIndex
                                    ) ||
                                    disabled
                                  }
                                  onChange={(event) => {
                                    handleQuantityChange(
                                      {
                                        target: {
                                          value:
                                            values?.data?.[rowIndex]?.quantity,
                                        },
                                      },
                                      rowIndex,
                                      event.target.value
                                    );
                                    setFieldValue(
                                      `data[${rowIndex}].item_unit_price`,
                                      event.target.value
                                    );
                                  }}
                                  onKeyDown={(event) => {
                                    if (
                                      event.key === "-" ||
                                      event.key === "e"
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                  className="focus-within:ring-2 text-right min-w-[5rem]   px-2 w-full py-3 rounded focus-within:outline-none focus-within:shadow-[0px_0px_0px_4px_#EBF2FF input-field-no-spin-buttons"
                                />
                              )}
                            </Field>
                          </div>
                        ) : colIndex === 5 &&
                          rowIndex !== values?.data?.length - 1 ? (
                          cellData ? (
                            "No"
                          ) : (
                            "Yes"
                          )
                        ) : colIndex === 11 ? (
                          <span>
                            {rowData?.item_minimun_purchasing ?? "-"} -{" "}
                            {rowData?.item_maximum_purchasing ?? ""}
                          </span>
                        ) : (
                          cellData
                        )}
                      </td>
                    );
                  }
                  return null;
                })}
                {!disabled && (
                  <td className="table-cell w-[2.5rem]">
                    {rowIndex !== values?.data?.length - 1 && (
                      <DeleteIcon onClickFn={() => removeRow(rowIndex)} />
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SalesBillingTable;
