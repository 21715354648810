import { INVOICE_CLEAR, INVOICE_ROW } from "../../types";
import { initialOrderState } from "./initial-state";

const invoiceRowReducer = (state = initialOrderState, action) => {
  switch (action.type) {
    case INVOICE_ROW:
      return {
        ...action.data,
        data: [...state?.data, ...action?.data?.data],
      };
    case INVOICE_CLEAR:
      return {
        phone: null,
        cmr_first_name: "",
        cmr_last_name: "",
        cmr_acquired_source: "",
        gst_tax: "",
        RepeatLastOrder: false,
        cmr_balance: 0,

        sot_round_off_value: 0,
        store_id: "",
        data: [],
        doctor: "",
        payment_method: "",
        paymentData: [
          {
            payment_mode_name: "",
            payment_amount: null,
            transaction: "",
          },
          {
            payment_mode_name: "",
            payment_amount: null,
            transaction: "",
          },
          {
            payment_mode_name: "",
            payment_amount: null,
            transaction: "",
          },
        ],
      };
    default:
      return state;
  }
};

export default invoiceRowReducer;
