import React from "react";

const StatusIcons = ({ status }) => {
  return (
    <>
      {status === "paid" ? (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 3L4.5 8.5L2 6"
            stroke="#12B76A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.5 3.5L3.5 8.5M3.5 3.5L8.5 8.5"
            stroke="#F94B4B"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
};

export default StatusIcons;

export const PaymentStatusIcon = ({ status }) => {
  return (
    <>
      {status === "paid" ? (
        <svg
          width="12"
          height="12"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_11298_1899)">
            <path
              d="M3.69995 4H3.70495M6.19995 1.5H1.19995V6.5L5.84495 11.145C6.31495 11.615 7.08495 11.615 7.55495 11.145L10.845 7.855C11.315 7.385 11.315 6.615 10.845 6.145L6.19995 1.5Z"
              stroke="#FAFAFA"
              stroke-width="1.33"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_11298_1899">
              <rect
                width="12"
                height="12"
                fill="white"
                transform="translate(0.199951 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.5 3.5L3.5 8.5M3.5 3.5L8.5 8.5"
            stroke="#F94B4B"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
};
