import { Field, Formik, useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";
import SelectField from "../../forms/select-field";
import AddButton from "../../buttons/button";
import DeleteIcon from "../../../assets/icons/delete-icon";
import moment from "moment";
import { useDispatch } from "react-redux";
import { stockTransferBatchAction } from "../../../redux/actions/inventory-action";
import errorToast from "../../helper-components/error-toast";
import {
  decimalNumberFn,
  positiveIntegerFn,
} from "../../helper-components/helper-function";
import * as Yup from "yup";

const BatchQuantityBinPopover = ({
  setAddbatch,
  itemId,
  setItemId,
  disabled = false,
  binLocationList = [],
  itemValues = {},
}) => {
  const batchRef = useRef();
  const today = new Date();
  const sixMonthsLater = new Date(today.setMonth(today.getMonth() + 6));
  const { values, setFieldValue, errors } = useFormikContext();
  const dispatch = useDispatch();
  const defaultSelectedItem = itemValues?.data?.find(
    (item) => itemId === item?.item_id
  );
  const defaultSelectedItemIndex = itemValues?.data?.findIndex(
    (item) => itemId === item?.item_id
  );
  useEffect(() => {
    dispatch(stockTransferBatchAction(itemId, onSuccess, onError));
  }, [itemId]);
  const onSuccess = (data) => {
    setFieldValue(
      `data[${defaultSelectedItemIndex}].itemBatchList`,
      data?.data
    );
  };
  const onError = () => {};
  const initialValues = defaultSelectedItem ?? {};
  const validationSchema = Yup.object().shape({
    item_batches: Yup.array().of(
      Yup.object().shape({
        item_batch_purchase_rate: Yup.number()
          .typeError("Must be a number")
          .required("Purchase rate is required")
          .moreThan(0, "Purchase rate must be greater than 0"),
        item_batch_unit_price: Yup.number()
          .typeError("Must be a number")
          .required("Unit price is required")
          .moreThan(0, "Unit price must be greater than 0"),

        item_batch_quantity: Yup.number()
          .integer("Quantity must be an integer")
          .required("Sellable quantity is required")
          .typeError("Must be a number")
          .moreThan(0, "Qty must be greater than 0"),
        item_batch_free_quantity: Yup.number()
          .transform((value) =>
            typeof value === "string" ? parseInt(value, 10) : value
          ) // Transform string to number
          .typeError("Must be a number")
          .integer("Quantity must be an integer"),
        item_batch_number: Yup.string().required("Batch number is required"),
        // to_bin_id: Yup.string().required("Bin ID is required"),
        item_exp_date: Yup.date().required("Expiration date is required"),
      })
    ),
  });

  // const selectedBatchNumbers = values.data
  //   .find((_, index) => index === defaultSelectedItemIndex)
  //   .item_batches.map((row) => row?.item_batch_number);
  // const selectedFromBins = values.data
  //   .find((_, index) => index === defaultSelectedItemIndex)
  //   .item_batches.map((row) => row?.from_bin_id);
  // const selectedFromBins = values.data
  //   .find((_, index) => index === defaultSelectedItemIndex)
  //   .item_batches.map((row) => row?.created_date);
  const initData = {
    item_id: defaultSelectedItem?.item_id,
    item_batch_number: "",
    remainingQty: 0,
    item_batch_quantity: 0,
    from_bin_location: "",
    to_bin_location: "receiving-bin",
    to_bin_id:
      itemValues?.stock_management_action === "stock_in" ||
      itemValues?.stock_management_action === "in_store_transfer"
        ? binLocationList?.find((bin) => bin?.actualLabel === "receiving-bin")
            ?.value
        : "",
    from_bin_id: "",
    created_date: "",
    item_exp_date: moment(sixMonthsLater).format("YYYY-MM-DD"),
    item_batch_free_quantity: 0,
    item_batch_purchase_rate: 0,
    item_batch_unit_price: 0,
  };

  // const handleQtyChangeFn = (currentBatchIndex, newValue) => {
  //   const totalQuantity = values.data[
  //     defaultSelectedItemIndex
  //   ]?.item_batches.reduce((acc, batch, index) => {
  //     if (index === currentBatchIndex) {
  //       return acc + (parseFloat(newValue) || 0);
  //     }
  //     return acc + (parseFloat(batch.item_batch_quantity) || 0);
  //   }, 0);
  //   setFieldValue(`data[${defaultSelectedItemIndex}].quantity`, totalQuantity);
  // };

  const getAvailableBatches = (values, defaultSelectedItemIndex) => {
    console.log(values, "dffsdfsfdsfdsfsdfsdff");
    return values?.itemBatchList
      ?.filter((item) => {
        if (!item?.item_batch_number) return false;
        const sumBatchQuantity =
          values?.item_batches
            .filter(
              (batch) =>
                batch?.created_date === item?.created_date &&
                batch?.item_batch_number === item?.item_batch_number
            )
            .reduce(
              (sum, batch) =>
                sum + (parseFloat(batch?.item_batch_quantity) || 0),
              0
            ) * parseFloat(values?.base_quantity);

        const remainingQty = parseFloat(item?.item_quantity) - sumBatchQuantity;
        return remainingQty > 0;
      })
      .map((item) => {
        const sumBatchQuantity = values?.item_batches
          .filter(
            (batch) =>
              batch?.created_date === item?.created_date &&
              batch?.item_batch_number === item?.item_batch_number
          )
          .reduce(
            (sum, batch) => sum + (parseFloat(batch?.item_batch_quantity) || 0),
            0
          );

        const remainingQty =
          parseFloat(item?.item_quantity) -
          sumBatchQuantity * values?.base_quantity;

        return {
          label: (
            <span className="flex flex-col">
              <span>Avail Qty: {remainingQty}</span>
              <span>Btch Num: {item?.item_batch_number}</span>
            </span>
          ),
          remainingQty: remainingQty,
          value: item?.item_batch_number,
          from_bin_id: item?.to_bin_id,
          from_bin_location: item?.item_rack_location,
          created_date: item?.created_date,
          ...item,
        };
      });
  };

  const handleShowErrorToast = async (errors) => {
    console.log(errors, "fdsfdsfsdfsdfsdfsf");
    if (errors && errors.item_batches) {
      const dataError = errors;
      if (dataError?.item_batches) {
        let hasError = false;
        for (const batchError of dataError.item_batches) {
          if (batchError) {
            if (batchError.item_batch_number && !hasError) {
              errorToast(batchError.item_batch_number + "!");
              hasError = true;
            } else if (batchError.item_batch_purchase_rate && !hasError) {
              errorToast(batchError.item_batch_purchase_rate + "!");
              hasError = true;
            } else if (batchError.item_batch_unit_price && !hasError) {
              errorToast(batchError.item_batch_unit_price + "!");
              hasError = true;
            } else if (batchError.item_batch_quantity && !hasError) {
              errorToast(batchError.item_batch_quantity + "!");
              hasError = true;
            } else if (batchError.item_batch_free_quantity && !hasError) {
              errorToast(batchError.item_batch_free_quantity + "!");
              hasError = true;
            } else if (batchError.item_exp_date && !hasError) {
              errorToast(batchError.item_exp_date + "!");
              hasError = true;
            } else if (batchError.to_bin_id && !hasError) {
              errorToast(batchError.to_bin_id + "!");
              hasError = true;
            }
          }
          if (hasError) {
            break;
          }
        }
        if (!hasError) {
          setAddbatch(false);
        }
      } else {
        setAddbatch(false);
      }
    } else {
      setAddbatch(false);
    }
  };
  const innerOnSubmit = (values) => {
    setFieldValue(`data.${defaultSelectedItemIndex}`, values);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={innerOnSubmit}
    >
      {({ values, handleSubmit, errors, setFieldValue, setValues }) => {

        console.log(values, "dfdsfdffdsfdsfsffs");
        return (
          <>
            <div className="absolute w-[-webkit-fill-available]  z-40">
              <div className="h-[72vh] bg-white flex flex-col justify-between  overflow-y-scroll">
                <div>
                  <div className="bg-[#F4F6F9] w-full p-3 gap-2 font-semibold text-xl  flex items-center">
                    Batch details -
                    <SelectField
                      general_color={false}
                      isDisabled={disabled}
                      handleSelected={(selected) => {
                        setItemId(selected?.value);
                      }}
                      containerClass="w-[20rem]"
                      placeholder={"Select product"}
                      defaultValue={
                        defaultSelectedItem
                          ? {
                              label: defaultSelectedItem.item_name,
                              value: defaultSelectedItem.item_id,
                            }
                          : null
                      }
                      options={itemValues?.data?.map((item) => ({
                        label: item?.item_name,
                        value: item?.item_id,
                      }))}
                      label={""}
                      id={"item_i"}
                      name="item_i"
                      isSearchable={true}
                      errMsgContainerClass="hidden"
                      labelWidth="10.5rem"
                    />
                  </div>
                  <div className="p-3">
                    <table className="lg:w-[100%] w-full border-D0D5DD border border-1 rounded-lg ">
                      <thead>
                        <tr className="bg-F2F4F5">
                          <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-475467 fw_700 text-sm w-[20%]">
                            Batch Nos.
                          </th>
                          {(itemValues?.stock_management_action ===
                            "stock_out" ||
                            itemValues?.stock_management_action ===
                              "in_store_transfer") &&
                            !disabled && (
                              <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-475467 fw_700 text-sm w-[10%]">
                                Avail Qty in {values?.item_uom}
                              </th>
                            )}
                          <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-475467 fw_700 text-sm w-[10%]">
                            Purchase Rate
                          </th>
                          <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-475467 fw_700 text-sm w-[10%]">
                            MRP
                          </th>

                          <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-475467 fw_700 text-sm w-[10%]">
                            Qty
                          </th>
                          <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-475467 fw_700 text-sm w-[10%]">
                            Free Qty
                          </th>
                          {itemValues?.stock_management_action ===
                            "stock_in" && (
                            <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-475467 fw_700 text-sm w-[10%]">
                              Exp. Date
                            </th>
                          )}
                          <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-475467 fw_700 text-sm w-[10%]">
                            From Bin
                          </th>
                          <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-475467 fw_700 text-sm w-[15%]">
                            To Bin
                          </th>
                          <th className="py-2 px-1 border-D0D5DD border border-1 text-left  text-475467 fw_700 text-sm w-[2%]"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {values?.item_batches?.map((batch, batchindex) => (
                          <tr key={batchindex}>
                            <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm ">
                              {itemValues?.stock_management_action ===
                              "stock_in" ? (
                                <Field
                                  name={`item_batches.${batchindex}.item_batch_number`}
                                >
                                  {({ field }) => (
                                    <input
                                      autoFocus={true}
                                      placeholder=""
                                      {...field}
                                      type="text"
                                      className={`w-full focus_outline__none focus:border-primary focus:border rounded-md py-2 px-2`}
                                      onKeyDown={(e) => {
                                        const regex = /[a-zA-Z0-9]/;
                                        if (
                                          e.key === " " ||
                                          !regex.test(e.key)
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                  )}
                                </Field>
                              ) : (
                                <SelectField
                                  general_color={false}
                                  ref={batchRef}
                                  autoFocus={true}
                                  isDisabled={disabled}
                                  handleSelected={(sel) => {
                                    console.log(sel, "dffsdfsfdsfdsfsdfsdff");
                                    if (
                                      itemValues?.stock_management_action ===
                                        "stock_out" ||
                                      itemValues?.stock_management_action ===
                                        "in_store_transfer"
                                    ) {
                                      setFieldValue(
                                        `item_batches[${batchindex}].item_batch_free_quantity`,
                                        sel?.item_batch_free_quantity ?? 0
                                      );
                                      setFieldValue(
                                        `item_batches[${batchindex}].item_batch_purchase_rate`,
                                        sel?.item_batch_purchase_rate ?? 0
                                      );
                                      setFieldValue(
                                        `item_batches[${batchindex}].item_batch_unit_price`,
                                        sel?.item_batch_unit_price ?? 0
                                      );
                                      setFieldValue(
                                        `item_batches[${batchindex}].from_bin_id`,
                                        sel?.from_bin_id ?? ""
                                      );

                                      setFieldValue(
                                        `item_batches[${batchindex}].from_bin_location`,
                                        sel?.from_bin_location ?? ""
                                      );

                                      setFieldValue(
                                        `item_batches[${batchindex}].created_date`,
                                        sel?.created_date ?? ""
                                      );
                                      setFieldValue(
                                        `item_batches[${batchindex}].remainingQty`,
                                        sel?.remainingQty ?? 0
                                      );
                                    }
                                  }}
                                  // borderclass=""
                                  // defaultSelectClass="border-0"
                                  selectClass="w-full"
                                  defaultValue={{
                                    label:
                                      values?.item_batches[batchindex]
                                        ?.item_batch_number,
                                    value: "",
                                  }}
                                  errMsgContainerClass="hidden"
                                  id={`item_batches[${batchindex}].item_batch_number`}
                                  isContainer={false}
                                  options={getAvailableBatches(
                                    values,
                                    defaultSelectedItemIndex
                                  )}
                                  name={`item_batches[${batchindex}].item_batch_number`}
                                  placeholder={"Select Batch"}
                                />
                              )}
                            </td>

                            {(itemValues?.stock_management_action ===
                              "stock_out" ||
                              itemValues?.stock_management_action ===
                                "in_store_transfer") &&
                              !disabled && (
                                <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm">
                                  {(values?.item_batches?.[batchindex]
                                    ?.item_batch_number
                                    ? parseFloat(
                                        values?.item_batches[batchindex]
                                          ?.remainingQty
                                      ) / parseFloat(values?.base_quantity)
                                    : 0
                                  ).toFixed(2)}
                                </td>
                              )}
                            <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm ">
                              <Field
                                name={`item_batches.${batchindex}.item_batch_purchase_rate`}
                              >
                                {({ field }) => (
                                  <input
                                    // autoFocus={true}
                                    value={
                                      field.value
                                        ? parseInt(field.value, 10)
                                        : ""
                                    }
                                    onKeyDown={decimalNumberFn}
                                    placeholder=""
                                    {...field}
                                    type="number"
                                    className={`w-full focus_outline__none focus:border-primary focus:border rounded-md py-2 px-2`}
                                  />
                                )}
                              </Field>
                            </td>
                            <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm ">
                              <Field
                                name={`item_batches.${batchindex}.item_batch_unit_price`}
                              >
                                {({ field }) => (
                                  <input
                                    onKeyDown={decimalNumberFn}
                                    placeholder=""
                                    {...field}
                                    type="number"
                                    className={`w-full focus_outline__none focus:border-primary focus:border rounded-md py-2 px-2`}
                                  />
                                )}
                              </Field>
                            </td>

                            <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm ">
                              <Field
                                name={`item_batches.${batchindex}.item_batch_quantity`}
                              >
                                {({ field, form }) => (
                                  <input
                                    min={0}
                                    disabled={disabled}
                                    placeholder=""
                                    {...field}
                                    type="number"
                                    className={`w-full focus_outline__none focus:border-primary focus:border rounded-md py-2 pl-2`}
                                    onChange={(e) => {
                                      let value = parseInt(e.target.value, 10);
                                      const currentBatch =
                                        values.item_batches[batchindex];
                                      const createdDate =
                                        currentBatch.created_date;
                                      // Find the item in itemBatchList corresponding to the createdDate
                                      const itemBatch =
                                        values.itemBatchList?.find(
                                          (bch) =>
                                            bch.created_date === createdDate
                                        );

                                      const totalItemQuantity =
                                        parseFloat(itemBatch?.item_quantity) /
                                          parseFloat(values?.base_quantity) ||
                                        0;

                                      // Calculate the sum of all item_batch_quantity for this createdDate, excluding the current batch
                                      const sumOtherBatches =
                                        values.item_batches
                                          .filter(
                                            (batch, idx) =>
                                              batch.created_date ===
                                                createdDate &&
                                              idx !== batchindex
                                          )
                                          .reduce(
                                            (sum, batch) =>
                                              sum +
                                              (parseFloat(
                                                batch.item_batch_quantity
                                              ) || 0),
                                            0
                                          );

                                      // Calculate the remaining quantity available for this batch
                                      const remainingQty = parseInt(
                                        totalItemQuantity - sumOtherBatches
                                      );

                                      // If the entered value exceeds the remaining quantity, cap it
                                      if (value > remainingQty) {
                                        value =
                                          itemValues?.stock_management_action ===
                                          "stock_in"
                                            ? value
                                            : remainingQty;
                                      }

                                      // Update the form state with the new value
                                      form.setFieldValue(field.name, value);

                                      // Optionally, update the total quantity if needed

                                      // handleQtyChangeFn(batchindex, value);

                                      // if (
                                      //   value >
                                      //   parseInt(
                                      //     // values.data[
                                      //     //   defaultSelectedItemIndex
                                      //     // ].itemBatchList?.find(
                                      //     //   (bch) =>
                                      //     //     bch?.to_bin_id ===
                                      //     //     values.data[defaultSelectedItemIndex]
                                      //     //       ?.item_batches[batchindex]?.from_bin_id
                                      //     values.data[
                                      //       defaultSelectedItemIndex
                                      //     ].itemBatchList?.find(
                                      //       (bch) =>
                                      //         bch?.created_date ===
                                      //         values.data[defaultSelectedItemIndex]
                                      //           ?.item_batches[batchindex]?.created_date
                                      //     )?.item_quantity
                                      //   )
                                      // ) {
                                      //   // value = parseInt(
                                      //   //   values.data[
                                      //   //     defaultSelectedItemIndex
                                      //   //   ].itemBatchList?.find(
                                      //   //     (bch) =>
                                      //   //       bch?.to_bin_id ===
                                      //   //       values.data[defaultSelectedItemIndex]
                                      //   //         ?.item_batches[batchindex]?.from_bin_id
                                      //   //   )?.item_quantity
                                      //   // );
                                      //   value = parseInt(
                                      //     values.data[
                                      //       defaultSelectedItemIndex
                                      //     ].itemBatchList?.find(
                                      //       (bch) =>
                                      //         bch?.created_date ===
                                      //         values.data[defaultSelectedItemIndex]
                                      //           ?.item_batches[batchindex]?.created_date
                                      //     )?.item_quantity
                                      //   );
                                      // }

                                      // field.onChange(e); // Update form state
                                      // form.setFieldValue(field.name, value); // Ensure the value doesn't exceed 1

                                      // if (e.target === document.activeElement) {
                                      //   handleQtyChangeFn(batchindex, e.target.value); // Call quantity function
                                      // }
                                    }}
                                    onInput={(e) => {
                                      const value = e.target.value;
                                      if (value < 0) {
                                        form.setFieldValue(field.name, 0); // Prevent negative value
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (["-", "e", " "].includes(e.key)) {
                                        e.preventDefault(); // Prevent negative sign, scientific notation, and space
                                      }
                                    }}
                                  />
                                )}
                              </Field>
                            </td>

                            <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm ">
                              <Field
                                name={`item_batches.${batchindex}.item_batch_free_quantity`}
                              >
                                {({ field }) => (
                                  <input
                                    onKeyDown={positiveIntegerFn}
                                    placeholder=""
                                    {...field}
                                    type="number"
                                    className={`w-full focus_outline__none focus:border-primary focus:border rounded-md py-2 px-2`}
                                  />
                                )}
                              </Field>
                            </td>

                            {itemValues?.stock_management_action ===
                              "stock_in" && (
                              <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm">
                                <Field
                                  name={`item_batches.${batchindex}.item_exp_date`}
                                >
                                  {({ field }) => (
                                    <input
                                      placeholder=""
                                      {...field}
                                      type="date"
                                      className={`w-full focus_outline__none focus:border-primary focus:border rounded-md py-2`}
                                    />
                                  )}
                                </Field>
                              </td>
                            )}
                            <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm">
                              <SelectField
                                general_color={false}
                                autoFocus={
                                  values?.item_batches[batchindex]
                                    ?.item_batch_number
                                }
                                defaultValue={{
                                  label:
                                    binLocationList?.find(
                                      (bin) =>
                                        bin?.value ===
                                        values?.item_batches[batchindex]
                                          ?.from_bin_id
                                    )?.label ?? "",
                                  value: "",
                                }}
                                handleSelected={(selectedoption) => {
                                  setFieldValue(
                                    `item_batches.${batchindex}.from_bin_location`,
                                    selectedoption?.actualLabel
                                  );
                                }}
                                labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
                                containerClass="w-full"
                                isDisabled={
                                  disabled ||
                                  itemValues?.stock_management_action ===
                                    "stock_in" ||
                                  itemValues?.stock_management_action ===
                                    "stock_out" ||
                                  itemValues?.stock_management_action ===
                                    "in_store_transfer"
                                }
                                placeholder={"select Bin"}
                                options={binLocationList}
                                label={""}
                                id={`item_batches.${batchindex}.from_bin_id`}
                                name={`item_batches.${batchindex}.from_bin_id`}
                                isSearchable={true}
                                errMsgContainerClass="ps-2 hidden"
                                labelWidth="7rem"
                              />
                            </td>
                            <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm">
                              <SelectField
                                // menuPosition="auto"
                                autoFocus={
                                  values?.item_batches[batchindex]
                                    ?.item_batch_number
                                }
                                defaultValue={{
                                  label:
                                    binLocationList?.find(
                                      (bin) =>
                                        bin?.value ===
                                        values?.item_batches[batchindex]
                                          ?.to_bin_id
                                    )?.label ?? "",
                                  value: "",
                                }}
                                handleSelected={(selectedoption) => {
                                  setFieldValue(
                                    `item_batches.${batchindex}.to_bin_location`,
                                    selectedoption?.actualLabel
                                  );
                                }}
                                labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
                                containerClass="w-full"
                                isDisabled={
                                  disabled ||
                                  itemValues?.stock_management_action ===
                                    "stock_out"
                                }
                                placeholder={"select Bin"}
                                options={binLocationList}
                                label={""}
                                id={`item_batches.${batchindex}.to_bin_id`}
                                name={`item_batches.${batchindex}.to_bin_id`}
                                // isSearchable={true}
                                errMsgContainerClass="ps-2 hidden"
                                labelWidth="7rem"
                                value_color="var(--secondary)"
                              />
                            </td>
                            {!disabled && (
                              <td className="border-D0D5DD border border-1 py-2 px-2 text-secondary fw_400 text-sm">
                                <button
                                  onClick={() => {
                                    const updatedBatches = [
                                      ...values.item_batches,
                                    ];
                                    updatedBatches.splice(batchindex, 1);
                                    setFieldValue(
                                      `item_batches`,
                                      updatedBatches
                                    );
                                  }}
                                  type="button"
                                >
                                  <DeleteIcon />
                                </button>
                                {/* )} */}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {!disabled && (
                      <div className="flex justify-end lg:w-[100%] w-full">
                        <AddButton
                          className="bg-primary text-white px-8 py-2 mt-2 rounded flex justify-center fw_500 text-sm"
                          onclkFn={() => {
                            if (batchRef && batchRef.current) {
                              batchRef?.current?.focus();
                            }
                            setFieldValue(`item_batches`, [
                              ...values?.item_batches,
                              initData,
                            ]);
                          }}
                          prefixIcon={true}
                          text=""
                          type="button"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex justify-between px-10 sticky bottom-0   bg-white py-2 invoice-bottom-container">
                  <AddButton
                    onclkFn={() => {
                      const totalBatchQty = values.item_batches?.reduce(
                        (accumulator, currentBatch) =>
                          accumulator + currentBatch?.item_batch_quantity,
                        0
                      );
                      if (
                        (itemValues?.stock_management_action === "stock_out" ||
                          itemValues?.stock_management_action ===
                            "in_store_transfer") &&
                        !(totalBatchQty === parseInt(values?.quantity))
                      ) {
                        errorToast(
                          "Quantity is not matching with batch Quantities"
                        );
                      } else {
                        handleShowErrorToast(errors);
                        handleSubmit();
                      }
                    }}
                    prefixIcon={false}
                    text="Done"
                    type="button"
                  />
                </div>
              </div>
              <div
                className="bg-black h-[23vh] opacity-50"
                onClick={() => {
                  handleShowErrorToast();
                  handleSubmit();
                }}
              ></div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default BatchQuantityBinPopover;
