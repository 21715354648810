import {
  INVENTORY_CREATE,
  BINLOCATION_ADD,
  WAREHOUSE_LIST,
  STOCK_TRANSFER_TABLE,
  STOCK_TRANSFER_CREATE,
  STOCK_TRANSFER_VIEW,
  STOCK_TRANSFER_BATCH,
  VOID_STOCK_TRANSFER_BATCH,
  VOID_STOCK_TRANSFER,
  VOID_STOCK_TRANSFER_ITEM,
  STOCK_TRANSFER_EDIT,
} from "../types";

export const inventoryCreateAction = (data, onSuccess, onError) => {
  return {
    type: INVENTORY_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const binlocationAddAction = (data, onSuccess, onError) => {
  return {
    type: BINLOCATION_ADD,
    data,
    onSuccess,
    onError,
  };
};

export const warehouseListAction = (data, onSuccess, onError) => {
  return {
    type: WAREHOUSE_LIST,
    data,
    onSuccess,
    onError,
  };
};

export const stockTransferTableAction = (data, onSuccess, onError) => {
  return {
    type: STOCK_TRANSFER_TABLE,
    data,
    onSuccess,
    onError,
  };
};
export const stockTransferCreateAction = (data, onSuccess, onError) => {
  return {
    type: STOCK_TRANSFER_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const stockTransferEditAction = (data, onSuccess, onError) => {
  return {
    type: STOCK_TRANSFER_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const stockTransferViewAction = (data, onSuccess, onError) => {
  return {
    type: STOCK_TRANSFER_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const stockTransferBatchAction = (data, onSuccess, onError) => {
  return {
    type: STOCK_TRANSFER_BATCH,
    data,
    onSuccess,
    onError,
  };
};
export const voidStockTransferAction = (data, onSuccess, onError) => {
  return {
    type: VOID_STOCK_TRANSFER,
    data,
    onSuccess,
    onError,
  };
};
export const voidStockTransferItemAction = (data, onSuccess, onError) => {
  return {
    type: VOID_STOCK_TRANSFER_ITEM,
    data,
    onSuccess,
    onError,
  };
};
