import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { manufacturesListAction } from "../../../redux/actions/general-action";
import AddButton from "../../buttons/button";
import CheckBox from "../../forms/check-box-field";
import errorToast from "../../helper-components/error-toast";
import successToast from "../../helper-components/success-toast";
import Select from "react-select";
import "../discount-group.css";
import EditIcon from "../../../assets/icons/action-icons";
import PlusIcon from "../../../assets/icons/plus-icon";
import MinusIcon from "../../../assets/icons/minus-icon";
import { disManufacGroupCreateAction } from "../../../redux/actions/discount-action";
import moment from "moment";
import search from "../../../assets/icons/searchIcon.svg";
import LoadingSpinner from "../../helper-components/loading-spinner";

const ManufacturerTab = ({
  mastervalues,
  initialData,
  fetchDisGrpFn = () => {},
  loading,
  permissionFn = () => {},
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [currentIndex, setCurrentIndex] = useState(null);
  const [manufacturesList, setManufacturesList] = useState([]);
  useEffect(() => {
    dispatch(
      manufacturesListAction(onmanufacturesSuccess, onmanufacturesError)
    );
  }, []);

  const onmanufacturesSuccess = (data) => {
    setManufacturesList(
      data?.data?.map((item) => {
        return {
          label: item?.name,
          value: item?.id,
        };
      })
    );
  };
  const onmanufacturesError = (data) => {};
  const initialValues = {
    manufacture_group_id: "",
    manufacture_group_name: "",
    discount_percentage: "",
    is_active: initialData?.is_active,
    from_date: initialData?.from_date
      ? moment(initialData?.from_date).format("YYYY-MM-DD")
      : "",
    to_date: initialData?.from_date
      ? moment(initialData?.to_date).format("YYYY-MM-DD")
      : "",
    ManufactureGroupData:
      initialData?.manufacture_groups?.length > 0
        ? initialData?.manufacture_groups?.map((item) => ({
            manufacture_group_id: item?.manufacture_group_id,
            discount_percentage: item?.discount_percentage,
            manufacture_group_name: item?.manufacture_group_name,
          }))
        : [],
  };

  const validationSchema = Yup.object().shape({});
  const innerOnSubmit = (values) => {
    dispatch(
      disManufacGroupCreateAction(
        {
          DiscountGroupData: {
            is_active: values?.is_active,
            discount_type_id: mastervalues?.discount_type_id,
            customer_group_id: mastervalues?.customer_group_id,
            from_date: values?.from_date,
            to_date: values?.to_date,
          },
          ManufactureGroupData: values?.ManufactureGroupData.map((grp) => ({
            manufacture_group_id: grp?.manufacture_group_id,
            discount_percentage: grp?.discount_percentage,
          })),
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast(data?.message);
    fetchDisGrpFn(
      mastervalues?.discount_type_id,
      mastervalues?.customer_group_id,
      "Manufacturers"
    );
  };
  const onError = (data) => {
    errorToast(data?.message);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={innerOnSubmit}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            return (
              <>
                <div className="flex justify-between flex-col grow">
                  <div>
                    <div className="flex lg:flex-row flex-col whitespace-wrap justify-between px-4">
                      <div className="flex gap-5">
                        <div
                          style={{
                            width: "fit-content",
                          }}
                          className="border-D0D5DD  border-2  rounded-md text-344054 fw_600 flex items-center md:flex-wrap"
                        >
                          <Field name="manufacture_group_id">
                            {({ field }) => (
                              <Select
                                name="manufacture_group_id"
                                {...field}
                                styles={customStyles}
                                options={manufacturesList}
                                value={
                                  manufacturesList.find(
                                    (option) =>
                                      option.value ===
                                      values.manufacture_group_id
                                  ) || ""
                                }
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "manufacture_group_id",
                                    selectedOption.value
                                  );
                                  setFieldValue(
                                    "manufacture_group_name",
                                    selectedOption.label
                                  );
                                }}
                                placeholder="Manufacture Name"
                                isSearchable={false}
                              />
                            )}
                          </Field>
                          <div className="h-[40px] w-0.5 border-r-D0D5DD border-r-2  mx-2"></div>{" "}
                          <Field name={`discount_percentage`}>
                            {({ field }) => (
                              <input
                                placeholder="Discount %"
                                {...field}
                                type="number"
                                className={`text-sm text-value-color placeholder:text-placeholder-color w-[7rem]  focus_outline__none input-field-no-spin-buttons`}
                              />
                            )}
                          </Field>
                        </div>
                        {currentIndex === null ? (
                          <button
                            type="button"
                            onClick={() => {
                              if (
                                values?.manufacture_group_id &&
                                values?.discount_percentage
                              ) {
                                setFieldValue("ManufactureGroupData", [
                                  ...values.ManufactureGroupData,
                                  {
                                    manufacture_group_id:
                                      values?.manufacture_group_id,
                                    manufacture_group_name:
                                      values?.manufacture_group_name,
                                    discount_percentage:
                                      values?.discount_percentage,
                                  },
                                ]);
                              }
                              setFieldValue("manufacture_group_id", "");
                              setFieldValue("discount_percentage", "");
                            }}
                            className="bg-primary text-white px-10 py-2 rounded flex justify-center items-center fw_500 text-sm"
                          >
                            <PlusIcon />
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => {
                              if (
                                values?.manufacture_group_id &&
                                values?.discount_percentage
                              ) {
                                const updatedManufactureGroupData = [
                                  ...values.ManufactureGroupData,
                                ];
                                updatedManufactureGroupData[currentIndex] = {
                                  manufacture_group_id:
                                    values.manufacture_group_id,
                                  manufacture_group_name:
                                    values.manufacture_group_name,
                                  discount_percentage:
                                    values.discount_percentage,
                                };

                                setFieldValue(
                                  "ManufactureGroupData",
                                  updatedManufactureGroupData
                                );
                                setCurrentIndex(null);
                              }
                              setFieldValue("manufacture_group_id", "");
                              setFieldValue("discount_percentage", "");
                            }}
                            className="bg-primary text-white px-10 py-2 rounded flex justify-center items-center fw_500 text-sm"
                          >
                            <EditIcon fill="#FFFFFF" />
                          </button>
                        )}
                      </div>
                      <div
                        style={{ minWidth: "18rem", height: "2.5rem" }}
                        className={`input_border flex items-center rounded-md pb-1`}
                      >
                        <span className="px-2">
                          {" "}
                          <img width={"16px"} src={search} alt="search icon" />
                        </span>
                        <input
                          placeholder={`Search here`}
                          className=" table_search_input w-full rounded-md fs_12 placeholder:text-fs_12"
                          type="text"
                          value={inputValue}
                          onChange={(e) => {
                            setInputValue(e?.target?.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="mt-5 px-4 flex gap-10 flex-wrap">
                      {values?.ManufactureGroupData?.filter((grp) =>
                        grp?.manufacture_group_name
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      )?.map((grp, index) => {
                        return (
                          <div className="border-D0D5DD  border-2  rounded-md w-[30rem] flex h-[2.8rem] items-center">
                            <span className="text-344054 fw_600 text-sm pl-4 w-[14rem]">
                              {grp?.manufacture_group_name}
                            </span>
                            <div className="h-[2.8rem] w-0.5 border-r-D0D5DD border-r-2 mx-2"></div>
                            <span className="text-344054 fw_600 text-sm pl-4 w-[6rem]">
                              {grp?.discount_percentage}
                            </span>
                            <button
                              onClick={() => {
                                setCurrentIndex(index);
                                setFieldValue(
                                  "manufacture_group_id",
                                  grp?.manufacture_group_id
                                );
                                setFieldValue(
                                  "discount_percentage",
                                  grp?.discount_percentage
                                );
                              }}
                              className="bg-FFBA08 h-[2.8rem] w-[4.5rem] flex justify-center items-center"
                            >
                              <EditIcon fill="#FFFFFF" />
                            </button>
                            <button
                              onClick={() => {
                                const updatedManufactureGroupData = [
                                  ...values.ManufactureGroupData,
                                ];
                                updatedManufactureGroupData.splice(index, 1);
                                setFieldValue(
                                  "ManufactureGroupData",
                                  updatedManufactureGroupData
                                );
                              }}
                              className="bg-F51D00 h-[2.8rem] w-[4.5rem] flex justify-center items-center rounded-r-lg"
                            >
                              <MinusIcon />
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    style={{
                      bottom: "0",
                    }}
                    className="flex items-start justify-between px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                  >
                    <div
                      style={{
                        width: "fit-content",
                      }}
                      className="border-D0D5DD  border-2  h-[2.8rem] px-4 rounded-md text-344054 fw_600 flex items-center md:flex-wrap"
                    >
                      <CheckBox items={items} />
                      <div className="h-[2.8rem] w-0.5 border-r-D0D5DD border-r-2 mx-2"></div>
                      <span className="mr-2">From :{"  "}</span>
                      <Field name={`from_date`}>
                        {({ field }) => (
                          <input
                            placeholder="  "
                            {...field}
                            type="date"
                            className={`text-sm text-value-color placeholder:text-placeholder-color w-[7rem]  focus_outline__none input-field-no-spin-buttons`}
                          />
                        )}
                      </Field>
                      <div className="h-[2.8rem] w-0.5 border-r-D0D5DD border-r-2 mx-2"></div>
                      <span className="mr-2">to :{"  "}</span>
                      <Field name={`to_date`}>
                        {({ field }) => (
                          <input
                            placeholder="  "
                            {...field}
                            type="date"
                            className={`text-sm text-value-color placeholder:text-placeholder-color w-[7rem]  focus_outline__none input-field-no-spin-buttons`}
                          />
                        )}
                      </Field>
                    </div>
                    {permissionFn("discount_groups")?.update && (
                      <AddButton
                        onclkFn={handleSubmit}
                        prefixIcon={false}
                        text="Save"
                        type="submit"
                      />
                    )}
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default ManufacturerTab;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: "600",
    color: "#344054",
    width: "200px",
    border: "none", // Remove border
    boxShadow: "none", // Remove box shadow
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#344054",
      fontFamily: "Inter,sans-serif",
      fontSize: "14px",
      fontWeight: "600",
      opacity: "1",
      position: "absolute",
      whiteSpace: "nowrap",
    };
  },
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide indicator separator
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "700",
    color: "#344054",
    fontSize: "14px",
  }),
  option: (provided, state) => {
    return {
      ...provided,
      ...(state.data.color ? { color: state.data.color } : { color: "black" }),
      backgroundColor: state.isSelected ? "#e5ecffb2" : null,
      borderBottom: "1px solid #F5F5F5",
      fontSize: "14px",
      fontFamily: "Inter,sans-serif",
      "&:hover": {
        backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
      },
    };
  },
};
const items = [{ label: "Active", name: "is_active", isRequired: false }];
