import React, { useEffect, useRef, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import search from "../../assets/icons/searchIcon.svg";
import { Link } from 'react-scroll';
import reportsData from "./reports-data";
import { useNavigate } from "react-router-dom";
import ReportssItemContainer from "../../components/reports/reports-item-container";
import { GetFavAction } from "../../redux/actions/report-action";
import { useDispatch } from "react-redux";
import { 
  Star, 
  FileText, 
  BookOpen,
  BookOpenCheck,
} from 'lucide-react';
import { SidebarSection } from "./SidebarSection";
import { SidebarItem } from "./SidebarItem";
import { TableSection } from "./TableSection";
import ReportsFavItemContainer from "../../components/reports/reports-fav";
const Reports = () => {
  const dispatch = useDispatch();
  const [reportModules, setreportModules] = useState(reportsData);
  const reportRefs = useRef([]);
  const [selectedIndex, setSelectedIndex] = useState(0); // State to track the selected quick link
  const [addedFav,setAddedFav]=useState([]);
  const navigate = useNavigate();
  const handleItemClick = (index, report) => {
    navigate(report.path);
    const element = document.querySelector(".innerLayoutScroller");
    if (element.style.overflow === "hidden") {
      element.style.overflow = "auto";
    }
  };

  // const SearchBar = () => {
  //   const [searchValue, setSearchValue] = useState("");
  //   const [searchResults, setSearchResults] = useState([]);

  //   useEffect(() => {
  //     const element = document.querySelector(".innerLayoutScroller");
  //     if (searchValue) {
  //       const results = reportsData
  //         .map((report) => {
  //           const childrens = report.childrens.filter((child) =>
  //             child.title.toLowerCase().includes(searchValue.toLowerCase())
  //           );
  //           if (childrens.length > 0) {
  //             return {
  //               childrens,
  //             };
  //           }
  //         })
  //         .filter((result) => result !== undefined)
  //         .reduce((acc, curr) => {
  //           return [...acc, ...curr.childrens];
  //         }, []);

  //       element.style.overflow = "hidden";

  //       setSearchResults(results.slice(0, 15));
  //     } else {
  //       element.style.overflow = "auto";
  //     }
  //     
  //   }, [searchValue]);

  //   return (
  //     <>
  //       {searchValue && (
  //         <div
  //           className="h-[120%] -mt-5 w-full bg-[#0000005a] absolute z-0 "
  //           onClick={() => {
  //             setSearchValue("");
  //           }}
  //         ></div>
  //       )}
  //       <div className="w-[calc(100%-60px)] mx-[30px] mt-[1rem] flex flex-row mb-4 p-5 justify-between items-center rounded-lg absolute">
  //       <div className="w-1/12 font-medium text-xlg">All Reports</div>
  //       <div
  //           style={{ height: "2.5rem" }}
  //           className={`input_border flex items-center rounded-md w-11/12 bg-white`}
  //         >
  //           <span className="px-2">
  //             <img width={"16px"} src={search} alt="search icon" />
  //           </span>
  //           <input
  //             placeholder={`Search reports...`}
  //             className=" table_search_input w-full rounded-md fs_12 placeholder:text-fs_12"
  //             type="text"
  //             onChange={(e) => {
  //               setTimeout(() => {
  //                 setSearchValue(e.target.value);
  //               }, 500);
  //             }}
  //             onFocus={(e) => {
  //               setSearchValue(e.target.value);
  //             }}
  //           />
  //         </div>
  //         {searchValue &&
  //           (searchResults.length > 0 ? (
  //             <div className="mt-5 grid grid-cols-3 gap-3 resultContainer">
  //               {searchResults.map((result, index) => {
  //                 
  //                 return (
  //                   <>
  //                     <ReportssItemContainer
  //                       key={index}
  //                       report={result}
  //                       search
  //                       handleItemClick={handleItemClick}
  //                     />
  //                   </>
  //                 );
  //               })}
  //             </div>
  //           ) : (
  //             <div className="text-center mt-5 text-000000">
  //               No results found
  //             </div>
  //           ))}
  //       </div>
  //     </>
  //   );
  // };
  const SearchBar = () => {
    const [searchValue, setSearchValue] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    useEffect(() => {
        const element = document.querySelector(".innerLayoutScroller");
        if (searchValue) {
            const results = reportsData
                .map((report) => {
                    const childrens = report.childrens.filter((child) =>
                        child.title.toLowerCase().includes(searchValue.toLowerCase())
                    );
                    if (childrens.length > 0) {
                        return {
                            childrens,
                        };
                    }
                })
                .filter((result) => result !== undefined)
                .reduce((acc, curr) => {
                    return [...acc, ...curr.childrens];
                }, []);

            element.style.overflow = "hidden";

            setSearchResults(results.slice(0, 15));
        } else {
            element.style.overflow = "auto";
        }
    }, [searchValue]);

    return (
        <>
            {searchValue && (
                <div
                    className="h-[120%] -mt-5 w-full bg-[#0000005a] absolute z-0"
                    onClick={() => {
                        setSearchValue("");
                    }}
                ></div>
            )}

            <div className="w-[calc(100%-60px)] mx-[30px] bg-[#F9F9F9] mt-[1rem] mb-4 p-5 rounded-lg absolute">
            <div className="flex items-center w-full">
    <div className="text-xl font-bold mr-20">All Reports</div>
    <div
        style={{ height: "3rem" }}
        className={`input_border flex items-center rounded-md flex-grow bg-white`}
    >
        <span className="px-2">
            <img width={"16px"} src={search} alt="search icon" />
        </span>
        <input
            style={{ height: "2rem" }}
            placeholder={`Search reports...`}
            className="table_search_input w-full h-3rem rounded-md fs_12 placeholder:text-fs_12"
            type="text"
            onChange={(e) => {
                setTimeout(() => {
                    setSearchValue(e.target.value);
                }, 500);
            }}
            onFocus={(e) => {
                setSearchValue(e.target.value);
            }}
        />
    </div>
</div>

                {searchValue &&
                    (searchResults.length > 0 ? (
                        <div className="mt-5 grid grid-cols-3 gap-3 resultContainer">
                            {searchResults.map((result, index) => {
                                return (
                                    <>
                                        <ReportssItemContainer
                                            key={index}
                                            report={result}
                                            search
                                            handleItemClick={handleItemClick}
                                        />
                                    </>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="text-center mt-5 text-000000">
                            No results found
                        </div>
                    ))}
            </div>
        </>
    );
};
  useEffect(() => {
    reportRefs.current = reportModules.map(
      (_, i) => reportRefs.current[i] ?? React.createRef()
    );
  }, [reportModules]);
  const [get,setGet]=useState(true);
  const handleQuickLinkClick = (index) => {
    setSelectedIndex(index); // Update the selected index

    const element = reportRefs.current[index]?.current;
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash.startsWith("#report-")) {
        const index = hash.replace("#report-", "");
        const element = reportRefs.current[index]?.current;
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    };

    window.addEventListener("hashchange", handleHashChange, false);

    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange, false);
    };
  }, []);

  useEffect(() => {
    if(get){
      dispatch(GetFavAction ({},onSuccess, onError));
      setGet(false);
    }
   },[get]);
  
    const onSuccess = (data) => {
      console.log(data.result);
      setAddedFav(data?.data?.result ?? []);  
    };
  
    const onError = (data) => {
    };

  return (
    <InnerLayout backBtn={true} list={true} title="Reports">
      <div className="h-full w-full overflow-hidden">
        {/* <nav className="sticky top-0 z-30   bg-f3f8ff quick-div  w-full flex justify-center px-[3rem]">
        <ul className="flex gap-8 py-4 overflow-x-auto w-full  justify-center">
            {reportModules?.map((rep, repIndex) => {
              return (
                <li>
                  <button
                    onClick={() => handleQuickLinkClick(repIndex)}
                    className={`cursor-pointer bg-transparent fs_14 fw_400 text-000000 border-none hover:text-primary hover:underline   ${
                      selectedIndex === repIndex
                        ? "text-primary font-bold underline"
                        : "text-blue-500"
                    }`}
                  >
                    {rep.title}
                  </button>
                </li>
              );
            })}
          </ul>
        </nav> */}
        <div
          style={
            {
              // maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
              // height: `calc(100% - ${heightOfFirstDiv}px)`,
            }
          }
          className={`w-full flex gap-10 relative h-full pr-3 `}
        >
          <SearchBar />
          <div
            className={`flex flex-wrap ml-3 mr-3 w-full gap-3  flex-col px5 py-5  ${
              reportModules.length > 0 ? "mt-32" : "mt-5"
            }`}
          >
            <div className="flex w-[18%] h-5/6 overflow-y-auto border scrollbar-thin rounded-lg text-black flex-col">
            {/* <ul className="list-disc">
            {reportsData
            .flatMap(report => report.childrens)
            .filter(child => child && child.title) 
            .map((child, index) => (
              <li key={index}>
                {child.title}
              </li>
            ))}
            </ul> */}
              <SidebarSection title="">
                <SidebarItem title="Favourites" icon={BookOpen} />
                <SidebarItem title="Custom Reports" icon={BookOpen} />
              </SidebarSection>
              <SidebarSection title="REPORT CATEGORY">
                {
                  
                  reportsData.map(report => (
                    <Link
                      key={report.title}
                      to={report.title}
                      spy={true}
                      smooth={true}
                      offset={0} // Adjust offset to account for fixed headers
                      duration={500}
                    >
                      <SidebarItem 
                          title={report.title} 
                          icon={FileText} 
                        />
                    </Link>
                            
                  ))
                }
              </SidebarSection>
            </div>
            <div className="flex w-[82%] border rounded-lg text-black h-full custom-bg justify-start items-center flex-col overflow-y-auto">
            <div className="w-full">
            <div 
                className="flex items-center bg-F2F4F5  border-b text-c6c6ca bg-faf9fa border-D0D5DD px-4 py-3"
            >
              <div className="flex-1 text-475467">REPORT NAME</div> 
              <div className="flex-1 text-475467">TYPE</div> 
            </div>
            </div>
                <ReportsFavItemContainer saved="false" addedFav={addedFav} setAddedFav={setAddedFav} navigate={navigate}/>
                <ReportsFavItemContainer saved="true" addedFav={addedFav} setAddedFav={setAddedFav} navigate={navigate}/>
                {
                  reportsData.map(report => (
                    <TableSection addedFav={addedFav} setAddedFav={setAddedFav} title={report.title} key={report.title} id={report.title} reports={report.childrens} navigate={navigate}/>
                  ))
                }
            </div>
          </div>
        </div>
      </div>
    </InnerLayout>
  );
};

export default Reports;
