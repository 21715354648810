import React, { useEffect, useState } from "react";
import { GetSavedReportsAction } from "../../redux/actions/report-action";
import { useDispatch } from "react-redux";
import errorToast from "../helper-components/error-toast";
import { TableRow } from "../../modules/reports/TableRow";
import reportsData from "../../modules/reports/reports-data";
const ReportsFavItemContainer = ({ saved, addedFav, setAddedFav, navigate }) => {
  const [fav, setFav] = useState([]);
  const dispatch = useDispatch();
  const [get, setGet] = useState(true);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [filters, setFilters] = useState({
    filter_by: "",
    filters: [
      { column: null, condition: null, value: "", from: null, to: null },
    ],
    selectedColumns: [],
    from: null,
    to: null,
    group_by: "",
    save: "false",
    name: "",
  });

  useEffect(() => {
    if (get) {
      dispatch(GetSavedReportsAction(filters, onSuccess, onError));
      setGet(false);
    }
  }, [get]);

  const onSuccess = (data) => {
    setFav(data?.data?.result ?? []);
  };

  const onError = (data) => {};

  return (
    <div className="w-full bg-faf9fa" id="saved_reports">
      <div className="px-4 py-2 border-b border-D0D5DD bg-F2F4F5">
        <h2 className="font-bold">{saved === "true" ?"Saved Reports":"Favourites"}</h2>
      </div>
      {saved === "true" ? (
        fav.length > 0 ? (
          <div className="w-full">
            {fav.map((item, index) => (
              <TableRow
                key={`${item}`}
                name={item}
                type={"Custom Report"}
                report={`/reports/savedReports?name=${item}`}
                navigate={navigate}
                setAddedFav={setAddedFav}
                addedFav={addedFav}
              />
            ))}
          </div>
        ) : (
          <div className="w-full flex items-center bg-white border-b border-D0D5DD px-4 py-3 justify-center text-787878">
            No Saved Reports
          </div>
        )
      ) : addedFav.length > 0 ? (
        <div className="w-full">
            {addedFav.map((item, index) => (
              <TableRow
                key={`${item}`}
                name={item?.slice(0, -1)}
                type={"Favorite Report"}
                report={
                  item[item.length - 1] === "C"
                    ? `/reports/savedReports?name=${item?.slice(0, -1)}`
                    : (reportsData.flatMap(report => report.childrens).find(child => child.title === item?.slice(0, -1))?.path || "")
                }
                navigate={navigate}
                addedFav={addedFav}
                setAddedFav={setAddedFav}
              />
            ))}
        </div>
      ) : (
        <div className="w-full flex items-center bg-white border-b border-D0D5DD px-4 py-3 justify-center text-787878">
          No Favorites
        </div>
      )}
    </div>
  );
};

export default ReportsFavItemContainer;
