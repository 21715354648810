import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import AddButton from "../../components/buttons/button";
import "../../components/customer-information/customer-information.css";
import CheckBox from "../../components/forms/check-box-field";
import InputField from "../../components/forms/input-field";
import RadioButton from "../../components/forms/radio-button";
import SelectField from "../../components/forms/select-field";
import TextAreaField from "../../components/forms/textarea-field";
import errorToast from "../../components/helper-components/error-toast";
import { hsnRegExp } from "../../components/helper-components/regexp";
import successToast from "../../components/helper-components/success-toast";
import InnerLayout from "../../components/layouts/inner-layout";
import InventoryTab from "../../components/product-information/inventory-tab";
import PlanningTab from "../../components/product-information/planning-tab";
import PurchasingTab from "../../components/product-information/purchasing-tab";
import RemarksTab from "../../components/product-information/remarks-tab";
import RestrictionsTab from "../../components/product-information/restrictions-tab";
import SalesTab from "../../components/product-information/sales-tab";
import {
  catListAction,
  itemGroupListAction,
  itemTypeListAction,
  manufacturesListAction,
  priceListAction,
  schListAction,
  sectorListAction,
  shippingTypeListAction,
  taxCatListAction,
  uomListAction,
  vendorListAction,
} from "../../redux/actions/general-action";
import {
  productCreateAction,
  productEditAction,
  productGenerateItemCodeAction,
  productVerifyItemCodeAction,
} from "../../redux/actions/product-action";
import TabsComponent from "../../components/helper-components/tabs-component";
import { uomGroupTableListAction } from "../../redux/actions/settings-action";
import { handleShowErrorToast } from "../../components/helper-components/helper-function";
import { useLocation, useSearchParams } from "react-router-dom";
import DiscountTab from "../../components/product-information/discount-tab";

const AddProduct = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const suggestedProducts = location.state?.suggestedProducts || [];
  const [searchParams] = useSearchParams();
  const itemText = searchParams.get("itemText") || "";
  const itemIndex = searchParams.get("itemIndex") || "";
  const [formValues, setFormValues] = useState({
    purchasing: null,
    sales: null,
    inventory: null,
    planning: null,
    restrictions: null,
    discount: null,
  });
  const [disableItemCode, setDisableItemCode] = useState(false);
  const [catList, setCatList] = useState([]);
  const [uomList, setUomList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [uomGroupList, setUomGroupList] = useState([]);
  const [uomGroupData, setUomGroupData] = useState([]);
  const [uomGroupID, setUomGroupID] = useState("");
  const [itemGroup, setItemGroup] = useState([]);
  const [itemType, setItemType] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [manufacturesList, setManufacturesList] = useState([]);
  const [shippingTypeList, setShippingTypeList] = useState([]);
  const [sectorList, setSectorList] = useState([]);
  const [schList, setSchList] = useState([]);
  const [currentTab, setCurrentTab] = useState("General");
  const containerRef = useRef(null);
  const [vendorList, setVendorList] = useState([]);

  const [productId, setProductId] = useState(null);
  const [item_code, setItem_code] = useState(null);
  const handleClick = (item) => {
    setCurrentTab(productId ? item : "General");
    const container = containerRef.current;
    const selectedTab = document.getElementById(item);
    if (container && selectedTab) {
      const containerWidth = container.offsetWidth;
      const selectedTabWidth = selectedTab.offsetWidth;
      const selectedTabLeft = selectedTab.offsetLeft;
      const scrollTo =
        selectedTabLeft - (containerWidth - selectedTabWidth) / 2;
      container.scrollTo({
        left: scrollTo,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    dispatch(vendorListAction(onVendorSuccess, onVendorError));
    dispatch(catListAction(onCatSuccess, onCatError));
    dispatch(uomListAction(onUomSuccess, onUomError));
    dispatch(priceListAction(onpriceSuccess, onpriceError));
    dispatch(taxCatListAction(ontaxCatSuccess, ontaxCatError));
    dispatch(
      manufacturesListAction(onmanufacturesSuccess, onmanufacturesError)
    );
    dispatch(
      shippingTypeListAction(onshippingTypeSuccess, onshippingTypeError)
    );
    dispatch(itemGroupListAction(onitemGroupSuccess, onitemGroupError));
    dispatch(itemTypeListAction(onitemTypeSuccess, onitemTypeError));
    dispatch(sectorListAction(onsectorSuccess, onsectorError));
    dispatch(schListAction(onschSuccess, onschError));
    // dispatch(uomGroupListAction(onUomGrpSuccess, onUomGrpError));

    dispatch(
      uomGroupTableListAction(
        {},
        onFetchUomGroupListSuccess,
        onFetchUomGroupListError
      )
    );
  }, []);

  const onFetchUomGroupListSuccess = (data) => {
    setUomGroupData(data?.data?.uomList);
    setUomGroupList(
      data?.data?.uomList?.map((item) => {
        return { label: item?.name, value: item?.group_id };
      })
    );
  };

  const onFetchUomGroupListError = (data) => {
    setUomGroupData([]);
    errorToast(data?.message);
  };

  // const onUomGrpError = (data) => {
  //   errorToast(data?.message);
  // };
  // const onUomGrpSuccess = (data) => {
  //   setUomGroupList(
  //     data?.data?.map((item) => {
  //       return { label: item?.name, value: item?.group_id };
  //     })
  //   );
  // };
  const onVendorSuccess = (data) => {
    setVendorList(
      data?.data?.vendors?.map((item) => {
        return { label: item?.vendors, value: item?.id };
      })
    );
  };
  const onVendorError = (data) => {
    errorToast(data?.message);
  };
  const onCatSuccess = (data) => {
    setCatList(
      data?.data?.map((item) => {
        return { label: item?.name, value: item?.id };
      })
    );
  };
  const onCatError = (data) => {
    // errorToast(data?.message);
  };
  const onUomSuccess = (data) => {
    setUomList(
      data?.data?.map((item) => {
        return { label: item?.name, value: item?.name };
      })
    );
  };
  const onUomError = (data) => {
    setUomList([]);
  };
  const onpriceSuccess = (data) => {
    setPriceList(
      data?.data?.map((item) => {
        return { label: item?.price_list_name, value: item?.price_list_id };
      })
    );
  };
  const onpriceError = (data) => {
    // errorToast(data?.message);
  };
  const ontaxCatSuccess = (data) => {
    setTaxList(
      data?.data?.map((item) => {
        return { label: item?.code, value: item?.id };
      })
    );
  };
  const ontaxCatError = (data) => {
    // errorToast(data?.message);
  };
  const onmanufacturesSuccess = (data) => {
    setManufacturesList(
      data?.data?.map((item) => {
        return {
          label: item?.name,
          value: item?.id,
        };
      })
    );
  };
  const onmanufacturesError = (data) => {
    setManufacturesList([]);
  };
  const onshippingTypeSuccess = (data) => {
    setShippingTypeList(
      data?.data?.map((item) => {
        return { label: item?.name, value: item?.id };
      })
    );
  };
  const onshippingTypeError = (data) => {
    setShippingTypeList([]);
  };
  const onitemGroupSuccess = (data) => {
    setItemGroup(
      data?.data?.map((item) => {
        return { label: item?.name, value: item?.id };
      })
    );
  };
  const onitemGroupError = (data) => {};
  const onitemTypeSuccess = (data) => {
    setItemType(
      data?.data?.map((item) => {
        return { label: item?.name, value: item?.id };
      })
    );
  };
  const onitemTypeError = (data) => {};
  const onsectorSuccess = (data) => {
    setSectorList(
      data?.data?.sector?.map((item) => {
        return { label: item?.sector, value: item?.sector };
      })
    );
  };
  const onsectorError = (data) => {
    // errorToast(data?.message);
  };
  const onschSuccess = (data) => {
    setSchList(
      data?.data?.scheduleList?.map((item) => {
        return { label: item?.schedule, value: item?.schedule };
      })
    );
  };
  const onschError = (data) => {
    // errorToast(data?.message);
  };
  // const onGenerateItemCodeSuccess = (data) => {
  //     console.log(data.data.item_code);
  //     const item_code = Number(data.data.item_code);
  //     setItem_code(item_code);
  //     setFieldValue('item_code', item_code)
  // }
  // const onGenerateItemCodeError = (data) => {
  //     console.log(data);
  // }
  const onVerifyItemCodeSuccess = (data) => {
    const exists = data?.data?.exists;
    console.log(data?.data?.exists);
    if (exists) {
      //Show error
      errorToast("Item Code already exists");
      setItem_code(null);
    } else {
      //We can go ahead all good
    }
  };
  const onVerifyItemCodeError = (data) => {
    console.log(data);
  };

  const initialValues = {
    item_type: "",
    item_code: "",
    item_name: itemText ?? "",
    item_categeory: "",
    item_price_list: "",
    item_group: "",
    item_generic_name: "",
    item_barcode: "",
    item_uom: "",
    item_unit_price: null,
    item_description: "",
    is_inventory_item: false,
    is_purchase_item: false,
    is_sales_item: false,
    item_manufacturer: "",
    item_shipping_type: "",
    item_sector: "",
    item_schedule: "",
    // item_gst: false,
    item_hsn: "",
    item_tax_category: "",
    item_remarks: "",
    country: "",
    // manage_item_by: "",
    active_from: null,
    active_to: null,
    inactive_from: null,
    inactive_to: null,
    is_active: true,
  };
  const validationSchema = Yup.object().shape({
    item_code: Yup.number().required("item code is required"),
    item_name: Yup.string().required("product name is required"),
    item_hsn: Yup.string()
      .required("HSN code is required") // Added required validation
      .matches(hsnRegExp, "Invalid HSN code"),
    // item_unit_price: Yup.number(),
    item_uom: Yup.string().required("uom is required"),
    item_tax_category: Yup.string().required("tax category is required"),
  });
  const onSubmit = (values, actions) => {
    let actionType = productCreateAction;
    if (productId) {
      actionType = productEditAction;
    }
    const submittedValues = {
      ...values,
      item_id: productId,
    };

    // Deleting the unnecessary key
    delete submittedValues.auto_generate_code;
    delete values.auto_generate_code;

    dispatch(
      actionType(productId ? submittedValues : values, onSuccess, onError)
    );
    actions.setSubmitting(false);
  };

  const onSuccess = (data) => {
    setProductId(data?.data?.item_id);
    setItem_code(data?.data?.item_code);
    setUomGroupID(data?.data?.item_uom);
    setCurrentTab("Purchasing");
    successToast("Product created Successfully");
  };

  const onError = (data) => {
    errorToast(data?.message);
  };

  const verifyItemCode = (ItemCode) => {
    //Call the api to verify the entered Item Code
    if (ItemCode) {
      console.log("Verify Triggered");
      dispatch(
        productVerifyItemCodeAction(
          { item_code: ItemCode },
          onVerifyItemCodeSuccess,
          onVerifyItemCodeError
        )
      );
    }
  };

  return (
    <InnerLayout backBtn={true} onclkFn={"/add-product"} title="Add Item">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors }) => {
          const generateItemCode = () => {
            // Call the API to generate the item code
            dispatch(
              productGenerateItemCodeAction(
                {},
                (data) => {
                  console.log("Generated Item Code:", data.data.item_code);
                  setFieldValue("item_code", data.data.item_code); // Update Formik with the generated item code
                },
                (error) => {
                  console.error("Error generating item code:", error);
                }
              )
            );
          };

          return (
            <Form className="h-full pt-5">
              <div className="flex flex-col justify-between h-full ">
                {" "}
                <div>
                  <div className="flex flex-wrap">
                    <div className="w-full 2xl:w-[43%] lg:w-[60%] px-5 xl:px-10">
                      <div>
                        <InputField
                          type="number"
                          name="item_code"
                          placeholder="Enter item Code"
                          label="Item Code"
                          isRequired={true}
                          labelWidth="7rem"
                          isDisabled={disableItemCode}
                          onBlur={(ev) => {
                            verifyItemCode(ev.target.value);
                          }}
                          handleChange={(ev) => {
                            setFieldValue("item_code", item_code).then();
                          }}
                        />
                      </div>
                      <InputField
                        type="text"
                        name="item_name"
                        placeholder="Enter item name"
                        label="Item Name"
                        isRequired={true}
                        labelWidth="7rem"
                      />
                      <InputField
                        type="text"
                        name="item_generic_name"
                        placeholder="Enter Generic name"
                        label="Generic Name"
                        isRequired={false}
                        labelWidth="7rem"
                      />
                      <TextAreaField
                        label="Description"
                        placeholder="Enter Description"
                        name="item_description"
                        id={"item_description"}
                        isRequired={false}
                        labelWidth="7rem"
                        feildHeight="9rem"
                      />
                      <InputField
                        type="text"
                        name="item_barcode"
                        placeholder="Enter bar code"
                        label="Bar Code"
                        isRequired={false}
                        labelWidth="7rem"
                      />
                      {/* <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10"> */}
                      <CheckBox
                        conClass="flex flex-wrap justify-between gap-4"
                        items={itemoptions}
                      />
                      {/* </div> */}
                    </div>
                    <div className="w-full 2xl:w-[27%] lg:w-[40%] px-5 xl:px-10 mt-10 lg:mt-0">
                      <div className="flex items-center gap-2 p-2 border-width-0 rounded-lg border-white bg-white my-2 mb-8">
                        <CheckBox
                          items={[
                            {
                              label: "Auto Generate",
                              name: "auto_generate_code",
                              isRequired: false,
                            },
                          ]}
                          labelClassname={`text-md fw_400`}
                          onChange={(field_name, is_checked) => {
                            console.log(field_name);
                            if (
                              field_name === "auto_generate_code" &&
                              is_checked === true
                            ) {
                              generateItemCode();
                              //disable the item code input
                              setDisableItemCode(true);
                            } else if (
                              field_name === "auto_generate_code" &&
                              is_checked === false
                            ) {
                              setDisableItemCode(false);
                            }
                          }}
                        />
                      </div>
                      <SelectField
                        placeholder="Select item type"
                        options={itemType}
                        label="Item Type"
                        id="item_type"
                        name="item_type"
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        isRequired={false}
                        labelWidth="7rem"
                      />

                      <SelectField
                        placeholder="Select item category"
                        options={catList}
                        label="Item Category"
                        id="item_categeory"
                        name="item_categeory"
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        isRequired={false}
                        labelWidth="7rem"
                      />
                      <SelectField
                        placeholder="Select item group"
                        options={itemGroup}
                        label="Item Group"
                        id="item_group"
                        name="item_group"
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        isRequired={false}
                        labelWidth="7rem"
                      />
                      <SelectField
                        placeholder="Select Price"
                        options={priceList}
                        label="Price List"
                        id="item_price_list"
                        name="item_price_list"
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        isRequired={false}
                        labelWidth="7rem"
                      />

                      <SelectField
                        handleSelected={() => {
                          setFieldValue();
                        }}
                        placeholder="Select uom"
                        // options={uomList}
                        options={uomGroupList}
                        label="UOM Group"
                        id="item_uom"
                        name="item_uom"
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        isRequired={true}
                        labelWidth="7rem"
                      />
                      <InputField
                        type="number"
                        name="item_unit_price"
                        placeholder="xxxxx"
                        label="Unit Price"
                        labelWidth="7rem"
                      />
                    </div>
                  </div>
                  <TabsComponent
                    handleClickfn={(item) => {
                      setCurrentTab(item);
                    }}
                    itemTabs={itemTabs}
                    setCurrentTab={setCurrentTab}
                    currentTab={currentTab}
                  />
                </div>
                {currentTab === "General" && (
                  <>
                    <div className="flex flex-wrap mt-3">
                      <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                        <SelectField
                          placeholder="Select manufacturer"
                          options={manufacturesList}
                          label="Manufacturer"
                          id="item_manufacturer"
                          name="item_manufacturer"
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={false}
                          labelWidth="7rem"
                        />
                        <SelectField
                          placeholder="Select sector"
                          options={sectorList}
                          label="Sector"
                          id="item_sector"
                          name="item_sector"
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={false}
                          labelWidth="7rem"
                        />
                        <SelectField
                          placeholder="Select SCH"
                          options={schList}
                          label="SCH"
                          id="item_schedule"
                          name="item_schedule"
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={false}
                          labelWidth="7rem"
                        />
                      </div>
                      <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10 mt-10 lg:mt-0">
                        <SelectField
                          placeholder="Select shipping type"
                          options={shippingTypeList}
                          label="Shipping Type"
                          id="item_shipping_type"
                          name="item_shipping_type"
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={false}
                          labelWidth="7rem"
                        />
                      </div>
                    </div>

                    <h2 className="text-000000 fw_500 text-sm mb-8 px-5 xl:px-10 mt-10">
                      Item Category
                    </h2>

                    <div className="flex flex-wrap mt-3">
                      <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                        <InputField
                          type="text"
                          name="item_hsn"
                          placeholder="Enter HSN"
                          label="HSN"
                          isRequired={false}
                          labelWidth="7rem"
                        />
                        <InputField
                          type="text"
                          name="item_remarks"
                          placeholder="Enter remarks"
                          label="Remarks"
                          isRequired={false}
                          labelWidth="7rem"
                        />
                        {/* <CheckBox
                          conClass="flex flex-col gap-4"
                          items={[
                            {
                              label: "Gst",
                              name: "item_gst",
                              isRequired: false,
                            },
                          ]}
                        /> */}
                        {/* <h2 className="text-000000 fw_500 text-sm mb-8 mt-5">
                          Serial and Batch Numbers{" "}
                        </h2>
                        <SelectField
                          placeholder="Select item"
                          options={serialBatchesOptns}
                          label="Manage Item by"
                          id="manage_item_by"
                          name="manage_item_by"
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={false}
                          labelWidth="8rem"
                        /> */}
                      </div>
                      <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10 mt-10 lg:mt-0">
                        <SelectField
                          placeholder="Select tax category"
                          options={taxList}
                          label="Tax Category"
                          id="item_tax_category"
                          name="item_tax_category"
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={true}
                          labelWidth="7rem"
                        />
                        <SelectField
                          placeholder="Select country"
                          options={[{ label: "India", value: "india" }]}
                          label="Country"
                          id="country"
                          name="country"
                          isSearchable={true}
                          errMsgContainerClass="ps-2"
                          isRequired={false}
                          labelWidth="7rem"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="flex flex-wrap my-5">
                      <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                        <div className="flex flex-wrap items-center justify-between">
                          <div className="w-full xl:w-[30%]">
                            <RadioButton
                              inputLabelClass="ms-2"
                              onChange={(e) => {
                                setFieldValue("active_from", null);
                                setFieldValue("active_to", null);
                                setFieldValue("inactive_from", null);
                                setFieldValue("inactive_to", null);
                                const { value } = e.target;
                                setFieldValue(
                                  "is_active",
                                  value === "false" ? false : true
                                );
                              }}
                              labelClass="color_9E9E9E d-flex text-sm mb-1 "
                              name="is_active"
                              options={activeOptns}
                              label=""
                            />
                          </div>
                          <div className="w-full xl:w-[60%] mt-5 xl:mt-0">
                            <InputField
                              errMsgContainerClass="hidden"
                              type="date"
                              name={`${
                                values?.is_active
                                  ? "active_from"
                                  : "inactive_from"
                              }`}
                              placeholder=""
                              label="From"
                              isRequired={false}
                              labelWidth="3rem"
                            />
                            <InputField
                              containerClass="mt-3"
                              errMsgContainerClass="hidden"
                              type="date"
                              name={`${
                                values?.is_active ? "active_to" : "inactive_to"
                              }`}
                              placeholder=""
                              label="To"
                              isRequired={false}
                              labelWidth="3rem"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {currentTab === "Purchasing" && (
                  <PurchasingTab
                    itemGeneralData={{
                      ...values,
                      item_id: productId,
                    }}
                    vendorList={vendorList}
                    setCurrentTab={setCurrentTab}
                    formValues={formValues}
                    uomList={uomList}
                    productId={productId}
                    setFormValues={setFormValues}
                    uomGroupID={uomGroupID}
                    uomGroupData={uomGroupData}
                  />
                )}
                {currentTab === "Sales" && (
                  <SalesTab
                    itemGeneralData={{
                      ...values,
                      item_id: productId,
                    }}
                    setCurrentTab={setCurrentTab}
                    formValues={formValues}
                    uomList={uomList}
                    productId={productId}
                    setFormValues={setFormValues}
                    uomGroupID={uomGroupID}
                    uomGroupData={uomGroupData}
                  />
                )}
                {currentTab === "Inventory" && (
                  <InventoryTab
                    itemGeneralData={{
                      ...values,
                      item_id: productId,
                    }}
                    setCurrentTab={setCurrentTab}
                    formValues={formValues}
                    uomList={uomList}
                    productId={productId}
                    setFormValues={setFormValues}
                  />
                )}
                {currentTab === "Planning" && (
                  <PlanningTab
                    itemGeneralData={{
                      ...values,
                      item_id: productId,
                    }}
                    setCurrentTab={setCurrentTab}
                    formValues={formValues}
                    productId={productId}
                    setFormValues={setFormValues}
                  />
                )}
                {currentTab === "Discounts & Scheme" && (
                  <DiscountTab
                    itemGeneralData={{
                      ...values,
                      item_id: productId,
                    }}
                    setCurrentTab={setCurrentTab}
                    formValues={formValues}
                    productId={productId}
                    setFormValues={setFormValues}
                    uomGroupData={uomGroupData}
                  />
                )}
                {currentTab === "Restrictions" && (
                  <RestrictionsTab
                    itemGeneralData={{
                      ...values,
                      item_id: productId,
                    }}
                    setCurrentTab={setCurrentTab}
                    formValues={formValues}
                    productId={productId}
                    setFormValues={setFormValues}
                  />
                )}
                {currentTab === "Remarks" && (
                  <RemarksTab
                    uomGroupData={uomGroupData}
                    suggestedProducts={suggestedProducts}
                    itemIndex={itemIndex}
                    itemText={itemText}
                    itemGeneralData={{
                      ...values,
                      item_id: productId,
                      item_code: item_code,
                    }}
                    setCurrentTab={setCurrentTab}
                    formValues={formValues}
                    productId={productId}
                    setFormValues={setFormValues}
                  />
                )}
                {currentTab === "General" && (
                  <div
                    style={{
                      bottom: "0",
                    }}
                    className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                  >
                    <AddButton
                      onclkFn={() => handleShowErrorToast(errors)}
                      prefixIcon={false}
                      text="Save"
                      type="submit"
                    />
                  </div>
                )}                             
              </div>
            </Form>
          );
        }}
      </Formik>
    </InnerLayout>
  );
};

export default AddProduct;

const items = [{ label: "All", value: "all" }];
const itemoptions = [
  { label: "Inventory Item", name: "is_inventory_item", isRequired: false },
  { label: "Purchase Item", name: "is_purchase_item", isRequired: false },
  { label: "Sales Item", name: "is_sales_item", isRequired: false },
];
const itemTabs = [
  "General",
  "Purchasing",
  "Sales",
  "Inventory",
  "Planning",
  "Discounts & Scheme",
  "Restrictions",
  "Remarks",
];
export const activeOptns = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];
export const serialBatchesOptns = [
  { label: "Batches", value: "batches" },
  { label: "Numbers", value: "numbers" },
  { label: "Batches and Numbers", value: "batches_numbers" },
];
