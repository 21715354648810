import { Field, useFormikContext } from "formik";
import React, { Fragment } from "react";
export default function RadioButton({
  index = 1,
  options,
  isContainer = true,
  containerClass = "",
  name = "",
  label = "",
  labelClass = "text-value-color mb-4 fw_400",
  buttonContainerClass = "flex items-center gap-1 color_030304",
  mainContainerClass = "flex gap-3 flex-col mt-3",
  inputClass = "",
  inputLabelClass = "",
  onChange,
  disabled = false,
  onClick,
}) {
  const Container = isContainer ? "div" : Fragment;

  const { values, setFieldValue } = useFormikContext();

  return (
    <Container
      {...(isContainer && containerClass ? { className: containerClass } : {})}
    >
      {label && (
        <label className={labelClass} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={mainContainerClass}>
        <Field name={name}>
          {({ field }) => {
            return options.map((option, ind) => {
              return (
                <div
                  className={buttonContainerClass}
                  key={option.key ?? option.label}
                >
                  <input
                    disabled={disabled}
                    className={`radio-button ${inputClass} cursor-pointer`}
                    type="radio"
                    id={
                      options?.[0]?.value === "fixed"
                        ? index + option?.value
                        : option.label ?? option.value
                    }
                    {...field}
                    value={option.value}
                    checked={field.value === option.value}
                    {...(onChange ? { onChange: onChange } : {})}
                    onClick={onClick} // Inline deselection logic
                  />
                  <label
                    className={`${inputLabelClass} text-value-color text-sm cursor-pointer`}
                    htmlFor={
                      options?.[0]?.value === "fixed"
                        ? index + option?.value
                        : option.label ?? option.value
                    }
                  >
                    {option.label}
                  </label>
                </div>
              );
            });
          }}
        </Field>
      </div>
    </Container>
  );
}
