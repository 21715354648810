import { Field, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import "./sales.css";
import TextAreaField from "../forms/textarea-field";
import AddButton, { DraftButton } from "../buttons/button";
import ShareTransactionModal from "../helper-components/share-popup";
import { useNavigate, useParams } from "react-router-dom";
import { handlea4Print } from "../helper-components/helper-function";
import { useDispatch } from "react-redux";
import errorToast from "../helper-components/error-toast";
import RadioButton from "../forms/radio-button";
import { activeOptns } from "../../modules/products/add-product";
import {
  tcsTaxListAction,
  tdsTaxListAction,
} from "../../redux/actions/settings-action";
import SelectField from "../forms/select-field";

const SalesPurchaseFinalBillTable = ({
  name = "",
  disabled = false,
  page = "",
  EditFn = () => {},
  status = true,
  buttonLoading = false,
  transaction_type = "",
  transaction = "",
  tcsTdsAmount = "",
  setTcsTdsAmount,
  prefixName = "",
  formValuesRef,
  handleBilling,
}) => {
  const navigate = useNavigate();
  const { values, setFieldValue, errors } = useFormikContext();
  const [button, setButton] = useState("");
  const [isModalOpen, setIsModalOpen] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();

  function checkErrors(errors) {
    const batchesError = errors?.data?.filter(
      (item) => item?.item_batches?.length > 0
    );
    if (batchesError?.length > 0) {
      errorToast("Please verify the batches of all items!");
    }
  }
  const [tcslist, setTcslist] = useState([]);
  const [tdslist, setTdslist] = useState([]);
  useEffect(() => {
    dispatch(tcsTaxListAction("", onSuccessTcs, onErrorTcs));
    dispatch(tdsTaxListAction("", onSuccessTds, onErrorTds));
  }, []);

  const onSuccessTcs = (data) => {
    setTcslist(
      data?.data?.map((item) => ({
        label: item?.tcs_name + " " + item?.tcs_percentage,
        value: item?.tcs_id,
        ...item,
      }))
    );
  };
  const onErrorTcs = (data) => {};
  const onSuccessTds = (data) => {
    setTdslist(
      data?.data?.map((item) => ({
        label: item?.tds_name + " " + item?.tds_percentage + "%",
        value: item?.tds_id,
        ...item,
      }))
    );
  };
  const onErrorTds = (data) => {};

  useEffect(() => {
    return () => {
      if (
        formValuesRef?.current &&
        !formValuesRef?.current?.submittedButton &&
        !disabled &&
        (prefixName === "gort" || prefixName === "poit")
      ) {
        handleBilling({
          ...formValuesRef?.current,
          submittedButton: "draft",
          save: "auto",
        });
      }
    };
  }, [navigate]);

  return (
    <>
      <div className="table-container flex">
        <div
          style={{
            minWidth: `calc(100% - 18rem)`,
          }}
          className=" flex "
        >
          <div
            style={{
              minWidth: `calc(100% - 15rem)`,
            }}
            className="   flex"
          >
            <div
              className="border-r-EAECF0 border-r-2"
              style={{
                minWidth:
                  transaction_type === "sales_order" ||
                  transaction_type === "purchase_order"
                    ? "100%"
                    : `calc(100% - 18rem)`,
              }}
            >
              <div className="text-foot-tab-head-color fw_700 fs_14 bg-foot-tab-head-bg h-10 flex items-center pl-5">
                Remarks
              </div>
              <TextAreaField
                errMsgContainerClass="hidden"
                isDisabled={page === "view"}
                containerClass="mt-2 px-5"
                placeholder="Enter Remarks"
                name="remarks"
                id={"remarks"}
                isRequired={false}
                labelWidth="7rem"
                feildHeight="6.5rem"
              />
            </div>

            {transaction_type !== "sales_order" &&
              transaction_type !== "purchase_order" && (
                <div
                  className="flex flex-col  fs_14 border-r-EAECF0 border-r-2 fw_500 text-secondary whitespace-nowrap"
                  style={{
                    minWidth: "18rem",
                  }}
                >
                  <div className="text-foot-tab-head-color fw_700 fs_14 bg-foot-tab-head-bg h-10 flex items-center pl-5">
                    TCS / TDS
                  </div>
                  <div className="px-5 py-5">
                    <RadioButton
                      disabled={disabled}
                      onChange={(sel) => {
                        if (sel?.target?.value === "tcs") {
                          const percentage = tcslist?.find(
                            (item) => item?.tcs_id === values?.cmr_tcs
                          )?.tcs_percentage;
                          setFieldValue(
                            `${prefixName}_tds_tcs_type`,
                            sel?.target?.value
                          );

                          setFieldValue(
                            `${prefixName}_tcs_or_tds_percentage_id`,
                            values?.cmr_tcs ?? ""
                          );
                          setFieldValue(
                            `${prefixName}_tcs_or_tds_percentage`,
                            percentage ?? 0
                          );
                        } else if (sel?.target?.value === "tds") {
                          const percentage = tdslist?.find(
                            (item) => item?.tds_id === values?.cmr_tds
                          )?.tds_percentage;
                          setFieldValue(
                            `${prefixName}_tds_tcs_type`,
                            sel?.target?.value
                          );
                          setFieldValue(
                            `${prefixName}_tcs_or_tds_percentage_id`,
                            values?.cmr_tds ?? ""
                          );
                          setFieldValue(
                            `${prefixName}_tcs_or_tds_percentage`,
                            percentage ?? 0
                          );
                          setTcsTdsAmount(0);
                        }
                      }}
                      mainContainerClass="flex gap-5"
                      inputLabelClass="ms-1"
                      labelClass="color_9E9E9E d-flex text-sm mb-1 flex"
                      name={`${prefixName}_tds_tcs_type`}
                      options={tcsTdsOptns}
                      label=""
                      onClick={(e) => {
                        if (
                          e?.target?.value ===
                          values?.[`${prefixName}_tds_tcs_type`]
                        ) {
                          setFieldValue(
                            `${prefixName}_tcs_or_tds_percentage_id`,
                            ""
                          );
                          setFieldValue(`${prefixName}_tds_tcs_type`, "");
                          setFieldValue(
                            `${prefixName}_tcs_or_tds_percentage`,
                            0
                          );
                          setTcsTdsAmount(0);
                        }
                      }}
                    />
                    {values?.[`${prefixName}_tds_tcs_type`] && (
                      <SelectField
                        isDisabled={disabled}
                        handleSelected={(sel) => {
                          setFieldValue(
                            `${prefixName}_tcs_or_tds_percentage`,
                            sel?.tds_percentage || sel?.tcs_percentage
                          );
                          setTcsTdsAmount(0);
                        }}
                        defaultSelectClass={
                          "selectField focus_outline__none py-1 h-[2.6rem]"
                        }
                        containerClass="mt-5"
                        placeholder="Select"
                        options={
                          values?.[`${prefixName}_tds_tcs_type`] === "tcs"
                            ? tcslist
                            : tdslist
                        }
                        label=""
                        id={`${prefixName}_tcs_or_tds_percentage_id`}
                        name={`${prefixName}_tcs_or_tds_percentage_id`}
                        isSearchable={true}
                        errMsgContainerClass="ps-2"
                        isRequired={false}
                        labelWidth="10rem"
                      />
                    )}
                  </div>
                </div>
              )}
          </div>
          <div
            style={{
              minWidth: "15rem",
            }}
            className="flex flex-col  fs_14 border-r-EAECF0 border-r-2 fw_500 text-secondary whitespace-nowrap"
          >
            <div className="text-foot-tab-head-color fw_700 fs_14 bg-foot-tab-head-bg h-10 flex items-center pl-5">
              GST Info
            </div>
            <div className="w-full flex justify-between px-5 py-5 ">
              <span>CGST (₹)</span>{" "}
              <span className="text-000000">
                {(calculateTotalTax(values?.data) / 2).toFixed(2)}
              </span>
            </div>
            <div className="w-full flex justify-between px-5 py-5 ">
              <span>SGST (₹)</span>{" "}
              <span className="text-000000">
                {(calculateTotalTax(values?.data) / 2).toFixed(2)}
              </span>
            </div>
            <div className="w-full flex justify-between px-5 py-5 ">
              <span>IGST (₹)</span>{" "}
              <span className="text-000000">
                {calculateTotalIGST(values?.data).toFixed(2)}
              </span>
            </div>
          </div>
        </div>
        <div
          className="bg-foot-tab-head-bg fs_14 fw_500 text-secondary"
          style={{
            minWidth: "18rem",
          }}
        >
          <div className="pt-2 pb-1 px-4 flex justify-between items-center">
            <span>Sub Total (₹)</span>
            <span>
              {" "}
              <input
                disabled={true}
                className="text-end w-[8rem] px-2 py-1 border border-D0D5DD rounded-md text-000000"
                type="text"
                value={`${(
                  values.data?.reduce(
                    (total, item) =>
                      total + parseFloat(item.item_total_amount || 0),
                    0
                  ) - calculateTotalTax(values?.data)
                ).toFixed(2)}`}
              />
            </span>
          </div>
          <div className="py-1 px-4 flex justify-between items-center">
            <span>Tax (₹)</span>
            <span>
              <input
                disabled={true}
                className="text-end w-[8rem] px-2 py-1 border border-D0D5DD rounded-md text-000000"
                type="text"
                value={`${calculateTotalTax(values?.data).toFixed(2)}`}
              />
            </span>
          </div>
          <div className="py-1 px-4 flex justify-between items-center">
            <span>Discount (₹)</span>
            <span>
              <Field name={name}>
                {({ field, form }) => (
                  <input
                    // disabled={disabled}
                    disabled={true}
                    placeholder="₹ 0.00"
                    {...field}
                    className="text-end w-[8rem] px-2 py-1 rounded-md border focus:placeholder-transparent text-000000 focus:outline-none border-D0D5DD input-field-no-spin-buttons"
                    type="number"
                    value={calculateTotalDis(values?.data)?.toFixed(2)}
                    onBlur={(e) => {
                      const inputValue = e.target.value;
                      if (
                        !inputValue.includes(".") &&
                        !isNaN(parseInt(inputValue))
                      ) {
                        form.setFieldValue(
                          field.name,
                          parseFloat(inputValue).toFixed(2)
                        );
                      }
                    }}
                  />
                )}
              </Field>
            </span>
          </div>

          {transaction_type !== "sales_order" &&
            transaction_type !== "purchase_order" && (
              <div className="py-1 px-4 flex justify-between items-center ">
                <span>
                  {values?.[`${prefixName}_tds_tcs_type`] === "tcs"
                    ? "TCS "
                    : values?.[`${prefixName}_tds_tcs_type`] === "tds"
                    ? "TDS"
                    : "TCS/TDS "}{" "}
                  <span className="text-xsm">
                    {" "}
                    {`(${parseFloat(
                      values?.[`${prefixName}_tcs_or_tds_percentage`]
                    ).toFixed(2)} %)`}
                  </span>
                </span>
                <span>
                  {values?.[`${prefixName}_tds_tcs_type`] === "tds"
                    ? " - "
                    : ""}
                  <input
                    disabled={
                      disabled || !values?.[`${prefixName}_tds_tcs_type`]
                    }
                    className="text-end w-[8rem] px-2 py-1 border rounded-md border-D0D5DD text-000000"
                    type="text"
                    value={calculatedTcsTdsAmount(
                      values,
                      tcsTdsAmount,
                      prefixName
                    )}
                    onChange={(e) => {
                      const enteredAmount = parseFloat(e.target.value) || 0;
                      let updatedPercentage = 0;

                      if (values?.[`${prefixName}_tds_tcs_type`] === "tcs") {
                        const totalAmount =
                          parseFloat(calculateTotal(values?.data)) || 1;
                        updatedPercentage = (enteredAmount / totalAmount) * 100;
                      } else {
                        const totalWithoutTax =
                          values.data?.reduce(
                            (total, item) =>
                              total + parseFloat(item.item_total_amount || 0),
                            0
                          ) - calculateTotalTax(values?.data);

                        updatedPercentage =
                          (enteredAmount / (totalWithoutTax || 1)) * 100;
                      }

                      setFieldValue(
                        `${prefixName}_tcs_or_tds_percentage`,
                        updatedPercentage.toFixed(2)
                      );
                      // setFieldValue(`${prefixName}_tcs_or_tds_percentage_id`, "");
                      setTcsTdsAmount(e.target.value); // Maintain the user-inputted value
                    }}
                  />
                </span>
              </div>
            )}

          <div className="py-1 px-4 flex justify-between items-center">
            <span>Round off (+/-)</span>
            <span>
              <input
                value={(
                  Math.round(
                    calculateFinalTotal(values, tcsTdsAmount, prefixName)
                  ) - calculateFinalTotal(values, tcsTdsAmount, prefixName)
                )?.toFixed(2)}
                disabled={true}
                className="text-end w-[8rem] px-2 py-1 rounded-md border focus:placeholder-transparent focus:outline-none border-D0D5DD input-field-no-spin-buttons text-000000"
                type="text"
              />
            </span>
          </div>
          <div className="py-1 px-4 flex justify-between fw_700 fs_15 items-center">
            <span>Total (₹)</span>
            <span>
              <input
                disabled={true}
                className="text-end w-[8rem] px-2 py-1 border rounded-md border-D0D5DD text-000000"
                type="text"
                value={Math.round(
                  calculateFinalTotal(values, tcsTdsAmount, prefixName)
                )?.toFixed(2)}
              />
            </span>
          </div>
        </div>
      </div>

      <div className="flex justify-between invoice-bottom-container w-full py-4 px-4 items-center">
        <div className="flex gap-3 fw_600"></div>
        {page !== "view" && (
          <div className="flex gap-3">
            {/* <DraftButton
              // isDisabled={isbarcodeScanning}
              // loading={isbarcodeScanning}
              type="submit"
              onclkFn={() => setFieldValue("submittedButton", "draft")}
            /> */}
            <AddButton
              isDisabled={buttonLoading}
              loading={buttonLoading && button === "save"}
              prefixIcon={false}
              text="Save"
              type="submit"
              onclkFn={() => {
                checkErrors(errors);
                setFieldValue("submittedButton", "save");
                setButton("save");
              }}
            />
            <AddButton
              isDisabled={buttonLoading}
              loading={buttonLoading && button === "print"}
              onclkFn={() => {
                checkErrors(errors);
                setFieldValue("submittedButton", "print");
                setButton("print");
              }}
              prefixIcon={false}
              text="Save and Print"
              type="submit"
            />
          </div>
        )}
        {page === "view" && (
          <div className="flex gap-3">
            {(name === "sott_total_discount" ||
              name === "pot_total_discount") &&
              status && (
                <AddButton
                  prefixIcon={false}
                  text="Edit"
                  type="button"
                  onclkFn={EditFn}
                />
              )}
            <AddButton
              prefixIcon={false}
              text="Share"
              type="button"
              onclkFn={() => {
                setIsModalOpen(id);
              }}
            />

            <AddButton
              prefixIcon={false}
              text="Print"
              type="button"
              onclkFn={() => {
                handlea4Print(id, transaction_type, dispatch);
              }}
            />
          </div>
        )}
      </div>
      <ShareTransactionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        transaction={transaction}
        transaction_type={transaction_type}
      />
    </>
  );
};

export default SalesPurchaseFinalBillTable;

export const calculateTotal = (data) => {
  let overallTotal = 0;

  data.forEach((item) => {
    const totalAmount = parseFloat(item.item_total_amount) || 0; // Get item_total_amount
    overallTotal += totalAmount; // Add to overall total
  });

  return overallTotal;
};
export const calculateTotalTax = (data) => {
  let totalTax = 0;

  data?.forEach((rowData) => {
    totalTax += parseFloat(rowData.item_tax_amount) || 0;
  });

  return totalTax;
};
export const calculateTotalDis = (data) => {
  let totalTax = 0;

  data?.forEach((rowData) => {
    totalTax += parseFloat(rowData.item_discount_amount) || 0;
  });

  return totalTax;
};

// const calculateSubtotal = (data) => {
//   return data.reduce((subtotal, item) => {
//     const unitPrice = parseFloat(item.item_unit_price || 0);
//     const quantity = parseInt(item.quantity, 10) || 0;
//     return subtotal + unitPrice * quantity;
//   }, 0);
// };

export const calculateSubtotal = (data) => {
  return data.reduce((subtotal, item) => {
    return (
      subtotal +
      (parseFloat(item.item_unit_price) * parseInt(item?.quantity) -
        parseFloat(item?.item_tax_amount))
    );
  }, 0);
};

export const calculateTotalCGST = (data) => {
  let totalCGST = 0;
  data?.forEach((rowData) => {
    totalCGST += parseFloat(rowData.item_cgst) || 0;
  });
  return totalCGST;
};

export const calculateTotalSGST = (data) => {
  let totalSGST = 0;
  data?.forEach((rowData) => {
    totalSGST += parseFloat(rowData.item_sgst) || 0;
  });
  return totalSGST;
};
export const calculateTotalIGST = (data) => {
  let totalIGST = 0;
  data?.forEach((rowData) => {
    totalIGST += parseFloat(rowData.item_igst) || 0;
  });
  return totalIGST;
};

export const tcsTdsOptns = [
  { label: "TCS", value: "tcs" },
  { label: "TDS", value: "tds" },
];

export const calculatedTcsTdsAmount = (values, tcsTdsAmount, prefixName) => {
  if (
    tcsTdsAmount &&
    (values?.[`${prefixName}_tds_tcs_type`] === "tcs" ||
      values?.[`${prefixName}_tds_tcs_type`] === "tds")
  ) {
    return tcsTdsAmount;
  }
  // Prioritize user input

  if (values?.[`${prefixName}_tds_tcs_type`] === "tcs") {
    const tcsvalue = (
      parseFloat(calculateTotal(values?.data)) *
      (parseFloat(values?.[`${prefixName}_tcs_or_tds_percentage`] ?? 0) / 100)
    ).toFixed(2);
    return tcsvalue;
  } else if (values?.[`${prefixName}_tds_tcs_type`] === "tds") {
    const tdsvalue = (
      (values.data?.reduce(
        (total, item) => total + parseFloat(item.item_total_amount || 0),
        0
      ) -
        calculateTotalTax(values?.data)) *
      (parseFloat(values?.[`${prefixName}_tcs_or_tds_percentage`] ?? 0) / 100)
    ).toFixed(2);
    return tdsvalue;
  }
  return 0;
};

export const calculateFinalTotal = (values, tcsTdsAmount, prefixName) => {
  if (values?.[`${prefixName}_tds_tcs_type`] === "tcs") {
    return (
      parseFloat(calculateTotal(values?.data)) +
      parseFloat(calculatedTcsTdsAmount(values, tcsTdsAmount, prefixName))
    );
  }
  if (values?.[`${prefixName}_tds_tcs_type`] === "tds") {
    return (
      parseFloat(calculateTotal(values?.data)) -
      parseFloat(calculatedTcsTdsAmount(values, tcsTdsAmount, prefixName))
    );
  } else return calculateTotal(values?.data);
};
