import React, { useEffect, useState } from "react";
import InnerLayout from "../../../components/layouts/inner-layout";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import AddButton from "../../../components/buttons/button";
import { useLocation, useNavigate } from "react-router-dom";
import SearchAddFeild from "../../../components/forms/search-add-field";
import { fetchData } from "../../../components/helper-components/helper-function";
import CloseIcon from "../../../assets/icons/close-icon";
import errorToast from "../../../components/helper-components/error-toast";
import { useDispatch } from "react-redux";
import { goodsTempProImpAction } from "../../../redux/actions/purchase-action";

const GoodsOrderCsv = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.data || [];

  const initialValue = {
    ...data,
  };
  const validationSchema = Yup.object().shape({
    // data: Yup.array().of(
    //   Yup.object().shape({
    //     item_name_suggested: Yup.string().required(
    //       "Suggested Item is required"
    //     ),
    //   })
    // ),
  });
  const handleSubmit = (values, actions) => {
    const missingSuggestedItem = values?.itemData?.some(
      (item) => !item.item_id
    );
    const missingBaseUOM = values?.itemData?.some((item) => !item.item_uom_id);
    if (missingSuggestedItem) {
      errorToast("Linked Product Name is required!");
      actions.setSubmitting(false); // Stop the form submission if the validation fails
      return;
    }
    if (missingBaseUOM) {
      errorToast("Base UOM is required!");
      actions.setSubmitting(false); // Stop the form submission if the validation fails
      return;
    }

    dispatch(
      goodsTempProImpAction(
        {
          itemData: values?.itemData?.map((item) => ({
            item_uom: item?.item_uom,
            item_code: item?.item_code,
            item_name: item?.item_name,
            item_exp_date: item?.item_exp_date,
            item_sellable_quantity: item?.item_sellable_quantity,
            item_batch_number: item?.item_batch_number,
            item_generic_name: item?.item_generic_name,
            item_free_quantity: item?.item_free_quantity,
            item_tax_percentage: item?.item_tax_percentage,
            item_batch_unit_price: item?.item_batch_unit_price,
            item_batch_purchase_rate: item?.item_batch_purchase_rate,
            item_batch_discount_percentage:
              item?.item_batch_discount_percentage,
            item_id: item?.item_id,
            is_item_in_db: item?.is_item_in_db,
            item_uom_id: item?.item_uom_id,
            is_item_uom_in_db: item?.is_item_uom_in_db,
            item_gst: item?.item_gst ?? 0,
            item_cgst_percentage: item?.item_cgst_percentage ?? 0,
            item_sgst_percentage: item?.item_sgst_percentage ?? 0,
          })),
        },
        (data) => {
          console.log(data, "dfdsfsfdsdsfdsfdsfdsf");
          navigate(
            `/goods-receipt/${encodeURIComponent(JSON.stringify([]))}/goods`,
            {
              state: {
                values: {
                  itemData: data?.data?.itemData ?? [],
                  ...values?.customerDetails,
                  
                },
              },
            }
          );
        },
        onErrorImport
      )
    );
  };

  const onErrorImport = (data) => {
    errorToast(data?.data?.message);
  };

  // const handleQuantityChange = async (
  //   event,
  //   rowIndex,
  //   values,
  //   setFieldValue,
  //   selectedOption
  // ) => {
  //   const updatedData = [...values.data];

  //   const totalAmount =
  //     parseFloat(values?.data?.[rowIndex]?.item_unit_price) * parseFloat(event);

  //   if (parseFloat(event) > 0) {
  //     try {
  //       const response = await api.get(
  //         `${url}/discount/get?totalAmountOfItem=${totalAmount}&item_quantity=${event}&item_id=${selectedOption?.item_id}&cmr_phone_number=${values?.cmr_phone_number}`,
  //         JSON.stringify(totalAmount)
  //       );

  //       const tax =
  //         response.data?.code === 200 ? response.data.data.item_tax_amount : 0;
  //       const updatedDataRow = {
  //         ...updatedData[rowIndex],
  //         item_gst:
  //           response.data?.code === 200
  //             ? response.data.data.item_total_tax_percentage
  //             : 0,
  //         item_sgst:
  //           response.data?.code === 200
  //             ? (
  //                 parseInt(event) *
  //                 parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
  //                 (response.data.data.sgstRate / 100)
  //               )?.toFixed(2)
  //             : 0,
  //         item_cgst:
  //           response.data?.code === 200
  //             ? (
  //                 parseInt(event) *
  //                 parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
  //                 (response.data.data.cgstRate / 100)
  //               )?.toFixed(2)
  //             : 0,
  //         item_igst:
  //           response.data?.code === 200
  //             ? (
  //                 parseInt(event) *
  //                 parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
  //                 (response.data.data.igstRate / 100)
  //               )?.toFixed(2)
  //             : 0,
  //         item_total_amount:
  //           parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
  //           parseFloat(parseInt(event)),
  //         item_tax_amount: parseFloat(tax),
  //         item_price_wiithout_tax: (
  //           parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
  //             parseFloat(parseInt(event)) -
  //             // parseFloat(discount) -
  //             parseFloat(tax) || 0
  //         ).toFixed(2),
  //         item_name_suggested: selectedOption.item_name,
  //         item_id: selectedOption.item_id,
  //         item_code: selectedOption.item_code,
  //         item_sheet_uom_id: selectedOption.item_sheet_uom_id,
  //         item_sheet_uom: selectedOption.item_sheet_uom,
  //         uom_dropdown: selectedOption.uom_dropdown,
  //       };
  //       updatedData[rowIndex] = updatedDataRow;

  //       setFieldValue("data", [...updatedData]);
  //     } catch (error) {}
  //   }
  // };

  return (
    <InnerLayout backBtn={true} title="Goods Receipt CSV Import">
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors }) => {
          console.log(values, "dsfsfdsfdsfdsfdsf");
          return (
            <Form className="h-full">
              <div
                className="flex gap-3 justify-between  overflow-auto"
                style={{
                  maxHeight: `calc(100% - 4.5rem)`,
                  height: `calc(100% - 4.5rem)`,
                }}
              >
                <div className="min-w-[100%] border-EAECF0 border mb-2 h-fit     scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                  <table className="my-tab">
                    <thead className="sticky top-0 z-10">
                      <tr className="bg-F2F4F5">
                        {tableHeading.map((heading, index) => (
                          <th
                            key={index}
                            className={`${"border-b-EAECF0 border-b h-[3rem] text-left px-2"} whitespace-nowrap text-sm fw_700 text-475467 bg-F2F4F5
                           `}
                          >
                            {heading}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {values?.itemData?.map((item, index) => (
                        <tr
                        // className={`${
                        //   item?.is_item_in_db ? "" : "bg-F51D00"
                        // }`}
                        >
                          <td className="text-sm h-[3.5rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary ">
                            {index + 1}
                          </td>
                          <td className="text-sm h-[3.5rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary text-nowrap">
                            {item?.[values?.columnMapping?.item_code] || "N/A"}
                          </td>
                          <td className="text-sm h-[3.5rem] px-3 text-center border-b-EAECF0 border-b  fw_400 text-secondary ">
                            {item?.[values?.columnMapping?.item_name] || "N/A"}
                          </td>
                          <td className="text-sm h-[3.5rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary ">
                            {item?.[values?.columnMapping?.item_uom] || "N/A"}
                          </td>
                          <td className="text-sm h-[3.5rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary ">
                            {item?.item_code || "N/A"}
                          </td>
                          <td
                            className={`text-sm h-[3.5rem] min-w-[20rem] border-b-EAECF0 border-b    fw_400 text-secondary `}
                          >
                            <div
                              className={`relative w-full ${
                                item?.is_item_in_db || item?.item_id
                                  ? ""
                                  : "border-F51D00 border-2  rounded-md"
                              } `}
                            >
                              <SearchAddFeild
                                errorClass="hidden"
                                isRequired={false}
                                value={{
                                  label: item?.item_name,
                                  value: "",
                                }}
                                name={`itemData[${index}].item_id`}
                                field={`item_name`}
                                isLabelReq={false}
                                defaultSelectClass=""
                                placeholder=""
                                fetchData={fetchData}
                                onChange={async (selectedOption) => {
                                  // handleQuantityChange(
                                  //   item.item_quantity,
                                  //   index,
                                  //   values,
                                  //   setFieldValue,
                                  //   selectedOption
                                  // );
                                  const updatedData = [...values.itemData];
                                  const updatedItem = updatedData[index];
                                  updatedData[index] = {
                                    ...updatedItem,
                                    item_name: selectedOption.item_name,
                                    item_id: selectedOption.item_id,
                                    item_code: selectedOption.item_code,
                                    is_item_in_db: true,
                                    // item_uom_id: selectedOption.item_uom_id,
                                    // item_uom: selectedOption.item_uom,
                                    // uom_dropdown: selectedOption.uom_dropdown,
                                  };
                                  setFieldValue("itemData", updatedData);
                                }}
                              />

                              <div
                                onClick={() => {
                                  setFieldValue(
                                    `itemData[${index}].item_id`,
                                    ""
                                  );
                                  setFieldValue(
                                    `itemData[${index}].item_name`,
                                    ""
                                  );
                                  // setFieldValue(
                                  //   `itemData[${index}].item_uom_id`,
                                  //   ""
                                  // );
                                  // setFieldValue(
                                  //   `itemData[${index}].item_uom`,
                                  //   ""
                                  // );
                                  // setFieldValue(
                                  //   `itemData[${index}].uom_dropdown`,
                                  //   []
                                  // );
                                }}
                                className="absolute cursor-pointer z-15 right-1 top-1/2 transform -translate-y-1/2"
                              >
                                {item?.item_name && (
                                  <CloseIcon width={15} height={15} />
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="text-sm h-[3.5rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary ">
                            {item?.item_batch_number || "N/A"}
                          </td>

                          <td
                            className={`text-sm h-[3.5rem] min-w-[9rem] px-3  fw_400 text-secondary rounded-md border-b-EAECF0 border-b   `}
                          >
                            <span
                              className={`${
                                item?.is_item_uom_in_db
                                  ? ""
                                  : "border-F51D00 border-2  rounded-md py-[11px] px-4"
                              }`}
                            >
                              {" "}
                              {item?.item_uom || "N/A"}
                            </span>
                          </td>
                          <td className="text-sm h-[3.5rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary ">
                            {item?.item_batch_purchase_rate || "N/A"}
                          </td>
                          <td className="text-sm h-[3.5rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary ">
                            {item?.item_gst ||
                              parseFloat(item?.item_cgst_percentage) +
                                parseFloat(item?.item_sgst_percentage)}
                          </td>

                          <td className="text-sm h-[3.5rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary ">
                            {item?.item_sellable_quantity || "N/A"}
                          </td>
                          <td className="text-sm h-[3.5rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary ">
                            {item?.item_batch_free_quantity || "N/A"}
                          </td>
                          <td className="text-sm h-[3.5rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary ">
                            {item?.item_batch_unit_price || "N/A"}
                          </td>
                          <td className="text-sm h-[3.5rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary ">
                            {item?.item_exp_date || "N/A"}
                          </td>
                          <td className="text-sm h-[3.5rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary ">
                            {item?.item_batch_discount_percentage || "N/A"}
                          </td>
                          {/* <td className="text-sm h-[3rem] px-3 border-b-EAECF0 border-b  fw_400 text-secondary ">
                            {item?.item_batch_discount_amount || "N/A"}
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex justify-between invoice-bottom-container w-full py-4 px-4 items-center h-[4rem] ">
                <div className="flex gap-3 fw_600"></div>
                <AddButton prefixIcon={false} text="Import" type="submit" />
              </div>
            </Form>
          );
        }}
      </Formik>
    </InnerLayout>
  );
};

export default GoodsOrderCsv;
const tableHeading = [
  "SL No",
  "Prod. Code",
  "Prod. Name",
  "Supp. Prod. Pack",
  "Linked Prod. Code",
  "Linked Product Name",
  "Batch Number",
  "Packing",
  "Pur. Price",
  "GST %",
  "Qty",
  "Free",
  "MRP",
  "Expiry",
  "Pur Sch Disc %",
  // "Pur Sch Disc Amt.",
  // "Bulk Pur Sch Disc Amt.",
];
