//INVOICE
export const INVOICE_CREATE = "INVOICE_CREATE";
export const INVOICE_DRAFT_CREATE = "INVOICE_DRAFT_CREATE";
export const INVOICE_EDIT = "INVOICE_EDIT";
export const REPEAT_ORDER = "REPEAT_ORDER";
export const INVOICE_NUMBER = "INVOICE_NUMBER";
export const INVOICE_TABLE = "INVOICE_TABLE";
export const RETURN_BILL_TABLE = "RETURN_BILL_TABLE";
export const RETURN_BILL_VIEW = "RETURN_BILL_VIEW";
export const RETURN_BILL_ADD = "RETURN_BILL_ADD";
export const RETURN_BILL_ITEMS_GET = "RETURN_BILL_ITEMS_GET";
export const INVOICE_VIEW = "INVOICE_VIEW";
export const INVOICE_DISCOUNT = "INVOICE_DISCOUNT";
export const INVOICE_PAST = "INVOICE_PAST";
export const INVOICE_DELETE = "INVOICE_DELETE";
export const INVOICE_ALTERNATIVE = "INVOICE_ALTERNATIVE";
export const INVOICE_ROW = "INVOICE_ROW";
export const INVOICE_CLEAR = "INVOICE_CLEAR";
export const STORE_DETAILS = "STORE_DETAILS";
export const INVOICE_SCANNED_ITEM = "INVOICE_SCANNED_ITEM";
export const PROFIT_REPORT="PROFIT_REPORT";
export const VENDOR_MASTER="VENDOR_MASTER";
export const STOCK_LEDGER_BATCH="STOCK_LEDGER_BATCH";
export const VENDOR_PAYABLE="VENDOR_PAYABLE";
export const DAY_WISE="DAY_WISE";
//CUSTOMER
export const CUSTOMER_CREATE = "CUSTOMER_CREATE";
export const CUSTOMER_VIEW = "CUSTOMER_VIEW";
export const CUSTOMER_DELETE = "CUSTOMER_DELETE";
export const CUSTOMER_EDIT = "CUSTOMER_EDIT";
export const CUSTOMER_TABLE = "CUSTOMER_TABLE";
//DOCTOR
export const DOCTOR_CREATE = "DOCTOR_CREATE";
export const DOCTOR_VIEW = "DOCTOR_VIEW";
export const DOCTOR_DELETE = "DOCTOR_DELETE";
export const DOCTOR_EDIT = "DOCTOR_EDIT";
export const DOCTOR_TABLE = "DOCTOR_TABLE";

//USERS
export const USER_CREATE = "USER_CREATE";
export const USER_VIEW = "USER_VIEW";
export const USER_DELETE = "USER_DELETE";
export const USER_EDIT = "USER_EDIT";
export const USER_TABLE = "USER_TABLE";
export const USER_PERMISSIONS = "USER_PERMISSIONS";
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS";

//GENERAL
export const PRODUCT_LIST = "PRODUCT_LIST";
export const PRODUCT_DETAILS = "PRODUCT_DETAILS";
export const PHONE_LIST = "PHONE_LIST";
export const STATE_LIST = "STATE_LIST";
export const DOCTOR_LIST = "DOCTOR_LIST";
export const STORE_ID = "STORE_ID";
export const PAYMENT_METHODS_LIST = "PAYMENT_METHODS_LIST";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const UOM_LIST = "UOM_LIST";
export const PRICE_LIST = "PRICE_LIST";
export const ITEM_GROUP_LIST = "ITEM_GROUP_LIST";
export const ITEM_TYPE_DROP_DOWN = "ITEM_TYPE_DROP_DOWN";
export const TAX_CAT_LIST = "TAX_CAT_LIST";
export const MANUFACTURERS_LIST = "MANUFACTURERS_LIST";
export const SHIPPING_TYPE_LIST = "SHIPPING_TYPE_LIST";
export const SECTOR_LIST = "SECTOR_LIST";
export const SCH_LIST = "SCH_LIST";
export const VENDOR_LIST = "VENDOR_LIST";
export const VALUATION_LIST = "VALUATION_LIST";
export const UOM_GROUP_LIST = "UOM_GROUP_LIST";
export const UOM_GROUP_FIND_ONE = "UOM_GROUP_FIND_ONE";
export const IMAGE_UPLOAD = "IMAGE_UPLOAD";
export const ADMIN_ROLES = "ADMIN_ROLES";
export const DEPARTMENR_LIST = "DEPARTMENR_LIST";
export const ROLES_LIST = "ROLES_LIST";
export const USERS_LIST = "USERS_LIST";
//ADMIN
export const CUSTOMER_TYPE_LIST = "CUSTOMER_TYPE_LIST";
export const ACQ_SRC_LIST = "ACQ_SRC_LIST";
export const BUISNESS_TYPE_LIST = "BUISNESS_TYPE_LIST";
export const BANK_NAME_LIST = "BANK_NAME_LIST";
export const DIS_CUS_LIST = "DIS_CUS_LIST";
export const DIS_CUS_GROUP_LIST = "DIS_CUS_GROUP_LIST";
export const CUSTOMER_LIST = "CUSTOMER_LIST";
export const PINCODE_LIST = "PINCODE_LIST";
export const BIN_LOCATION_LIST = "BIN_LOCATION_LIST";
export const WARE_HOUSE_LIST = "WARE_HOUSE_LIST";
export const TRANSACTION_SHARE = "TRANSACTION_SHARE";
export const TRANSACTION_PRINT = "TRANSACTION_PRINT";
export const STORE_LIST = "STORE_LIST";
export const SAVED_REPORTS = "SAVED_REPORTS";
export const SAVED_DATA_REPORTS = "SAVED_DATA_REPORTS";
export const FAV_ITEMS = "FAV_ITEMS";
export const SET_FAV_ITEMS = "SET_FAV_ITEMS";
//PRODUCT
export const PRODUCT_CREATE = "PRODUCT_CREATE";
export const PRODUCT_PURCHASING_CREATE = "PRODUCT_PURCHASING_CREATE";
export const PRODUCT_SALES_CREATE = "PRODUCT_SALES_CREATE";
export const PRODUCT_INVENTORY_CREATE = "PRODUCT_INVENTORY_CREATE";
export const PRODUCT_PLANNING_CREATE = "PRODUCT_PLANNING_CREATE";
export const PRODUCT_DIS_CREATE = "PRODUCT_DIS_CREATE";
export const PRODUCT_RESTRICTIONS_CREATE = "PRODUCT_RESTRICTIONS_CREATE";
export const PRODUCT_REMARKS_CREATE = "PRODUCT_REMARKS_CREATE";
export const PRODUCT_TABLE = "PRODUCT_TABLE";
export const PRODUCT_VIEW = "PRODUCT_VIEW";
export const PRODUCT_DELETE = "PRODUCT_DELETE";
export const PRODUCT_EDIT = "PRODUCT_EDIT";
export const PRODUCT_BARCODE = "PRODUCT_BARCODE";
export const PRODUCT_BARCODES = "PRODUCT_BARCODES";
export const PRODUCT_GENERATE_ITEMCODE = "PRODUCT_GENERATE_ITEMCODE";
export const PRODUCT_VERIFY_ITEMCODE = "PRODUCT_VERIFY_ITEMCODE";

//PRODUCT
export const INVENTORY_CREATE = "INVENTORY_CREATE";
export const BINLOCATION_ADD = "BINLOCATION_ADD";
export const WAREHOUSE_LIST = "WAREHOUSE_LIST";

//STORE INFORMATION
export const STORE_ADDRESS_CREATE = "STORE_ADDRESS_CREATE";
export const STORE_PERSONAL_CREATE = "STORE_PERSONAL_CREATE";
export const STORE_FIRM_CREATE = "STORE_FIRM_CREATE";
export const STORE_AGREEMENT_CREATE = "STORE_AGREEMENT_CREATE";
export const STORE_PAYMENT_CREATE = "STORE_PAYMENT_CREATE";
export const STORE_DRUG_CREATE = "STORE_DRUG_CREATE";
export const STORE_ADDRESS_UPDATE = "STORE_ADDRESS_UPDATE";
export const STORE_TABLE = "STORE_TABLE";
export const STORE_VIEW = "STORE_VIEW";

//AUTH
export const STORE_LOGIN = "STORE_LOGIN";
export const STORE_FORGOT_PASSWORD = "STORE_FORGOT_PASSWORD";
export const STORE_RESET_PASSWORD = "STORE_RESET_PASSWORD";

//ADMIN_CUSTOMER
export const ADMIN_CUSTOMER_CREATE = "ADMIN_CUSTOMER_CREATE";
export const ADMIN_CUSTOMER_EDIT = "ADMIN_CUSTOMER_EDIT";
export const ADMIN_CUSTOMER_CONTACT_PERSON = "ADMIN_CUSTOMER_CONTACT_PERSON";
export const ADMIN_CUSTOMER_BNK_DTLS = "ADMIN_CUSTOMER_BNK_DTLS";
export const ADMIN_CUSTOMER_PAYMENT_TERMS = "ADMIN_CUSTOMER_PAYMENT_TERMS";
export const ADMIN_CUSTOMER_ACCOUNTING = "ADMIN_CUSTOMER_ACCOUNTING";
export const ADMIN_CUSTOMER_ADDRESS = "ADMIN_CUSTOMER_ADDRESS";

export const ADMIN_CUSTOMER_TABLE = "ADMIN_CUSTOMER_TABLE";
export const ADMIN_CUSTOMER_DELETE = "ADMIN_CUSTOMER_DELETE";
export const ADMIN_CUSTOMER_VIEW = "ADMIN_CUSTOMER_VIEW";
export const ADMIN_CUSTOMER_UPDATE_CONTACT_PERSON =
  "ADMIN_CUSTOMER_UPDATE_CONTACT_PERSON";
export const ADMIN_CUSTOMER_UPDATE_BNK_DTLS = "ADMIN_CUSTOMER_UPDATE_BNK_DTLS";
export const ADMIN_CUSTOMER_UPDATE_PAYMENT_TERMS =
  "ADMIN_CUSTOMER_UPDATE_PAYMENT_TERMS";
export const ADMIN_CUSTOMER_UPDATE_ACCOUNTING =
  "ADMIN_CUSTOMER_UPDATE_ACCOUNTING";
export const ADMIN_CUSTOMER_UPDATE_ADDRESS = "ADMIN_CUSTOMER_UPDATE_ADDRESS";
//DISCOUNTS
export const PRICE_LIST_TABLE = "PRICE_LIST_TABLE";
export const PRICE_LIST_CREATE = "PRICE_LIST_CREATE";
export const PRICE_LIST_DELETE = "PRICE_LIST_DELETE";
export const PRICE_LIST_ITEMS = "PRICE_LIST_ITEMS";
export const PRICE_LIST_VIEW = "PRICE_LIST_VIEW";
export const PRICE_LIST_EDIT = "PRICE_LIST_EDIT";
export const PRICE_LIST_ITEM_EDIT = "PRICE_LIST_ITEM_EDIT";
export const ALL_DISCOUNT_GROUP = "ALL_DISCOUNT_GROUP";
export const DIS_ITEM_GROUP_CREATE = "DIS_ITEM_GROUP_CREATE";
export const DIS_MANUFAC_GROUP_CREATE = "DIS_MANUFAC_GROUP_CREATE";
export const DIS_ITEMS_CREATE = "DIS_ITEMS_CREATE";
export const DIS_ITEMS_FETCH = "DIS_ITEMS_FETCH";
export const PER_DIS_ITEMS_LIST = "PER_DIS_ITEMS_LIST";
export const PER_VOL_PRICE_LIST_ITEMS = "PER_VOL_PRICE_LIST_ITEMS";
export const PERIOD_VOLUME_CREATE = "PERIOD_VOLUME_CREATE";
export const PERIOD_VOLUME_ADD = "PERIOD_VOLUME_ADD";
export const PERIOD_VOLUME_GET = "PERIOD_VOLUME_GET";

// STORE EXECUTION
export const ADD_STR_INFRA_DETAILS = "ADD_STR_INFRA_DETAILS";
export const ADD_STR_BRANDING_DETAILS = "ADD_STR_BRANDING_DETAILS";
export const ADD_STR_BRAND_SFT_DETAILS = "ADD_STR_BRAND_SFT_DETAILS";
export const ADD_STR_ACCOUNT_DETAILS = "ADD_STR_ACCOUNT_DETAILS";
export const ADD_STR_DISPATCH_DETAILS = "ADD_STR_DISPATCH_DETAILS";
export const ADD_STR_OPENING_DETAILS = "ADD_STR_OPENING_DETAILS";
export const ADD_STR_FOLLOW_UP_REMARKS = "ADD_STR_FOLLOW_UP_REMARKS";
export const FOLLOW_UP_REMARKS_GET = "FOLLOW_UP_REMARKS_GET";
export const STORE_EXE_GET = "STORE_EXE_GET";
//---------------------SALES-----------------------------
//SALES ORDER
export const SALES_CREATE = "SALES_CREATE";
export const SALES_EDIT = "SALES_EDIT";
export const SALES_TABLE = "SALES_TABLE";
export const SALES_PRODUCT_LIST = "SALES_PRODUCT_LIST";
export const SALES_ORDER_ITEMS_GET = "SALES_ORDER_ITEMS_GET";
export const SALES_VIEW = "SALES_VIEW";
export const SALES_VOID = "SALES_VOID";
export const SALES_PRODUCT_DETAILS = "SALES_PRODUCT_DETAILS";
//SALES DELIVERY RECEIPT
export const SALES_DELIVERY_TABLE = "SALES_DELIVERY_TABLE";
export const SALES_DELIVERY_RECEIPT_CREATE = "SALES_DELIVERY_RECEIPT_CREATE";
export const SALES_DELIVERY_VIEW = "SALES_DELIVERY_VIEW";
export const SALES_DELIVERY_ITEMS_GET = "SALES_DELIVERY_ITEMS_GET";
export const SALES_DELIVERY_VOID = "SALES_DELIVERY_VOID";
export const SALES_DELIVERY_EDIT = "SALES_DELIVERY_EDIT";
//SALES INVOICE RECEIPT
export const SALES_INVOICE_TABLE = "SALES_INVOICE_TABLE";
export const SALES_INVOICE_RECEIPT_CREATE = "SALES_INVOICE_RECEIPT_CREATE";
export const SALES_INVOICE_VIEW = "SALES_INVOICE_VIEW";
export const SALES_INVOICE_ITEMS_GET = "SALES_INVOICE_ITEMS_GET";
export const SALES_INVOICE_VOID = "SALES_INVOICE_VOID";
export const SALES_INVOICE_EDIT = "SALES_INVOICE_EDIT";
//SALES RETURN
export const SALES_RETURN_TABLE = "SALES_RETURN_TABLE";
export const SALES_RETURN_RECEIPT_CREATE = "SALES_RETURN_RECEIPT_CREATE";
export const SALES_RETURN_VIEW = "SALES_RETURN_VIEW";
export const SALES_RETURN_ITEMS_GET = "SALES_RETURN_ITEMS_GET";
export const SALES_RETURN_VOID = "SALES_RETURN_VOID";
export const SALES_RETURN_EDIT = "SALES_RETURN_EDIT";
//SALES CREDIT NOTE
export const SALES_CREDIT_TABLE = "SALES_CREDIT_TABLE";
export const SALES_CREDIT_RECEIPT_CREATE = "SALES_CREDIT_RECEIPT_CREATE";
export const SALES_CREDIT_VIEW = "SALES_CREDIT_VIEW";
export const SALES_CREDIT_ITEMS_GET = "SALES_CREDIT_ITEMS_GET";
export const SALES_CREDIT_VOID = "SALES_CREDIT_VOID";
export const SALES_CREDIT_EDIT = "SALES_CREDIT_EDIT";
//PAYMENT IN
export const SALES_PAYMENT_IN_CUS = "SALES_PAYMENT_IN_CUS";
export const PAYMENT_IN_TABLE = "PAYMENT_IN_TABLE";
export const GL_ACC_LIST = "GL_ACC_LIST";
export const PAYMENT_IN_CREATE = "PAYMENT_IN_CREATE";
export const PAYMENT_IN_VIEW = "PAYMENT_IN_VIEW";

//---------------------------PURCHASE----------------------------------------
//PURCHASE ORDER
export const PURCHASE_CREATE = "PURCHASE_CREATE";
export const PURCHASE_TABLE = "PURCHASE_TABLE";
export const PURCHASE_PRODUCT_LIST = "PURCHASE_PRODUCT_LIST";
export const PURCHASE_VIEW = "PURCHASE_VIEW";
export const PURCHASE_EDIT = "PURCHASE_EDIT";
export const PURCHASE_ITEMS_GET = "PURCHASE_ITEMS_GET";
export const PURCHASE_VOID = "PURCHASE_VOID";
export const PURCHASE_PRODUCT_DETAILS = "PURCHASE_PRODUCT_DETAILS";

//GOODS RECEIPT
export const GOODS_ITEMS_GET = "GOODS_ITEMS_GET";
export const GOODS_TABLE = "GOODS_TABLE";
export const GOODS_RECEIPT_CREATE = "GOODS_RECEIPT_CREATE";
export const GOODS_RECEIPT_CREATE_BULK = "GOODS_RECEIPT_CREATE_BULK";
export const GOODS_RECEIPT_VIEW = "GOODS_RECEIPT_VIEW";
export const GOODS_RECEIPT_VOID = "GOODS_RECEIPT_VOID";
export const GOODS_RECEIPT_EDIT = "GOODS_RECEIPT_EDIT";
export const GOODS_TEMP_PRODUCTS_VERIFY = "GOODS_TEMP_PRODUCTS_VERIFY";
export const GOODS_TEM_PRODUCTS_IMPORT = "GOODS_TEM_PRODUCTS_IMPORT";
//PURCHASE INVOICE RECEIPT
export const PURCHASE_INVOICE_TABLE = "PURCHASE_INVOICE_TABLE";
export const PURCHASE_INVOICE_RECEIPT_CREATE =
  "PURCHASE_INVOICE_RECEIPT_CREATE";
export const PURCHASE_INVOICE_VIEW = "PURCHASE_INVOICE_VIEW";
export const PURCHASE_INVOICE_ITEMS_GET = "PURCHASE_INVOICE_ITEMS_GET";
export const PURCHASE_INVOICE_VOID = "PURCHASE_INVOICE_VOID";
export const PURCHASE_INVOICE_EDIT = "PURCHASE_INVOICE_EDIT";
//PURCHASE RETURN
export const PURCHASE_RETURN_TABLE = "PURCHASE_RETURN_TABLE";
export const PURCHASE_RETURN_RECEIPT_CREATE = "PURCHASE_RETURN_RECEIPT_CREATE";
export const PURCHASE_RETURN_VIEW = "PURCHASE_RETURN_VIEW";
export const PURCHASE_RETURN_ITEMS_GET = "PURCHASE_RETURN_ITEMS_GET";
export const PURCHASE_RETURN_VOID = "PURCHASE_RETURN_VOID";
export const PURCHASE_RETURN_EDIT = "PURCHASE_RETURN_EDIT";
//PURCHASE CREDIT NOTE
export const PURCHASE_CREDIT_TABLE = "PURCHASE_CREDIT_TABLE";
export const PURCHASE_CREDIT_RECEIPT_CREATE = "PURCHASE_CREDIT_RECEIPT_CREATE";
export const PURCHASE_CREDIT_VIEW = "PURCHASE_CREDIT_VIEW";
export const PURCHASE_CREDIT_ITEMS_GET = "PURCHASE_CREDIT_ITEMS_GET";
export const PURCHASE_CREDIT_VOID = "PURCHASE_CREDIT_VOID";
export const PURCHASE_CREDIT_EDIT = "PURCHASE_CREDIT_EDIT";

//PAYMENT IN
export const PURCHASE_PAYMENT_OUT_CUS = "PURCHASE_PAYMENT_OUT_CUS";
export const PAYMENT_OUT_TABLE = "PAYMENT_OUT_TABLE";
export const PAYMENT_OUT_CREATE = "PAYMENT_OUT_CREATE";
export const PAYMENT_OUT_VIEW = "PAYMENT_OUT_VIEW";

//BULK PURCHASE
export const LOADING_CSV = "LOADING_CSV";
export const LOADED_MIN_QTY_ITEMS = "LOADED_MIN_QTY_ITEMS";
export const LOADED_FAILED_MIN_QTY_ITEMS = "LOADED_FAILED_MIN_QTY_ITEMS";

export const BULK_PURCHASE_CREATE = "BULK_PURCHASE_CREATE";
export const LOAD_MIN_QTY_ITEMS = "LOAD_MIN_QTY_ITEMS";
export const UPLOAD_PO_CSV = "UPLOAD_PO_CSV";
export const PURCHASE_TAX_CAL = "PURCHASE_TAX_CAL";

//FINANCIAL LEDGER EFFECT
export const FINANCIAL_ACCOUNT_ADD = "FINANCIAL_ACCOUNT_ADD";
export const FINANCIAL_ACCOUNT_GET = "FINANCIAL_ACCOUNT_GET";
export const FINANCIAL_ACCOUNT_DELETE = "FINANCIAL_ACCOUNT_DELETE";
export const FINANCIAL_ACCOUNT_UPDATE = "FINANCIAL_ACCOUNT_UPDATE";
export const FINANCIAL_PERIOD_SELECTION = "FINANCIAL_PERIOD_SELECTION";
export const FINANCIAL_ALL_ACC = "FINANCIAL_ALL_ACC";
export const FINANCIAL_ACC_LIST = "FINANCIAL_ACC_LIST";
export const FINANCIAL_ACC_ALLOCATION_UPDATE =
  "FINANCIAL_ACC_ALLOCATION_UPDATE";
export const JOURNAL_ENTRY_FETCH = "JOURNAL_ENTRY_FETCH";
export const JOURNAL_ENTRY_UPDATE = "JOURNAL_ENTRY_UPDATE";
export const JOURNAL_ENTRY_VIEW = "JOURNAL_ENTRY_VIEW";

//SETTINGS
export const ADD_UOM = "ADD_UOM";
export const UPDATE_UOM = "UPDATE_UOM";
export const LIST_UOM = "LIST_UOM";

export const UOM_GROUP_TABLE_LIST = "UOM_GROUP_TABLE_LIST";
export const ADD_UOM_GROUP = "ADD_UOM_GROUP";
export const UPDATE_UOM_GROUP = "UPDATE_UOM_GROUP";
export const DELETE_UOM_GROUP = "DELETE_UOM_GROUP";
export const DELETE_UOM_GROUP_ITEM = "DELETE_UOM_GROUP_ITEM";
export const UOM_DROPDOWN = "UOM_DROPDOWN";

export const ITEM_TYPE_LIST = "ITEM_TYPE_LIST";
export const ADD_ITEM_TYPE = "ADD_ITEM_TYPE";
export const UPDATE_ITEM_TYPE = "UPDATE_ITEM_TYPE";
export const DELETE_ITEM_TYPE = "DELETE_ITEM_TYPE";

export const ITEM_CATEGORY_LIST = "ITEM_CATEGORY_LIST";
export const ADD_ITEM_CATEGORY = "ADD_ITEM_CATEGORY";
export const UPDATE_ITEM_CATEGORY = "UPDATE_ITEM_CATEGORY";
export const DELETE_ITEM_CATEGORY = "DELETE_ITEM_CATEGORY";

export const ITEM_GROUP_TABLE_LIST = "ITEM_GROUP_TABLE_LIST";
export const ADD_ITEM_GROUP = "ADD_ITEM_GROUP";
export const UPDATE_ITEM_GROUP = "UPDATE_ITEM_GROUP";
export const DELETE_ITEM_GROUP = "DELETE_ITEM_GROUP";

export const MANUFACTURERS_TABLE_LIST = "MANUFACTURERS_TABLE_LIST";
export const ADD_MANUFACTURER = "ADD_MANUFACTURER";
export const UPDATE_MANUFACTURER = "UPDATE_MANUFACTURER";
export const DELETE_MANUFACTURER = "DELETE_MANUFACTURER";

export const TAX_TYPE_LIST = "TAX_TYPE_LIST";
export const ADD_TAX_TYPE = "ADD_TAX_TYPE";
export const UPDATE_TAX_TYPE = "UPDATE_TAX_TYPE";
export const DELETE_TAX_TYPE = "DELETE_TAX_TYPE";

export const TAX_COMBINATION_LIST = "TAX_COMBINATION_LIST";
export const ADD_TAX_COMBINATION = "ADD_TAX_COMBINATION";
export const UPDATE_TAX_COMBINATION = "UPDATE_TAX_COMBINATION";
export const DELETE_TAX_COMBINATION = "DELETE_TAX_COMBINATION";
export const DELETE_TAX_COMBINATION_ITEM = "DELETE_TAX_COMBINATION_ITEM";
export const TAX_TYPE_DROPDOWN = "TAX_TYPE_DROPDOWN";

export const SHIPPING_TYPE_TABLE__LIST = "SHIPPING_TYPE_TABLE__LIST";
export const ADD_SHIPPING_TYPE = "ADD_SHIPPING_TYPE";
export const UPDATE_SHIPPING_TYPE = "UPDATE_SHIPPING_TYPE";
export const DELETE_SHIPPING_TYPE = "DELETE_SHIPPING_TYPE";

export const DOCUMENT_NUMBERING_LIST = "DOCUMENT_NUMBERING_LIST";
export const DOCUMENT_NUMBERING_UPDATE = "DOCUMENT_NUMBERING_UPDATE";
export const DOCUMENT_NUMBERING_SERIES_LIST = "DOCUMENT_NUMBERING_SERIES_LIST";
export const DOCUMENT_NUMBERING_SERIES_ADD = "DOCUMENT_NUMBERING_SERIES_ADD";
export const DOCUMENT_NUMBERING_SERIES_UPDATE =
  "DOCUMENT_NUMBERING_SERIES_UPDATE";

export const SMTP_VIEW = "SMTP_VIEW";
export const SMTP_ADD = "SMTP_ADD";
export const SMTP_UPDATE = "SMTP_UPDATE";

export const WHATSAPP_VIEW = "WHATSAPP_VIEW";
export const WHATSAPP_ADD = "WHATSAPP_ADD";
export const WHATSAPP_UPDATE = "WHATSAPP_UPDATE";

export const SMS_VIEW = "SMS_VIEW";
export const SMS_ADD = "SMS_ADD";
export const SMS_UPDATE = "SMS_UPDATE";

export const RAZORPAY_VIEW = "RAZORPAY_VIEW";
export const RAZORPAY_ADD = "RAZORPAY_ADD";
export const RAZORPAY_UPDATE = "RAZORPAY_UPDATE";

export const STRIPE_VIEW = "STRIPE_VIEW";
export const STRIPE_ADD = "STRIPE_ADD";
export const STRIPE_UPDATE = "STRIPE_UPDATE";

export const PHONEPE_VIEW = "PHONEPE_VIEW";
export const PHONEPE_ADD = "PHONEPE_ADD";
export const PHONEPE_UPDATE = "PHONEPE_UPDATE";

export const PAYTM_VIEW = "PAYTM_VIEW";
export const PAYTM_ADD = "PAYTM_ADD";
export const PAYTM_UPDATE = "PAYTM_UPDATE";

export const PINE_LABS_VIEW = "PINE_LABS_VIEW";
export const PINE_LABS_ADD = "PINE_LABS_ADD";
export const PINE_LABS_UPDATE = "PINE_LABS_UPDATE";

export const PRINTPREFERENCE_LIST = "PRINTPREFERENCE_LIST";
export const PRINTPREFERENCE_UPDATE = "PRINTPREFERENCE_UPDATE";
export const CURRENT_PRINTPREFERENCE = "CURRENT_PRINTPREFERENCE";

export const STORE_UPDATE = "STORE_UPDATE";
export const STORE_GET = "STORE_GET";

export const MAP_ALTERNATIVE_ITEM = "MAP_ALTERNATIVE_ITEM";
export const ALTERNATIVE_ITEMS_LIST = "ALTERNATIVE_ITEMS_LIST";
export const DELETE_ALTERNATIVE_TRANSACTION = "DELETE_ALTERNATIVE_TRANSACTION";

//template
export const TEMPLATE_CREATE = "TEMPLATE_CREATE";
export const TEMPLATE_VIEW = "TEMPLATE_VIEW";
export const TEMPLATE_DELETE = "TEMPLATE_DELETE";
export const TEMPLATE_EDIT = "TEMPLATE_EDIT";
export const TEMPLATE_TABLE = "TEMPLATE_TABLE";

/*TCS-TDS*/
export const TCS_TAX_LIST = "TCS_TAX_LIST";
export const TCS_TAX_ADD = "TCS_TAX_ADD";
export const TCS_TAX_DELETE = "TCS_TAX_DELETE";
export const TDS_TAX_LIST = "TDS_TAX_LIST";
export const TDS_TAX_ADD = "TDS_TAX_ADD";
export const TDS_TAX_DELETE = "TDS_TAX_DELETE";

//STOCK TRANSFER
export const STOCK_TRANSFER_TABLE = "STOCK_TRANSFER_TABLE";
export const STOCK_TRANSFER_CREATE = "STOCK_TRANSFER_CREATE";
export const STOCK_TRANSFER_EDIT = "STOCK_TRANSFER_EDIT";
export const STOCK_TRANSFER_VIEW = "STOCK_TRANSFER_VIEW";
export const STOCK_TRANSFER_BATCH = "STOCK_TRANSFER_BATCH";
export const VOID_STOCK_TRANSFER = "VOID_STOCK_TRANSFER";
export const VOID_STOCK_TRANSFER_ITEM = "VOID_STOCK_TRANSFER_ITEM";

//Reports
export const OVERALL_SALES_REPORT = "OVERALL_SALES_REPORT";
export const DAILY_SALES_REPORT = "DAILY_SALES_REPORT";
export const PRODUCT_WISE_SALES_REPORT = "PRODUCT_WISE_SALES_REPORT";
export const BRANCH_WISE_SALES_REPORT = "BRANCH_WISE_SALES_REPORT";
export const DOCTOR_WISE_SALES_REPORT = "DOCTOR_WISE_SALES_REPORT";
export const PAYMENT_MODE_WISE_SALES_REPORT = "PAYMENT_MODE_WISE_SALES_REPORT";
export const PARTY_WISE_SALES_REPORT = "PARTY_WISE_SALES_REPORT";
export const PRODUCT_PURCHASE_RETURN_REPORT = "PRODUCT_PURCHASE_RETURN_REPORT";
export const B2B_SALES_SUMMARY_REPORT = "B2B_SALES_SUMMARY_REPORT";
export const STORE_PURCHASE_REPORT = "STORE_PURCHASE_REPORT";
export const REORDER_REPORT = "REORDER_REPORT";
export const PARTY_WISE_PROFIT_REPORT = "PARTY_WISE_PROFIT_REPORT";
export const DISCOUNT_ANALYSIS_REPORT = "DISCOUNT_ANALYSIS_REPORT";
export const SALES_RETURN_REPORT = "SALES_RETURN_REPORT";

export const PURCHASE_FREQ_REPORT = "PURCHASE_FREQ_REPORT";
export const PURCHASE_BEHAVIOR_REPORT = "PURCHASE_BEHAVIOR_REPORT";

export const BOUNCE_REPORT = "BOUNCE_REPORT";
export const INVENTORY_SALES_REPORT = "INVENTORY_SALES_REPORT";
export const INVENTORY_REPORT = "INVENTORY_REPORT";
export const SCHEDULED_HDRUG_REG_REPORT = "SCHEDULED_HDRUG_REG_REPORT";
export const EXPIRY_LOSS_REPORT = "EXPIRY_LOSS_REPORT";
export const SLOW_FAST_MOVING_PRODUCTS = "SLOW_FAST_MOVING_PRODUCTS";
export const PRODUCT_INFO_REPORT = "PRODUCT_INFO_REPORT";

export const GSTR1_REPORT = "GSTR1_REPORT";
export const GSTR2A_REPORT = "GSTR2A_REPORT";
export const GSTR_HSN_CODE_SUMMARY_REPORT = "GSTR_HSN_CODE_SUMMARY_REPORT";
export const GSTR3B_REPORT = "GSTR3B_REPORT";
export const GSTR9_REPORT = "GSTR9_REPORT";
export const GSTR9C_REPORT = "GSTR9C_REPORT";
export const GST_ITC_MISMATCH_REPORT = "GST_ITC_MISMATCH_REPORT";
export const GST_E_WAY_BILL_REPORT = "GST_E_WAY_BILL_REPORT";
export const GST_PAYMENT_SUMMARY_REPORT = "GST_PAYMENT_SUMMARY_REPORT";
export const GST_ITC_AGING_REPORT = "GST_ITC_AGING_REPORT";
export const PURCHASE_ORDER_REPORT= "PURCHASE_ORDER_REPORT";

//DASHBOARD
export const DASHBOARD_SALES = "DASHBOARD_SALES";
export const DASHBOARD_NEW_CUS_GRAPH = "DASHBOARD_ NEW_CUS_GRAPH";
export const DASHBOARD_TOP_SELLING_PRODUCTS = "DASHBOARD_TOP_SELLING_PRODUCTS";
export const DASHBOARD_LOW_STOCK = "DASHBOARD_LOW_STOCK";
export const DASHBOARD_EXPIRATION = "DASHBOARD_EXPIRATION";
