import { Field, Formik, useFormikContext } from "formik";
import React from "react";
import SelectField from "../forms/select-field";
import AddButton from "../buttons/button";
import DeleteIcon from "../../assets/icons/delete-icon";
import {
  decimalNumberFn,
  positiveIntegerFn,
} from "../helper-components/helper-function";
import moment from "moment";
import errorToast from "../helper-components/error-toast";
import { useDispatch } from "react-redux";
import { purchaseTaxCalAction } from "../../redux/actions/purchase-action";
import BatchIcon from "../../assets/icons/batches-icons/batch-icon";
import QtyIcon from "../../assets/icons/batches-icons/qty-icon";
import RupeeIcon from "../../assets/icons/batches-icons/rupeeIcon";
import CalendorIcon from "../../assets/icons/batches-icons/calendor-icon";
import { todaysDate } from "../helper-components/regexp";
import * as Yup from "yup";

const PurchaseBatchesPopover = ({
  setAddbatch,
  itemId,
  setItemId,
  binLocationList = [],
  disName = "",
  disabled = false,
  itemValues = {},
}) => {
  const defaultSelectedItem = itemValues?.data?.find(
    (item, index) => itemId === item?.item_id
  );
  const { setFieldValue, errors } = useFormikContext();
  const dispatch = useDispatch();
  const defaultSelectedItemIndex = itemValues?.data?.findIndex(
    (item, index) => itemId === item?.item_id
  );
  const initialValues = defaultSelectedItem ?? {};
  const validationSchema = Yup.object().shape({
    item_batches: Yup.array().of(
      Yup.object().shape({
        item_batch_purchase_rate: Yup.number()
          .typeError("Must be a number")
          .required("Purchase rate is required")
          .moreThan(0, "Purchase rate must be greater than 0"),

        item_batch_unit_price: Yup.number()
          .typeError("Must be a number")
          .required("MRP is required")
          .moreThan(0, "MRP must be greater than 0")
          .test(
            "MRP-greater-than-final-purchase-rate",
            "MRP must be greater than the final purchase rate",
            function (value) {
              const { item_batch_final_purchase_rate } = this?.parent;
              return value > item_batch_final_purchase_rate;
            }
          ),
        item_sellable_quantity: Yup.number()
          .typeError("Must be a number")
          .integer("Quantity must be an integer")
          .required("Sellable quantity is required")
          .moreThan(0, "Sellable quantity must be greater than 0"),
        item_batch_free_quantity: Yup.number()
          .transform((value) =>
            typeof value === "string" ? parseInt(value, 10) : value
          ) // Transform string to number
          .typeError("Must be a number")
          .integer("Quantity must be an integer"),
        item_batch_number: Yup.string().required("Batch number is required"),
        // to_bin_id: Yup.string().required("Bin ID is required"),
        item_exp_date: Yup.date().required("Expiration date is required"),
      })
    ),
  });

  const today = new Date();
  const sixMonthsLater = new Date(today.setMonth(today.getMonth() + 6));
  const initData = {
    item_id: defaultSelectedItem?.item_id,
    item_batch_number: "",
    item_sellable_quantity: null,
    item_non_sellable_quantity: 0,
    item_batch_free_quantity: 0,
    item_batch_total_effective_qauntity: 0, //new

    item_batch_purchase_rate: 0,
    item_batch_total_base_purchase_rate: 0, //new

    item_batch_for_qauntity: 0, //new
    item_batch_for_free_qauntity: 0, //new
    item_batch_scheme_discount_percentage: 0, //new
    item_batch_scheme_discount_amount: 0, //new

    item_batch_scheme_purchase_rate: 0, //new

    item_batch_discount_percentage: 0,
    item_batch_gen_discount_amount: 0, //new
    item_batch_discounted_purchase_rate: 0, //new
    item_batch_discount_amount: 0,

    item_batch_tax_percentage: 0,
    item_batch_tax_amount: 0,

    item_batch_final_purchase_rate: 0,
    item_batch_unit_price: 0,
    item_batch_total_purchase_rate: 0,
    item_batch_margin_percentage: 0,
    item_exp_date: moment(sixMonthsLater).format("YYYY-MM-DD"), // Correct format
    item_mfg_date: "2023/12/02",
    from_bin_location: "",
    to_bin_location: "receiving-bin",
    to_bin_id: binLocationList?.find(
      (bin) => bin?.actualLabel === "receiving-bin"
    )?.value,
    from_bin_id: "",
  };

  const handleQuantityChange = async (
    batchIndex,
    qty,
    pr = 0,
    dis = 0,
    item_batch_for_qauntity = 0,
    item_batch_for_free_qauntity = 0,
    item_batch_free_quantity = 0,
    setValues,
    values,
    schemePer = 0,
    item_batch_unit_price
  ) => {
    let updatedData = { ...values };
    const denominator =
      parseInt(item_batch_for_qauntity) +
      parseInt(item_batch_for_free_qauntity);

    const item_batch_scheme_discount_percentage = schemePer
      ? schemePer
      : denominator > 0
      ? parseFloat(
          (parseInt(item_batch_for_free_qauntity) / denominator) * 100
        ).toFixed(2)
      : 0;
    const item_batch_total_base_purchase_rate =
      parseFloat(pr || 0) * parseInt(qty || 0);

    const item_batch_scheme_discount_amount = parseFloat(
      (item_batch_scheme_discount_percentage / 100) *
        item_batch_total_base_purchase_rate
    ).toFixed(2);

    const item_batch_scheme_purchase_rate =
      (
        parseFloat(item_batch_total_base_purchase_rate) -
        parseFloat(item_batch_scheme_discount_amount)
      ).toFixed(2) || 0;
    const item_batch_gen_discount_amount =
      parseFloat(dis * item_batch_scheme_purchase_rate).toFixed(2) ?? 0;

    const item_batch_discounted_purchase_rate =
      parseFloat(
        item_batch_scheme_purchase_rate - item_batch_gen_discount_amount
      )?.toFixed(2) || 0;

    const item_batch_total_effective_qauntity =
      parseInt(qty) + parseInt(item_batch_free_quantity);

    dispatch(
      purchaseTaxCalAction(
        {
          item_id: defaultSelectedItem?.item_id,
          purchase_rate:
            item_batch_scheme_purchase_rate -
            item_batch_scheme_purchase_rate * dis,
        },
        (data) => {
          const {
            item_discount_amount,
            item_tax_amount,
            item_total_tax_percentage,
          } = data.data;

          const discount = item_discount_amount || 0;
          const tax = +item_tax_amount || 0;
          const sgst = +(tax / 2)?.toFixed(2);
          const cgst = +(tax / 2)?.toFixed(2);
          const igst = 0;
          const item_batch_total_purchase_rate = parseFloat(
            parseFloat(item_batch_discounted_purchase_rate) + parseFloat(tax)
          ).toFixed(2);
          const item_batch_final_purchase_rate = parseFloat(
            item_batch_total_purchase_rate / item_batch_total_effective_qauntity
          ).toFixed(2);

          const item_batch_margin_percentage =
            (
              ((parseFloat(item_batch_unit_price) -
                parseFloat(item_batch_final_purchase_rate)) /
                parseFloat(item_batch_final_purchase_rate)) *
              100
            ).toFixed(2) || 0;

          const updatedBatch = {
            ...updatedData?.item_batches[batchIndex],
            item_sellable_quantity: +qty,
            item_batch_free_quantity: +item_batch_free_quantity,
            item_batch_total_effective_qauntity:
              item_batch_total_effective_qauntity,
            item_batch_purchase_rate: pr,
            item_batch_total_base_purchase_rate:
              item_batch_total_base_purchase_rate,
            item_batch_for_qauntity: item_batch_for_qauntity || 0,
            item_batch_for_free_qauntity: item_batch_for_free_qauntity || 0,
            item_batch_scheme_discount_percentage:
              item_batch_scheme_discount_percentage || 0,
            item_batch_scheme_discount_amount:
              item_batch_scheme_discount_amount || 0,
            item_batch_scheme_purchase_rate:
              item_batch_scheme_purchase_rate || 0,
            item_batch_discount_percentage: dis * 100,
            item_batch_gen_discount_amount: item_batch_gen_discount_amount,
            item_batch_discounted_purchase_rate:
              item_batch_discounted_purchase_rate || 0,
            item_batch_discount_amount: (
              parseFloat(item_batch_gen_discount_amount) +
              parseFloat(item_batch_scheme_discount_amount)
            ).toFixed(2),
            item_batch_tax_percentage: item_total_tax_percentage,
            item_batch_tax_amount: parseFloat(tax).toFixed(2),
            item_batch_total_purchase_rate: item_batch_total_purchase_rate,
            item_batch_unit_price: item_batch_unit_price || 0,
            item_batch_final_purchase_rate: item_batch_final_purchase_rate || 0,
            item_batch_margin_percentage: item_batch_margin_percentage,
            item_batch_sgst: +sgst.toFixed(2),
            item_batch_cgst: +cgst.toFixed(2),
            item_batch_igst: +igst,
          };
          updatedData.item_batches[batchIndex] = updatedBatch;
          const sumQuantity = updatedData.item_batches.reduce(
            (sum, batch) =>
              sum + (parseFloat(batch.item_sellable_quantity) || 0),
            0
          );
          const totalDiscount = updatedData.item_batches.reduce(
            (sum, batch) =>
              sum + (parseFloat(batch.item_batch_discount_amount) || 0),
            0
          );
          const totalTax = updatedData.item_batches.reduce(
            (sum, batch) =>
              sum + (parseFloat(batch.item_batch_tax_amount) || 0),
            0
          );
          const totalAmt = updatedData.item_batches.reduce(
            (sum, batch) =>
              sum + (parseFloat(batch.item_batch_total_purchase_rate) || 0),
            0
          );
          const totalFreeQty = updatedData.item_batches.reduce(
            (sum, batch) =>
              sum + (parseFloat(batch.item_batch_free_quantity) || 0),
            0
          );
          const updatedDataRow = {
            ...updatedData,
            quantity: sumQuantity,
            item_free_quantity: +totalFreeQty,
            item_discount_amount: totalDiscount?.toFixed(2),
            item_tax_amount: totalTax?.toFixed(2),
            // item_discount_percentage: +item_discount_percentage,
            item_pack_size: defaultSelectedItem?.base_quantity * sumQuantity,
            item_gst: +item_total_tax_percentage || 0,
            item_sgst: +(totalTax / 2)?.toFixed(2),
            item_cgst: +(totalTax / 2)?.toFixed(2),
            item_igst: +igst,
            item_total_amount: totalAmt.toFixed(2),
          };
          updatedData = updatedDataRow;
          const finalData = itemValues?.data?.map((item, index) => {
            if (index === defaultSelectedItemIndex) {
              return updatedData;
            } else return item;
          });
          setValues(updatedData);
          setFieldValue(
            disName,
            finalData
              .reduce(
                (acc, item) =>
                  acc + (parseFloat(item?.item_discount_amount) || 0),
                0
              )
              .toFixed(2)
          );
        },
        (data) => {
          errorToast("");
        }
      )
    );
  };

  const onErrorDis = (data) => {
    console.error("API Error:", data);
  };

  const handleShowErrorToast = async (item_id = "", errors, values) => {
    console.log(errors, "sddasdsaddfdsadasdasdad");
    console.log(values, "sddasdsaddfdsadasdasdad");

    if (values?.item_batches?.length > 0) {
      // const errors = errors?.data[defaultSelectedItemIndex];

      if (errors?.item_batches) {
        let hasError = false;

        // Loop through each item batch and show the first error found in the correct order
        for (const batchError of errors?.item_batches) {
          if (batchError) {
            if (batchError.item_batch_number && !hasError) {
              errorToast(batchError.item_batch_number + "!");
              hasError = true;
            } else if (batchError.item_batch_purchase_rate && !hasError) {
              errorToast(batchError.item_batch_purchase_rate + "!");
              hasError = true;
            } else if (batchError.item_batch_unit_price && !hasError) {
              errorToast(batchError.item_batch_unit_price + "!");
              hasError = true;
            } else if (batchError.item_sellable_quantity && !hasError) {
              errorToast(batchError.item_sellable_quantity + "!");
              hasError = true;
            } else if (batchError.item_batch_free_quantity && !hasError) {
              errorToast(batchError.item_batch_free_quantity + "!");
              hasError = true;
            } else if (batchError.item_exp_date && !hasError) {
              errorToast(batchError.item_exp_date + "!");
              hasError = true;
            } else if (batchError.to_bin_id && !hasError) {
              errorToast(batchError.to_bin_id + "!");
              hasError = true;
            }
          }

          // If an error is found in the batch, stop and don't check the rest of the fields in that batch
          if (hasError) {
            break;
          }
        }

        // If no errors were found, set addBatch to false
        if (!hasError && item_id === "") {
          setAddbatch(false);
        }
        if (!hasError && item_id !== "") {
          setItemId(item_id);
        }
      } else {
        if (item_id === "") {
          setAddbatch(false);
        }
        if (item_id !== "") {
          setItemId(item_id);
        }
      }
    } else {
      if (item_id === "") {
        setAddbatch(false);
      }
      if (item_id !== "") {
        setItemId(item_id);
      }
    }
  };

  const handleDeleteBatch = (values, batchIndex, setValues) => {
    const updatedBatches = [...values?.item_batches];
    updatedBatches.splice(batchIndex, 1);
    // setFieldValue(`item_batches`, updatedBatches);

    const sumQuantity = updatedBatches.reduce(
      (sum, batch) => sum + (parseFloat(batch.item_sellable_quantity) || 0),
      0
    );
    const totalDiscount = updatedBatches.reduce(
      (sum, batch) => sum + (parseFloat(batch.item_batch_discount_amount) || 0),
      0
    );
    const totalTax = updatedBatches.reduce(
      (sum, batch) => sum + (parseFloat(batch.item_batch_tax_amount) || 0),
      0
    );
    const totalAmt = updatedBatches.reduce(
      (sum, batch) =>
        sum + (parseFloat(batch.item_batch_total_purchase_rate) || 0),
      0
    );
    const totalFreeQty = updatedBatches.reduce(
      (sum, batch) => sum + (parseFloat(batch.item_batch_free_quantity) || 0),
      0
    );

    let updatedData = values; // Ensure values.data is your main data array
    const updatedDataRow = {
      ...updatedData,
      item_batches: updatedBatches,
      quantity: sumQuantity,
      item_free_quantity: +totalFreeQty,
      gort_total_discount: +totalDiscount,
      item_discount_amount: +totalDiscount,
      item_tax_amount: +totalTax?.toFixed(2),
      // item_discount_percentage: +item_discount_percentage,
      item_gst: updatedBatches?.[0]?.item_total_tax_percentage || 0,
      item_sgst: +(totalTax / 2)?.toFixed(2),
      item_cgst: +(totalTax / 2)?.toFixed(2),
      item_igst: 0,
      item_pack_size: defaultSelectedItem?.base_quantity * sumQuantity,
      item_total_amount: totalAmt.toFixed(2),
    };
    updatedData = updatedDataRow;
    setValues(updatedData);
    const finalData = itemValues?.data?.map((item, index) => {
      if (index === defaultSelectedItemIndex) {
        return updatedData;
      } else return item;
    });
    setFieldValue(
      disName,
      finalData
        ?.reduce(
          (acc, item) => acc + (parseFloat(item?.item_discount_amount) || 0),
          0
        )
        .toFixed(2)
    );
  };
  const innerOnSubmit = (values) => {
    setFieldValue(`data.${defaultSelectedItemIndex}`, values);
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      // onSubmit={innerOnSubmit}
    >
      {({ values, handleSubmit, errors, setFieldValue, setValues }) => {
        return (
          <>
            <div className="absolute w-[-webkit-fill-available]  z-40">
              <div className="  h-[72vh] bg-white flex flex-col justify-between">
                <div
                  style={{
                    height: `calc(72vh - 5rem)`,
                  }}
                >
                  <div className="bg-[#F4F6F9] w-full p-3 gap-2 font-semibold text-xl  flex items-center">
                    Batch details -
                    <SelectField
                      onFocus={() => {
                        handleSubmit();
                      }}
                      isDisabled={disabled}
                      general_color={false}
                      handleSelected={(selected) => {
                        setFieldValue("item_i", "");
                        handleShowErrorToast(selected?.value, errors, values);
                      }}
                      containerClass="w-[20rem]"
                      placeholder={"Select product"}
                      defaultValue={
                        defaultSelectedItem
                          ? {
                              label: defaultSelectedItem.item_name,
                              value: defaultSelectedItem.item_id,
                            }
                          : null
                      }
                      options={itemValues?.data
                        ?.map((item) => ({
                          label: item?.item_name,
                          value: item?.item_id,
                        }))
                        ?.filter((item) => item?.value)}
                      label={""}
                      id={"item_i"}
                      name="item_i"
                      isRequired={false}
                      isSearchable={true}
                      errMsgContainerClass="hidden"
                      labelWidth="10.5rem"
                    />
                  </div>
                  <div
                    style={{
                      height: `calc(100% - 5rem)`,
                    }}
                    className="p-3 overflow-y-auto "
                  >
                    <table className="lg:w-[100%] w-full border-D0D5DD border border-1 rounded-lg ">
                      <thead>
                        <tr className="bg-F2F4F5">
                          <th colSpan={6}></th>{" "}
                          <th
                            colSpan={2}
                            className="py-2 px-2 border-D0D5DD border border-1 text-center  text-475467 fw_700 text-sm text-nowrap min-w-[5rem]"
                          >
                            <span className="flex gap-2 items-center justify-center">
                              {" "}
                              <RupeeIcon fill="var(--table-heading-color)" />{" "}
                              Scheme
                            </span>
                          </th>
                          <th colSpan={17}></th>{" "}
                          {/* Empty columns to maintain alignment */}
                        </tr>

                        <tr className="bg-F2F4F5">
                          <th className="py-2 px-2  border-D0D5DD border border-1 text-left  text-475467 fw_700 text-sm text-nowrap  min-w-[10rem]">
                            <span className="flex gap-2 items-center ">
                              {" "}
                              <BatchIcon fill="var(--table-heading-color)" />{" "}
                              Batch No.
                            </span>
                          </th>
                          <th className="py-2 px-2  border-D0D5DD border border-1   text-475467 fw_700 text-sm text-nowrap m min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <QtyIcon fill="var(--table-heading-color)" />
                              Pur Qty
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border border-1 text-right  text-475467 fw_700 text-sm text-nowrap min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <QtyIcon fill="var(--table-heading-color)" /> Free
                              Qty
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border border-1 text-right  text-475467 fw_700 text-sm text-nowrap min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <QtyIcon fill="var(--table-heading-color)" />{" "}
                              Total Qty
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border border-1 text-right  text-475467 fw_700 text-sm text-nowrap min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <RupeeIcon fill="var(--table-heading-color)" />{" "}
                              Base PR
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border border-1 text-right  text-475467 fw_700 text-sm text-nowrap min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <RupeeIcon fill="var(--table-heading-color)" />{" "}
                              Total PR
                            </span>
                          </th>

                          <th className="py-2 px-2 border-D0D5DD border border-1 text-center text-475467 fw_700 text-sm text-nowrap min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-center">
                              Min PQ
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border border-1 text-center text-475467 fw_700 text-sm text-nowrap min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-center">
                              Sch Qty
                            </span>
                          </th>

                          <th className="py-2 px-2 border-D0D5DD border border-1 text-right  text-475467 fw_700 text-sm text-nowrap min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <QtyIcon fill="var(--table-heading-color)" />
                              Sch Disc. %
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border border-1 text-right  text-475467 fw_700 text-sm text-nowrap min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <QtyIcon fill="var(--table-heading-color)" />
                              Sch Disc Amt
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border border-1 text-right  text-475467 fw_700 text-sm text-nowrap min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <QtyIcon fill="var(--table-heading-color)" />
                              Sch PR
                            </span>
                          </th>

                          <th className="py-2 px-2 border-D0D5DD border border-1 text-right  text-475467 fw_700 text-sm text-nowrap min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <QtyIcon fill="var(--table-heading-color)" />{" "}
                              Disc. %
                            </span>
                          </th>

                          <th className="py-2 px-2 border-D0D5DD border border-1 text-right  text-475467 fw_700 text-sm text-nowrap min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <RupeeIcon fill="var(--table-heading-color)" />{" "}
                              Disc. Amt
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border border-1 text-right text-nowrap  text-475467 fw_700 text-sm  min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <RupeeIcon fill="var(--table-heading-color)" />{" "}
                              Disc. PR
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border border-1 text-right text-nowrap  text-475467 fw_700 text-sm  min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <RupeeIcon fill="var(--table-heading-color)" />
                              Tot Disc. Amt
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border border-1 text-right text-nowrap  text-475467 fw_700 text-sm  min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <RupeeIcon fill="var(--table-heading-color)" />
                              Disc. PR (/Qty)
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border border-1 text-right text-nowrap  text-475467 fw_700 text-sm min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <QtyIcon fill="var(--table-heading-color)" /> Tax
                              %
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border border-1 text-right text-nowrap  text-475467 fw_700 text-sm min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <RupeeIcon fill="var(--table-heading-color)" />
                              Tax Amt.
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border border-1 text-nowrap text-right  text-475467 fw_700 text-sm min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <RupeeIcon fill="var(--table-heading-color)" />{" "}
                              Final PR
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border border-1 text-right text-nowrap  text-475467 fw_700 text-sm min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <RupeeIcon fill="var(--table-heading-color)" />{" "}
                              MRP
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border border-1 text-right text-nowrap  text-475467 fw_700 text-sm min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <QtyIcon fill="var(--table-heading-color)" />{" "}
                              Margin %
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border text-nowrap border-1 text-right  text-475467 fw_700 text-sm min-w-[5rem]">
                            <span className="flex gap-2 items-center justify-end">
                              {" "}
                              <RupeeIcon fill="var(--table-heading-color)" />{" "}
                              Tot Amt
                            </span>
                          </th>

                          <th className="py-2 px-2 border-D0D5DD border border-1 text-left  text-475467 fw_700 text-sm min-w-[5rem]">
                            <span className="flex gap-2 items-center text-nowrap">
                              {" "}
                              <CalendorIcon fill="var(--table-heading-color)" />{" "}
                              Exp. Date
                            </span>
                          </th>
                          <th className="py-2 px-2 border-D0D5DD border border-1 text-left  text-475467 fw_700 text-sm  min-w-[8rem]">
                            <span className="flex gap-2 items-center ">
                              {" "}
                              <QtyIcon fill="var(--table-heading-color)" /> To
                              Bin{" "}
                            </span>
                          </th>
                          {!disabled && (
                            <th className="py-2 px-2 border-D0D5DD border border-1 text-left  text-475467 fw_700 text-sm"></th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {values?.item_batches?.map((batch, batchindex) => (
                          <>
                            <tr key={batchindex}>
                              <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm">
                                <Field
                                  name={`item_batches.${batchindex}.item_batch_number`}
                                >
                                  {({ field }) => (
                                    <input
                                      disabled={disabled}
                                      autoFocus={true}
                                      onKeyDown={(e) => {
                                        if (e.key === " ") {
                                          e.preventDefault(); // Prevent space from being typed
                                        }
                                      }}
                                      placeholder=""
                                      {...field}
                                      type="text"
                                      className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2`}
                                    />
                                  )}
                                </Field>
                              </td>
                              <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[6rem] min-w-[5rem]">
                                <Field
                                  name={`item_batches.${batchindex}.item_sellable_quantity`}
                                >
                                  {({ field, form }) => (
                                    <input
                                      disabled={disabled}
                                      min={0}
                                      placeholder=""
                                      {...field}
                                      type="number"
                                      className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                                      onChange={(e) => {
                                        field.onChange(e); // Ensure field.onChang
                                        handleQuantityChange(
                                          batchindex,
                                          +e?.target?.value ?? 0,
                                          +batch?.item_batch_purchase_rate ?? 0,
                                          parseFloat(
                                            batch?.item_batch_discount_percentage ??
                                              0
                                          ) / 100,
                                          parseInt(
                                            batch?.item_batch_for_qauntity ?? 0
                                          ),
                                          parseInt(
                                            batch?.item_batch_for_free_qauntity ??
                                              0
                                          ),
                                          parseInt(
                                            batch?.item_batch_free_quantity
                                          ),
                                          setValues,
                                          values,
                                          parseFloat(
                                            batch?.item_batch_scheme_discount_percentage
                                          ),
                                          parseFloat(
                                            batch?.item_batch_unit_price
                                          )
                                        );
                                      }}
                                      onInput={(e) => {
                                        const value = e.target.value;
                                        if (value < 0) {
                                          form.setFieldValue(field.name, 0);
                                        }
                                      }}
                                      onKeyDown={positiveIntegerFn}
                                    />
                                  )}
                                </Field>
                              </td>

                              <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[6rem] min-w-[5rem]">
                                <Field
                                  name={`item_batches.${batchindex}.item_batch_free_quantity`}
                                >
                                  {({ field }) => (
                                    <input
                                      disabled={disabled}
                                      onKeyDown={positiveIntegerFn}
                                      placeholder=""
                                      {...field}
                                      type="number"
                                      className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                                      onChange={(e) => {
                                        field.onChange(e); // Ensure field.onChang
                                        handleQuantityChange(
                                          batchindex,
                                          batch?.item_sellable_quantity ?? 0,
                                          +batch?.item_batch_purchase_rate ?? 0,
                                          parseFloat(
                                            batch?.item_batch_discount_percentage ??
                                              0
                                          ) / 100,
                                          parseInt(
                                            batch?.item_batch_for_qauntity ?? 0
                                          ),
                                          parseInt(
                                            batch?.item_batch_for_free_qauntity ??
                                              0
                                          ),
                                          +e?.target?.value ?? 0,
                                          setValues,
                                          values,
                                          parseFloat(
                                            batch?.item_batch_scheme_discount_percentage
                                          ),
                                          parseFloat(
                                            batch?.item_batch_unit_price
                                          )
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                              </td>
                              <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[6rem] min-w-[5rem] text-end">
                                {batch?.item_batch_total_effective_qauntity ??
                                  0}
                              </td>

                              <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[10rem]">
                                <Field
                                  name={`item_batches.${batchindex}.item_batch_purchase_rate`}
                                >
                                  {({ field }) => (
                                    <input
                                      min={0}
                                      disabled={disabled}
                                      // value={field.value ? parseInt(field.value, 10) : ""}
                                      onKeyDown={decimalNumberFn}
                                      placeholder=""
                                      {...field}
                                      type="number"
                                      onChange={(e) => {
                                        field.onChange(e); // Ensure field.onChang
                                        handleQuantityChange(
                                          batchindex,
                                          +batch?.item_sellable_quantity,
                                          e?.target?.value !== ""
                                            ? parseFloat(e?.target?.value)
                                            : e?.target?.value,
                                          parseFloat(
                                            batch?.item_batch_discount_percentage ??
                                              0
                                          ) / 100,
                                          parseInt(
                                            batch?.item_batch_for_qauntity ?? 0
                                          ),
                                          parseInt(
                                            batch?.item_batch_for_free_qauntity ??
                                              0
                                          ),
                                          parseInt(
                                            batch?.item_batch_free_quantity
                                          ),
                                          setValues,
                                          values,
                                          parseFloat(
                                            batch?.item_batch_scheme_discount_percentage
                                          ),
                                          parseFloat(
                                            batch?.item_batch_unit_price
                                          )
                                        );
                                      }}
                                      className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                                    />
                                  )}
                                </Field>
                              </td>

                              <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[10rem] text-end">
                                {batch?.item_batch_total_base_purchase_rate}
                              </td>

                              {/* 


//scheme =================================================================*/}

                              <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm min-w-[5rem]">
                                <Field
                                  name={`item_batches.${batchindex}.item_batch_for_qauntity`}
                                >
                                  {({ field }) => (
                                    <input
                                      min={0}
                                      max={100}
                                      disabled={disabled}
                                      onKeyDown={decimalNumberFn}
                                      placeholder=""
                                      {...field}
                                      type="number"
                                      className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        handleQuantityChange(
                                          batchindex,
                                          +batch?.item_sellable_quantity,
                                          parseFloat(
                                            batch?.item_batch_purchase_rate
                                          ),
                                          parseFloat(
                                            batch?.item_batch_discount_percentage ??
                                              0
                                          ) / 100,
                                          parseInt(e?.target?.value),
                                          parseInt(
                                            batch?.item_batch_for_free_qauntity
                                          ),
                                          parseInt(
                                            batch?.item_batch_free_quantity
                                          ),
                                          setValues,
                                          values,
                                          0,
                                          parseFloat(
                                            batch?.item_batch_unit_price
                                          )
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                              </td>

                              <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm min-w-[5rem]">
                                <Field
                                  name={`item_batches.${batchindex}.item_batch_for_free_qauntity`}
                                >
                                  {({ field }) => (
                                    <input
                                      min={0}
                                      max={100}
                                      disabled={disabled}
                                      onKeyDown={decimalNumberFn}
                                      placeholder=""
                                      {...field}
                                      type="number"
                                      className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        handleQuantityChange(
                                          batchindex,
                                          +batch?.item_sellable_quantity,
                                          parseFloat(
                                            batch?.item_batch_purchase_rate
                                          ),
                                          parseFloat(
                                            batch?.item_batch_discount_percentage ??
                                              0
                                          ) / 100,
                                          parseInt(
                                            batch?.item_batch_for_qauntity
                                          ),
                                          parseInt(e?.target?.value),
                                          parseInt(
                                            batch?.item_batch_free_quantity
                                          ),
                                          setValues,
                                          values,
                                          0,
                                          parseFloat(
                                            batch?.item_batch_unit_price
                                          )
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                              </td>

                              <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[10rem] text-end">
                                <Field
                                  name={`item_batches.${batchindex}.item_batch_scheme_discount_percentage`}
                                >
                                  {({ field }) => (
                                    <input
                                      min={0}
                                      max={100}
                                      disabled={disabled}
                                      onKeyDown={decimalNumberFn}
                                      placeholder=""
                                      {...field}
                                      type="number"
                                      className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        handleQuantityChange(
                                          batchindex,
                                          +batch?.item_sellable_quantity,
                                          parseFloat(
                                            batch?.item_batch_purchase_rate
                                          ),
                                          parseFloat(
                                            batch?.item_batch_discount_percentage ??
                                              0
                                          ) / 100,
                                          0,
                                          0,
                                          parseInt(
                                            batch?.item_batch_free_quantity
                                          ),
                                          setValues,
                                          values,
                                          parseFloat(e?.target?.value),
                                          parseFloat(
                                            batch?.item_batch_unit_price
                                          )
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                              </td>

                              {/* 
                              <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[10rem] text-end">
                                {batch?.item_batch_scheme_discount_percentage}
                              </td> */}
                              <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[10rem] text-end">
                                {batch?.item_batch_scheme_discount_amount}
                              </td>
                              <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[10rem] text-end">
                                {batch?.item_batch_scheme_purchase_rate}
                              </td>
                              {/* 
//scheme =================================================================*/}

                              <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm ">
                                <Field
                                  name={`item_batches.${batchindex}.item_batch_discount_percentage`}
                                >
                                  {({ field }) => (
                                    <input
                                      min={0}
                                      max={100}
                                      disabled={disabled}
                                      onKeyDown={decimalNumberFn}
                                      placeholder=""
                                      {...field}
                                      type="number"
                                      className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        handleQuantityChange(
                                          batchindex,
                                          +batch?.item_sellable_quantity,
                                          parseFloat(
                                            batch?.item_batch_purchase_rate
                                          ),
                                          parseFloat(e?.target?.value) / 100,
                                          parseInt(
                                            batch?.item_batch_for_qauntity
                                          ),
                                          parseInt(
                                            batch?.item_batch_for_free_qauntity
                                          ),
                                          parseInt(
                                            batch?.item_batch_free_quantity
                                          ),
                                          setValues,
                                          values,
                                          parseFloat(
                                            batch?.item_batch_scheme_discount_percentage
                                          ),
                                          parseFloat(
                                            batch?.item_batch_unit_price
                                          )
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                              </td>

                              <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm w-[5rem] text-right">
                                {batch?.item_batch_gen_discount_amount}
                              </td>
                              <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm text-right">
                                {batch?.item_batch_discounted_purchase_rate}
                              </td>
                              <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm text-right">
                                {batch?.item_batch_discount_amount}
                              </td>

                              <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm text-right">
                                {parseInt(batch?.item_sellable_quantity) > 0
                                  ? parseFloat(
                                      parseFloat(
                                        batch?.item_batch_discounted_purchase_rate
                                      ) /
                                        parseFloat(
                                          batch?.item_sellable_quantity
                                        )
                                    ).toFixed(2) || 0
                                  : 0}
                              </td>

                              <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm w-[5rem] text-right">
                                {batch?.item_batch_tax_percentage}
                              </td>
                              <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm w-[5rem] text-right">
                                {batch?.item_batch_tax_amount}
                              </td>
                              <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm w-[5rem] text-right">
                                {batch?.item_batch_final_purchase_rate}
                              </td>
                              <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm max-w-[12rem]">
                                <Field
                                  name={`item_batches.${batchindex}.item_batch_unit_price`}
                                >
                                  {({ field }) => (
                                    <input
                                      min={0}
                                      disabled={disabled}
                                      onKeyDown={decimalNumberFn}
                                      placeholder=""
                                      {...field}
                                      type="number"
                                      className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2 px-2 text-right`}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        handleQuantityChange(
                                          batchindex,
                                          +batch?.item_sellable_quantity,
                                          parseFloat(
                                            batch?.item_batch_purchase_rate
                                          ),
                                          parseFloat(
                                            batch?.item_batch_discount_percentage ??
                                              0
                                          ) / 100,
                                          parseInt(
                                            batch?.item_batch_for_qauntity
                                          ),
                                          parseInt(
                                            batch?.item_batch_for_free_qauntity
                                          ),
                                          parseInt(
                                            batch?.item_batch_free_quantity
                                          ),
                                          setValues,
                                          values,
                                          parseFloat(
                                            batch?.item_batch_scheme_discount_percentage
                                          ),
                                          e?.target?.value
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                              </td>
                              <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm w-[5rem] text-right">
                                {batch?.item_batch_margin_percentage || 0}
                              </td>
                              <td className="border-D0D5DD border border-1 py-2 pr-4 text-secondary fw_400 text-sm w-[5rem] text-right">
                                {batch?.item_batch_total_purchase_rate}
                              </td>

                              <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm min-w-[5rem]">
                                <Field
                                  name={`item_batches.${batchindex}.item_exp_date`}
                                >
                                  {({ field }) => (
                                    <input
                                      {...field}
                                      disabled={disabled}
                                      placeholder=""
                                      type="date"
                                      min={todaysDate}
                                      className={`w-full focus_outline__none focus:border-focus-color focus:border rounded-md py-2`}
                                    />
                                  )}
                                </Field>
                              </td>
                              <td className="border-D0D5DD border border-1  py-2 px-1 text-secondary fw_400 text-sm w-[8rem]">
                                <SelectField
                                  isDisabled={disabled}
                                  general_color={false}
                                  // autoFocus={
                                  //   values.data[defaultSelectedItemIndex]?.item_batches[
                                  //     batchindex
                                  //   ]?.item_batch_number
                                  // }
                                  defaultValue={{
                                    label:
                                      binLocationList?.find(
                                        (bin) => bin?.value === batch?.to_bin_id
                                      )?.label ?? "",
                                    value: "",
                                  }}
                                  handleSelected={(selectedoption) => {
                                    setFieldValue(
                                      `item_batches.${batchindex}.to_bin_location`,
                                      selectedoption?.actualLabel
                                    );
                                  }}
                                  labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
                                  containerClass="w-full"
                                  placeholder={"select Bin"}
                                  options={binLocationList}
                                  label={""}
                                  id={`item_batches.${batchindex}.to_bin_id`}
                                  name={`item_batches.${batchindex}.to_bin_id`}
                                  isSearchable={true}
                                  errMsgContainerClass="ps-2 hidden"
                                  labelWidth="7rem"
                                />
                              </td>

                              {!disabled && (
                                <td className="border-D0D5DD border border-1 py-2 px-2 text-secondary fw_400 text-sm ">
                                  <button
                                    onClick={() =>
                                      handleDeleteBatch(
                                        values,
                                        batchindex,
                                        setValues
                                      )
                                    }
                                    // onClick={() => {
                                    //   const updatedBatches = [
                                    //     ...defaultSelectedItem.item_batches,
                                    //   ];
                                    //   updatedBatches.splice(batchindex, 1);
                                    //   setFieldValue(
                                    //     `item_batches`,
                                    //     updatedBatches
                                    //   );
                                    //   handleQuantityChange(
                                    //     defaultSelectedItemIndex,
                                    //     batchindex,
                                    //     +batch?.item_sellable_quantity,
                                    //     +batch?.item_batch_purchase_rate,
                                    //     true
                                    //   );
                                    // }}
                                    type="button"
                                  >
                                    <DeleteIcon />
                                  </button>
                                  {/* )} */}
                                </td>
                              )}

                              {/* <td className="border-D0D5DD border border-1 py-2 px-1 text-secondary fw_400 text-sm">
                      <SelectField
                        defaultValue={{
                          label:
                            binLocationList?.find(
                              (bin) =>
                                bin?.value ===
                                values.data[defaultSelectedItemIndex]
                                  ?.item_batches[batchindex]?.from_bin_id
                            )?.label ?? "",
                          value: "",
                        }}
                        handleSelected={(selectedoption) => {
                          setFieldValue(
                            `item_batches.${batchindex}.from_bin_location`,
                            selectedoption?.actualLabel
                          );
                        }}
                        labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
                        containerClass="w-full"
                        isDisabled={true}
                        placeholder={"select Bin"}
                        options={binLocationList}
                        label={""}
                        id={`item_batches.${batchindex}.from_bin_id`}
                        name={`item_batches.${batchindex}.from_bin_id`}
                        isSearchable={true}
                        errMsgContainerClass="ps-2 hidden"
                        labelWidth="7rem"
                      />
                    </td> */}
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>{" "}
                    {!disabled && (
                      <div className="flex lg:w-[100%] w-full">
                        <AddButton
                          className="bg-primary text-white px-8 py-1 mt-2 rounded flex justify-center fw_500 text-sm"
                          onclkFn={() => {
                            setFieldValue(`item_batches`, [
                              ...values?.item_batches,
                              initData,
                            ]);
                          }}
                          prefixIcon={true}
                          text=""
                          type="button"
                        />
                      </div>
                    )}
                  </div>
                </div>
                {!disabled && (
                  <div className="flex justify-between px-10 sticky bottom-0   bg-white py-2 invoice-bottom-container">
                    <AddButton
                      type="button"
                      onclkFn={() => {
                        handleShowErrorToast("", errors, values);
                        innerOnSubmit(values);
                      }}
                      prefixIcon={false}
                      text="Done"
                    />
                  </div>
                )}
              </div>
              <div
                className="bg-black h-[23vh] opacity-50"
                onClick={() => {
                  handleShowErrorToast("", errors, values);
                  innerOnSubmit(values);
                }}
              ></div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default PurchaseBatchesPopover;
