import React from "react";

const PaginationLeftIcon = () => {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.47138 0.528575C5.73173 0.788925 5.73173 1.21103 5.47138 1.47138L1.94279 4.99998L5.47138 8.52858C5.73173 8.78893 5.73173 9.21103 5.47138 9.47138C5.21103 9.73173 4.78892 9.73173 4.52858 9.47138L0.528575 5.47138C0.268226 5.21103 0.268226 4.78892 0.528575 4.52858L4.52858 0.528575C4.78892 0.268226 5.21103 0.268226 5.47138 0.528575Z"
        fill="#192839"
      />
    </svg>
  );
};

export default PaginationLeftIcon;

export const PaginationRightIcon = () => {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.528575 0.528575C0.788925 0.268226 1.21103 0.268226 1.47138 0.528575L5.47138 4.52858C5.73173 4.78892 5.73173 5.21103 5.47138 5.47138L1.47138 9.47138C1.21103 9.73173 0.788925 9.73173 0.528575 9.47138C0.268226 9.21103 0.268226 8.78893 0.528575 8.52858L4.05717 4.99998L0.528575 1.47138C0.268226 1.21103 0.268226 0.788925 0.528575 0.528575Z"
        fill="#192839"
      />
    </svg>
  );
};
