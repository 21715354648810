import {call, takeLatest} from "redux-saga/effects";
import {
    productBarcodeApi,
    productBarcodesApi,
    productCreateApi,
    productDeleteApi,
    productDisCreateApi,
    productEditApi, productGenerateItemCodeApi,
    productInventoryCreateApi,
    productPlanningCreateApi,
    productPurchasingCreateApi,
    productRemarksCreateApi,
    productRestrictionCreateApi,
    productSalesCreateApi,
    productTableApi, productVerifyItemCodeApi,
    productViewApi,
} from "../apis/product-api";
import {
    PRODUCT_BARCODE,
    PRODUCT_BARCODES,
    PRODUCT_CREATE,
    PRODUCT_DELETE,
    PRODUCT_DIS_CREATE,
    PRODUCT_EDIT, PRODUCT_GENERATE_ITEMCODE,
    PRODUCT_INVENTORY_CREATE,
    PRODUCT_PLANNING_CREATE,
    PRODUCT_PURCHASING_CREATE,
    PRODUCT_REMARKS_CREATE,
    PRODUCT_RESTRICTIONS_CREATE,
    PRODUCT_SALES_CREATE,
    PRODUCT_TABLE, PRODUCT_VERIFY_ITEMCODE,
    PRODUCT_VIEW,
} from "../types";

export function* productCreateWorker(action) {
    try {
        const res = yield call(productCreateApi, action.data);
        if (res.data?.code === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* productTableWorker(action) {
    try {
        const res = yield call(productTableApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* productPurchasingCreateWorker(action) {
    try {
        const res = yield call(productPurchasingCreateApi, action.data);
        if (res.data?.code === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* productSalesCreateWorker(action) {
    try {
        const res = yield call(productSalesCreateApi, action.data);
        if (res.data?.code === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* productInventoryCreateWorker(action) {
    try {
        const res = yield call(productInventoryCreateApi, action.data);
        if (res.data?.code === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* productPlanningCreateWorker(action) {
    try {
        const res = yield call(productPlanningCreateApi, action.data);
        if (res.data?.code === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}
export function* productDisCreateWorker(action) {
  try {
    const res = yield call(productDisCreateApi, action.data);
    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* productRestrictionCreateWorker(action) {
    try {
        const res = yield call(productRestrictionCreateApi, action.data);
        if (res.data?.code === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* productRemarksCreateWorker(action) {
    try {
        const res = yield call(productRemarksCreateApi, action.data);
        if (res.data?.code === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* productViewWorker(action) {
    try {
        const res = yield call(productViewApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* productDeleteWorker(action) {
    try {
        const res = yield call(productDeleteApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* productEditWorker(action) {
    try {
        const res = yield call(productEditApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* productBarcodeWorker(action) {
    try {
        const res = yield call(productBarcodeApi, action.data);
        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* productBarcodesWorker(action) {
    try {
        const res = yield call(productBarcodesApi, action.data);

        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* productGenerateItemCodeWorker(action) {
    try {
        const res = yield call(productGenerateItemCodeApi, action.data);

        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* productVerifyItemCodeWorker(action) {
    try {
        const res = yield call(productVerifyItemCodeApi, action.data);

        if (res.status === 200) {
            yield action.onSuccess(res.data);
        } else {
            yield action.onError(res?.data);
        }
    } catch (error) {
        yield action.onError(error?.response);
    }
}

export function* productCreateWatcher() {
    yield takeLatest(PRODUCT_CREATE, productCreateWorker);
}

export function* productTableWatcher() {
    yield takeLatest(PRODUCT_TABLE, productTableWorker);
}

export function* productPurchasingCreateWatcher() {
    yield takeLatest(PRODUCT_PURCHASING_CREATE, productPurchasingCreateWorker);
}

export function* productSalesCreateWatcher() {
    yield takeLatest(PRODUCT_SALES_CREATE, productSalesCreateWorker);
}

export function* productInventoryCreateWatcher() {
    yield takeLatest(PRODUCT_INVENTORY_CREATE, productInventoryCreateWorker);
}

export function* productPlanningCreateWatcher() {
    yield takeLatest(PRODUCT_PLANNING_CREATE, productPlanningCreateWorker);
}
export function* productDisCreateWatcher() {
  yield takeLatest(PRODUCT_DIS_CREATE, productDisCreateWorker);
}
export function* productRestrictionCreateWatcher() {
    yield takeLatest(PRODUCT_RESTRICTIONS_CREATE, productRestrictionCreateWorker);
}

export function* productRemarksCreateWatcher() {
    yield takeLatest(PRODUCT_REMARKS_CREATE, productRemarksCreateWorker);
}

export function* productViewWatcher() {
    yield takeLatest(PRODUCT_VIEW, productViewWorker);
}

export function* productEditWatcher() {
    yield takeLatest(PRODUCT_EDIT, productEditWorker);
}

export function* productDeleteWatcher() {
    yield takeLatest(PRODUCT_DELETE, productDeleteWorker);
}

export function* productBarcodeWatcher() {
    yield takeLatest(PRODUCT_BARCODE, productBarcodeWorker);
}

export function* productBarcodesWatcher() {
    yield takeLatest(PRODUCT_BARCODES, productBarcodesWorker);
}

export function* productGenerateItemCodeWatcher() {
    yield takeLatest(PRODUCT_GENERATE_ITEMCODE, productGenerateItemCodeWorker);
}

export function* productVerifyItemCodeWatcher() {
    yield takeLatest(PRODUCT_VERIFY_ITEMCODE, productVerifyItemCodeWorker);
}
