export const orderStatusoptions = [
  { label: "Completed", value: "completed" },
  { label: "Draft", value: "draft" },
];
export const salesPurchaseStatusoptions = [
  { label: "Open", value: "open" },
  { label: "Closed", value: "closed" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Draft", value: "draft" },
];
export const paymentStatusoptions = [
  { label: "Paid", value: "paid" },
  { label: "Cancelled", value: "cancelled" },
];
export const customerStatusoptions = [
  { label: "Active Customers", value: "active" },
  { label: "Inactive Customers", value: "inactive" },
];
export const customerTypeoptions = [
  { label: "Individual", value: "individual" },
  { label: "Business", value: "business" },
];

export const roundingMethodoptions = [
  { label: "No Rounding", value: "no_rounding" },
  { label: "Round to Full Decimal Amount", value: "round_full_dec_amt" },
  { label: "Round to Full Amount", value: "round_full_amt" },
  { label: "Round to Full Tens Amount", value: "round_full_tens_amt" },
];
export const roundingRuleoptions = [
  { label: "Round To Closest", value: "round_closest" },
  { label: "Round Up", value: "round_up" },
  { label: "Round Down", value: "round_down" },
];
export const stockOptions = [
  { label: "No Stock", value: "no_stock" },
  { label: "Low Stock", value: "low_stock" },
];
export const TransTypeOptions = [
  { value: "billing", label: "Billing" },
  { value: "invoice_credit_note", label: "Invoice Credit Note" },
  { value: "sales_invoice", label: "Sales Invoice" },
  { value: "sales_credit_note", label: "Sales Credit Note" },
];
