import { Field, useFormikContext } from "formik";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "../../assets/icons/delete-icon";
import { url } from "../../redux/config";
import "../forms/form.css";
import SearchAddFeild from "../forms/search-add-field";
import errorToast from "../helper-components/error-toast";
import { api } from "../../redux/apis/api";
import useBarcodeScanner from "../helper-components/helper-function";
import { invoiceScannedItemAction } from "../../redux/actions/invoices-action";
import {
  salesProductDetailsAction,
  salesProductListAction,
} from "../../redux/actions/sales-action";
import SelectField from "../forms/select-field";

const SalesItemsTable = ({
  initialData,
  disabled = false,
  addBatchFn = () => {},
  searchRef,
  type = "",
  disName = "",
  qtyDis = false,
  standAlone = false,
}) => {
  const inputRefs = useRef(null);
  const { values, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const [localData, setLocalData] = useState([]);

  const tableHeading = [
    { label: "S No.", value: "sl_no" },
    { label: "Item code", value: "item_code" },
    { label: "Generic Name.", value: "item_generic_name" },
    { label: "Product Name", value: "item_product_name" },
    { label: "HSN code", value: "item_hsn" },
    { label: "Mfg By", value: "item_manufacturer_name" },
    { label: "UOM", value: "item_uom" },
    { label: "Open Qty", value: "item_to_be_delivered" },
    { label: "Qty", value: "item_quantity" },
    { label: "Free Qty", value: "item_free_quantity" },
    {
      label: "Bch - Qty - Free Qty - exp - Final SP - MRP",
      value: "item_batch_no",
    },
    { label: "Disc (₹)", value: "item_discount_amount" },
    { label: "Tax (%)", value: "item_tax_amount" },
    { label: "Tax (₹)", value: "item_tax_amount" },
    { label: "Total (₹)", value: "item_total" },
    ...(disabled ? [] : [{ label: "", value: "" }]),
  ];

  const fetchData = async (inputValue, callback, field) => {
    const onSuccess = (data) => {
      const options =
        data?.data === null
          ? []
          : data.data
              .map((item) => ({
                value: item.item_id,
                label: item[field],
              }))
              .filter(
                (option) =>
                  !values?.data?.some((item) => item?.item_id === option.value)
              );
      setLocalData(data.data);
      callback(options);
    };
    const onError = (data) => {};
    if (inputValue?.length > 0) {
      dispatch(
        salesProductListAction(
          {
            field: field,
            value: inputValue,
          },
          onSuccess,
          onError
        )
      );
    }
  };

  const removeRow = (rowIndex) => {
    const updatedData = [...values.data];
    updatedData.splice(rowIndex, 1);
    setFieldValue("data", updatedData);
  };

  //ON QUANTITY CHANGE
  const handleQuantityChange = async (
    event,
    rowIndex,
    base_quantity,
    item_uom_id,
    item_uom
  ) => {
    const updatedData = values.data.map((row, index) =>
      index === rowIndex
        ? { ...row, quantity: parseFloat(event.target.value) }
        : row
    );
    setFieldValue("data", updatedData);
    const item = values.data[rowIndex];
    const stockApiUrl = `${url}/v1/inventory/stock/sales/items?item_id=${
      item.item_id
    }&item_quantity=${event.target.value}&base_quantity=${parseInt(
      base_quantity
    )}&cmr_id=${values?.cmr_id}`;
    if (parseFloat(event?.target?.value) > 0) {
      try {
        const stockApiResponse = await api.get(stockApiUrl);
        if (
          stockApiResponse?.data?.code === 200 &&
          stockApiResponse?.data?.data?.itemDetails?.length > 0
        ) {
          const itemDetail = stockApiResponse?.data?.data?.itemDetails;
          const itemBatchList = stockApiResponse?.data?.data?.itemBatchList;
          const item_discount_amount = itemDetail.reduce((acc, batch) => {
            return acc + parseFloat(batch.item_discount_amount || 0);
          }, 0);
          const item_tax_amount = itemDetail.reduce((acc, batch) => {
            return acc + parseFloat(batch.item_tax_amount || 0);
          }, 0);
          const item_total_amount = itemDetail.reduce((acc, batch) => {
            return (
              acc +
              parseFloat(batch.item_selling_price || 0) *
                parseFloat(batch.quantity || 0) -
              parseFloat(batch.item_discount_amount || 0) +
              parseFloat(batch.item_tax_amount || 0)
            );
          }, 0);

          const updatedDataRow = {
            ...updatedData[rowIndex],
            item_discount_amount: item_discount_amount,
            item_tax_amount: item_tax_amount,
            item_total_tax_percentage:
              itemDetail?.[0]?.item_total_tax_percentage,
            item_batches:
              itemDetail?.map((batch) => ({
                item_batch_number: batch?.batchNumber,
                item_batch_sales_rate: batch?.item_selling_price
                  ? parseFloat(batch?.item_selling_price)
                  : 0,
                item_batch_quantity: batch?.quantity
                  ? parseInt(batch?.quantity)
                  : 0,
                item_batch_free_quantity: batch?.item_batch_free_quantity
                  ? parseInt(batch?.item_batch_free_quantity)
                  : 0,
                item_batch_discount_percentage: batch?.item_discount_percentage
                  ? parseFloat(batch?.item_discount_percentage)
                  : 0,
                item_batch_discount_amount: batch?.item_discount_amount
                  ? parseFloat(batch?.item_discount_amount)
                  : 0,
                item_batch_tax_percentage: batch?.item_total_tax_percentage
                  ? parseFloat(batch?.item_total_tax_percentage)
                  : 0,
                item_batch_tax_amount: batch?.item_tax_amount
                  ? parseFloat(batch?.item_tax_amount)
                  : 0,
                item_batch_final_sales_rate: (
                  (parseFloat(batch?.item_selling_price) *
                    parseFloat(batch?.quantity) -
                    parseFloat(batch?.item_discount_amount) +
                    parseFloat(batch?.item_tax_amount)) /
                  parseFloat(batch?.quantity)
                ).toFixed(2),
                item_batch_unit_price: batch?.item_unit_price
                  ? parseFloat(batch?.item_unit_price)
                  : 0,
                item_batch_total_sales_rate: (
                  parseFloat(batch?.item_selling_price) *
                    parseFloat(batch?.quantity) -
                  parseFloat(batch?.item_discount_amount) +
                  parseFloat(batch?.item_tax_amount)
                ).toFixed(2),
                item_exp_date: batch?.expiryDate,
                from_bin_location: batch?.item_rack_location,
                from_bin_id: batch?.to_bin_id,
                item_id: item.item_id,
                item_batch_avail_quantity: parseInt(batch?.quantity) ?? 0,
              })) ?? [],
            item_uom_id: item_uom_id,
            item_uom: item_uom,
            base_quantity: base_quantity,
            item_gst: itemDetail?.[0]?.item_total_tax_percentage,
            item_sgst: item_tax_amount / 2,
            item_cgst: item_tax_amount / 2,
            item_igst: 0,

            item_total_amount: item_total_amount?.toFixed(2),

            itemBatchList: itemBatchList ?? [],
            instock: true,
          };
          // if (rowIndex === updatedRowIndex) {
          updatedData[rowIndex] = updatedDataRow;
          // } else {
          //   updatedData.splice(updatedRowIndex, 0, updatedDataRow);
          // }
          setFieldValue("data", [...updatedData]);
          setFieldValue(
            disName,
            updatedData
              .reduce(
                (acc, item) =>
                  acc + (parseFloat(item?.item_discount_amount) || 0),
                0
              )
              .toFixed(2)
          );
        } else {
          const updatedDataWithInstock = updatedData.map((row, index) =>
            index === rowIndex
              ? {
                  ...row,
                  item_discount_amount: 0,
                  item_tax_amount: 0,
                  item_discount_percentage: 0,
                  item_gst: 0,
                  item_sgst: 0,
                  item_cgst: 0,
                  item_igst: 0,
                  item_total_amount: 0,
                  item_total_tax_percentage: 0,
                  item_batches: [],
                  item_uom_id: item_uom_id,
                  item_uom: item_uom,
                  base_quantity: base_quantity,
                }
              : row
          );
          setFieldValue("data", updatedDataWithInstock);
          errorToast(
            `Product ${item.item_name} with quantity ${event.target.value} is not available`
          );
        }
      } catch (error) {
        const updatedDataWithError = updatedData.map((row, index) =>
          index === rowIndex
            ? {
                ...row,
                item_discount_amount: 0,
                item_tax_amount: 0,
                item_discount_percentage: 0,
                item_gst: 0,
                item_sgst: 0,
                item_cgst: 0,
                item_igst: 0,
                item_total_amount: 0,
                item_total_tax_percentage: 0,
                item_batches: [],
                item_uom_id: item_uom_id,
                item_uom: item_uom,
                base_quantity: base_quantity,
              }
            : row
        );
        setFieldValue("data", updatedDataWithError);
        errorToast(
          `${item.item_name} available quantity is ${parseInt(
            error?.response?.data?.data?.itemDetails?.item_sellable_quantity
          )}`
        );
      }
    } else {
      const updatedData = values.data.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              item_discount_amount: 0,
              item_tax_amount: 0,
              item_discount_percentage: 0,
              item_gst: 0,
              item_sgst: 0,
              item_cgst: 0,
              item_igst: 0,
              item_total_amount: 0,
              item_total_tax_percentage: 0,
              item_batches: [],
              item_uom_id: item_uom_id,
              item_uom: item_uom,
              base_quantity: base_quantity,
            }
          : row
      );
      setFieldValue("data", updatedData);
    }
  };
  const handleDisChange = (event, rowIndex) => {
    if (event?.target?.value >= 0) {
      const item_discount_amount = event.target.value;
      const totalAmountwithoutDis =
        parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
        parseFloat(values?.data?.[rowIndex]?.quantity);
      const item_discount_percentage =
        (item_discount_amount / totalAmountwithoutDis) * 100;
      const updatedData = values.data.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              item_discount_amount: item_discount_amount,
              item_discount_percentage: item_discount_percentage,
            }
          : row
      );
      setFieldValue("data", updatedData);
      const totalAmount =
        parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
          parseFloat(values?.data?.[rowIndex]?.quantity) -
        parseFloat(event.target.value) +
        parseFloat(values?.data?.[rowIndex]?.item_tax_amount);

      const updatedDataWithTotal = updatedData.map((row, index) =>
        index === rowIndex ? { ...row, item_total_amount: totalAmount } : row
      );
      setFieldValue("data", updatedDataWithTotal);
    }
  };
  const handleDisPerChange = (event, rowIndex) => {
    if (event?.target?.value >= 0) {
      const item_discount_percentage =
        event.target.value <= 100 ? event.target.value : 0;
      const totalAmountwithoutDis =
        parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
        parseFloat(values?.data?.[rowIndex]?.quantity);
      const item_discount_amount =
        (totalAmountwithoutDis * item_discount_percentage) / 100;
      const updatedData = values.data.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              item_discount_amount: item_discount_amount,
              item_discount_percentage: item_discount_percentage,
            }
          : row
      );
      setFieldValue("data", updatedData);
      const totalAmount =
        parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
          parseFloat(values?.data?.[rowIndex]?.quantity) -
        parseFloat(item_discount_amount) +
        parseFloat(values?.data?.[rowIndex]?.item_tax_amount);

      const updatedDataWithTotal = updatedData.map((row, index) =>
        index === rowIndex ? { ...row, item_total_amount: totalAmount } : row
      );
      setFieldValue("data", updatedDataWithTotal);
    }
  };

  const hasDuplicateItemId = (itemId, currentIndex) => {
    const itemIdsExceptCurrent = values.data
      .filter((_, index) => index !== currentIndex)
      .map((item) => item.item_id);

    return itemIdsExceptCurrent.includes(itemId);
  };

  useBarcodeScanner((barcode) => {
    if (barcode) {
      dispatch(
        invoiceScannedItemAction(barcode, onSuccessScanned, onErrorScanned)
      );
    }
  });

  const onSuccessScanned = (data) => {
    const itemData = data?.data?.item_information;
    if (
      !values?.data?.some(
        (item) => item?.item_id === data?.data?.item_information?.item_id
      )
    ) {
      const newdata = {
        sl_no: 1,
        item_code: itemData?.item_code,
        item_generic_name: itemData?.item_generic_name,
        item_name: itemData?.item_name,
        item_hsn: itemData.item_hsn,
        item_manufacturer_name: itemData.item_manufacturer_name,
        item_uom: itemData?.item_uom,
        item_to_be_delivered: 0,
        quantity: 1,
        item_free_qty: 0,
        item_batches: data?.data?.item_fullfilled_Batches.map((batch) => ({
          item_batch_number: batch?.batchNumber,
          item_batch_quantity: batch?.quantity,
          item_exp_date: batch?.expiryDate,
          item_id: itemData?.item_id,
        })),
        item_unit_price: itemData?.item_unit_price,
        item_discount_amount: data.data.discount_details?.item_discount_amount,
        item_discount_percentage: parseFloat(
          data.data.discount_details?.item_discount_percentage
        )?.toFixed(2),
        item_tax_amount: data.data.discount_details?.item_tax_amount,
        item_total_amount:
          parseFloat(itemData?.item_unit_price) * 1 -
          data.data.discount_details?.item_discount_amount +
          data.data.discount_details?.item_tax_amount,
        item_id: itemData?.item_id,
        instock: true,
        itemBatchList: data?.data?.itemBatchList,
        item_gst: data.data.discount_details?.item_total_tax_percentage,
        item_sgst: (
          (parseFloat(itemData?.item_unit_price) -
            parseFloat(data.data.discount_details?.item_discount_amount)) *
          (data.data.discount_details?.sgstRate / 100)
        )?.toFixed(2),
        item_cgst: (
          (parseFloat(itemData?.item_unit_price) -
            parseFloat(data.data.discount_details?.item_discount_amount)) *
          (data.data.discount_details?.cgstRate / 100)
        )?.toFixed(2),
        item_igst: (
          (parseFloat(itemData?.item_unit_price) -
            parseFloat(data.data.discount_details?.item_discount_amount)) *
          (data.data.discount_details?.igstRate / 100)
        )?.toFixed(2),
        item_manufacturer_id: itemData.item_manufacturer,
      };
      const oldData = [...values?.data];
      oldData.pop();
      setFieldValue("data", [...oldData, newdata, initialData]);
    } else {
      errorToast(
        "Item already added to invoice. Please adjust quantity if you wish to add more."
      );
    }
  };
  const onErrorScanned = (data) => {
    errorToast(data?.data?.message);
  };

  const onSuccessDetails = (data, rowIndex, filteredData) => {
    const oldData = [...values?.data];
    oldData.pop();
    const updatedData = {
      ...filteredData,
      uom_dropdown: data?.data?.uom_dropdown
        ? data?.data?.uom_dropdown.map((uom) => ({
            item_uom_id: uom?.item_id,
            label: uom?.alt_uom,
            value: uom?.alt_uom,
            base_quantity: uom?.base_quantity,
          }))
        : [],
      base_quantity: data?.data?.uom_dropdown?.find(
        (uom) => uom?.item_id === filteredData?.item_uom_id
      )?.base_quantity,
    };

    setFieldValue("data", [...oldData, updatedData, initialData]);
    inputRefs.current?.focus();
  };

  const onErrorDetails = (data) => {
    errorToast(data?.data?.message);
  };
  return (
    <>
      <div
        className="table-container mt-3"
        style={{
          maxHeight: `calc(100% - 20rem)`,
          height: `calc(100% - 20rem)`,
        }}
      >
        <table className="my-table m-0">
          <thead className="sticky top-0 z-10">
            <tr className="bg-F2F4F5">
              {tableHeading.map((heading, index) => (
                <th
                  key={index}
                  className={`${
                    index === tableHeading?.length - 1
                      ? "table-header"
                      : "table-header"
                  } whitespace-nowrap text-sm fw_700 text-475467 ${
                    index === 7 ||
                    index === 8 ||
                    index === 11 ||
                    index === 12 ||
                    index === 13
                      ? "text-right w-[5rem]"
                      : "text-left"
                  } 
                  ${index === 6 && "min-w-[8rem] p-0"}
                  ${index === 10 && "text-center w-[5rem]"} ${
                    (index === 14 || index === 0) && "w-[2.5rem]"
                  } ${index === 3 ? "w-full min-w-[20rem]" : ""} `}
                >
                  {heading?.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {values?.data?.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {Object?.values(rowData)?.map((cellData, colIndex) => {
                  if (colIndex < 15) {
                    return (
                      <td
                        key={colIndex}
                        className={`table-cell text-sm fw_400 text-secondary ${
                          colIndex === 11 ||
                          colIndex === 9 ||
                          colIndex === 12 ||
                          colIndex === 7 ||
                          colIndex === 14 ||
                          colIndex === 13
                            ? "text-end"
                            : ""
                        } ${colIndex === 10 && "text-center"} `}
                      >
                        {colIndex === 1 ||
                        colIndex === 2 ||
                        colIndex === 3 ||
                        colIndex === 8 ? (
                          colIndex !== 8 ? (
                            <div
                              title={
                                values?.data[rowIndex][
                                  Object.keys(rowData)[colIndex]
                                ]
                              }
                            >
                              <SearchAddFeild
                                searchRef={colIndex === 3 ? searchRef : {}}
                                isDisabled={
                                  values?.data?.length - 1 !== rowIndex ||
                                  disabled
                                    ? true
                                    : false
                                }
                                errorClass="hidden"
                                isRequired={false}
                                value={{
                                  label:
                                    values?.data[rowIndex][
                                      Object.keys(rowData)[colIndex]
                                    ],
                                  value: "",
                                }}
                                name={`data[${rowIndex}].${
                                  Object.keys(rowData)[colIndex]
                                }`}
                                field={Object.keys(rowData)[colIndex]}
                                isLabelReq={false}
                                defaultSelectClass=""
                                placeholder=""
                                fetchData={fetchData}
                                onChange={async (selectedOption) => {
                                  const filteredData = localData
                                    ?.filter(
                                      (item) =>
                                        item?.item_id === selectedOption?.value
                                    )
                                    .map((item) => ({
                                      sl_no: 1,
                                      item_code: item.item_code,
                                      item_generic_name:
                                        item?.item_generic_name,
                                      item_name: item.item_name,
                                      item_hsn: item?.item_hsn,
                                      item_manufacturer_name:
                                        item?.item_manufacturer_name,
                                      item_uom:
                                        item.item_sales_uom_name === null
                                          ? ""
                                          : item.item_sales_uom_name,
                                      item_to_be_delivered: 0,
                                      quantity: 0,
                                      item_free_quantity: 0,
                                      item_batches: [],
                                      item_discount_amount: 0,
                                      item_total_tax_percentage: 0,
                                      item_tax_amount: 0,
                                      item_total_amount: 0,
                                      item_id: item?.item_id,
                                      instock: true,
                                      item_uom_id:
                                        item?.item_sales_uom_name_id ?? "",
                                      uom_dropdown: [],
                                      // uom_dropdown: item?.uom_dropdown
                                      //   ? item?.uom_dropdown.map((uom) => ({
                                      //       item_uom_id: uom?.item_id,
                                      //       label: uom?.alt_uom,
                                      //       value: uom?.alt_uom,
                                      //       base_quantity: uom?.base_quantity,
                                      //     }))
                                      //   : [],
                                      item_manufacturer_id:
                                        item.item_manufacturer,
                                      base_quantity: 0,
                                      // base_quantity: item?.uom_dropdown?.find(
                                      //   (uom) =>
                                      //     uom?.item_id ===
                                      //     item?.item_sales_uom_name_id
                                      // )?.base_quantity,
                                      uom_group_id: item?.item_uom || "",
                                    }));

                                  dispatch(
                                    salesProductDetailsAction(
                                      {
                                        item_id: filteredData?.[0]?.item_id,
                                        item_uom:
                                          filteredData?.[0]?.uom_group_id,
                                      },
                                      (data) => {
                                        onSuccessDetails(
                                          data,
                                          rowIndex,
                                          ...filteredData
                                        );
                                      },
                                      onErrorDetails
                                    )
                                  );
                                  // const oldData = [...values?.data];
                                  // oldData.pop();

                                  // setFieldValue("data", [
                                  //   ...oldData,
                                  //   ...filteredData,
                                  //   initialData,
                                  // ]);
                                  // inputRefs.current?.focus();
                                }}
                              />
                            </div>
                          ) : (
                            <div>
                              <Field name={`data[${rowIndex}].quantity`}>
                                {({ field }) => (
                                  <input
                                    min={0}
                                    max={
                                      standAlone === false &&
                                      parseInt(rowData?.item_to_be_delivered)
                                    }
                                    ref={inputRefs}
                                    {...field}
                                    type="number"
                                    step="1"
                                    placeholder=""
                                    readOnly={
                                      values?.data?.length - 1 === rowIndex ||
                                      hasDuplicateItemId(
                                        rowData.item_id,
                                        rowIndex
                                      ) ||
                                      disabled ||
                                      !rowData?.item_uom_id ||
                                      qtyDis
                                    }
                                    onKeyDown={(event) => {
                                      if (
                                        event.key === "-" ||
                                        event.key === "e" ||
                                        event.key === "."
                                      ) {
                                        event.preventDefault();
                                      }
                                      if (
                                        event.key === "Tab" ||
                                        event.key === "Enter"
                                      ) {
                                        if (searchRef && searchRef.current) {
                                          setTimeout(() => {
                                            searchRef.current.focus();
                                          }, 0);
                                        }
                                      }
                                    }}
                                    onBlur={(event) => {
                                      if (
                                        values?.data?.length - 1 === rowIndex ||
                                        hasDuplicateItemId(
                                          rowData.item_id,
                                          rowIndex
                                        ) ||
                                        disabled ||
                                        !rowData?.item_uom_id ||
                                        qtyDis
                                      ) {
                                        event?.preventDefault();
                                      } else {
                                        handleQuantityChange(
                                          event,
                                          rowIndex,
                                          rowData?.base_quantity,
                                          rowData?.item_uom_id,
                                          rowData?.item_uom
                                        );
                                      }
                                    }}
                                    className="focus-within:ring-2 text-right px-2 w-full py-3 rounded focus-within:outline-none focus-within:shadow-[0px_0px_0px_4px_#EBF2FF input-field-no-spin-buttons min-w-[4rem]"
                                  />
                                )}
                              </Field>
                            </div>
                          )
                        ) : colIndex === 0 ? (
                          <div>
                            <label
                              htmlFor={rowIndex}
                              className="block cursor-pointer select-none rounded-xl p-3 text-center hover:bg-slate-100 peer-checked:bg-blue-100 peer-checked:font-bold peer-checked:outline-none peer-checked:ring-2 peer-checked:ring-blue-500"
                            >
                              {rowIndex + 1}
                            </label>
                          </div>
                        ) : colIndex === 10 ? (
                          <>
                            {rowIndex < values?.data?.length - 1 &&
                              rowData.item_batches?.length > 0 && (
                                <button
                                  onClick={() => addBatchFn(rowData?.item_id)}
                                  type="button"
                                  className="text-secondary fw_500 text-sm opacity-30"
                                >
                                  {disabled
                                    ? "View Batch Details"
                                    : " Click to Update"}
                                </button>
                              )}
                            {Array.isArray(rowData.item_batches) &&
                              rowData?.item_batches.map((batch, index) => (
                                <div className="whitespace-nowrap" key={index}>
                                  {batch?.item_batch_number} -{" "}
                                  {batch?.item_batch_quantity} -
                                  {batch?.item_batch_free_quantity ?? 0} -
                                  {batch?.item_exp_date} -{" "}
                                  {batch?.item_batch_final_sales_rate} -{" "}
                                  {batch?.item_batch_unit_price}
                                </div>
                              ))}
                          </>
                        ) : colIndex === 11 ? (
                          <>
                            <div>
                              <input
                                style={{
                                  textAlign: "right",
                                }}
                                readOnly={
                                  values?.data?.length - 1 === rowIndex
                                    ? true
                                    : true
                                }
                                type="text"
                                onChange={(event) => {
                                  handleDisChange(event, rowIndex);
                                }}
                                name={`data[${rowIndex}].item_discount_amount`}
                                value={
                                  values.data[rowIndex].item_discount_amount < 0
                                    ? 0
                                    : values.data[rowIndex].item_discount_amount
                                }
                                className="border-0 focus_outline__none w-full "
                              />
                            </div>
                          </>
                        ) : colIndex === 12 || colIndex === 13 ? (
                          parseFloat(cellData)?.toFixed(2)
                        ) : colIndex === 6 ? (
                          <>
                            <SelectField
                              general_color={false}
                              handleSelected={(sel) => {
                                console.log(sel, "dffsfsdfsdfdsffdfsdfs");
                                setFieldValue(
                                  `data[${rowIndex}].item_uom_id`,
                                  sel?.item_uom_id
                                );
                                setFieldValue(
                                  `data[${rowIndex}].item_uom`,
                                  sel?.label
                                );
                                setFieldValue(
                                  `data[${rowIndex}].base_quantity`,
                                  parseInt(sel?.base_quantity)
                                );
                                if (parseInt(rowData?.quantity) > 0) {
                                  handleQuantityChange(
                                    {
                                      target: {
                                        value: rowData?.quantity,
                                      },
                                    },
                                    rowIndex,
                                    sel?.base_quantity,
                                    sel?.item_uom_id,
                                    sel?.label
                                  );
                                }
                              }}
                              borderclass=""
                              defaultSelectClass="border-0"
                              selectClass="w-full"
                              defaultValue={
                                values.data[rowIndex]?.item_uom
                                  ? {
                                      label:
                                        values.data[rowIndex]?.item_uom ?? "",
                                    }
                                  : null
                              }
                              isDisabled={disabled}
                              errMsgContainerClass="hidden"
                              id={`data[${rowIndex}].item_uom`}
                              isContainer={false}
                              options={values.data[rowIndex]?.uom_dropdown}
                              name={`data[${rowIndex}].item_uom`}
                              placeholder={"Select UOM"}
                            />
                          </>
                        ) : colIndex === 9 &&
                          rowData.item_batches?.length > 0 ? (
                          rowData?.item_batches?.reduce(
                            (sum, batch) =>
                              sum +
                              (parseInt(batch.item_batch_free_quantity, 10) ||
                                0),
                            0
                          )
                        ) : (
                          cellData
                        )}
                      </td>
                    );
                  }
                  return null;
                })}
                {!disabled && (
                  <td className="last-column">
                    {rowIndex !== values?.data?.length - 1 && (
                      <DeleteIcon onClickFn={() => removeRow(rowIndex)} />
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SalesItemsTable;
