import React from "react";

const CalendorIcon = ({
  fill = "#64758B",
  width = "14px",
  height = "14px",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.4333 1.33337V4.00004"
        stroke={fill}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.7666 1.33337V4.00004"
        stroke={fill}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7666 2.66663H3.43331C2.69693 2.66663 2.09998 3.26358 2.09998 3.99996V13.3333C2.09998 14.0697 2.69693 14.6666 3.43331 14.6666H12.7666C13.503 14.6666 14.1 14.0697 14.1 13.3333V3.99996C14.1 3.26358 13.503 2.66663 12.7666 2.66663Z"
        stroke={fill}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.09998 6.66663H14.1"
        stroke={fill}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CalendorIcon;
