import React, { useCallback, useEffect, useRef, useState } from "react";
import Select from "react-select";
import FilterIcon from "../../assets/icons/filter-icon";
import search from "../../assets/icons/searchIcon.svg";
import AddButton from "../buttons/button";
import { capitalizeWords } from "../helper-components/helper-function";
import DatePickerInput from "../forms/date-picker";
import CloseIcon from "../../assets/icons/close-icon";
import { debounce } from "lodash";
import PlusIcon from "../../assets/icons/plus-icon";
import SelectOption from "../helper-components/select-option";

const FilterSection = ({
  filterData,
  setFilterData,
  initialFilter = {},
  placeholder,
  filterFn = () => {},
  tableHeading,
  filterBy,
  showFilter,
  paginationProps,
  filterText = "Filter",
  date = true,
  filter,
  permissionFn,
  module,
  onclkFn,
  list,
  filterSec = true,
  newBtnTxt = "New",
  // SUB TABLE
  invoiceItems,
  handleSelect = () => {},
  options = [],
}) => {
  const [searchTerm, setSearchTerm] = useState(filterData?.inputValue || "");

  const defaultPermissionFn = () => {};
  const filterPopupRef = useRef(null);
  const handleSelectChange = (event) => {
    setFilterData({ ...filterData, selectColumn: event?.value });
  };
  const searchRef = useRef();
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        showFilter &&
        filterPopupRef.current &&
        !filterPopupRef.current.contains(event.target)
      ) {
        filterFn();
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [filterFn, showFilter]);
  const [isFocused, setIsFocused] = useState(false);

  const debouncedFetchData = useCallback(
    debounce((inputValue) => {
      setFilterData({ ...filterData, inputValue }); // Update filter data after debounce
      paginationProps.setCurrentPage(1);
    }, 500), // 500ms delay
    []
  );
  const handleChange = (e) => {
    setSearchTerm(e.target.value); // Instant UI update
    debouncedFetchData(e.target.value); // Delayed API call
  };
  return (
    <>
      {filterSec ? (
        <div className="flex items-center flex-wrap justify-between px-[24px] ">
          <div className="py-[16px]">
            <div
              style={{ maxWidth: "30rem", height: "32px" }}
              className={`w-full  flex items-center border-radius bg-[var(--head-input-bg-color)]    ${
                isFocused
                  ? "border border-[var(--head-input-focus-border-color)] "
                  : "border border-[var(--head-input-border-color)] "
              }`}
            >
              <Select
                className="border-0 bg-[var(--head-input-bg-color)] z-50 text-[var(--head-input-fg-color)] text-[length:--head-input-font-size] font-[var(--head-input-font-weight)] placeholder:text-[var(--head-input-placeholder-color)] placeholder:text-[length:--head-input-font-size] placeholder:font-[var(--head-input-place-font-weight)]"
                // menuPortalTarget={document.body}
                styles={customStyles}
                value={
                  filterData?.selectColumn
                    ? tableHeading?.find(
                        (item) => item?.value === filterData?.selectColumn
                      )
                      ? {
                          ...tableHeading?.find(
                            (item) => item?.value === filterData?.selectColumn
                          ),
                          label: capitalizeWords(
                            tableHeading?.find(
                              (item) => item?.value === filterData?.selectColumn
                            )?.label
                          ),
                          // Capitalize first letter of each word
                        }
                      : null
                    : null
                }
                onChange={(e) => {
                  handleSelectChange(e);
                  setIsFocused(true);
                  if (searchRef && searchRef?.current) {
                    searchRef?.current?.focus();
                  }
                }}
                options={tableHeading
                  ?.filter((item) => item?.column)
                  ?.map((item) => ({
                    ...item,
                    label: capitalizeWords(item?.label),
                    // Capitalize first letter
                  }))}
                placeholder="Select Column"
                isSearchable={false}
              />
              <div
                className={`h-full w-0.5 ${
                  isFocused
                    ? "border-r border-r-focus-color"
                    : "border-r border-r-D0D5DD"
                }`}
              ></div>{" "}
              <input
                ref={searchRef}
                autoFocus={true}
                onBlur={() => setIsFocused(false)}
                onFocus={() => setIsFocused(true)}
                placeholder={`${placeholder}`}
                className="text-[var(--head-input-fg-color)] bg-[var(--head-input-bg-color)] text-[length:--head-input-font-size] font-[var(--head-input-font-weight)] w-full rounded-md  h-full placeholder:text-[var(--head-input-placeholder-color)] placeholder:text-[length:--head-input-font-size] placeholder:font-[var(--head-input-place-font-weight)] px-[8px] focus_outline__none"
                type="text"
                // value={filterData?.inputValue}
                value={searchTerm}
                onChange={handleChange}
              />
              <span className="pr-2">
                <img
                  style={{
                    height: "16px",
                    width: "16px",
                  }}
                  src={search}
                  alt="search icon"
                />
              </span>
            </div>
          </div>

          <div className={"flex items-center gap-3 py-[16px]"}>
            {" "}
            {date && (
              <div
                style={{
                  width: "fit-content",
                  height: "32px",
                }}
                className=" flex items-center gap-2"
              >
                <>
                  {" "}
                  <DatePickerInput
                    placeholder="From Date"
                    onChange={(date) => {
                      console.log(date, "dsfssfdssfsfs");
                      setFilterData({ ...filterData, from: date });
                      paginationProps.setCurrentPage(1);
                    }}
                    value={filterData?.from}
                  />
                  <DatePickerInput
                    placeholder="To Date"
                    onChange={(date) => {
                      setFilterData({ ...filterData, to: date });
                      paginationProps.setCurrentPage(1);
                    }}
                    value={filterData?.to}
                  />
                </>
              </div>
            )}
            {filter && (
              <div ref={filterPopupRef} className="relative">
                <AddButton
                  className="flex items-center px-[12px] h-[32px] whitespace-nowrap border-radius  gap-[8px] bg-[var(--header-button-bg-color)]  cursor-pointer text-[var(--header-button-fg-color)] border border-[var(--header-button-border-color)] hover:bg-[var(--header-button-hover-color)]"
                  icon={<FilterIcon />}
                  onclkFn={filterFn}
                  text={filterText}
                />
                {filterBy}
              </div>
            )}
            <AddButton
              isDisabled={
                JSON.stringify(filterData) === JSON.stringify(initialFilter)
              }
              icon={
                <CloseIcon
                  width="14px"
                  height="14px"
                  fill={
                    JSON.stringify(filterData) === JSON.stringify(initialFilter)
                      ? "var(--header-button-disabled-fg-color)"
                      : "var(--header-button-fg-color)"
                  }
                />
              }
              className={` h-[32px] border border-[var(--header-button-border-color)]  text-[length:--head-button-font-size] font-[var(--head-button-font-weight)] whitespace-nowrap  border-radius  flex items-center  gap-[8px] px-[12px] ${
                JSON.stringify(filterData) === JSON.stringify(initialFilter)
                  ? "bg-[var(--header-button-disabled-bg-color)] cursor-not-allowed  text-[var(--header-button-disabled-fg-color)]"
                  : " bg-[var(--header-button-bg-color)]  cursor-pointer text-[var(--header-button-fg-color)]"
              }`}
              onclkFn={() => {
                setFilterData(initialFilter);
                paginationProps.setCurrentPage(1);
                if (searchRef && searchRef?.current) {
                  searchRef?.current?.focus();
                }
              }}
              text="Clear All"
            />
            {invoiceItems?.length > 0 && (
              <SelectOption options={options} handleSelect={handleSelect} />
            )}
            {(permissionFn || defaultPermissionFn)(module)?.create && list && (
              <AddButton
                icon={<PlusIcon fill="var(--primary-button-fg-color)" />}
                className="border-radius hover:bg-[var(--primary-button-hover-color)] flex justify-center gap-[8px] h-[32px] px-[12px]  items-center   whitespace-nowrap   transition duration-300 ease-in-out   text-[length:--primary-button-font-size] font-[var(--primary-button-font-weight)] bg-[var(--primary-button-bg-color)]  cursor-pointer text-[var(--primary-button-fg-color)]"
                onclkFn={onclkFn}
                text={newBtnTxt}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-end px-[24px] py-[16px]">
          <></>
          {(permissionFn || defaultPermissionFn)(module)?.create && list && (
            <AddButton
              icon={<PlusIcon fill="var(--primary-button-fg-color)" />}
              className="border-radius hover:bg-[var(--primary-button-hover-color)] flex justify-center gap-[8px] h-[32px] px-[12px]  items-center   whitespace-nowrap   transition duration-300 ease-in-out   text-[length:--primary-button-font-size] font-[var(--primary-button-font-weight)] bg-[var(--primary-button-bg-color)]  cursor-pointer text-[var(--primary-button-fg-color)]     "
              onclkFn={onclkFn}
              text={newBtnTxt}
            />
          )}
        </div>
      )}
    </>
  );
};

export default FilterSection;
export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    // fontSize: "12px",
    fontWeight: "600",
    zIndex: 99,
    backgroundColor: "var(--head-input-bg-color)",
    color: "#344054",
    width: "10rem",
    minHeight: "25px",
    boxShadow: "none", // Remove box shadow
    border: "none",
    borderRadius: "6px",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "var(--head-input-placeholder-color)",
      // fontFamily: "Inter,sans-serif",
      fontSize: "var(--head-input-font-size)",
      fontWeight: "var(--head-input-place-font-weight)",
      // opacity: "1",
      // position: "absolute",
      // whiteSpace: "nowrap",
    };
  },

  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Hide indicator separator
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: "400",
    color: "var(--head-input-fg-color)",
    // fontSize: "12px",
  }),
  option: (provided, state) => {
    return {
      ...provided,
      ...(state.data.color ? { color: state.data.color } : { color: "black" }),
      backgroundColor: state.isSelected ? "#e5ecffb2" : null,
      borderBottom: "1px solid #F5F5F5",
      fontSize: "12px",
      zIndex: 99,
      fontFamily: "Inter,sans-serif",
      "&:hover": {
        backgroundColor: state.isFocused ? "#0000002E" : "#0000002E",
      },
    };
  },

  indicatorContainer: (provided) => ({
    ...provided,
    padding: 0, // Remove padding
  }),
  dropdownIndicator: (provided) => ({
    ...provided,

    fontSize: "14px",
    padding: 0, // Removes padding from the dropdown icon
    fontWeight: "400",
  }),
};
