import { call, takeLatest } from "redux-saga/effects";
import {
  GL_ACC_LIST,
  PAYMENT_IN_CREATE,
  PAYMENT_IN_TABLE,
  PAYMENT_IN_VIEW,
  SALES_CREATE,
  SALES_CREDIT_EDIT,
  SALES_CREDIT_RECEIPT_CREATE,
  SALES_CREDIT_TABLE,
  SALES_CREDIT_VIEW,
  SALES_CREDIT_VOID,
  SALES_DELIVERY_EDIT,
  SALES_DELIVERY_ITEMS_GET,
  SALES_DELIVERY_RECEIPT_CREATE,
  SALES_DELIVERY_TABLE,
  SALES_DELIVERY_VIEW,
  SALES_DELIVERY_VOID,
  SALES_EDIT,
  SALES_INVOICE_EDIT,
  SALES_INVOICE_ITEMS_GET,
  SALES_INVOICE_RECEIPT_CREATE,
  SALES_INVOICE_TABLE,
  SALES_INVOICE_VIEW,
  SALES_INVOICE_VOID,
  SALES_ORDER_ITEMS_GET,
  SALES_PAYMENT_IN_CUS,
  SALES_PRODUCT_DETAILS,
  SALES_PRODUCT_LIST,
  SALES_RETURN_EDIT,
  SALES_RETURN_ITEMS_GET,
  SALES_RETURN_RECEIPT_CREATE,
  SALES_RETURN_TABLE,
  SALES_RETURN_VIEW,
  SALES_RETURN_VOID,
  SALES_TABLE,
  SALES_VIEW,
  SALES_VOID,
} from "../types";

import {
  glAccListApi,
  paymentInCreateApi,
  paymentInTableApi,
  paymentInViewApi,
  salesCreateApi,
  salesCreditEditApi,
  salesCreditReceiptCreateApi,
  salesCreditTableApi,
  salesCreditViewApi,
  salesCreditVoidApi,
  salesDeliveryEditApi,
  salesDeliveryItemsGetApi,
  salesDeliveryReceiptCreateApi,
  salesDeliveryTableApi,
  salesDeliveryViewApi,
  salesDeliveryVoidApi,
  salesEditApi,
  salesInvoiceEditApi,
  salesInvoiceItemsGetApi,
  salesInvoiceReceiptCreateApi,
  salesInvoiceTableApi,
  salesInvoiceViewApi,
  salesInvoiceVoidApi,
  salesOrderItemsGetApi,
  salesPaymentInCusApi,
  salesProductDetailsApi,
  salesProductListApi,
  salesReturnEditApi,
  salesReturnItemsGetApi,
  salesReturnReceiptCreateApi,
  salesReturnTableApi,
  salesReturnViewApi,
  salesReturnVoidApi,
  salesTableApi,
  salesViewApi,
  salesVoidApi,
} from "../apis/sales-api";

export function* salesCreateWorker(action) {
  try {
    const res = yield call(salesCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* salesEditWorker(action) {
  try {
    const res = yield call(salesEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* salesTableWorker(action) {
  try {
    const res = yield call(salesTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* salesProductListWorker(action) {
  try {
    const res = yield call(salesProductListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* salesViewWorker(action) {
  try {
    const res = yield call(salesViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* salesVoidWorker(action) {
  try {
    const res = yield call(salesVoidApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* salesOrderItemsGetWorker(action) {
  try {
    const res = yield call(salesOrderItemsGetApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* salesDeliveryReceiptCreateWorker(action) {
  try {
    const res = yield call(salesDeliveryReceiptCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* salesDeliveryTableWorker(action) {
  try {
    const res = yield call(salesDeliveryTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* salesDeliveryViewWorker(action) {
  try {
    const res = yield call(salesDeliveryViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* salesDeliveryEditWorker(action) {
  try {
    const res = yield call(salesDeliveryEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* salesDeliveryVoidWorker(action) {
  try {
    const res = yield call(salesDeliveryVoidApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* salesDeliveryItemsGetWorker(action) {
  try {
    const res = yield call(salesDeliveryItemsGetApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
// SALES INVOICE
export function* salesInvoiceReceiptCreateWorker(action) {
  try {
    const res = yield call(salesInvoiceReceiptCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* salesInvoiceTableWorker(action) {
  try {
    const res = yield call(salesInvoiceTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* salesInvoiceViewWorker(action) {
  try {
    const res = yield call(salesInvoiceViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* salesInvoiceEditWorker(action) {
  try {
    const res = yield call(salesInvoiceEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* salesInvoiceVoidWorker(action) {
  try {
    const res = yield call(salesInvoiceVoidApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* salesInvoiceItemsGetWorker(action) {
  try {
    const res = yield call(salesInvoiceItemsGetApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
// SALES RETURN
export function* salesReturnReceiptCreateWorker(action) {
  try {
    const res = yield call(salesReturnReceiptCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* salesReturnTableWorker(action) {
  try {
    const res = yield call(salesReturnTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* salesReturnViewWorker(action) {
  try {
    const res = yield call(salesReturnViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* salesReturnEditWorker(action) {
  try {
    const res = yield call(salesReturnEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* salesReturnVoidWorker(action) {
  try {
    const res = yield call(salesReturnVoidApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* salesReturnItemsGetWorker(action) {
  try {
    const res = yield call(salesReturnItemsGetApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
// SALES CREDIT
export function* salesCreditReceiptCreateWorker(action) {
  try {
    const res = yield call(salesCreditReceiptCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* salesCreditTableWorker(action) {
  try {
    const res = yield call(salesCreditTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* salesCreditViewWorker(action) {
  try {
    const res = yield call(salesCreditViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* salesCreditEditWorker(action) {
  try {
    const res = yield call(salesCreditEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* salesCreditVoidWorker(action) {
  try {
    const res = yield call(salesCreditVoidApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* salesPaymentInCusWorker(action) {
  try {
    const res = yield call(salesPaymentInCusApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* paymentInTableWorker(action) {
  try {
    const res = yield call(paymentInTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* PaymentInCreateWorker(action) {
  try {
    const res = yield call(paymentInCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* glAccListWorker(action) {
  try {
    const res = yield call(glAccListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* paymentInViewWorker(action) {
  try {
    const res = yield call(paymentInViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* salesProductDetailsWorker(action) {
  try {
    const res = yield call(salesProductDetailsApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* paymentInViewWatcher() {
  yield takeLatest(PAYMENT_IN_VIEW, paymentInViewWorker);
}
export function* glAccListWatcher() {
  yield takeLatest(GL_ACC_LIST, glAccListWorker);
}
export function* PaymentInCreateWatcher() {
  yield takeLatest(PAYMENT_IN_CREATE, PaymentInCreateWorker);
}

export function* paymentInTableWatcher() {
  yield takeLatest(PAYMENT_IN_TABLE, paymentInTableWorker);
}
export function* salesPaymentInCusWatcher() {
  yield takeLatest(SALES_PAYMENT_IN_CUS, salesPaymentInCusWorker);
}
export function* salesViewWatcher() {
  yield takeLatest(SALES_VIEW, salesViewWorker);
}
export function* salesVoidWatcher() {
  yield takeLatest(SALES_VOID, salesVoidWorker);
}
export function* salesCreateWatcher() {
  yield takeLatest(SALES_CREATE, salesCreateWorker);
}
export function* salesEditWatcher() {
  yield takeLatest(SALES_EDIT, salesEditWorker);
}
export function* salesTableWatcher() {
  yield takeLatest(SALES_TABLE, salesTableWorker);
}
export function* salesProductListWatcher() {
  yield takeLatest(SALES_PRODUCT_LIST, salesProductListWorker);
}
export function* salesOrderItemsGetWatcher() {
  yield takeLatest(SALES_ORDER_ITEMS_GET, salesOrderItemsGetWorker);
}
export function* salesDeliveryTableWatcher() {
  yield takeLatest(SALES_DELIVERY_TABLE, salesDeliveryTableWorker);
}

export function* salesDeliveryReceiptCreateWatcher() {
  yield takeLatest(
    SALES_DELIVERY_RECEIPT_CREATE,
    salesDeliveryReceiptCreateWorker
  );
}

export function* salesDeliveryViewWatcher() {
  yield takeLatest(SALES_DELIVERY_VIEW, salesDeliveryViewWorker);
}
export function* salesDeliveryVoidWatcher() {
  yield takeLatest(SALES_DELIVERY_VOID, salesDeliveryVoidWorker);
}
export function* salesDeliveryEditWatcher() {
  yield takeLatest(SALES_DELIVERY_EDIT, salesDeliveryEditWorker);
}
export function* salesDeliveryItemsGetWatcher() {
  yield takeLatest(SALES_DELIVERY_ITEMS_GET, salesDeliveryItemsGetWorker);
}
// SALES INVOICE
export function* salesInvoiceTableWatcher() {
  yield takeLatest(SALES_INVOICE_TABLE, salesInvoiceTableWorker);
}

export function* salesInvoiceReceiptCreateWatcher() {
  yield takeLatest(
    SALES_INVOICE_RECEIPT_CREATE,
    salesInvoiceReceiptCreateWorker
  );
}

export function* salesInvoiceViewWatcher() {
  yield takeLatest(SALES_INVOICE_VIEW, salesInvoiceViewWorker);
}
export function* salesInvoiceEditWatcher() {
  yield takeLatest(SALES_INVOICE_EDIT, salesInvoiceEditWorker);
}
export function* salesInvoiceVoidWatcher() {
  yield takeLatest(SALES_INVOICE_VOID, salesInvoiceVoidWorker);
}
export function* salesInvoiceItemsGetWatcher() {
  yield takeLatest(SALES_INVOICE_ITEMS_GET, salesInvoiceItemsGetWorker);
}
// SALES RETURN
export function* salesReturnTableWatcher() {
  yield takeLatest(SALES_RETURN_TABLE, salesReturnTableWorker);
}

export function* salesReturnReceiptCreateWatcher() {
  yield takeLatest(SALES_RETURN_RECEIPT_CREATE, salesReturnReceiptCreateWorker);
}

export function* salesReturnViewWatcher() {
  yield takeLatest(SALES_RETURN_VIEW, salesReturnViewWorker);
}
export function* salesReturnEditWatcher() {
  yield takeLatest(SALES_RETURN_EDIT, salesReturnEditWorker);
}
export function* salesReturnVoidWatcher() {
  yield takeLatest(SALES_RETURN_VOID, salesReturnVoidWorker);
}
export function* salesReturnItemsGetWatcher() {
  yield takeLatest(SALES_RETURN_ITEMS_GET, salesReturnItemsGetWorker);
}
// SALES CREDIT
export function* salesCreditTableWatcher() {
  yield takeLatest(SALES_CREDIT_TABLE, salesCreditTableWorker);
}

export function* salesCreditReceiptCreateWatcher() {
  yield takeLatest(SALES_CREDIT_RECEIPT_CREATE, salesCreditReceiptCreateWorker);
}

export function* salesCreditViewWatcher() {
  yield takeLatest(SALES_CREDIT_VIEW, salesCreditViewWorker);
}
export function* salesCreditVoidWatcher() {
  yield takeLatest(SALES_CREDIT_VOID, salesCreditVoidWorker);
}
export function* salesCreditEditWatcher() {
  yield takeLatest(SALES_CREDIT_EDIT, salesCreditEditWorker);
}

export function* salesProductDetailsWatcher() {
  yield takeLatest(SALES_PRODUCT_DETAILS, salesProductDetailsWorker);
}
