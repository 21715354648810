import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditIcon, {
  PrintIcon,
  ShareIcon,
  VoidIcon,
} from "../../assets/icons/action-icons";
import KibabMenu from "../../assets/icons/kibab-menu";
import FilterSection from "./filtering-section2";
import Pagination from "./pagination1";
import "./table.css";
import ShareTransactionModal from "../helper-components/share-popup";
import SortingIcons1 from "../../assets/icons/arrowup-down";
import { ChevronRight } from "../../assets/icons/chevron-down-right";
import { goodsViewAction } from "../../redux/actions/purchase-action";
import ChevronDown from "../../assets/icons/chevron-down-right";
import LoadingSpinner from "../helper-components/loading-spinner";
import { handlea4Print } from "../helper-components/helper-function";
import errorToast from "../helper-components/error-toast";
import successToast from "../helper-components/success-toast";
import DeleteIcon from "../../assets/icons/delete-icon";
const DynamicTransactionTable = ({
  tableHeading,
  tableBodyData = [],
  paginationProps,
  filterData,
  setFilterData,
  placeholder,
  filterFn = () => {},
  filterBy,
  showFilter,
  label = "Invoice",
  initialFilter,
  reload,
  setReload,
  setLoading,
  deleteAction = () => {},
  id_name = "cmr_id",
  path = "invoice",
  filterText,
  date,
  module,
  permissionFn = () => {},
  filterSec = true,
  menu = true,
  filter = true,
  onclkFn,
  list,
  edit = true,
  onClickItemFn = () => {},
  usePassedComp = false,
  passedCompFn = () => {},
  newBtnTxt = "New",
  transaction = "",
  transaction_type = "",
  is_checkbox_req = true,
  invoicePrntFn = () => {},
  // for sub
  tableHeading1,
  orders,
  setOrders,
  options = [],
  handleSelect = () => {},
  setInvoiceItems,
  invoiceItems,
  ViewAction = goodsViewAction,
  transactionKey = "gort",
  actionsPermissions = {
    edit: ["draft"],
    delete: ["open", "draft"],
    send: ["open", "draft"],
    print: ["open", "draft"],
  },
  cloneFn = () => {},
}) => {
  console.log(tableBodyData, "dfsdfsdfsdfsdfsdfs");
  const [currentOrder, setCurrentOrder] = useState(null);
  const [itemLoading, setItemLoading] = useState(false);
  const defaultPermissionFn = () => {};
  const [expandedRows, setExpandedRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState("");
  const [checkedId, setCheckedId] = useState([]);
  const navigate = useNavigate();
  const [action, setAction] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const dispatch = useDispatch();
  const isItemChecked = (rowData) => {
    return invoiceItems.some(
      (item) =>
        item[`${transactionKey}_id`] === rowData.id &&
        item.cmr_id === rowData.cmr_id &&
        Object.keys(item).length === 2
    );
  };
  function sortFn(item) {
    let direction = "ASC";
    if (
      filterData != null &&
      filterData?.sort_by === item &&
      filterData?.sort_order === "ASC"
    ) {
      direction = "DESC";
    }
    setFilterData({ ...filterData, sort_by: item, sort_order: direction });
  }

  function checkboxChecker(e, all) {
    if (all === "all" && checkedId.length === e.length) {
      setCheckedId([]);
    } else if (all === "all") {
      setCheckedId(e);
    } else {
      if (checkedId.includes(e?.target?.id)) {
        const checkedVAlues = [...checkedId];
        const findIndex = checkedVAlues.findIndex(
          (item) => item === e.target?.id
        );
        checkedVAlues.splice(findIndex, 1);
        setCheckedId(checkedVAlues);
      } else {
        setCheckedId((previous) => {
          return [...previous, e.target.id];
        });
      }
    }
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        action &&
        currentIndex != null &&
        !event.target.closest(".relative")
      ) {
        setAction(false);
        setCurrentIndex(null);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [action, currentIndex]);

  const handleDeleteAction = (id) => {
    setLoading(true);
    dispatch(
      deleteAction(
        {
          [id_name]: id,
        },
        onSuccessDel,
        onErrorDel
      )
    );
  };

  const onSuccessDel = () => {
    setReload(!reload);
    setLoading(false);
  };
  const onErrorDel = () => {
    setLoading(false);
  };
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight = document.querySelector(".head-div")?.clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const handleCheckboxChange = (e, rowData) => {
    if (e.target.checked) {
      setInvoiceItems((prevItems) => [
        ...prevItems,
        {
          [`${transactionKey}_id`]: rowData.id,
          cmr_id: rowData.cmr_id,
        },
      ]);
    } else {
      setInvoiceItems((prevItems) =>
        prevItems.filter(
          (item) =>
            !(
              item[`${transactionKey}_id`] === rowData.id &&
              item.cmr_id === rowData.cmr_id &&
              Object.keys(item).length === 2
            )
        )
      );
    }
  };

  const toggleRow = (index, id) => {
    if (
      invoiceItems?.some((item) => item[`${transactionKey}_id`] === id) &&
      Object.keys(
        invoiceItems?.find((item) => item[`${transactionKey}_id`] === id)
      ).length === 2
    ) {
      setInvoiceItems(
        invoiceItems?.filter((item) => item[`${transactionKey}_id`] !== id)
      );
    }
    if (
      invoiceItems?.some((item) => item[`${transactionKey}_id`] === id) &&
      Object.keys(
        invoiceItems?.find((item) => item[`${transactionKey}_id`] === id)
      )?.length === 3
    ) {
      setInvoiceItems(
        invoiceItems?.filter((item) => item[`${transactionKey}_id`] !== id)
      );
    }
    setItemLoading(true);
    if (expandedRows.includes(index)) {
      setExpandedRows((prevRows) => prevRows.filter((row) => row !== index));
    } else {
      setExpandedRows((prevRows) => [...prevRows, index]);
      dispatch(
        ViewAction(
          id,
          (data) => {
            setOrders((prevOrders) =>
              prevOrders.map((order) =>
                order[`${transactionKey}_id`] === id
                  ? {
                      ...order,
                      itemData:
                        data?.data?.[0]?.itemData || data?.data?.itemData,
                    }
                  : order
              )
            );
            setItemLoading(false);
          },
          onErrPur
        )
      );
    }
  };
  const onErrPur = (data) => {
    setItemLoading(false);
  };

  const removeItemData = (id) => {
    const order = orders.find((item) => item[`${transactionKey}_id`] === id);
    if (order) {
      const updatedOrders = orders.map((item) => {
        if (item[`${transactionKey}_id`] === id) {
          return {
            ...item,
            itemData: [],
          };
        }
        return item;
      });
      setOrders(updatedOrders);
    }
  };

  function itemsChecker(e, rowData) {
    const id = e.target.id;
    if (e.target.checked) {
      setInvoiceItems((prevItems) => [
        ...prevItems,
        {
          [`${transactionKey}_id`]: rowData.id,
          item_id: id,
          cmr_id: rowData.cmr_id,
        },
      ]);
    } else {
      setInvoiceItems((prevItems) =>
        prevItems.filter(
          (item) =>
            !(
              item[`${transactionKey}_id`] === rowData.id &&
              item.item_id === id &&
              item.cmr_id === rowData.cmr_id
            )
        )
      );
    }
  }

  // function itemsChecker(e, rowData, row_item_data = null) {
  //   const isChecked = e.target.checked;
  //   setInvoiceItems((prevItems) => {
  //     if (row_item_data) {
  //       // Handling individual row item selection
  //       if (isChecked) {
  //         return [
  //           ...prevItems,
  //           {
  //             sot_id: rowData.id,
  //             item_id: row_item_data.item_id,
  //             cmr_id: rowData.cmr_id,
  //             item_batch_number: row_item_data.item_batch_number,
  //             item_rack_location: row_item_data.item_rack_location,
  //           },
  //         ];
  //       } else {
  //         return prevItems.filter(
  //           (item) =>
  //             !(
  //               item.sot_id === rowData.id &&
  //               item.item_id === row_item_data.item_id &&
  //               item.cmr_id === rowData.cmr_id &&
  //               item.item_batch_number === row_item_data.item_batch_number &&
  //               item.item_rack_location === row_item_data.item_rack_location
  //             )
  //         );
  //       }
  //     } else {
  //       // Handling bulk selection (Header Checkbox)
  //       if (isChecked) {
  //         // Select all items
  //         return [
  //           ...prevItems,
  //           ...rowData?.itemData?.map((item) => ({
  //             sot_id: rowData.id,
  //             item_id: item.item_id,
  //             cmr_id: rowData.cmr_id,
  //             item_batch_number: item.item_batch_number,
  //             item_rack_location: item.item_rack_location,
  //           })),
  //         ];
  //       } else {
  //         // Deselect all items
  //         return prevItems.filter(
  //           (item) =>
  //             !rowData?.itemData?.some(
  //               (rowItem) =>
  //                 item.sot_id === rowData.id &&
  //                 item.item_id === rowItem.item_id &&
  //                 item.cmr_id === rowData.cmr_id &&
  //                 item.item_batch_number === rowItem.item_batch_number &&
  //                 item.item_rack_location === rowItem.item_rack_location
  //             )
  //         );
  //       }
  //     }
  //   });
  // }

  function printFn(id) {
    handlea4Print(id, module, dispatch);
  }

  function voidFn(id) {
    dispatch(
      deleteAction(
        {
          [`${transactionKey}_id`]: id,
        },
        (data) => {
          setReload(!reload);
          successToast(data?.message);
          setItemLoading(false);
        },
        (error) => {
          errorToast(
            <p>
              {error?.data?.message}
              {error?.data?.data?.map((invoice) => (
                <div>
                  {invoice?.poit_invoice_number ?? invoice?.prt_invoice_number}
                </div>
              ))}
            </p>
          );
        }
      )
    );
  }

  console.log(tableBodyData, "dfgdfgdfgdfgdfgdfgdfgd");

  return (
    <>
      <div className="head-div">
        <FilterSection
          newBtnTxt={newBtnTxt}
          list={list}
          filterSec={filterSec}
          onclkFn={onclkFn}
          module={module}
          permissionFn={permissionFn}
          filter={filter}
          date={date}
          filterText={filterText}
          paginationProps={paginationProps}
          showFilter={showFilter}
          placeholder={placeholder}
          filterFn={filterFn}
          tableHeading={
            module === "stock_transfer"
              ? [
                  ...tableHeading,
                  {
                    label: "ITEM NAME",
                    value: "item_name",
                    display: false,
                    column: true,
                  },
                ]
              : tableHeading
          }
          filterData={filterData}
          setFilterData={setFilterData}
          filterBy={filterBy}
          initialFilter={initialFilter}
          // forSUB
          options={options}
          invoiceItems={invoiceItems}
          handleSelect={handleSelect}
        />
      </div>

      <div
        style={{
          height: `calc(100% - ${heightOfFirstDiv}px)`,
        }}
        className="flex flex-col justify-between  px-[24px]"
      >
        <div
          style={{
            height: `calc(100% - 50px)`,
          }}
          className="tab-container outline outline-[var(--list-table-border-color)] outline-1 border-radius"
        >
          <table className="w-full border-collapse mt-0">
            <thead className="sticky top-0 bg-[var(--list-table-heading-bg)] z-[10]">
              <tr className="relative ">
                {is_checkbox_req && (
                  <th className={`whitespace-nowrap tab-header`}>
                    {/* <input
                      checked={
                        checkedId?.length ===
                          tableBodyData?.map((item) => item?.id).length &&
                        checkedId.length > 0
                      }
                      onChange={() =>
                        checkboxChecker(
                          tableBodyData.map((item) => item?.id),
                          "all"
                        )
                      }
                      style={{ transform: "scale(1.1)" }}
                      type="checkbox"
                      id="myCheckbox"
                      className="custom-checkbox form-check-input cursor-pointer"
                    /> */}
                  </th>
                )}

                {tableHeading.map((heading, index) => (
                  <th
                    key={index}
                    className={`${"tab-header"} whitespace-nowrap text-[var(--list-table-heading-fg-color)]  text-[length:--list-table-heading-font-size] font-[var(--list-table-heading-font-weight)] h-[50px]`}
                  >
                    <span
                      className={`flex items-center gap-2 ${heading?.className}`}
                    >
                      {heading?.label}
                      {heading?.display && (
                        <button onClick={() => sortFn(heading?.value)}>
                          {" "}
                          <SortingIcons1
                            inc={
                              filterData?.sort_order === "ASC" &&
                              heading?.value === filterData?.sort_by
                                ? "var(--list-table-heading-fg-color)"
                                : "var(--list-table-heading-fg-op-50-color)"
                            }
                            dec={
                              filterData?.sort_order === "DESC" &&
                              heading?.value === filterData?.sort_by
                                ? "var(--list-table-heading-fg-color)"
                                : "var(--list-table-heading-fg-op-50-color)"
                            }
                          />
                        </button>
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="h-full">
              {tableBodyData?.length > 0 ? (
                <>
                  {tableBodyData.map((rowData, rowIndex) => (
                    <>
                      {" "}
                      <tr
                        className={`hover:bg-[var(--list-table-row-hover-bg)] bg-[var(--list-table-row-bg)] ${
                          checkedId.includes(rowData?.id) &&
                          "bg-D5E6Ff hover:bg-[var(--list-table-row-hover-bg)]"
                        } h-[55px] `}
                        key={rowIndex}
                      >
                        {is_checkbox_req && (
                          <td className="tab-cell  text-[length:--list-table-row-font-size] font-[var(--list-table-row-font-weight)] text-[var(--list-table-row-fg-color)] whitespace-nowrap cursor-pointer gap-[8px] ">
                            <div className="flex items-center gap-[8px] ">
                              <input
                                disabled={
                                  invoiceItems.some(
                                    (invoice) =>
                                      invoice.cmr_id !== rowData.cmr_id
                                  ) ||
                                  expandedRows.includes(rowIndex) ||
                                  rowData?.[
                                    `${transactionKey}_order_status`
                                  ] === "closed" ||
                                  rowData?.[
                                    `${transactionKey}_order_status`
                                  ] === "cancelled" ||
                                  rowData?.[
                                    `${transactionKey}_order_status`
                                  ] === "draft"
                                }
                                checked={isItemChecked(rowData)}
                                id={rowData?.id}
                                onChange={(e) =>
                                  handleCheckboxChange(e, rowData)
                                }
                                // style={{ transform: "scale(1.2" }}
                                type="checkbox"
                                className="custom-checkbox form-check-input text-[length:--list-table-row-icon-size]"
                              />

                              {expandedRows.includes(rowIndex) ? (
                                <button
                                  onClick={() => {
                                    removeItemData(rowData?.id);
                                    toggleRow(rowIndex, rowData?.id);
                                  }}
                                >
                                  <ChevronDown fill="black" />
                                </button>
                              ) : (
                                <button
                                  disabled={
                                    invoiceItems.some(
                                      (invoice) =>
                                        invoice.cmr_id !== rowData.cmr_id
                                    ) ||
                                    rowData?.[
                                      `${transactionKey}_order_status`
                                    ] === "closed" ||
                                    rowData?.[
                                      `${transactionKey}_order_status`
                                    ] === "cancelled" ||
                                    rowData?.[
                                      `${transactionKey}_order_status`
                                    ] === "draft"
                                  }
                                  onClick={() => {
                                    toggleRow(rowIndex, rowData?.id);
                                    setCurrentOrder(rowIndex);
                                  }}
                                >
                                  <ChevronRight fill="black" />
                                </button>
                              )}
                            </div>
                          </td>
                        )}
                        {tableHeading.map((heading, index) =>
                          heading?.value ? (
                            <td
                              key={index}
                              onClick={() => onClickItemFn(rowData?.id)}
                              className={`tab-cell text-[length:--list-table-row-font-size] font-[var(--list-table-row-font-weight)] text-[var(--list-table-row-fg-color)] whitespace-nowrap cursor-pointer`}
                            >
                              {heading.value === "total_order_value"
                                ? rowData[heading.value]?.label
                                : rowData[heading.value]}
                            </td>
                          ) : null
                        )}

                        {menu && (
                          <td
                            className={`tab-cell text-[length:--list-table-row-font-size] font-[var(--list-table-row-font-weight)] text-[var(--list-table-row-fg-color)] whitespace-nowrap cursor-pointer`}
                          >
                            {(permissionFn(module)?.update ||
                              permissionFn(module)?.delete ||
                              permissionFn(module)?.print ||
                              permissionFn(module)?.send) && (
                              <div className="relative">
                                <button
                                  onClick={() => {
                                    setAction(!action);
                                    setCurrentIndex(rowIndex);
                                  }}
                                >
                                  <KibabMenu />
                                </button>
                                {action && currentIndex === rowIndex ? (
                                  <div
                                    style={{
                                      minWidth: "13rem",
                                      zIndex: 999,
                                      top: "25px",
                                      right: "35px",
                                      boxShadow: `0px 1px 3px 0px var(--popover-shadow-color)`,
                                    }}
                                    className="absolute  border border-[var(--popover-border-color)] border-solid border-1 top-5 right-0  py-2 px-2  bg-[var(--popover-bg-color)]  rounded  z-10 flex flex-col"
                                  >
                                    {permissionFn(module)?.update &&
                                      actionsPermissions?.edit?.includes(
                                        rowData?.[
                                          `${transactionKey}_order_status`
                                        ]
                                      ) && (
                                        <button
                                          onClick={() => {
                                            navigate(
                                              `/edit-${path}/${rowData?.id}`
                                            );
                                          }}
                                          className="flex items-center text-nowrap gap-2 text-[length:--popover-font-size] font-[var(--popover-font-weight)] text-[var(--popover-fg-color)] whitespace-nowrap cursor-pointer h-[35px] hover:bg-[var(--popover-hover-color)] border-radius px-[8px]"
                                        >
                                          <EditIcon fill="var(--popover-fg-color)" />
                                          Edit {transaction}
                                        </button>
                                      )}

                                    {permissionFn(module)?.send &&
                                      actionsPermissions?.send?.includes(
                                        rowData?.[
                                          `${transactionKey}_order_status`
                                        ]
                                      ) && (
                                        <button
                                          onClick={() => {
                                            setIsModalOpen(rowData?.id);
                                          }}
                                          className="flex items-center text-nowrap gap-2 text-[length:--popover-font-size] font-[var(--popover-font-weight)] text-[var(--popover-fg-color)] whitespace-nowrap cursor-pointer h-[35px] hover:bg-[var(--popover-hover-color)] border-radius px-[8px]"
                                        >
                                          <ShareIcon fill="var(--popover-fg-color)" />
                                          Share {transaction}
                                        </button>
                                      )}
                                    {permissionFn(module)?.print &&
                                      actionsPermissions?.print?.includes(
                                        rowData?.[
                                          `${transactionKey}_order_status`
                                        ]
                                      ) && (
                                        <button
                                          onClick={() => printFn(rowData?.id)}
                                          className="flex items-center text-nowrap gap-2 text-[length:--popover-font-size] font-[var(--popover-font-weight)] text-[var(--popover-fg-color)] whitespace-nowrap cursor-pointer h-[35px] hover:bg-[var(--popover-hover-color)] border-radius px-[8px]"
                                        >
                                          <PrintIcon fill="var(--popover-fg-color)" />
                                          Print {transaction}
                                        </button>
                                      )}
                                    {permissionFn(module)?.print &&
                                      actionsPermissions?.clone?.includes(
                                        rowData?.[
                                          `${transactionKey}_order_status`
                                        ]
                                      ) && (
                                        <button
                                          onClick={() => cloneFn(rowData?.id)}
                                          className="flex items-center text-nowrap gap-2 text-[length:--popover-font-size] font-[var(--popover-font-weight)] text-[var(--popover-fg-color)] whitespace-nowrap cursor-pointer h-[35px] hover:bg-[var(--popover-hover-color)] border-radius px-[8px]"
                                        >
                                          <ShareIcon fill="var(--popover-fg-color)" />
                                          Clone {transaction}
                                        </button>
                                      )}
                                    {permissionFn(module)?.delete &&
                                      actionsPermissions?.delete?.includes(
                                        rowData?.[
                                          `${transactionKey}_order_status`
                                        ]
                                      ) && (
                                        // rowData?.[
                                        //   `${transactionKey}_order_status`
                                        // ] === "open"
                                        <button
                                          onClick={() => {
                                            voidFn(rowData?.id);
                                          }}
                                          className="flex items-center text-nowrap gap-2 text-[length:--popover-font-size] font-[var(--popover-font-weight)]  text-[var(--error-color)] whitespace-nowrap cursor-pointer h-[35px] hover:bg-[var(--popover-hover-color)] border-radius px-[8px]"
                                        >
                                          <DeleteIcon
                                            fill="var(--error-color)"
                                            width="14px"
                                            height="14px"
                                          />
                                          Void {transaction}
                                        </button>
                                      )}
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </td>
                        )}
                      </tr>
                      {expandedRows.includes(rowIndex) && (
                        <>
                          {itemLoading && rowIndex === currentOrder ? (
                            <tr>
                              <td colSpan={tableHeading.length + 1}>
                                <LoadingSpinner />
                              </td>
                            </tr>
                          ) : (
                            <>
                              {rowData?.itemData?.length > 0 && (
                                <tr className="border-b border-b-[var(--list-table-row-border-color)]">
                                  <td colSpan={1}></td>
                                  <td
                                    className="p-0 m-0"
                                    colSpan={tableHeading.length}
                                  >
                                    <div className="border-collapse">
                                      <table className="w-full border-collapse mt-0">
                                        <thead className=" bg-[var(--list-table-heading-bg)] z-[10]">
                                          <tr className="border-l-[var(--list-table-border-color)] border-l">
                                            <th
                                              className={` w-[3rem] tab-header`}
                                            >
                                              {/* <input
                                                type="checkbox"
                                                className="custom-checkbox form-check-input"
                                                checked={rowData?.itemData?.every(
                                                  (row_item_data) =>
                                                    invoiceItems.some(
                                                      (item) =>
                                                        item.sot_id ===
                                                          rowData.id &&
                                                        item.item_id ===
                                                          row_item_data.item_id &&
                                                        item.cmr_id ===
                                                          rowData.cmr_id &&
                                                        item.item_batch_number ===
                                                          row_item_data.item_batch_number &&
                                                        item.item_rack_location ===
                                                          row_item_data.item_rack_location
                                                    )
                                                )}
                                                onChange={(e) => {
                                                  rowData?.itemData?.forEach(
                                                    (row_item_data) => {
                                                      itemsChecker(
                                                        e,
                                                        rowData,
                                                        row_item_data
                                                      );
                                                    }
                                                  );
                                                }}
                                          
                                              /> */}
                                            </th>
                                            {tableHeading1.map(
                                              (heading, index) => (
                                                <th
                                                  key={index}
                                                  className={` ${"tab-header"} whitespace-nowrap text-[var(--list-table-heading-fg-color)]  text-[length:--list-table-heading-font-size] font-[var(--list-table-heading-font-weight)] `}
                                                >
                                                  {heading?.label}
                                                </th>
                                              )
                                            )}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {rowData?.itemData?.map(
                                            (row_item_data, rowIndex) => {
                                              const isChecked =
                                                invoiceItems.some(
                                                  (item) =>
                                                    item[
                                                      `${transactionKey}_id`
                                                    ] === rowData.id &&
                                                    item.item_id ===
                                                      row_item_data.item_id &&
                                                    item.cmr_id ===
                                                      rowData.cmr_id
                                                );

                                              return (
                                                <tr
                                                  key={rowIndex}
                                                  className={
                                                    isChecked
                                                      ? "bg-C4EAB7 bg-opacity-60"
                                                      : "border-l-[var(--list-table-border-color)] border-l"
                                                  }
                                                >
                                                  {/* Checkbox Column */}
                                                  <td
                                                    className={`${
                                                      rowIndex ===
                                                      rowData?.itemData
                                                        ?.length -
                                                        1
                                                        ? ""
                                                        : "border-b border-b-[var(--list-table-row-border-color)]"
                                                    } py-[15px] px-[10px] h-[55px] text-[length:--list-table-row-font-size] font-[var(--list-table-row-font-weight)] text-[var(--list-table-row-fg-color)] whitespace-nowrap cursor-pointer`}
                                                  >
                                                    <input
                                                      checked={isChecked}
                                                      id={
                                                        row_item_data?.item_id
                                                      }
                                                      onChange={(e) =>
                                                        itemsChecker(e, rowData)
                                                      }
                                                      type="checkbox"
                                                      className="custom-checkbox form-check-input text-[length:--list-table-row-icon-size]"
                                                    />
                                                  </td>

                                                  {/* Dynamically Generate Table Data Based on tableHeading1 */}
                                                  {tableHeading1.map(
                                                    (heading, index) => (
                                                      <td
                                                        key={index}
                                                        className={`${
                                                          rowIndex ===
                                                          rowData?.itemData
                                                            ?.length -
                                                            1
                                                            ? ""
                                                            : "border-b border-b-[var(--list-table-row-border-color)]"
                                                        } py-[15px] px-[10px] h-[55px] text-[length:--list-table-row-font-size] font-[var(--list-table-row-font-weight)] text-[var(--list-table-row-fg-color)] cursor-pointer`}
                                                        style={{
                                                          maxWidth:
                                                            index <= 2
                                                              ? "10rem"
                                                              : "auto",
                                                        }}
                                                      >
                                                        {row_item_data[
                                                          heading.value
                                                        ] ?? "--"}
                                                      </td>
                                                    )
                                                  )}
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  ))}
                  <tr style={{ height: "10rem" }}></tr>
                </>
              ) : (
                <tr
                  style={{
                    height: "20rem",
                  }}
                >
                  <td
                    colSpan={tableHeading.length + 1}
                    className="text-center text-primary"
                  >
                    No {label + "'s"} found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination paginationProps={paginationProps} />
        <ShareTransactionModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          transaction={transaction}
          transaction_type={transaction_type}
        />
      </div>
    </>
  );
};

export default DynamicTransactionTable;
