import React from "react";
import { formatString } from "../helper-components/helper-function";
import { PaymentStatusIcon } from "../../assets/icons/check-icon";

const BadgesComponent = ({ status = "completed" }) => {
  return (
    <>
      {status === "completed" ? (
        <div
          className={`border border-[var(--success-color)] text-[var(--success-color)]  text-[length:--list-table-row-badges-font-size] font-[var(--list-table-row-font-weight)] border-radius px-2 py-1 w-fit`}
        >
          {formatString(status)}
        </div>
      ) : status === "draft" ? (
        <div
          className={`border border-[var(--draft-color)] text-[var(--draft-color)]  text-[length:--list-table-row-badges-font-size] font-[var(--list-table-row-font-weight)] border-radius px-2 py-1 w-fit`}
        >
          Saved as Draft
        </div>
      ) : status === "paid" ? (
        <div
          className={`bg-[var(--success-color)] text-[#ffffff] flex items-center gap-1  text-[length:--list-table-row-badges-font-size] font-[var(--list-table-row-font-weight)] border-radius px-2 py-1 w-fit`}
        >
          <PaymentStatusIcon status={status} />
          Paid
        </div>
      ) : status === "pending" ? (
        <div
          className={` text-[var(--error-color)] flex items-center gap-1  text-[length:--list-table-row-badges-font-size] font-[var(--list-table-row-font-weight)] border-radius px-2 py-1 w-fit`}
        >
          <PaymentStatusIcon status={status} />
          Pending
        </div>
      ) : status === "open" ? (
        <div
          className={`border border-[var(--draft-color)] text-[var(--draft-color)]  text-[length:--list-table-row-badges-font-size] font-[var(--list-table-row-font-weight)] border-radius px-2 py-1 w-fit`}
        >
          Open
        </div>
      ) : status === "closed" ? (
        <div
          className={`border border-[var(--success-color)] text-[var(--success-color)]  text-[length:--list-table-row-badges-font-size] font-[var(--list-table-row-font-weight)] border-radius px-2 py-1 w-fit`}
        >
          Closed
        </div>
      ) : status === "cancelled" ? (
        <div
          className={`border border-[var(--error-color)] text-[var(--error-color)] flex items-center gap-1  text-[length:--list-table-row-badges-font-size] font-[var(--list-table-row-font-weight)] border-radius px-2 py-1 w-fit`}
        >
          Cancelled
        </div>
      ) : status === "success" ? (
        <div
          className={`border border-[var(--success-color)] text-[var(--success-color)]  text-[length:--list-table-row-badges-font-size] font-[var(--list-table-row-font-weight)] border-radius px-2 py-1 w-fit`}
        >
          Success
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default BadgesComponent;
