import React, { useCallback, useEffect, useState } from "react";
import InnerLayout from "../../components/layouts/inner-layout";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { invoicePastAction } from "../../redux/actions/invoices-action";
import LoadingSpinner from "../../components/helper-components/loading-spinner";
import moment from "moment";
import InvoiceFilter from "../../components/tables/table-filter/invoice-filter";
import PastOrderDynamicTable from "../../components/tables/past-order-table";

const PastOrdersTable = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [pastorders, setPastOrders] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
    const [pageSize, setPageSize] = useState(10);;
  const [filterData, setFilterData] = useState({
    sort_by: "",
    sort_order: "",
    selectColumn: "",
    from: "",
    to: "",
    inputValue: "",
    order_status: "",
    payment_status: "",
  });
  useEffect(() => {
    dispatch(
      invoicePastAction(
        {
          pageNumber: currentPage,
          pageSize: pageSize,
          id,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, filterData]);
  const onSuccess = (data) => {
    setError(false);
    setLoading(false);
    setPastOrders(data?.data?.allItemsData ?? []);
    setDataCount(data?.data?.totalCount);
  };
  const onError = () => {
    setError(true);
    setLoading(false);
  };

  const navigate = useNavigate();
  const tableBodyData = pastorders?.map((item, index) => {
    return {
      id: item?.item_id + index,
      item_code: item?.item_code ?? "--",
      item_name: item?.item_name,
      item_unit_price: parseFloat(item?.item_unit_price).toFixed(2),
      created_date: moment(item?.created_date).format("MMM D, YYYY"),
    };
  });
  const paginationProps = {
    pageSize: pageSize,
    setPageSize: setPageSize,
    dataCount: dataCount,
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
  };
  const handleFilterClick = useCallback(() => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  }, []);

  const filterby = showFilter ? (
    <InvoiceFilter
      setCurrentPage={setCurrentPage}
      filterData={filterData}
      setFilterData={setFilterData}
      setShowFilter={setShowFilter}
    />
  ) : null;

  return (
    <InnerLayout
      phoneNumber={id}
      backBtn={true}
      list={false}
      onclkFn={() => navigate("/add-invoice")}
      title="Past Orders"
    > 
      {loading ? (
        <LoadingSpinner />
      ) : error ? (
        <></>
      ) : (
        <>
          <PastOrderDynamicTable
            id={id}
            pastorders={pastorders}
            showFilter={showFilter}
            filterFn={handleFilterClick}
            setFilterData={setFilterData}
            filterData={filterData}
            paginationProps={paginationProps}
            tableHeading={tableHeading}
            tableBodyData={tableBodyData}
            placeholder="Search"
            filterBy={filterby}
            initialFilter={{
              sort_by: "",
              sort_order: "",
              selectColumn: "",
              from: "",
              to: "",
              inputValue: "",
              order_status: "",
              payment_status: "",
            }}
          />
        </>
      )}
    </InnerLayout>
  );
};

export default PastOrdersTable;
const tableHeading = [
  {
    label: "ITEM CODE",
    value: "sot_invoice_number",
    display: true,
    column: true,
  },
  // { label: "GENERIC NAME", value: "update_date", display: true, column: false },
  {
    label: "PRODUCT NAME",
    value: "sot_payment_status",
    display: false,
    column: true,
  },
  {
    label: "AMOUNT",
    value: "order_status",
    display: false,
    column: false,
  },
  {
    label: "ORDERED DATE",
    value: "cmr_first_name",
    display: true,
    column: true,
  },
  //   {
  //     label: <span className="flex justify-end">AMOUNT</span>,
  //     value: "sot_total_amount",
  //     display: true,
  //     column: false,
  //     className: "justify-end pe-5",
  //   },
  //   { label: "", value: "", column: false },
  //   { label: "", value: "", column: false },
];
