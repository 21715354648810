import React, { useEffect, useRef, useState } from "react";
import {
  uomGroupDeleteAction,
  uomGroupTableListAction,
} from "../../../../redux/actions/settings-action";
import { useDispatch } from "react-redux";
import KibabMenu from "../../../../assets/icons/kibab-menu";
import checkBoxRightArrow from "../../../../assets/icons/checkBoxRightArrow.svg";
import checkBoxDownArrow from "../../../../assets/icons/checkBoxDownArrow.svg";
import AddEditUom from "./add-edit-uom-group";
import EditIcon from "../../../../assets/icons/editIconPencil.svg";
import DeleteIcon from "../../../../assets/icons/delete-icon";
import search from "../../../../assets/icons/searchIcon.svg";
import errorToast from "../../../helper-components/error-toast";
import LoadingSpinner from "../../../helper-components/loading-spinner";
import InnerLayout from "../../../layouts/inner-layout";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import successToast from "../../../helper-components/success-toast";
import Select from "react-select";
import { customStyles } from "../../../tables/filtering-section";

const UomGroup = () => {
  const searchRef = useRef();
  const searchFilter = [
    {
      label: "Group Name",
      value: "name",
    },
  ];
  const [isFocused, setIsFocused] = useState(true);
  const [filterData, setFilterData] = useState({
    searchColumn: "name",
    searchValue: "",
  });

  const tableHeading = ["Group Name", "Group Description"];
  const itemTableHeaders = ["Alt Qty", "Alt UOM", "Base Qty", "Base UOM"];
  const [uomGroupData, setUomGroupData] = useState([]);
  const [showItemsOf, setShowItemsOf] = useState(null);
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [editingData, setEditingData] = useState({});
  const [selectedDOtIndex, setSelectedDotIndex] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   useEffect(() => {
  //     dispatch(
  //       uomGroupTableListAction(
  //         {},
  //         onFetchUomGroupListSuccess,
  //         onFetchUomGroupListError
  //       )
  //     );
  //     setLoading(true);
  //   }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(
      uomGroupTableListAction(
        filterData,
        onFetchUomGroupListSuccess,
        onFetchUomGroupListError
      )
    );
  }, [filterData]);

  const reload = () => {
    dispatch(
      uomGroupTableListAction(
        {},
        onFetchUomGroupListSuccess,
        onFetchUomGroupListError
      )
    );
    setLoading(true);
  };

  const onFetchUomGroupListSuccess = (data) => {
    setUomGroupData(data?.data?.uomList);
    setLoading(false);
  };

  const onFetchUomGroupListError = (data) => {
    setLoading(false);
    setUomGroupData([]);
    errorToast(data?.message);
  };

  const toogleChange = () => {
    setShowAddEdit(!showAddEdit);
  };

  const onCLickEdit = (data) => {
    setEditingData(data);
    setShowAddEdit(true);
    setSelectedDotIndex(null);
  };

  const onClickDelete = (id) => {
    dispatch(uomGroupDeleteAction({ group_id: id }, onDeletSuccess, onError));
    setSelectedDotIndex(null);
  };
  const onDeletSuccess = (data) => {
    successToast(data?.message);
    reload();
  };

  const onError = (data) => {};

  const componentRef = useRef(null);

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setSelectedDotIndex(null);
    }
  };
  const handleSelectChange = (event) => {
    setFilterData({ ...filterData, searchColumn: event?.value });
  };



  return (
    <InnerLayout
      list={true}
      title="Settings"
      module="uom-group-setup"
      // permissionFn={permissionFn}
    >
      <div
        className="flex flex-col h-full"
        // style={{ height: "80vh" }}
        onClick={handleClickOutside}
      >
        <div className="flex justify-between pt-3 items-center px-4 py-3 h-[4rem]">
          <div className="flex items-center gap-2 text-sm">
            <button
              className="flex items-center gap-2"
              onClick={() => {
                navigate("/settings");
              }}
            >
              <ChevronLeftIcon className="h-5 w-5 inline-block" />
              Settings /
            </button>
            <span className="cursor-pointer">{" " + "UOM Group Setup"}</span>
          </div>
          {!showAddEdit && (
            <div className="flex items-center gap-2">
              <div
                style={{ maxWidth: "30rem", height: "2.6rem" }}
                className={`w-full  flex items-center rounded-md   ${
                  isFocused ? "border border-focus-color" : "input_border"
                }`}
              >
                <Select
                  className="z-30 text-xs placeholder:text-xs"
                  styles={customStyles}
                  value={
                    filterData?.searchColumn
                      ? searchFilter?.find(
                          (item) => item?.value === filterData?.searchColumn
                        )
                      : null
                  }
                  onChange={(e) => {
                    handleSelectChange(e);
                    setIsFocused(true);
                    if (searchRef && searchRef?.current) {
                      searchRef?.current?.focus();
                    }
                  }}
                  options={searchFilter}
                  placeholder="Select Column"
                  isSearchable={false}
                />{" "}
                <div
                  className={`h-full w-0.5 ${
                    isFocused
                      ? "border-r border-r-focus-color"
                      : "border-r border-r-D0D5DD"
                  }`}
                ></div>{" "}
                <input
                  ref={searchRef}
                  autoFocus={true}
                  onBlur={() => setIsFocused(false)}
                  onFocus={() => setIsFocused(true)}
                  placeholder={`Search`}
                  className=" table_search_input w-full rounded-md text-xs  h-full placeholder:text-xs placeholder:text-787878 pl-3"
                  type="text"
                  value={filterData?.searchValue}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      searchValue: e.target.value,
                    });
                  }}
                />
                <span className="px-2">
                  <img
                    style={{
                      height: "25px",
                      width: "25px",
                    }}
                    src={search}
                    alt="search icon"
                  />
                </span>
              </div>
              <button
                type="submit"
                className="bg-primary text-white px-6 py-2 rounded-md flex justify-center fw_500 text-sm whitespace-nowrap"
                onClick={() => setShowAddEdit(true)}
              >
                <span className="mx-1">+ New</span>
              </button>
            </div>
          )}
        </div>

        {loading ? (
          <LoadingSpinner />
        ) : !showAddEdit ? (
          <>
            <div
              style={{
                height: "calc(100% - 4rem)",
              }}
              className="w-full overflow-y-scroll h-full "
            >
              <table className=" w-full mb-10">
                <thead className="sticky top-0 z-10 bg-F2F4F5">
                  <tr>
                    {tableHeading.map((heading) => (
                      <th
                        className="tab-header whitespace-nowrap text-xs fw_700 text-475467 text-start bg-F2F4F5"
                        key={heading}
                      >
                        {heading}
                      </th>
                    ))}
                    <th className="tab-header whitespace-nowrap text-sm fw_700 text-475467 text-start p-6 bg-F2F4F5"></th>
                  </tr>
                </thead>
                <tbody>
                  {uomGroupData?.map((item, index) => (
                    <>
                      <tr key={index}>
                        <td className="tab-cell text-sm fw_400 text-secondary w-50 p-8">
                          <div className="flex gap-2">
                            <button
                              onClick={() =>
                                setShowItemsOf(
                                  showItemsOf === index ? null : index
                                )
                              }
                              className="mr-2"
                            >
                              <img
                                src={
                                  showItemsOf === index
                                    ? checkBoxDownArrow
                                    : checkBoxRightArrow
                                }
                                alt="Toggle"
                              />
                            </button>
                            <span className="">{item.name}</span>
                          </div>
                        </td>
                        <td className="tab-cell text-sm fw_400 text-secondary w-50 p-8">
                          {item.description}
                        </td>
                        <td className="tab-cell relative  w-[5rem] text-sm fw_400 text-secondary w-50 p-8">
                          <button onClick={() => setSelectedDotIndex(index)}>
                            <KibabMenu />
                          </button>

                          {selectedDOtIndex === index && (
                            <div
                              ref={componentRef}
                              style={{
                                position: "absolute",
                                right: "4.5rem",
                                boxShadow:
                                  "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                              }}
                              className="bg-white rounded w-40 z-20"
                            >
                              <div className="w-full p-4">
                                <button
                                  className="w-full flex align-middle gap-2 px-2 py-2"
                                  onClick={() => onCLickEdit(item)}
                                >
                                  <img src={EditIcon} />
                                  <span className="text-sm">Edit</span>
                                </button>
                                <button
                                  className="w-full flex align-middle gap-2 px-2 py-1"
                                  onClick={() => onClickDelete(item?.group_id)}
                                >
                                  <div className="mt-1">
                                    <DeleteIcon />
                                  </div>
                                  <span className="text-sm">Delete</span>
                                </button>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                      {showItemsOf === index && (
                        <tr key={`${index}-details`} className="">
                          <td colSpan="4" className="">
                            <div className="hide-scrollbar border-EAECF0 ml-10 border-2 rounded-md">
                              <table className="w-full ">
                                <thead className="sticky top-0 z-10 bg-F2F4F5">
                                  <tr>
                                    {itemTableHeaders.map((heading) => (
                                      <th
                                        className="tab-header whitespace-nowrap text-sm fw_700 text-475467 bg-F2F4F5 text-start p-2"
                                        key={heading}
                                      >
                                        {heading}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {item?.group_items?.map(
                                    (subItem, subIndex) => (
                                      <tr key={subIndex}>
                                        <td className="tab-cell text-sm fw_400 text-secondary p-2 w-24">
                                          {subItem.alt_quantity}
                                        </td>
                                        <td className="tab-cell text-sm fw_400 text-secondary p-2 w-24">
                                          {subItem.alt_uom}
                                        </td>
                                        <td className="tab-cell text-sm fw_400 text-secondary p-2 w-24">
                                          {subItem.base_quantity}
                                        </td>
                                        <td className="tab-cell text-sm fw_400 text-secondary p-2 w-24">
                                          {subItem.base_uom?.name}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div
            style={{
              height: "calc(100% - 4rem)",
            }}
            className="w-full "
          >
            <AddEditUom
              editingData={editingData}
              toogleChange={toogleChange}
              reload={reload}
            />
          </div>
        )}
      </div>
    </InnerLayout>
  );
};

export default UomGroup;
