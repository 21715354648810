import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";

import {
  binLocationListAction,
  storeIdAction,
} from "../../../redux/actions/general-action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { storeDetailsAction } from "../../../redux/actions/invoices-action";
import successToast from "../../helper-components/success-toast";
import errorToast from "../../helper-components/error-toast";
import {
  purchaseInvoiceEditAction,
  purchaseInvoiceReceiptCreateAction,
} from "../../../redux/actions/purchase-action";
import moment from "moment";
import SalesPurchaseFinalBillTable, {
  calculateFinalTotal,
  calculateTotal,
  calculateTotalDis,
  calculateTotalTax,
  calculatedTcsTdsAmount,
} from "../../sales-section/sales-purchase-finalBill-table";
import SalesPurchaseHeader from "../../sales-section/sales-purchase-header";
import { useHotkeys } from "react-hotkeys-hook";
import PurchaseItemsTable from "../purchase-items-table";
import PurchaseBatchesPopover from "../purchase-batches-popover1";
import { handlea4Print } from "../../helper-components/helper-function";

const CreateEditViewPurchaseInvoice = ({
  orderDetail = [],
  vendorDetails = [],
  disabled = false,
  type = "",
  page = "",
  data,
}) => {
  const formValuesRef = useRef(null); // Store latest form values persistently
  const [storeId, setStoreId] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [binLocationList, setBinLocationList] = useState([]);
  const rowData = useSelector((state) => state.invoiceRowReducer);
  const searchRef = useRef(null);
  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  const [tcsTdsAmount, setTcsTdsAmount] = useState("");
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight = document.querySelector(".first-div").clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);
  const initialData = {
    sl_no: 1,
    item_name: "",
    item_generic_name: "",
    item_code: "",
    item_hsn: "",
    item_mfg_by: "",
    item_uom: "",
    item_batches: "",
    // item_unit_price: 0,
    item_invoice_open_quantity: 0,
    quantity: 0,
    item_pack_size: 0,
    item_free_quantity: 0,
    item_discount_amount: 0,
    item_tax_amount: 0,
    item_total_amount: 0,
    item_id: "",
    item_gst: 0,
    item_sgst: 0,
    item_cgst: 0,
    item_tax_amount: 0,
    pot_id: null,
    item_uom_id: "",
  };
  const initialFormValues =
    rowData?.data?.length >= 1
      ? {
          ...rowData,
          store_id: storeId,
          data: [...rowData.data, initialData],
        }
      : {
          cmr_code: vendorDetails?.[0]?.cmr_id
            ? {
                label: vendorDetails?.[0]?.cmr_code ?? "",
                value: vendorDetails?.[0]?.cmr_id ?? "",
              }
            : "",
          cmr_name: vendorDetails?.[0]?.cmr_id
            ? {
                label: vendorDetails?.[0]?.cmr_name,
                value: vendorDetails?.[0]?.cmr_id,
              }
            : "",
          cmr_id: vendorDetails?.[0]?.cmr_id,
          store_id: vendorDetails?.[0]?.store_id || storeId || "",
          remarks: vendorDetails?.[0]?.remarks ?? "",
          poit_total_discount:
            type === "goods"
              ? orderDetail.reduce(
                  (sum, item) =>
                    sum + (parseFloat(item.item_discount_amount) || 0),
                  0
                )
              : vendorDetails?.[0]?.poit_total_discount,

          ...(vendorDetails?.[0]?.poit_id
            ? { poit_id: vendorDetails?.[0]?.poit_id }
            : { poit_id: "" }),
          poit_order_date: vendorDetails?.[0]?.poit_order_date
            ? moment(vendorDetails?.[0]?.poit_order_date).format("YYYY-MM-DD")
            : moment(new Date()).format("YYYY-MM-DD"),
          poit_document_date: vendorDetails?.[0]?.poit_document_date
            ? moment(vendorDetails?.[0]?.poit_document_date).format(
                "YYYY-MM-DD"
              )
            : moment(new Date()).format("YYYY-MM-DD"),
          poit_delivery_date: vendorDetails?.[0]?.poit_delivery_date
            ? moment(vendorDetails?.[0]?.poit_delivery_date).format(
                "YYYY-MM-DD"
              )
            : moment(new Date()).format("YYYY-MM-DD"),
          poit_order_status: vendorDetails?.[0]?.poit_order_status ?? "",
          poit_round_off_value: vendorDetails?.[0]?.poit_round_off_value ?? 0,
          cmr_tcs: "",
          cmr_tds: "",
          poit_tcs_or_tds_percentage_id:
            vendorDetails?.[0]?.tcs_or_tds_percentage_id ?? "",
          poit_tcs_or_tds_percentage:
            vendorDetails?.[0]?.tcs_or_tds_percentage ?? 0,
          poit_tds_tcs_type: vendorDetails?.[0]?.tds_tcs_type ?? "",
          poit_tcs_or_tds_amount: vendorDetails?.[0]?.tcs_or_tds_amount ?? 0,

          data: [
            ...orderDetail?.map((item, index) => ({
              sl_no: index + 1,
              item_name: item?.item_name,
              item_generic_name: item?.item_generic_name,
              item_code: item?.item_code,
              item_hsn: item?.item_hsn,
              item_manufacturer_name: item?.item_manufacturer_name,
              item_uom: item?.item_uom,
              item_batches:
                type === "purchase"
                  ? []
                  : item?.itemBatchData?.map((batch) => ({
                      //---------------------------OLD--------------------------------------
                      // item_sellable_quantity: batch?.item_sellable_quantity,
                      // item_batch_number: batch?.item_batch_number,
                      // item_exp_date: moment(batch?.item_exp_date).format(
                      //   "YYYY-MM-DD"
                      // ),
                      // item_id: batch?.item_id,
                      item_batch_number: batch?.item_batch_number,
                      item_sellable_quantity: parseInt(
                        batch?.item_sellable_quantity
                      ),
                      item_batch_free_quantity:
                        parseInt(batch?.item_batch_free_quantity) || 0,

                      item_batch_total_effective_qauntity:
                        parseInt(batch?.item_batch_total_effective_qauntity) ||
                        0, //new

                      item_batch_purchase_rate:
                        parseFloat(batch?.item_batch_purchase_rate) || 0,
                      item_batch_total_base_purchase_rate:
                        parseFloat(
                          batch?.item_batch_total_base_purchase_rate
                        ) || 0, //new

                      item_batch_for_qauntity:
                        parseInt(batch?.item_batch_for_qauntity) || 0, //new
                      item_batch_for_free_qauntity:
                        parseInt(batch?.item_batch_for_free_qauntity) || 0, //new
                      item_batch_scheme_discount_percentage:
                        parseFloat(
                          batch?.item_batch_scheme_discount_percentage
                        ) || 0, //new
                      item_batch_scheme_discount_amount:
                        parseFloat(batch?.item_batch_scheme_discount_amount) ||
                        0, //new

                      item_batch_scheme_purchase_rate:
                        parseFloat(batch?.item_batch_scheme_purchase_rate) || 0, //new

                      item_batch_discount_percentage:
                        parseFloat(batch?.item_batch_discount_percentage) || 0, //new
                      item_batch_gen_discount_amount:
                        parseFloat(batch?.item_batch_gen_discount_amount) || 0, //new
                      item_batch_discounted_purchase_rate:
                        parseFloat(
                          batch?.item_batch_discounted_purchase_rate
                        ) || 0, //new

                      item_batch_discount_percentage: parseFloat(
                        batch?.item_batch_discount_percentage
                      ),
                      item_batch_discount_amount: parseFloat(
                        batch?.item_batch_discount_amount
                      ),
                      item_batch_tax_percentage: parseFloat(
                        batch?.item_batch_tax_percentage
                      ),
                      item_batch_tax_amount: parseFloat(
                        batch?.item_batch_tax_amount
                      ),
                      item_batch_final_purchase_rate: parseFloat(
                        batch?.item_batch_final_purchase_rate
                      ),
                      item_batch_total_purchase_rate: parseFloat(
                        batch?.item_batch_total_purchase_rate
                      ),
                      item_batch_margin_percentage:
                      parseFloat(batch?.item_batch_margin_percentage) ?? 0,
                      item_batch_sgst:
                        parseFloat(batch?.item_batch_tax_amount) / 2,
                      item_batch_cgst:
                        parseFloat(batch?.item_batch_tax_amount) / 2,

                      item_batch_unit_price:
                        parseFloat(batch?.item_batch_unit_price) || 0,
                      item_batch_margin_percentage:
                        parseFloat(batch?.item_batch_margin_percentage) ?? 0,
                      item_exp_date: batch?.item_exp_date,
                      item_id: batch?.item_id,
                      from_bin_location: batch?.from_bin_location,
                      to_bin_location: batch?.to_bin_location,
                      from_bin_id: batch?.from_bin_id,
                      to_bin_id: batch?.to_bin_id,
                    })) || [],
              // item_unit_price: item?.item_unit_price || 0,
              item_invoice_open_quantity: item?.item_to_be_received || 0,
              quantity: type === "purchase" ? 0 : item?.item_quantity,
              item_pack_size: item?.item_pack_size || 0,
              item_free_quantity: parseInt(item?.item_free_quantity) || 0,
              item_discount_amount:
                type === "purchase" ? 0 : item?.item_discount_amount || 0,
              item_tax_amount:
                type === "purchase" ? 0 : item?.item_tax_amount || 0,
              item_total_amount:
                type === "purchase" ? 0 : item?.item_total_amount || 0,
              item_id: item?.item_id,
              pot_id: item?.pot_id || null,
              gort_id: item?.gort_id || null,
              item_tax_amount: parseFloat(item?.item_tax_amount),
              item_gst: parseFloat(item?.item_gst),
              item_sgst: parseFloat(item?.item_sgst),
              item_cgst: parseFloat(item?.item_cgst),
              item_uom_id: item?.item_uom_id,
              item_manufacturer_id: item?.item_manufacturer_id || "",
            })),
            // ...(type === "purchase" || type === "invoice"
            //   ? [{ ...initialData }]
            //   : []),
            initialData,
          ],
        };

  const [initialValue, setInitialValue] = useState(initialFormValues);
  const [reload, setReload] = useState(false);
  const [addbatch, setAddbatch] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [storeDetails, setStoreDetails] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    cmr_code: Yup.object().required("Customer code number is required"),
    cmr_name: Yup.object().required("Customer name is required"),
    data: Yup.array().of(
      Yup.object().shape({
        item_batches: Yup.array().of(
          Yup.object().shape({
            item_batch_purchase_rate: Yup.number()
              .typeError("Must be a number")
              .required("Purchase rate is required")
              .moreThan(0, "Purchase rate must be greater than 0"),

            item_batch_unit_price: Yup.number()
              .typeError("Must be a number")
              .required("MRP is required")
              .moreThan(0, "MRP must be greater than 0")
              .test(
                "MRP-greater-than-final-purchase-rate",
                "MRP must be greater than the final purchase rate",
                function (value) {
                  const { item_batch_final_purchase_rate } = this?.parent;
                  return value > item_batch_final_purchase_rate;
                }
              ),

            item_sellable_quantity: Yup.number()
              .typeError("Must be a number")
              .integer("Quantity must be an integer")
              .required("Sellable quantity is required")
              .moreThan(0, "Sellable quantity must be greater than 0"),
            item_batch_free_quantity: Yup.number()
              .transform((value) =>
                typeof value === "string" ? parseInt(value, 10) : value
              ) // Transform string to number
              .typeError("Must be a number")
              .integer("Quantity must be an integer"),
            item_batch_number: Yup.string().required(
              "Batch number is required"
            ),
            // to_bin_id: Yup.string().required("Bin ID is required"),
            item_exp_date: Yup.date().required("Expiration date is required"),
          })
        ),
      })
    ),
  });
  useEffect(() => {
    dispatch(storeIdAction(onSuccessStore, onErrorStore));
    dispatch(storeDetailsAction(onSuccessStoreDetails, onErrorStoreDetails));
    dispatch(binLocationListAction(onSuccessBin, onerrorBin));
  }, []);

  const onSuccessBin = (data) => {
    setBinLocationList(
      data?.data?.map((item) => ({
        label: (
          <span
            className={`${
              item?.is_expired_bin_location ||
              item?.is_non_sellable_bin_location
                ? "text-F51D00"
                : ""
            }`}
          >
            {item?.bin_location_no}
            {item?.is_expired_bin_location}
            {item?.is_non_sellable_bin_location}
          </span>
        ),
        value: item?.bin_no_id,
        actualLabel: item?.bin_location_no,
      }))
    );
  };
  const onerrorBin = () => {};

  const onSuccessStore = (data) => {
    setStoreId(data?.data?.store_id);
    setReload(!reload);
  };
  const onErrorStore = () => {};
  const onSuccessStoreDetails = (data) => {
    setStoreDetails(data?.data);
  };
  const onErrorStoreDetails = () => {};
  const handleBilling = (values) => {
    let actionType = purchaseInvoiceReceiptCreateAction;
    if (page === "edit") {
      actionType = purchaseInvoiceEditAction;
    }
    const missingUOM = values?.data
      ?.slice(0, -1)
      .some((item) => !item.item_uom);
    if (
      page === "edit" &&
      values?.submittedButton !== "draft" &&
      values?.poit_order_status !== "draft"
    ) {
      setButtonLoading(true);
      dispatch(
        purchaseInvoiceEditAction(
          {
            poit_id: values?.poit_id,
            only_remarks: true,
            remarks: values?.remarks,
            is_draft_order: values.submittedButton === "draft" ? true : false,
          },
          (data) => {
            onSuccessOrder(data, values);
          },
          onErrorOrder
        )
      );
    } else {
      if (
        values.data
          ?.slice(0, -1)
          ?.some((item) => item.item_batches.length === 0) &&
        values?.submittedButton !== "draft"
      ) {
        errorToast("Please add batches for all items");
      } else if (
        values?.data
          ?.slice(0, -1)
          .some(
            (item) => parseInt(item.quantity) === 0 || item.quantity === ""
          ) &&
        values?.submittedButton !== "draft"
      ) {
        errorToast("Quantity cannot be zero");
      } else if (missingUOM && values?.submittedButton !== "draft") {
        errorToast("Please add UOM for all items");
      } else {
        if (values?.data?.length > 1 || type === "goods") {
          setButtonLoading(true);
          dispatch(
            actionType(
              {
                orderData: {
                  is_draft_order:
                    values.submittedButton === "draft" ? true : false,
                  store_id: values?.store_id,
                  ...(values?.poit_id ? { poit_id: values?.poit_id } : {}),
                  poit_total_gst: parseFloat(
                    values.data
                      ?.slice(0, -1)
                      .reduce(
                        (total, item) =>
                          total + parseFloat(item?.item_tax_amount),
                        0
                      )
                  ),
                  poit_total_discount: calculateTotalDis(values?.data)?.toFixed(
                    2
                  ),
                  poit_payment_status: "paid",
                  poit_transaction_id: "",
                  poit_billing_address: "",
                  poit_total_amount: Math.round(
                    calculateFinalTotal(values, tcsTdsAmount, "poit")
                  )?.toFixed(2),
                  poit_sub_total:
                    parseFloat(calculateTotal(values?.data)) -
                    parseFloat(calculateTotalTax(values?.data)) -
                    calculateTotalDis(values?.data)?.toFixed(2),
                  cmr_code: values?.cmr_code?.label,
                  cmr_id: values?.cmr_id,
                  cmr_name: values?.cmr_name?.label,
                  remarks: values?.remarks,
                  poit_order_date: values?.poit_order_date,
                  poit_document_date: values?.poit_document_date,
                  poit_delivery_date: values?.poit_delivery_date,
                  poit_round_off_value: (
                    Math.round(
                      calculateFinalTotal(values, tcsTdsAmount, "poit")
                    ) - calculateFinalTotal(values, tcsTdsAmount, "poit")
                  )?.toFixed(2),
                  poit_tcs_or_tds_percentage_id:
                    values?.poit_tcs_or_tds_percentage_id ?? "",
                  poit_tcs_or_tds_percentage:
                    values?.poit_tcs_or_tds_percentage ?? 0,
                  poit_tds_tcs_type: values?.poit_tds_tcs_type ?? "",
                  poit_tcs_or_tds_amount:
                    calculatedTcsTdsAmount(values, tcsTdsAmount, "poit") ?? 0,
                  only_remarks: false,
                },
                itemData: values.data?.slice(0, -1).map((item, index) => ({
                  item_code: item?.item_code,
                  item_id: item?.item_id,
                  item_generic_name: item?.item_generic_name,
                  item_name: item?.item_name,
                  item_uom: item?.item_uom,
                  item_quantity: parseInt(item?.quantity),
                  item_pack_size: parseFloat(item.item_pack_size),
                  item_free_quantity: item?.item_batches?.reduce(
                    (sum, batch) =>
                      sum + (parseInt(batch.item_batch_free_quantity, 10) || 0),
                    0
                  ),
                  item_total_amount: parseFloat(item.item_total_amount),
                  item_tax_amount: item?.item_tax_amount ?? 0,
                  item_discount_amount: item?.item_discount_amount ?? 0,
                  item_gst: item?.item_gst,
                  item_cgst: item?.item_cgst,
                  item_sgst: item?.item_sgst,
                  item_tax_amount: item?.item_tax_amount ?? 0,
                  item_gst: item?.item_gst ?? 0,
                  item_cgst: item?.item_cgst ?? 0,
                  item_sgst: item?.item_sgst ?? 0,
                  ...(item?.pot_id && type === "purchase"
                    ? { pot_id: item?.pot_id }
                    : { pot_id: null }),
                  ...(item?.gort_id && type === "goods"
                    ? { gort_id: item?.gort_id }
                    : { gort_id: null }),
                  item_uom_id: item?.item_uom_id ?? "",
                  item_manufacturer_id: item?.item_manufacturer_id ?? "",
                  item_manufacturer_name: item?.item_manufacturer_name ?? "",
                })),
                itemBatchData: values.data
                  ?.slice(0, -1)
                  .map((item) => item.item_batches)
                  .flat()
                  .map((batch) => ({
                    item_batch_number: batch?.item_batch_number,
                    item_sellable_quantity: parseInt(
                      batch?.item_sellable_quantity
                    ),
                    item_batch_free_quantity: batch?.item_batch_free_quantity,

                    item_batch_total_effective_qauntity:
                      parseInt(batch?.item_batch_total_effective_qauntity) ?? 0, //new
                    item_batch_purchase_rate: batch?.item_batch_purchase_rate,

                    item_batch_total_base_purchase_rate:
                      parseFloat(batch?.item_batch_total_base_purchase_rate) ??
                      0, //new

                    item_batch_for_qauntity:
                      parseInt(batch?.item_batch_for_qauntity) ?? 0, //new
                    item_batch_for_free_qauntity:
                      parseInt(batch?.item_batch_for_free_qauntity) ?? 0, //new
                    item_batch_scheme_discount_percentage:
                      parseFloat(
                        batch?.item_batch_scheme_discount_percentage
                      ) ?? 0, //new
                    item_batch_scheme_discount_amount:
                      parseFloat(batch?.item_batch_scheme_discount_amount) ?? 0, //new

                    item_batch_scheme_purchase_rate:
                      parseFloat(batch?.item_batch_scheme_purchase_rate) ?? 0, //new

                    item_batch_discount_percentage:
                      parseFloat(batch?.item_batch_discount_percentage) ?? 0, //new
                    item_batch_gen_discount_amount:
                      parseFloat(batch?.item_batch_gen_discount_amount) ?? 0, //new
                    item_batch_discounted_purchase_rate:
                      parseFloat(batch?.item_batch_discounted_purchase_rate) ??
                      0, //new
                      item_batch_margin_percentage:
                      parseFloat(batch?.item_batch_margin_percentage) ?? 0,
                    item_batch_discount_percentage:
                      batch?.item_batch_discount_percentage,
                    item_batch_discount_amount:
                      batch?.item_batch_discount_amount,
                    item_batch_tax_percentage: batch?.item_batch_tax_percentage,
                    item_batch_tax_amount: batch?.item_batch_tax_amount,
                    item_batch_final_purchase_rate:
                      batch?.item_batch_final_purchase_rate,
                    item_batch_margin_percentage:
                      batch?.item_batch_margin_percentage,
                    item_batch_total_purchase_rate:
                      batch?.item_batch_total_purchase_rate,
                    item_batch_unit_price: batch?.item_batch_unit_price,
                    item_exp_date: batch?.item_exp_date,
                    item_id: batch?.item_id,
                    // from_bin_location: batch?.from_bin_location,
                    to_bin_location: batch?.to_bin_location,
                    // from_bin_id: batch?.from_bin_id,
                    to_bin_id: batch?.to_bin_id,
                  })),
              },
              (data) => {
                onSuccessOrder(data, values);
              },
              onErrorOrder
            )
          );
        }
      }
    }
  };

  const onSuccessOrder = (data, values) => {
    formValuesRef.current = null; // Reset after handling
    setButtonLoading(false);
    if (values?.save !== "auto") {
      navigate("/purchase-invoice");
    }
    if (formValues?.submittedButton === "print") {
      handlea4Print(
        data?.data?.orderData?.poit_id,
        "purchase_invoice",
        dispatch
      );
    }
    successToast(data?.message);
    setInitialValue({
      cmr_code: "",
      cmr_name: "",
      remarks: "",
      poit_total_discount: 0,
      poit_order_date: null,
      poit_document_date: null,
      poit_delivery_date: null,
      data: [initialData],
    });
  };
  const onErrorOrder = (data) => {
    setButtonLoading(false);

    errorToast(data?.data?.message);
  };

  useHotkeys("escape", () => {
    setAddbatch(false);
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleBilling(values, actions); // Pass values and actions if needed
            actions.setSubmitting(false); // Notify Formik that submission is done
          }, 500);
        }}
      >
        {({ values }) => {
          setFormValues(values);
          formValuesRef.current = values; // Store latest values persistently
          return (
            <Form className="h-full">
              <div className="flex flex-col h-full ">
                <div class="flex flex-wrap justify-between first-div px-3">
                  <SalesPurchaseHeader
                    section="purchase"
                    name="poit"
                    disabled={disabled}
                    searchRef={searchRef}
                  />
                </div>
                {/* {addbatch && (   
                  <BatchQuantityExpiryPopover
                    setItemId={setItemId}
                    itemId={itemId}
                    setAddbatch={setAddbatch}      
                  />
                )} */}
                {addbatch && (
                  <PurchaseBatchesPopover
                    disabled={disabled}
                    disName="poit_total_discount"
                    binLocationList={binLocationList}
                    setItemId={setItemId}
                    itemId={itemId}
                    setAddbatch={setAddbatch}
                    itemValues={values}
                  />
                )}

                <div
                  className="flex flex-col "
                  style={{
                    maxHeight: `calc(100% - ${heightOfFirstDiv}px)`,
                    height: `calc(100% - ${heightOfFirstDiv}px)`,
                  }}
                >
                  <PurchaseItemsTable
                    disabled={disabled || type === "goods"}
                    addBatchFn={(id) => {
                      setAddbatch(true);
                      setItemId(id);
                    }}
                    searchRef={searchRef}
                    initialData={initialData}
                  />
                  <div className="h-[18rem] mt-auto">
                    <SalesPurchaseFinalBillTable
                      formValuesRef={formValuesRef}
                      transaction="Purchase Invoice"
                      transaction_type="purchase_invoice"
                      buttonLoading={buttonLoading}
                      name="poit_total_discount"
                      page={page}
                      disabled={disabled}
                      prefixName="poit"
                      tcsTdsAmount={tcsTdsAmount}
                      setTcsTdsAmount={setTcsTdsAmount}
                      handleBilling={handleBilling}
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateEditViewPurchaseInvoice;
