import {all} from "redux-saga/effects";
import {
    productBarcodeWatcher,
    productBarcodesWatcher,
    productCreateWatcher,
    productDeleteWatcher,
    productEditWatcher,
    productInventoryCreateWatcher,
    productPlanningCreateWatcher,
    productPurchasingCreateWatcher,
    productRemarksCreateWatcher,
    productRestrictionCreateWatcher,
    productSalesCreateWatcher,
    productTableWatcher,
    productViewWatcher, productGenerateItemCodeWatcher, productVerifyItemCodeWatcher, productDisCreateWatcher,
} from "../product-saga";

export default function* productRootSaga() {
    yield all([
        productCreateWatcher(),
        productTableWatcher(),
        productPurchasingCreateWatcher(),
        productSalesCreateWatcher(),
        productInventoryCreateWatcher(),
        productPlanningCreateWatcher(),
        productDisCreateWatcher(),
        productRestrictionCreateWatcher(),
        productRemarksCreateWatcher(),
        productViewWatcher(),
        productEditWatcher(),
        productDeleteWatcher(),
        productBarcodeWatcher(),
        productBarcodesWatcher(),
        productGenerateItemCodeWatcher(),
        productVerifyItemCodeWatcher()
    ]);
}
