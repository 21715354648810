import React, { useState } from 'react';
import { ChevronRight, Star } from 'lucide-react';
import { SetFavAction } from '../../redux/actions/report-action';
import { useDispatch } from "react-redux";
import errorToast from '../../components/helper-components/error-toast';
import successToast from '../../components/helper-components/success-toast';
export const TableRow = ({ name, type, report, navigate, addedFav, setAddedFav, handleApply }) => {
  const [isModalVisible, setModalVisible] = useState(false); // State to control modal visibility
  const [actionType, setActionType] = useState(""); // State to control the action (add/remove)
  const dispatch = useDispatch();
  const handleItemClick = (clickedReport) => {
    if (type === "Custom Report" || type === "Favorite Report") {
      navigate(clickedReport);
    } else {
      navigate(clickedReport.path);
    }
    // Navigate to the path of the clicked report
    const element = document.querySelector(".innerLayoutScroller"); // Select the scrollable container
    if (element.style.overflow === "hidden") {
      element.style.overflow = "auto"; // Enable scrolling if it's disabled
    }
  };
  const handleAddToFav=(data)=>{
    dispatch(SetFavAction ({data:data},onSuccess, onError));
  }
  const onSuccess = (data) => {
    console.log("Right");
    successToast();
  };
  const onError =(data)=>{
    console.log("Wrong");
    errorToast();
  }
  const handleStarClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const isAlreadyFav = addedFav && addedFav.some(fav => fav.length === name.length + 1 && fav.startsWith(name));
    
    setActionType(isAlreadyFav ? "remove" : "add"); // Set the action based on the current state of the item
    setModalVisible(true); // Show the modal
  };

  const handleConfirm = () => {
    if (typeof setAddedFav !== 'function') {
      console.error("setAddedFav is not a function");
      return;
    }
  
    if (actionType === "remove") {
      if (type === "System Generated") {
        setAddedFav((prevFav) => prevFav.filter(item => item !== (name + "S")));
        handleAddToFav(addedFav.filter(item => item !== (name + "S")));
      } else if (type === "Custom Report") {
        setAddedFav((prevFav) => prevFav.filter(item => item !== (name + "C")));
        handleAddToFav(addedFav.filter(item => item !== (name + "C")));
      } else {
        setAddedFav((prevFav) => prevFav.filter(item => item !== (name + "C") && item !== (name + "S")));
        handleAddToFav(addedFav.filter(item => item !== (name + "C") && item !== (name + "S")));
      }
    } else if (actionType === "add") {
      if (type === "System Generated") {
        setAddedFav((prevFav) => [...prevFav, name + "S"]);
        handleAddToFav([...addedFav, name + "S"]);
      } else {
        setAddedFav((prevFav) => [...prevFav, name + "C"]);
        handleAddToFav([...addedFav, name + "C"]);
      }
    }
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false); // Close the modal if canceled
  };

  // Close modal when clicking outside of it
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('modal-background')) {
      setModalVisible(false);
    }
  };

  return (
    <div>
      <div
        className="flex items-center bg-white border-b border-D0D5DD px-4 py-3 hover:bg-f3f8ff cursor-pointer font-semibold"
        onClick={() => handleItemClick(report)} // Trigger the navigation and overflow change on click
      >
        {/* Use flex to space the items like columns */}
        <div className="mr-2" onClick={handleStarClick}>
          <Star className="w-4 h-4 text-primary" fill={
              addedFav && addedFav.some(fav => fav.length === name.length + 1 && fav.startsWith(name))
                ? "#ed6f24"
                : "#fff"
            } />
        </div>
        <div className="flex-1 text-primary">{name}</div> {/* Name aligned in the first column */}
        <div className="flex items-center flex-1">
          <span className="text-gray-600 text-sm">{type}</span> {/* Type aligned in the second column */}
          <ChevronRight className="w-4 h-4" />
        </div>
      </div>

      {/* Modal */}
      {isModalVisible && (
        <div
          className="modal-background fixed inset-0 bg-[#00000066]  flex items-center justify-center z-50"
          onClick={handleOutsideClick} // Close the modal when clicking outside
        >
          <div className="bg-white p-6 rounded-lg w-80" onClick={(e) => e.stopPropagation()}>
            <h2 className="text-lg font-medium mb-4">Are you sure?</h2>
            <p className="text-sm mb-4">
              {actionType === "remove" ? "Remove this item from favorites?" : "Add this item to favorites?"}
            </p>
            <div className="flex justify-end space-x-4">
              <button
                className="px-2 py-2 bg-white border text-primary-500 rounded"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-primary text-white rounded"
                onClick={handleConfirm}
              >
                {actionType === "remove" ? "Remove" : "Add"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
