import React from "react";
import FilterRadioButtons from "../../forms/filter-radio-buttons";
import {
  orderStatusoptions,
  paymentStatusoptions,
} from "../../helper-components/data";

const PaymentInOutFilter = ({ setFilterData, filterData, setCurrentPage }) => {
  const handlePaymentStatusChange = (e) => {
    setFilterData({
      ...filterData,
      pit_order_status: e.target.value,
    });
    setCurrentPage(1);
  };
  return (
    <div
      menuPortalTarget={document.body}
      style={{
        minWidth: "13rem",
        zIndex: 999,
        top: "40px",
        right: "-5px",
        boxShadow: `0px 1px 3px 0px var(--popover-shadow-color)`,
      }}
      className="absolute  border border-[var(--popover-border-color)] border-solid border-1   py-2 px-2  bg-[var(--popover-bg-color)]  rounded  z-10 flex flex-col"
    >
      <span className=" text-[length:--popover-heading-font-size] font-[var(--popover-heading-font-weight)] text-[var(--popover-heading-fg-color)] whitespace-nowrap cursor-pointer border-radius px-[8px] py-[12px]">
        FILTER BY
      </span>
      <hr className="border border-EAECF0" />
      <div className="px-5">
        <FilterRadioButtons
          name="pit_order_status"
          label={"Order Status"}
          options={paymentStatusoptions}
          handleChange={handlePaymentStatusChange}
          value={filterData?.pit_order_status}
        />
      </div>
    </div>
  );
};

export default PaymentInOutFilter;
