import { useFormikContext } from "formik";
import React from "react";
import "./table.css";
const AlternativesTable = ({
  tableHeading,
  tableBodyData = [],
  label = "Alternative",
  mainItemIndex,
  alternatives,
  setInvoiceType,
  setAlternativeID,
}) => {
  const { values, setFieldValue } = useFormikContext();

  function addAlternativeFn(id) {
    const alternativeMed = alternatives?.find(
      (item) => item?.alternative_item_id === id
    );

    const updatedRow = values?.data?.map((row, index) =>
      index === mainItemIndex
        ? {
            sl_no: mainItemIndex,
            item_code: alternativeMed.item_code,
            item_generic_name: alternativeMed?.item_generic_name,
            item_name: alternativeMed.item_name,
            total_sellable_quantity: alternativeMed.total_sellable_quantity,
            quantity: 0,
            item_free_quantity: 0,
            item_uom: alternativeMed?.item_uom,
            item_batch_number: "",
            item_exp_date: "",
            item_rack_location:
              alternativeMed?.item_rack_location === null
                ? ""
                : alternativeMed?.item_rack_location,
            item_manufacturer_name: alternativeMed?.item_manufacturer_name,
            item_schedule:
              alternativeMed?.item_schedule === null
                ? ""
                : alternativeMed?.item_schedule,
            item_hsn: alternativeMed?.item_hsn ?? "",
            item_unit_price: 0.0,
            item_price_wiithout_tax: 0.0,
            item_discount_amount: 0.0,
            item_discount_percentage: 0,
            item_tax_amount: 0.0,
            item_gst: 0,
            item_final_price: 0.0,
            item_total_amount: 0.0,
            item_id: alternativeMed?.item_id,
            item_total_tax_percentage: 0,
            instock: true,
            item_manufacturer_id: alternativeMed?.item_manufacturer,
          }
        : row
    );
    setFieldValue("data", updatedRow);
    setInvoiceType("");
  }
  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <div className="tab-container">
          <table className="my-tab h-full mt-0">
            <thead>
              <tr>
                {tableHeading.map((heading, index) => (
                  <th
                    key={index}
                    className={`${"tab-header"} whitespace-nowrap fs_12 fw_700 text-475467 bg-F2F4F5`}
                  >
                    <span
                      className={`flex items-center gap-2 ${heading?.className}`}
                    >
                      {heading?.label}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="h-full">
              {tableBodyData?.length > 0 ? (
                <>
                  {tableBodyData.map((rowData, rowIndex) => (
                    <tr key={rowIndex}>
                      {Object.values(rowData).map((cellData, cellIndex) => (
                        <>
                          {cellIndex !== 5 && (
                            <td
                              key={cellIndex}
                              className={`tab-cell text-sm fw_400 text-secondary`}
                            >
                              {cellData}
                            </td>
                          )}

                          {cellIndex === 5 && (
                            <td
                              key={cellIndex}
                              className={`tab-cell text-sm fw_400 text-secondary`}
                            >
                              <button
                                className="bg-primary text-white px-6 py-2 rounded flex justify-center fw_500 text-sm"
                                onClick={() => {
                                  addAlternativeFn(rowData?.id);
                                  setAlternativeID({
                                    itemid: "",
                                    index: null,
                                  });
                                }}
                              >
                                Add
                              </button>
                            </td>
                          )}
                        </>
                      ))}
                    </tr>
                  ))}
                </>
              ) : (
                <tr
                  style={{
                    height: "20rem",
                  }}
                >
                  <td
                    colSpan={tableHeading.length + 1}
                    className="text-center text-primary"
                  >
                    No {label + "s"} found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AlternativesTable;
