import {
    PRODUCT_BARCODE,
    PRODUCT_BARCODES,
    PRODUCT_CREATE,
    PRODUCT_DELETE,
    PRODUCT_DIS_CREATE,
    PRODUCT_EDIT, PRODUCT_GENERATE_ITEMCODE,
    PRODUCT_INVENTORY_CREATE, PRODUCT_ITEMCODE,
    PRODUCT_PLANNING_CREATE,
    PRODUCT_PURCHASING_CREATE,
    PRODUCT_REMARKS_CREATE,
    PRODUCT_RESTRICTIONS_CREATE,
    PRODUCT_SALES_CREATE,
    PRODUCT_TABLE, PRODUCT_VERIFY_ITEMCODE,
    PRODUCT_VIEW,
} from "../types";

export const productCreateAction = (data, onSuccess, onError) => {
    return {
        type: PRODUCT_CREATE,
        data,
        onSuccess,
        onError,
    };
};

export const productTableAction = (data, onSuccess, onError) => {
    return {
        type: PRODUCT_TABLE,
        data,
        onSuccess,
        onError,
    };
};

export const productPurchasingCreateAction = (data, onSuccess, onError) => {
    return {
        type: PRODUCT_PURCHASING_CREATE,
        data,
        onSuccess,
        onError,
    };
};
export const productSalesCreateAction = (data, onSuccess, onError) => {
    return {
        type: PRODUCT_SALES_CREATE,
        data,
        onSuccess,
        onError,
    };
};
export const productInventoryAction = (data, onSuccess, onError) => {
    return {
        type: PRODUCT_INVENTORY_CREATE,
        data,
        onSuccess,
        onError,
    };
};
export const productPlanningCreateAction = (data, onSuccess, onError) => {
    return {
        type: PRODUCT_PLANNING_CREATE,
        data,
        onSuccess,
        onError,
    };
};
export const productDisCreateAction = (data, onSuccess, onError) => {
  return {
    type: PRODUCT_DIS_CREATE,
    data,
    onSuccess,
    onError,
  };
};
export const productRestrictionCreateAction = (data, onSuccess, onError) => {
    return {
        type: PRODUCT_RESTRICTIONS_CREATE,
        data,
        onSuccess,
        onError,
    };
};
export const productRemarksCreateAction = (data, onSuccess, onError) => {
    return {
        type: PRODUCT_REMARKS_CREATE,
        data,
        onSuccess,
        onError,
    };
};

export const productViewAction = (data, onSuccess, onError) => {
    return {
        type: PRODUCT_VIEW,
        data,
        onSuccess,
        onError,
    };
};
export const productEditAction = (data, onSuccess, onError) => {
    return {
        type: PRODUCT_EDIT,
        data,
        onSuccess,
        onError,
    };
};
export const productDeleteAction = (data, onSuccess, onError) => {
    return {
        type: PRODUCT_DELETE,
        data,
        onSuccess,
        onError,
    };
};
export const productBarcodeAction = (data, onSuccess, onError) => {
    return {
        type: PRODUCT_BARCODE,
        data,
        onSuccess,
        onError,
    };
};
export const productBarcodesAction = (data, onSuccess, onError) => {
  return {
    type: PRODUCT_BARCODES,
    data,
    onSuccess,
    onError,
  };
};
export const productGenerateItemCodeAction = (data, onSuccess, onError) => {
    return {
        type: PRODUCT_GENERATE_ITEMCODE,
        data,
        onSuccess,
        onError,
    };
}
export const productVerifyItemCodeAction = (data, onSuccess, onError) => {
    return {
        type: PRODUCT_VERIFY_ITEMCODE,
        data,
        onSuccess,
        onError,
    };
}