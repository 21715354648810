import { Field, useFormikContext } from "formik";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DeleteIcon from "../../assets/icons/delete-icon";
import { productListAction } from "../../redux/actions/general-action";
import { url } from "../../redux/config";
import "../forms/form.css";
import SearchAddFeild from "../forms/search-add-field";
import { api } from "../../redux/apis/api";
import {
  calculateItemDis,
  itemTotalAmt,
  itemTotalPricewithoutTax,
  itemTotalTax,
} from "./create-edit-view-return-bill";
import { truncateString } from "../helper-components/helper-function";

const ReturnBillingTable = ({
  initialData,
  disabled = false,
  setAlternativeID,
  searchRef,
  page = "",
}) => {
  const inputRefs = useRef(null);
  const { values, setFieldValue } = useFormikContext();
  const isPaymentDone = values?.paymentData?.some((item) => item?.transaction);
  const dispatch = useDispatch();
  const [localData, setLocalData] = useState([]);
  const tableHeading = [
    { label: "S No.", value: "sl_no" },
    { label: "Linked Invoice No.", value: "linked_invoice_number" },
    { label: "Item code", value: "item_code" },
    { label: "Generic Name", value: "item_Generic_name" },
    { label: "Product Name", value: "item_product_name" },
    { label: "Open Qty", value: "item_open_quantity" },
    { label: "Qty", value: "item_quantity" },
    { label: "Free Qty", value: "item_free_quantity" },
    { label: "UOM", value: "item_uom" },
    { label: "Batch No.", value: "item_batch_no" },
    { label: "Exp. Date", value: "item_exp_date" },
    { label: "Loc.", value: "item_location" },
    { label: "Mfg by.", value: "item_mfg_by" },
    { label: "SCH", value: "item_schedule" },
    { label: "HSN", value: "item_hsn" },
    { label: "MRP", value: "item_price_wiithout_tax" },
    { label: "Price (₹)", value: "item_unit_price" },
    { label: "Disc. (₹)", value: "item_discount_amount" },
    { label: "Disc. (%)", value: "item_discount_percentage" },
    { label: "Tax (₹)", value: "item_tax_amount" },
    { label: "Tax %", value: "item_gst" },
    { label: "Final Price (₹)", value: "item_final_price" },
    { label: "Total (₹)", value: "item_total" },
    ...(disabled || isPaymentDone ? [] : [{ label: "", value: "" }]),
  ];

  const fetchData = async (inputValue, callback, field) => {
    const onSuccess = (data) => {
      const options =
        data?.data === null
          ? []
          : data.data
              ?.map((item) => ({
                value: item.item_id,
                label: item[field],
              }))
              .filter(
                (option) =>
                  !values?.data?.some((item) => item?.item_id === option.value)
              );
      setLocalData(data.data);
      callback(options);
    };
    const onError = (data) => {};
    if (inputValue?.length > 0) {
      dispatch(
        productListAction(
          {
            field: field,
            value: inputValue,
          },
          onSuccess,
          onError
        )
      );
    }
  };
  const removeRow = (rowIndex) => {
    const updatedData = [...values.data];
    updatedData.splice(rowIndex, 1);
    setFieldValue("data", updatedData);
  };
  async function discountFn(value, id) {
    try {
      const response = await api.get(
        `${url}/discount/get?totalAmountOfItem=${value}&item_id=${id}`,
        JSON.stringify(value)
      );
      const discount = response.data.data.item_discount_amount || 0;
      const tax = response.data.data.item_tax_amount || 0;
      const item_discount_percentage =
        response.data.data.item_discount_percentage || 0;
      const item_total_tax_percentage =
        response.data.data.item_total_tax_percentage || 0;
      return {
        discount: discount,
        tax: tax,
        item_total_tax_percentage: item_total_tax_percentage,
        item_discount_percentage: item_discount_percentage,
      };
    } catch (error) {
      onErrorDis(error);
      return 0;
    }
  }

  const onErrorDis = (data) => {};

  const handleQuantityChange = (event, rowIndex) => {
    const updatedData = values.data?.map((row, index) => {
      if (index === rowIndex) {
        const updatedQuantity = parseFloat(event.target.value);
        const updatedRow = {
          ...row,
          quantity: updatedQuantity,
          item_price_wiithout_tax: itemTotalPricewithoutTax({
            ...row,
            item_open_quantity: updatedQuantity,
          }),
          item_tax_amount: itemTotalTax({
            ...row,
            item_open_quantity: updatedQuantity,
          }),
          item_sgst:
            itemTotalTax({
              ...row,
              item_open_quantity: updatedQuantity,
            }) / 2,
          item_cgst:
            itemTotalTax({
              ...row,
              item_open_quantity: updatedQuantity,
            }) / 2,
          item_discount_amount: calculateItemDis({
            ...row,
            item_open_quantity: updatedQuantity,
          }),
          item_final_price:
            (parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
              parseFloat(updatedQuantity) -
              calculateItemDis({
                ...row,
                item_open_quantity: updatedQuantity,
              })) /
            parseFloat(updatedQuantity),

          item_total_amount: itemTotalAmt({
            ...row,
            item_open_quantity: updatedQuantity,
          }),
        };
        return updatedRow;
      }
      return row;
    });
    setFieldValue("data", updatedData);
  };

  const handleDisChange = (event, rowIndex) => {
    if (event?.target?.value >= 0) {
      const item_discount_amount = event.target.value;

      const totalAmountwithoutDis =
        parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
        parseFloat(values?.data?.[rowIndex]?.quantity);
      const item_discount_percentage =
        (item_discount_amount / totalAmountwithoutDis) * 100;
      const updatedData = values.data?.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              item_discount_amount: item_discount_amount,
              item_discount_percentage: item_discount_percentage,
            }
          : row
      );
      setFieldValue("data", updatedData);
      const totalAmount =
        parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
          parseFloat(values?.data?.[rowIndex]?.quantity) -
        parseFloat(event.target.value)?.toFixed(2);

      const updatedDataWithTotal = updatedData?.map((row, index) =>
        index === rowIndex ? { ...row, item_total_amount: totalAmount } : row
      );
      setFieldValue("data", updatedDataWithTotal);
    }
  };

  const handleDisPerChange = (event, rowIndex) => {
    if (event?.target?.value >= 0) {
      const item_discount_percentage =
        event.target.value <= 100 ? event.target.value : 0;

      const totalAmountwithoutDis =
        parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
        parseFloat(values?.data?.[rowIndex]?.quantity);
      const item_discount_amount =
        (totalAmountwithoutDis * item_discount_percentage) / 100;
      const updatedData = values.data?.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              item_discount_amount: item_discount_amount,
              item_discount_percentage: item_discount_percentage,
            }
          : row
      );
      setFieldValue("data", updatedData);
      const totalAmount = (
        parseFloat(values?.data?.[rowIndex]?.item_unit_price) *
          parseFloat(values?.data?.[rowIndex]?.quantity) -
        parseFloat(item_discount_amount)
      )?.toFixed(2);

      const updatedDataWithTotal = updatedData?.map((row, index) =>
        index === rowIndex ? { ...row, item_total_amount: totalAmount } : row
      );
      setFieldValue("data", updatedDataWithTotal);
    }
  };

  const hasDuplicateItemId = (itemId, currentIndex) => {
    const itemIdsExceptCurrent = values.data
      .filter((_, index) => index !== currentIndex)
      ?.map((item) => item.item_id);

    return itemIdsExceptCurrent.includes(itemId);
  };

  return (
    <>
      <div
        className="table-container mt-3"
        style={{
          maxHeight: `calc(100% - 15rem)`,
          height: `calc(100% - 15rem)`,
        }}
      >
        <table className="w-full">
          <thead className="sticky top-0 z-10">
            <tr>
              {tableHeading?.map((heading, index) => (
                <th
                  key={index}
                  className={`bg-F2F4F5 ${
                    index === tableHeading?.length - 1
                      ? "table-header w-[2rem]"
                      : "table-header"
                  } whitespace-nowrap text-sm fw_700 text-475467 ${
                    index === 17 ||
                    index === 19 ||
                    index === 20 ||
                    index === 18 ||
                    index === 13 ||
                    index === 15 ||
                    index === 10 ||
                    index === 16
                      ? "text-right w-[6rem]"
                      : "text-left"
                  } ${index === 0 && "w-[2rem]"}
                   ${index === 9 && "w-[4rem]"}
                   ${index === 6 && "min-w-[4.5rem] w-[4.5rem] text-right"}
                   ${
                     [3, 7, 11, 12, 16, 17, 19]?.includes(index) ? "hidden" : "" // Hide specific columns dynamically
                   }
                   `}
                >
                  {heading?.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {values?.data?.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {Object?.values(rowData)?.map((cellData, colIndex) => {
                  if (
                    colIndex < 23
                    //  &&
                    // colIndex !== 1 &&
                    // colIndex !== 2
                  ) {
                    return (
                      <td
                        key={colIndex}
                        className={`table-cell text-sm fw_400 text-secondary  ${
                          colIndex === 15 ||
                          colIndex === 5 ||
                          colIndex === 16 ||
                          colIndex === 19 ||
                          colIndex === 22 ||
                          colIndex === 17 ||
                          colIndex === 18 ||
                          colIndex === 20 ||
                          colIndex === 21
                            ? "text-end"
                            : ""
                        } ${colIndex === 4 ? "w-full min-w-[20rem]" : ""}   
                        ${
                          [3, 7, 11, 12, 16, 17, 19]?.includes(colIndex) ? "hidden" : "" // Hide specific columns dynamically
                        }
                     
                        `}
                      >
                        {colIndex === 2 || colIndex === 4 || colIndex === 6 ? (
                          colIndex !== 6 ? (
                            <div
                              title={
                                values?.data[rowIndex][
                                  Object.keys(rowData)[colIndex]
                                ]
                              }
                              className="w-full"
                            >
                              <SearchAddFeild
                                searchRef={colIndex === 3 ? searchRef : {}}
                                isDisabled={
                                  values?.data?.length - 1 !== rowIndex ||
                                  isPaymentDone ||
                                  disabled
                                    ? true
                                    : false
                                }
                                errorClass="hidden"
                                isRequired={false}
                                value={{
                                  label:
                                    values?.data[rowIndex][
                                      Object.keys(rowData)[colIndex]
                                    ],
                                  value: "",
                                }}
                                name={`data[${rowIndex}].${
                                  Object.keys(rowData)[colIndex]
                                }`}
                                field={Object.keys(rowData)[colIndex]}
                                isLabelReq={false}
                                defaultSelectClass=""
                                placeholder=""
                                fetchData={fetchData}
                                onChange={async (selectedOption) => {
                                  const data = await discountFn(
                                    parseFloat(
                                      localData?.find(
                                        (item) =>
                                          item?.item_id ===
                                          selectedOption?.value
                                      )?.item_unit_price
                                    ) * Object?.values(rowData)?.[5],
                                    selectedOption?.value
                                  );
                                  const filteredData = localData
                                    ?.filter(
                                      (item) =>
                                        item?.item_id === selectedOption?.value
                                    )
                                    ?.map((item) => ({
                                      sl_no: 1,
                                      item_code: item.item_code,
                                      item_generic_name:
                                        item?.item_generic_name,
                                      item_name: item.item_name,
                                      quantity: "",
                                      item_uom: item.item_uom,
                                      item_batch_number: "",
                                      item_exp_date: "",
                                      item_rack_location:
                                        item?.item_rack_location === null
                                          ? ""
                                          : item?.item_rack_location,
                                      item_mfg_by: item.item_mfg_by,
                                      item_schedule:
                                        item?.item_schedule === null
                                          ? ""
                                          : item?.item_schedule,
                                      item_hsn: item?.item_hsn,
                                      item_unit_price: item?.item_unit_price,
                                      item_price_wiithout_tax: 0,
                                      item_discount_amount:
                                        data?.discount?.toFixed(0),
                                      item_discount_percentage:
                                        data?.item_discount_percentage,
                                      item_tax_amount: data?.tax?.toFixed(0),
                                      item_final_price: 0,
                                      item_total_amount: 0,
                                      item_id: item?.item_id,
                                      instock: true,
                                    }));
                                  const oldData = [...values?.data];
                                  oldData.pop();
                                  setFieldValue("data", [
                                    ...oldData,
                                    ...filteredData,
                                    initialData,
                                  ]);
                                  inputRefs.current?.focus();
                                }}
                              />
                            </div>
                          ) : (
                            <Field name={`data[${rowIndex}].quantity`}>
                              {({ field }) => (
                                <input
                                  min={0}
                                  disabled={
                                    values?.data?.length - 1 === rowIndex
                                  }
                                  max={rowData?.item_open_quantity}
                                  ref={inputRefs}
                                  {...field}
                                  type="number"
                                  step="1"
                                  placeholder=""
                                  // value={rowData.quantity}
                                  readOnly={
                                    values?.data?.length - 1 === rowIndex ||
                                    page === "view"
                                  }
                                  onKeyDown={(event) => {
                                    if (
                                      event.key === "-" ||
                                      event.key === "e" ||
                                      event.key === "."
                                    ) {
                                      event.preventDefault();
                                    }
                                    if (
                                      event.key === "Tab" ||
                                      event.key === "Enter"
                                    ) {
                                      if (searchRef && searchRef.current) {
                                        setTimeout(() => {
                                          searchRef.current.focus();
                                        }, 0);
                                      }
                                    }
                                  }}
                                  onBlur={(event) => {
                                    if (!(page === "view")) {
                                      handleQuantityChange(event, rowIndex);
                                    }
                                    if (searchRef && searchRef.current) {
                                      setTimeout(() => {
                                        searchRef.current.focus();
                                      }, 0);
                                    }
                                  }}
                                  className="focus-within:ring-2 text-right px-2 w-full py-3 rounded focus-within:outline-none focus-within:shadow-[0px_0px_0px_4px_#EBF2FF input-field-no-spin-buttons"
                                />
                              )}
                            </Field>
                          )
                        ) : colIndex === 7 ? (
                          <Field name={`data[${rowIndex}].item_free_quantity`}>
                            {({ field }) => (
                              <input
                                type="number"
                                {...field}
                                onKeyDown={(event) => {
                                  if (
                                    event.key === "-" ||
                                    event.key === "e" ||
                                    event.key === "."
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                min={0}
                                max={
                                  parseInt(
                                    rowData?.item_max_free_quantity ?? 0,
                                    10
                                  ) || 0
                                }
                                step={1}
                                placeholder=""
                                readOnly={
                                  values?.data?.length - 1 === rowIndex ||
                                  page === "view"
                                }
                                className="focus-within:ring-2 text-right px-2 w-full py-3 rounded focus-within:outline-none focus-within:shadow-[0px_0px_0px_4px_#EBF2FF input-field-no-spin-buttons"
                              />
                            )}
                          </Field>
                        ) : colIndex === 0 ? (
                          <div>
                            <input
                              style={{}}
                              type="radio"
                              name="SerialNumberRadioGroup"
                              disabled={disabled}
                              value={rowIndex}
                              className="peer hidden"
                              id={`alt-${rowIndex}`}
                              onChange={(event) => {
                                setAlternativeID({
                                  item_id: rowData?.item_id,
                                  index: rowIndex,
                                });
                              }}
                            />
                            <label
                              htmlFor={`alt-${rowIndex}`}
                              className="block cursor-pointer select-none rounded-xl p-3 text-center hover:bg-slate-100 peer-checked:bg-blue-100 peer-checked:font-bold peer-checked:outline-none peer-checked:ring-2 peer-checked:ring-blue-500"
                            >
                              {rowIndex + 1}
                            </label>
                          </div>
                        ) : colIndex === 9 ? (
                          <div style={{ minWidth: "10rem" }}>
                            {values.data[rowIndex]?.item_batch_number}
                          </div>
                        ) : colIndex === 10 ? (
                          cellData ? (
                            moment(cellData).format("DD/MM/YYYY")
                          ) : (
                            ""
                          )
                        ) : colIndex === 19 ? (
                          cellData ?? 0
                        ) : colIndex === 17 ? (
                          <>
                            <div>
                              <input
                                disabled={true}
                                style={{
                                  textAlign: "right",
                                }}
                                readOnly={
                                  values?.data?.length - 1 === rowIndex
                                    ? true
                                    : false
                                }
                                type="text"
                                onChange={(event) => {
                                  handleDisChange(event, rowIndex);
                                }}
                                name={`data[${rowIndex}].item_discount_amount`}
                                value={
                                  values.data[rowIndex].item_discount_amount < 0
                                    ? 0
                                    : values.data[rowIndex].item_discount_amount
                                }
                                className="border-0 focus_outline__none w-full "
                              />
                            </div>
                          </>
                        ) : colIndex === 18 ? (
                          <>
                            <div>
                              <input
                                disabled={true}
                                style={{
                                  textAlign: "right",
                                }}
                                readOnly={
                                  values?.data?.length - 1 === rowIndex
                                    ? true
                                    : false
                                }
                                type="number"
                                min="0"
                                max="100"
                                onChange={(event) => {
                                  handleDisPerChange(event, rowIndex);
                                }}
                                name={`data[${rowIndex}].item_discount_percentage`}
                                value={
                                  values.data[rowIndex]
                                    .item_discount_percentage < 0
                                    ? 0
                                    : values.data[rowIndex]
                                        .item_discount_percentage
                                }
                                className="border-0 focus_outline__none w-full input-field-no-spin-buttons"
                              />
                            </div>
                          </>
                        ) : colIndex === 12 ? (
                          truncateString(cellData ?? "", 6)
                        ) : colIndex === 15 ||
                          colIndex === 18 ||
                          colIndex === 19 ? (
                          parseFloat(cellData)?.toFixed(2)
                        ) : (
                          cellData
                        )}
                      </td>
                    );
                  }
                  return null;
                })}
                {!disabled && !isPaymentDone && (
                  <td className="last-column w-[2rem]">
                    {rowIndex !== values?.data?.length - 1 && (
                      <DeleteIcon onClickFn={() => removeRow(rowIndex)} />
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ReturnBillingTable;
