import React from "react";
import SelectField from "../../forms/select-field";
import FromTo from "../../../assets/icons/from-to";
import InputField from "../../forms/input-field";
import { useFormikContext } from "formik";

const StocktransferHeader = ({
  warehouseList = [],
  warehouse = "",
  binLocationList = [],
  disabled = false,
  initialData = {},
}) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <>
      <div className="flex items-center w-full gap-5">
        <div className="w-[30%] flex items-center ">
          <SelectField
            handleSelected={(selectedOption) => {
              if (
                selectedOption?.value === "stock_in" ||
                selectedOption?.value === "stock_transfer_request"
              ) {
                setFieldValue("to_warehouse_id", warehouse);
                setFieldValue("from_warehouse_id", "");
              } else if (selectedOption?.value === "stock_out") {
                setFieldValue("from_warehouse_id", warehouse);
                setFieldValue("to_warehouse_id", "");
              } else if (selectedOption?.value === "in_store_transfer") {
                setFieldValue("from_warehouse_id", warehouse);
                setFieldValue("to_warehouse_id", warehouse);
              }
              setFieldValue("data", [initialData]);
            }}
            labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
            containerClass="w-full z-20"
            isDisabled={disabled}
            placeholder={"Select your action"}
            options={stockActionList}
            label={"Stock Management Action"}
            id={"stock_management_action"}
            name="stock_management_action"
            isSearchable={true}
            errMsgContainerClass="ps-2"
            labelWidth="12rem"
          />
        </div>
        <div className="w-[70%] flex items-center gap-8">
          <SelectField
            labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
            containerClass={`w-full z-20`}
            isDisabled={
              disabled ||
              values?.stock_management_action === "stock_out" ||
              values?.stock_management_action === "in_store_transfer"
            }
            placeholder={"Select source warehouse"}
            options={warehouseList}
            label={"Source (from)"}
            id={"from_warehouse_id"}
            name="from_warehouse_id"
            isSearchable={true}
            errMsgContainerClass="ps-2"
            labelWidth="7rem"
          />
          <div className="border-D0D5DD border flex justify-center mb-4 p-3 rounded-full">
            <FromTo />
          </div>
          <SelectField
            labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
            containerClass="w-full z-20"
            isDisabled={
              disabled ||
              values?.stock_management_action === "stock_in" ||
              values?.stock_management_action === "in_store_transfer" ||
              values?.stock_management_action === "stock_transfer_request"
            }
            placeholder={"Select Destination warehouse"}
            options={warehouseList}
            label={"Destination (to)"}
            id={"to_warehouse_id"}
            name="to_warehouse_id"
            isSearchable={true}
            errMsgContainerClass="ps-2"
            labelWidth="7rem"
          />
        </div>
      </div>
      <div className="flex items-center w-full gap-5 ">
        <div className="w-[30%] flex items-center ">
          <InputField
            labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
            isDisabled={disabled}
            containerClass="w-full"
            isRequired={false}
            type="date"
            name={`posting_date`}
            placeholder=""
            label="Transfer Date"
            labelWidth="12rem"
          />
        </div>
        <div className="w-[70%] flex items-center gap-8">
          <InputField
            maxLength={100}
            labelClass="whitespace-nowrap flex text-sm fw_600 text-secondary"
            isDisabled={disabled}
            containerClass="w-full"
            isRequired={false}
            type="text"
            name={`stock_transfer_reason`}
            placeholder="Enter reason for stock movement"
            label="Reason"
            labelWidth="7rem"
          />
        </div>
      </div>
    </>
  );
};

export default StocktransferHeader;

export const stockActionList = [
  {
    label: "Stock IN",
    value: "stock_in",
  },
  {
    label: "Stock OUT",
    value: "stock_out",
  },
  {
    label: "IN Store Transfer",
    value: "in_store_transfer",
  },
  {
    label: "Stock Transfer Request",
    value: "stock_transfer_request",
  },
];
