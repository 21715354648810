import { all } from "redux-saga/effects";
import {
  binlocationAddWatcher,
  inventoryCreateWatcher,
  stockTransferCreateWatcher,
  stockTransferEditWatcher,
  stockTransferTableWatcher,
  stockTransferViewWatcher,
  stockTransferbatchWatcher,
  voidStockTransferItemWatcher,
  voidStockTransferWatcher,
  warehouseListWatcher,
} from "../inventory-saga";

export default function* inventoryRootSaga() {
  yield all([
    inventoryCreateWatcher(),
    binlocationAddWatcher(),
    warehouseListWatcher(),
    stockTransferCreateWatcher(),
    stockTransferEditWatcher(),
    stockTransferTableWatcher(),
    stockTransferViewWatcher(),
    stockTransferbatchWatcher(),
    voidStockTransferWatcher(),
    voidStockTransferItemWatcher(),
  ]);
}
