import { call, takeLatest } from "redux-saga/effects";
import {
  ACQ_SRC_LIST,
  ADMIN_ROLES,
  BANK_NAME_LIST,
  BUISNESS_TYPE_LIST,
  CATEGORY_LIST,
  CUSTOMER_TYPE_LIST,
  BIN_LOCATION_LIST,
  CUSTOMER_LIST,
  DEPARTMENR_LIST,
  DIS_CUS_GROUP_LIST,
  DIS_CUS_LIST,
  DOCTOR_LIST,
  IMAGE_UPLOAD,
  ITEM_GROUP_LIST,
  ITEM_TYPE_DROP_DOWN,
  MANUFACTURERS_LIST,
  PAYMENT_METHODS_LIST,
  PHONE_LIST,
  PINCODE_LIST,
  PRICE_LIST,
  PRODUCT_DETAILS,
  PRODUCT_LIST,
  ROLES_LIST,
  SCH_LIST,
  SECTOR_LIST,
  SHIPPING_TYPE_LIST,
  STATE_LIST,
  STORE_ID,
  TAX_CAT_LIST,
  TRANSACTION_PRINT,
  TRANSACTION_SHARE,
  UOM_GROUP_FIND_ONE,
  UOM_GROUP_LIST,
  UOM_LIST,
  USERS_LIST,
  VALUATION_LIST,
  VENDOR_LIST,
  WARE_HOUSE_LIST,
  STORE_LIST,
} from "../types";
import {
  RolesListApi,
  UsersListApi,
  acqSrcListApi,
  adminRolesApi,
  bnkNameListApi,
  buisnessTypeListApi,
  binLocationListApi,
  catListApi,
  customerListApi,
  departmentListApi,
  disCusGrpListApi,
  disCusListApi,
  doctorListApi,
  imageUploadApi,
  itemGroupListApi,
  itemTypeListApi,
  manufacturesListApi,
  paymentMethodListApi,
  phoneListApi,
  pincodeListApi,
  priceListApi,
  productDetailsApi,
  productListApi,
  schListApi,
  sectorListApi,
  shippingTypeListApi,
  stateListApi,
  storeIdApi,
  taxCatListApi,
  transactionPrintApi,
  transactionShareApi,
  uomGroupFindOneApi,
  uomGroupListApi,
  uomListApi,
  valListApi,
  vendorListApi,
  wareHouseListApi,
  cusTypeListApi,
  storeListApi,
} from "../apis/general-api";

export function* productListWorker(action) {
  try {
    const res = yield call(productListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* productDetailsWorker(action) {
  try {
    const res = yield call(productDetailsApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* phoneListWorker(action) {
  try {
    const res = yield call(phoneListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* stateListWorker(action) {
  try {
    const res = yield call(stateListApi);
    const data = yield res.json();

    if (res.status === 200) {
      yield action.onSuccess(data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* doctorListWorker(action) {
  try {
    const res = yield call(doctorListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* storeIdWorker(action) {
  try {
    const res = yield call(storeIdApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* paymentMethodListWorker(action) {
  try {
    const res = yield call(paymentMethodListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* catListWorker(action) {
  try {
    const res = yield call(catListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* uomListWorker(action) {
  try {
    const res = yield call(uomListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* priceListWorker(action) {
  try {
    const res = yield call(priceListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* itemGroupListWorker(action) {
  try {
    const res = yield call(itemGroupListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* itemTypeListWorker(action) {
  try {
    const res = yield call(itemTypeListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* taxCatListWorker(action) {
  try {
    const res = yield call(taxCatListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* manufacturesListWorker(action) {
  try {
    const res = yield call(manufacturesListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* shippingTypeListWorker(action) {
  try {
    const res = yield call(shippingTypeListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* sectorListWorker(action) {
  try {
    const res = yield call(sectorListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* schListWorker(action) {
  try {
    const res = yield call(schListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* vendorListWorker(action) {
  try {
    const res = yield call(vendorListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* valListWorker(action) {
  try {
    const res = yield call(valListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* uomGroupListWorker(action) {
  try {
    const res = yield call(uomGroupListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* uomGroupFindOneWorker(action) {
  try {
    const res = yield call(uomGroupFindOneApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* adminRolesWorker(action) {
  try {
    const res = yield call(adminRolesApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* imageUploadWorker(action) {
  try {
    const formData = new FormData();
    formData.append("image", action?.data);
    const res = yield call(imageUploadApi, formData);
    const filePath = res?.data?.data;
    const parts = filePath.split("/");
    const lastPart = parts[parts.length - 1];
    if (res.status === 200) {
      yield action.onSuccess(lastPart);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* departmentListWorker(action) {
  try {
    const res = yield call(departmentListApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* rolesListWorker(action) {
  try {
    const res = yield call(RolesListApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* usersListWorker(action) {
  try {
    const res = yield call(UsersListApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* disCusListWorker(action) {
  try {
    const res = yield call(disCusListApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* disCusGrpListWorker(action) {
  try {
    const res = yield call(disCusGrpListApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* customerListWorker(action) {
  try {
    const res = yield call(customerListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* pincodeListWorker(action) {
  try {
    const res = yield call(pincodeListApi, action.data);
    const data = yield res.json();

    if (res.status === 200) {
      yield action.onSuccess(data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* binLocationListWorker(action) {
  try {
    const res = yield call(binLocationListApi);

    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* wareHouseListWorker(action) {
  try {
    const res = yield call(wareHouseListApi);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* storeListWorker(action) {
  try {
    const res = yield call(storeListApi);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* transactionShareWorker(action) {
  try {
    const res = yield call(transactionShareApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* transactionPrintWorker(action) {
  try {
    const res = yield call(transactionPrintApi, action?.data);
    if (res.status === 200) {
      yield action.onSuccess(res?.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}


export function* acqSrcListWorker(action) {
  try {
    const res = yield call(acqSrcListApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* buisnessTypeListWorker(action) {
  try {
    const res = yield call(buisnessTypeListApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* bnkNameListWorker(action) {
  try {
    const res = yield call(bnkNameListApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* cusTypeListWorker(action) {
  try {
    const res = yield call(cusTypeListApi);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* storeListWatcher() {
  yield takeLatest(STORE_LIST, storeListWorker);
}

export function* wareHouseListWatcher() {
  yield takeLatest(WARE_HOUSE_LIST, wareHouseListWorker);
}
export function* binLocationListWatcher() {
  yield takeLatest(BIN_LOCATION_LIST, binLocationListWorker);
}
export function* customerListWatcher() {
  yield takeLatest(CUSTOMER_LIST, customerListWorker);
}

export function* disCusGrpListWatcher() {
  yield takeLatest(DIS_CUS_GROUP_LIST, disCusGrpListWorker);
}
export function* disCusListWatcher() {
  yield takeLatest(DIS_CUS_LIST, disCusListWorker);
}
export function* productListWatcher() {
  yield takeLatest(PRODUCT_LIST, productListWorker);
}
export function* productDetailsWatcher() {
  yield takeLatest(PRODUCT_DETAILS, productDetailsWorker);
}
export function* phoneListWatcher() {
  yield takeLatest(PHONE_LIST, phoneListWorker);
}

export function* stateListWatcher() {
  yield takeLatest(STATE_LIST, stateListWorker);
}

export function* doctorListWatcher() {
  yield takeLatest(DOCTOR_LIST, doctorListWorker);
}
export function* storeIdWatcher() {
  yield takeLatest(STORE_ID, storeIdWorker);
}

export function* paymentMethodListWatcher() {
  yield takeLatest(PAYMENT_METHODS_LIST, paymentMethodListWorker);
}

export function* catListWatcher() {
  yield takeLatest(CATEGORY_LIST, catListWorker);
}
export function* uomListWatcher() {
  yield takeLatest(UOM_LIST, uomListWorker);
}
export function* priceListWatcher() {
  yield takeLatest(PRICE_LIST, priceListWorker);
}
export function* itemGroupListWatcher() {
  yield takeLatest(ITEM_GROUP_LIST, itemGroupListWorker);
}
export function* itemTypeListWatcher() {
  yield takeLatest(ITEM_TYPE_DROP_DOWN, itemTypeListWorker);
}
export function* taxCatListWatcher() {
  yield takeLatest(TAX_CAT_LIST, taxCatListWorker);
}
export function* manufacturesListWatcher() {
  yield takeLatest(MANUFACTURERS_LIST, manufacturesListWorker);
}
export function* shippingTypeListWatcher() {
  yield takeLatest(SHIPPING_TYPE_LIST, shippingTypeListWorker);
}
export function* sectorListWatcher() {
  yield takeLatest(SECTOR_LIST, sectorListWorker);
}
export function* schListWatcher() {
  yield takeLatest(SCH_LIST, schListWorker);
}
export function* vendorListWatcher() {
  yield takeLatest(VENDOR_LIST, vendorListWorker);
}
export function* valListWatcher() {
  yield takeLatest(VALUATION_LIST, valListWorker);
}
export function* uomGroupListWatcher() {
  yield takeLatest(UOM_GROUP_LIST, uomGroupListWorker);
}
export function* uomGroupFindOneWatcher() {
  yield takeLatest(UOM_GROUP_FIND_ONE, uomGroupFindOneWorker);
}
export function* imageUploadWatcher() {
  yield takeLatest(IMAGE_UPLOAD, imageUploadWorker);
}
export function* adminRolesWatcher() {
  yield takeLatest(ADMIN_ROLES, adminRolesWorker);
}
export function* departmentListWatcher() {
  yield takeLatest(DEPARTMENR_LIST, departmentListWorker);
}
export function* rolesListWatcher() {
  yield takeLatest(ROLES_LIST, rolesListWorker);
}
export function* usersListWatcher() {
  yield takeLatest(USERS_LIST, usersListWorker);
}
export function* acqSrcListWatcher() {
  yield takeLatest(ACQ_SRC_LIST, acqSrcListWorker);
}
export function* buisnessTypeListWatcher() {
  yield takeLatest(BUISNESS_TYPE_LIST, buisnessTypeListWorker);
}
export function* bnkNameListWatcher() {
  yield takeLatest(BANK_NAME_LIST, bnkNameListWorker);
}
export function* cusTypeListWatcher() {
  yield takeLatest(CUSTOMER_TYPE_LIST, cusTypeListWorker);
}
export function* pincodeListWatcher() {
  yield takeLatest(PINCODE_LIST, pincodeListWorker);
}
export function* transactionShareWatcher() {
  yield takeLatest(TRANSACTION_SHARE, transactionShareWorker);
}
export function* transactionPrintWatcher() {
  yield takeLatest(TRANSACTION_PRINT, transactionPrintWorker);
}
