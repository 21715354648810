import { api } from "./api";

export const adminCustomerCreateApi = (params) => {
  return api.post(
    `/customer/generalDetails/add`,
    JSON.stringify(params)
  );
};

export const adminCustomerUpdateApi = (params) => {
  return api.post(
    `/customer/generalDetails/update`,
    JSON.stringify(params)
  );
};

export const adminCustomerTableApi = (params) => {
  return api.get(
    `/customer/getAll?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${params?.filterData?.to}${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }&cmr_active_status=${
      params?.filterData?.customer_status === "active"
        ? true
        : params?.filterData?.customer_status === "inactive"
        ? false
        : ""
    }`,
    JSON.stringify(params)
  );
};

export const adminCustomerViewApi = (params) => {
  return api.get(
    `/customer/getCustomerAdminById?cmr_id=${params}`,
    JSON.stringify(params)
  );
};

export const adminCustomerDeleteApi = (params) => {
  return api.post(`/customer/delete`, JSON.stringify(params));
};

export const adminCustomerContactPersonApi = (params) => {
  return api.post(
    `/customer/contactPersons/add`,
    JSON.stringify(params)
  );
};
export const adminCustomerBnkDetailsApi = (params) => {
  return api.post(
    `/customer/bankDetails/add`,
    JSON.stringify(params)
  );
};
export const adminCustomerPaymentTermsApi = (params) => {
  return api.post(
    `/customer/paymentTerms/add`,
    JSON.stringify(params)
  );
};
export const adminCustomerAccountingApi = (params) => {
  return api.post(
    `/customer/accountInfo/add`,
    JSON.stringify(params)
  );
};
export const adminCustomerAddressApi = (params) => {
  return api.post(
    `/customer/address/add`,
    JSON.stringify(params)
  );
};

export const adminCustomerUpdateContactPersonApi = (params) => {
  return api.post(
    `/customer/contactPersons/update`,
    JSON.stringify(params)
  );
};
export const adminCustomerUpdateBnkDetailsApi = (params) => {
  return api.post(
    `/customer/bankDetails/update`,
    JSON.stringify(params)
  );
};
export const adminCustomerUpdatePaymentTermsApi = (params) => {
  return api.post(
    `/customer/paymentTerms/update`,
    JSON.stringify(params)
  );
};
export const adminCustomerUpdateAccountingApi = (params) => {
  return api.post(
    `/customer/accountInfo/update`,
    JSON.stringify(params)
  );
};
export const adminCustomerUpdateAddressApi = (params) => {
  return api.post(`/customer/address/update`, JSON.stringify(params));
};
