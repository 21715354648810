import { api } from "./api";

export const salesCreateApi = (params) => {
  return api.post(`/salesorder/add`, JSON.stringify(params));
};
export const salesEditApi = (params) => {
  return api.post(`/salesorder/update`, JSON.stringify(params));
};
export const salesTableApi = (params) => {
  return api.get(
    `/saleorder/orderList/getAll?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${
      params?.filterData?.to
    }&orderStatus=${params?.filterData?.orderStatus}${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }`,
    JSON.stringify(params)
  );
};
export const salesProductListApi = (params) => {
  return api.get(
    `/saleorder/products/get?field=${params?.field}&value=${params?.value}`
  );
};
   
export const salesViewApi = (params) => {
  return api.get(
    `/saleorder/getOrderById?sott_id=${params}`,
    JSON.stringify(params)
  );
};
export const salesVoidApi = (params) => {
  return api.post(`/salesorder/void`, JSON.stringify(params));
};

export const salesOrderItemsGetApi = (params) => {
  const queryString = JSON.stringify(params);
  const encodedQuery = encodeURIComponent(queryString);
  return api.get(`/saleorder/getSalesitemsList?query=${encodedQuery}`);
};

export const salesDeliveryReceiptCreateApi = (params) => {
  return api.post(`/saleorder/delivery/add`, JSON.stringify(params));
};
export const salesDeliveryTableApi = (params) => {
  return api.get(
    `/saleorder/delivery/getAll?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${
      params?.filterData?.to
    }&orderStatus=${params?.filterData?.orderStatus}${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }`,
    JSON.stringify(params)
  );
};

export const salesDeliveryViewApi = (params) => {
  return api.get(
    `/saleorder/delivery/getOrderById?sodtt_id=${params}`,
    JSON.stringify(params)
  );
};
export const salesDeliveryEditApi = (params) => {
  return api.post(`/saleorder/delivery/update`, JSON.stringify(params));
};
export const salesDeliveryVoidApi = (params) => {
  return api.post(`/saleorder/delivery/void`, JSON.stringify(params));
};
export const salesDeliveryItemsGetApi = (params) => {
  const queryString = JSON.stringify(params);
  const encodedQuery = encodeURIComponent(queryString);
  return api.get(
    `/saleorder/delivery/getDeliveryitemsList?query=${encodedQuery}`
  );
};

export const salesInvoiceReceiptCreateApi = (params) => {
  return api.post(`/saleorder/invoice/add`, JSON.stringify(params));
};
export const salesInvoiceTableApi = (params) => {
  return api.get(
    `/saleorder/invoice/getAll?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${
      params?.filterData?.to
    }&orderStatus=${params?.filterData?.orderStatus}${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }`,
    JSON.stringify(params)
  );
};

export const salesInvoiceViewApi = (params) => {
  return api.get(
    `/saleorder/invoice/getById?soit_id=${params}`,
    JSON.stringify(params)
  );
};
export const salesInvoiceEditApi = (params) => {
  return api.post(`/saleorder/invoice/update`, JSON.stringify(params));
};
export const salesInvoiceVoidApi = (params) => {
  return api.post(`/saleorder/invoice/void`, JSON.stringify(params));
};
export const salesInvoiceItemsGetApi = (params) => {
  const queryString = JSON.stringify(params);
  const encodedQuery = encodeURIComponent(queryString);
  return api.get(
    `/saleorder/invoice/getInvoiceitemsList?query=${encodedQuery}`
  );
};

export const salesReturnReceiptCreateApi = (params) => {
  return api.post(`/saleorder/returnInvoice/add`, JSON.stringify(params));
};
export const salesReturnTableApi = (params) => {
  return api.get(
    `/saleorder/returnInvoice/getAll?pageNumber=${
      params?.pageNumber
    }&pageSize=${params?.pageSize}&sortBy=${
      params?.filterData?.sort_by
    }&sortOrder=${params?.filterData?.sort_order}&fromDate=${
      params?.filterData?.from
    }&toDate=${params?.filterData?.to}&orderStatus=${
      params?.filterData?.orderStatus
    }${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }`,
    JSON.stringify(params)
  );
};

export const salesReturnViewApi = (params) => {
  return api.get(
    `/saleorder/returnInvoice/getById?srt_id=${params}`,
    JSON.stringify(params)
  );
};
export const salesReturnVoidApi = (params) => {
  return api.post(`/saleorder/returnInvoice/void`, JSON.stringify(params));
};
export const salesReturnEditApi = (params) => {
  return api.post(`/saleorder/returnInvoice/update`, JSON.stringify(params));
};
export const salesReturnItemsGetApi = (params) => {
  const queryString = JSON.stringify(params);
  const encodedQuery = encodeURIComponent(queryString);
  return api.get(
    `/saleorder/returnInvoice/getSaleReturnitemsList?query=${encodedQuery}`
  );
};

export const salesCreditReceiptCreateApi = (params) => {
  return api.post(`/saleorder/CreditNote/add`, JSON.stringify(params));
};
export const salesCreditTableApi = (params) => {
  return api.get(
    `/saleorder/creditnote/getAll?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${
      params?.filterData?.to
    }&orderStatus=${params?.filterData?.orderStatus}${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }`,
    JSON.stringify(params)
  );
};

export const salesCreditViewApi = (params) => {
  return api.get(
    `/saleorder/creditNote/getById?sct_id=${params}`,
    JSON.stringify(params)
  );
};
export const salesCreditEditApi = (params) => {
  return api.post(`/saleorder/creditNote/update`, JSON.stringify(params));
};
export const salesCreditVoidApi = (params) => {
  return api.post(`/saleorder/creditNote/void`, JSON.stringify(params));
};

export const salesPaymentInCusApi = (params) => {
  return api.get(
    `/saleorder/invoice/orderList/search?field=${params?.field}&value=${params?.value}`,
    JSON.stringify(params)
  );
};

export const paymentInTableApi = (params) => {
  
  return api.get(
    `/paymentIn/getAll?pageNumber=${params?.pageNumber}&pageSize=${
      params?.pageSize
    }&sortBy=${params?.filterData?.sort_by}&sortOrder=${
      params?.filterData?.sort_order
    }&fromDate=${params?.filterData?.from}&toDate=${params?.filterData?.to}${
      params?.filterData?.inputValue &&
      `&searchColumn=${params?.filterData?.selectColumn}&searchValue=${params?.filterData?.inputValue}`
    }`,
    JSON.stringify(params)
  );
};

export const paymentInCreateApi = (params) => {
  return api.post(`/paymentIn/add`, JSON.stringify(params));
};

export const glAccListApi = () => {
  return api.get(`/payment/glaccount/getAll`);
};

export const paymentInViewApi = (params) => {
  return api.get(`/paymentIn/getbyId?pit_id=${params}`);
};

export const salesProductDetailsApi = (params) => {
  return api.get(`/saleorder/getItemAvailableStockAndUOM?item_id=${params?.item_id}&item_uom=${params?.item_uom}`);
};
