import { call, takeLatest } from "redux-saga/effects";
import {
  BINLOCATION_ADD,
  INVENTORY_CREATE,
  STOCK_TRANSFER_BATCH,
  STOCK_TRANSFER_CREATE,
  STOCK_TRANSFER_EDIT,
  STOCK_TRANSFER_TABLE,
  STOCK_TRANSFER_VIEW,
  VOID_STOCK_TRANSFER,
  VOID_STOCK_TRANSFER_BATCH,
  VOID_STOCK_TRANSFER_ITEM,
  WAREHOUSE_LIST,
} from "../types";
import {
  binlocationAddApi,
  inventoryCreateApi,
  stockTransferBatchApi,
  stockTransferCreateApi,
  stockTransferEditApi,
  stockTransferTableApi,
  stockTransferViewApi,
  voidStockTransferApi,
  voidStockTransferItemApi,
  warehouseListApi,
} from "../apis/inventory-api";

export function* inventoryCreateWorker(action) {
  try {
    const res = yield call(inventoryCreateApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* binlocationAddWorker(action) {
  try {
    const res = yield call(binlocationAddApi, action.data);

    if (res.data?.code === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* warehouseListWorker(action) {
  try {
    const res = yield call(warehouseListApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* stockTransferCreateWorker(action) {
  try {
    const res = yield call(stockTransferCreateApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* stockTransferEditWorker(action) {
  try {
    const res = yield call(stockTransferEditApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* stockTransferTableWorker(action) {
  try {
    const res = yield call(stockTransferTableApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* stockTransferViewWorker(action) {
  try {
    const res = yield call(stockTransferViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* stockTransferbatchWorker(action) {
  try {
    const res = yield call(stockTransferBatchApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* voidStockTransferWorker(action) {
  try {
    const res = yield call(voidStockTransferApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* voidStockTransferItemWorker(action) {
  try {
    const res = yield call(voidStockTransferItemApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* voidStockTransferItemWatcher() {
  yield takeLatest(VOID_STOCK_TRANSFER_ITEM, voidStockTransferItemWorker);
}
export function* voidStockTransferWatcher() {
  yield takeLatest(VOID_STOCK_TRANSFER, voidStockTransferWorker);
}
export function* stockTransferbatchWatcher() {
  yield takeLatest(STOCK_TRANSFER_BATCH, stockTransferbatchWorker);
}
export function* stockTransferViewWatcher() {
  yield takeLatest(STOCK_TRANSFER_VIEW, stockTransferViewWorker);
}
export function* stockTransferTableWatcher() {
  yield takeLatest(STOCK_TRANSFER_TABLE, stockTransferTableWorker);
}
export function* stockTransferCreateWatcher() {
  yield takeLatest(STOCK_TRANSFER_CREATE, stockTransferCreateWorker);
}
export function* stockTransferEditWatcher() {
  yield takeLatest(STOCK_TRANSFER_EDIT, stockTransferEditWorker);
}
export function* inventoryCreateWatcher() {
  yield takeLatest(INVENTORY_CREATE, inventoryCreateWorker);
}

export function* binlocationAddWatcher() {
  yield takeLatest(BINLOCATION_ADD, binlocationAddWorker);
}

export function* warehouseListWatcher() {
  yield takeLatest(WAREHOUSE_LIST, warehouseListWorker);
}
