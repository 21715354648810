import React, { useEffect, useRef, useState } from "react";
import { customStylesForFilter } from "../../../components/reports/reports-layout";
import {
  ProductInfoAction,
  profitReportAction,
  vendorMasterReportAction
} from "../../../redux/actions/report-action";
import { useDispatch } from "react-redux";
import ReportLayout from "../../../components/reports/report-layout";
import DeleteIcon from "../../../assets/icons/delete-icon";
import Select from "react-select";
import { PlusIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "../../../components/helper-components/loading-spinner";
import SearchSelectFieldNonFormik from "../../../components/forms/search-select-field";
import {
  customerListAction,
  productListAction,
  itemTypeListAction,
  manufacturesListAction,
  productStatusAction
} from "../../../redux/actions/general-action";
import useItemHelper from "../../../components/helper-components/item-helper-function";
import {
  conditionsOptions,
  handleAddFilterRow,
  handleFilterRowChange,
  handleRemoveFilterRow,
} from "../report-helper-function";
import {
  preprocessData,
  transaction_type,
} from "../../../components/helper-components/helper-function";
import { isAdmin } from "../../../redux/config";
const VendorMaster = () => {
  const [allcolumns, setAllcolumns] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [overlayHeight, setOverlayHeight] = useState("85vh");
  const filterContainerRef = useRef(null);
  const [groupByOptions, setgroupByOptions] = useState([
    { label: "None", value: "" },
    { label: "Manufacturer Name", value: "manufacturer" },
    { label: "Invoice Number", value: "invoice" },
    { label: "Customer", value: "customer" },
    { label: "Invoice Date", value: "invoice_date" },
  ]);
  // const { dropdownOptions } = useItemHelper();

  const [tableData, setTableData] = useState([]);
  const [openMoreFilter, setOpenMoreFilter] = useState(false);
  const [filters, setFilters] = useState({
    filter_by: "",
    filters: [
      { column: null, condition: null, value: "", from: null, to: null },
    ],
    selectedColumns: tableData?.length > 0 ? Object.keys(tableData[0]) : [],
    from: null,
    to: null,
    group_by: "",
    save: "false",
    name: "",
  });
  const initFilter = {
    filter_by: "",
    filters: [
      { column: null, condition: null, value: "", from: null, to: null },
    ],
    selectedColumns: [],
    from: null,
    to: null,
    group_by: "",
    save: "false",
    name: "",
  };

  function ApplyFilterFn(filters) {
    setLoading(true);
    dispatch(
        vendorMasterReportAction(
        {
          ...filters,
          selectedColumns:
            tableData?.length > 0 ? Object.keys(tableData[0]) : [],
        },
        (data) => {
          setLoading(false);
          if (filters?.save === "false") {
            const transformedData = preprocessData(data?.data?.result ?? []);
            setTableData(transformedData ?? []);
            setAllcolumns(transformedData ?? []);
          }
          setOpenMoreFilter(false);
        },
        onError
      )
    );
  }

  const onError = (data) => {
    setLoading(false);
    setOpenMoreFilter(false);
  };

  useEffect(() => {
    if (openMoreFilter && filterContainerRef.current) {
      const filterContainerHeight = filterContainerRef.current.clientHeight;
      setOverlayHeight(`calc(85vh - ${filterContainerHeight}px)`);
    } else {
      setOverlayHeight("85vh");
    }
  }, [openMoreFilter]);

  useEffect(() => {
    ApplyFilterFn(filters);
  }, []);

  const [heightOfFirstDiv, setHeightOfFirstDiv] = useState(0);
  useEffect(() => {
    const updateHeight = () => {
      const firstDivHeight =
        document.querySelector(".report-header")?.clientHeight;
      setHeightOfFirstDiv(firstDivHeight);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);
  const dropdownOptions = {
    transaction_type: [
      {
        label: "Billing",
        value: "Billing",
      },
      {
        label: "Invoice Credit Note",
        value: "Invoice Credit Note",
      },
      {
        label: "Sales Delivery",
        value: "Sales Delivery",
      },
      {
        label: "Stock Transfer",
        value: "Stock Transfer",
      },
      {
        label: "GRN",
        value: "GRN",
      },
      {
        label: "Purchase Return",
        value: "Purchase Return",
      },
      {
        label: "Sales Return",
        value: "Sales Return",
      },
    ],
  };

  return (
    <ReportLayout
      allcolumns={allcolumns}
      setTableData={setTableData}
      tableData={tableData}
      availableColumns={[]}
      selectedColumns={[]}
      groupByOptions={groupByOptions}
      handleApply={(filters) => {
        ApplyFilterFn(filters);
      }}
      openMoreFilter={openMoreFilter}
      setOpenMoreFilter={setOpenMoreFilter}   
      filters={filters}
      setFilters={setFilters}
      title={"Vendor Master Report"}
      initFilter={initFilter}
      isProductInfo={true}
    >
      {openMoreFilter && (
        <div className="flex flex-col gap-3 pt-5 mx-6" ref={filterContainerRef}>
          {filters?.filters.map((row, index) => (
            <div key={index} className="flex gap-3">
              <div
                style={{ maxWidth: "11rem", height: "2.6rem" }}
                className={`w-full flex items-center rounded-md input_border`}
              >
                <Select
                  className="border-0 w-full text-xs placeholder:text-xs"
                  menuPortalTarget={document.body}
                  styles={customStylesForFilter}
                  value={filterOptions?.filter(
                    (item) => item?.value === row?.column
                  )}
                  options={filterOptions}
                  placeholder="Filters"
                  onChange={(selectedOption) =>
                    handleFilterRowChange(
                      index,
                      "column",
                      selectedOption?.value,
                      setFilters,
                      filters
                    )
                  }
                />
              </div>
              <div
                style={{ maxWidth: "11rem", height: "2.6rem" }}
                className={`w-full flex items-center rounded-md input_border`}
              >
                <Select
                  className="border-0 w-full text-xs placeholder:text-xs"
                  menuPortalTarget={document.body}
                  styles={customStylesForFilter}
                  value={conditionsOptions.filter(
                    (item) => item.value === row.condition
                  )}
                  options={conditionsOptions?.filter((opt) =>
                    filterOptions
                      ?.find((option) => option.value === row.column)
                      ?.operators?.includes(opt?.value)
                  )}
                  placeholder="Conditions"
                  onChange={(selectedOption) =>
                    handleFilterRowChange(
                      index,
                      "condition",
                      selectedOption?.value,
                      setFilters,
                      filters
                    )
                  }
                />
              </div>

              {row?.condition === "range" ? (
                <>
                  {filterOptions?.find((option) => option.value === row.column)
                    ?.valueField === "search" ? (
                    <>
                      <SearchSelectFieldNonFormik
                        isMulti={true}
                        value={{
                          label: row?.from ? (
                            row?.from
                          ) : (
                            <p className="text-667085 text-xs">
                              Search and Select Item..
                            </p>
                          ),
                          value: row?.from,
                        }}
                        handleChange={(selectedOption, actionMeta) => {
                          setFilters({
                            ...filters,
                            filters: filters?.filters.map((row, idx) =>
                              idx === index
                                ? {
                                    ...row,
                                    from: selectedOption?.value,
                                  }
                                : row
                            ),
                          });
                        }}
                        action={
                          filterOptions?.find(
                            (option) => option.value === row.column
                          )?.action
                        }
                        field={
                          filterOptions?.find(
                            (option) => option.value === row.column
                          )?.field
                        }
                      />

                      <SearchSelectFieldNonFormik
                        value={{
                          label: row?.to ? (
                            row?.to
                          ) : (
                            <p className="text-667085 text-xs">
                              Search and Select Item..
                            </p>
                          ),
                          value: row?.to,
                        }}
                        handleChange={(selectedOption, actionMeta) => {
                          setFilters({
                            ...filters,
                            filters: filters?.filters.map((row, idx) =>
                              idx === index
                                ? {
                                    ...row,
                                    to: selectedOption?.value,
                                  }
                                : row
                            ),
                          });
                        }}
                        action={
                          filterOptions?.find(
                            (option) => option.value === row.column
                          )?.action
                        }
                        field={
                          filterOptions?.find(
                            (option) => option.value === row.column
                          )?.field
                        }
                      />
                    </>
                  ) : (
                    <>
                      <div
                        style={{ maxWidth: "15rem", height: "2.6rem" }}
                        className={`w-full flex items-center rounded-md input_border `}
                      >
                        <input
                          min={0}
                          placeholder="From"
                          className="table_search_input w-full rounded-md text-xs h-full placeholder:text-text-xs placeholder:text-787878 px-3"
                          type={"number"}
                          value={row.from}
                          onChange={(e) =>
                            handleFilterRowChange(
                              index,
                              "from",
                              e.target.value,
                              setFilters,
                              filters
                            )
                          }
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "Subtract") {
                              e.preventDefault(); // Prevent minus key
                            }
                          }}
                        />
                      </div>
                      <div
                        style={{ maxWidth: "15rem", height: "2.6rem" }}
                        className={`w-full flex items-center rounded-md input_border`}
                      >
                        <input
                          min={0}
                          placeholder="To"
                          className="table_search_input w-full rounded-md text-xs h-full placeholder:text-text-xs placeholder:text-787878 px-3"
                          type={"number"}
                          value={row.to}
                          onChange={(e) =>
                            handleFilterRowChange(
                              index,
                              "to",
                              e.target.value,
                              setFilters,
                              filters
                            )
                          }
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "Subtract") {
                              e.preventDefault(); // Prevent minus key
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : filterOptions?.find((option) => option.value === row.column) // Find the column in filterOptions
                  ?.valueField === "search" ? (
                <>
                  <SearchSelectFieldNonFormik
                    value={{
                      label: row?.value ? (
                        row?.value
                      ) : (
                        <p className="text-667085 text-xs">
                          Search and Select Item..
                        </p>
                      ),
                      value: row?.value ?? row?.value,
                    }}
                    handleChange={(selectedOption, actionMeta) => {
                      setFilters({
                        ...filters,
                        filters: filters?.filters.map((row, idx) =>
                          idx === index
                            ? {
                                ...row,
                                value: selectedOption?.value,
                              }
                            : row
                        ),
                      });
                    }}
                    action={
                      filterOptions?.find(
                        (option) => option.value === row.column
                      )?.action
                    }
                    field={
                      filterOptions?.find(
                        (option) => option.value === row.column
                      )?.field
                    }
                  />
                </>
              ) : filterOptions?.find((option) => option.value === row.column) // Find the column in filterOptions
                  ?.valueField === "dropdown" ? (
                <div
                  style={{ maxWidth: "15rem", height: "2.6rem" }}
                  className={`w-full flex items-center rounded-md input_border`}
                >
                  <Select
                    value={{ label: row?.value, value: row?.value }}
                    className="border-0 w-full text-xs placeholder:text-xs"
                    menuPortalTarget={document.body}
                    styles={customStylesForFilter}
                    options={
                      dropdownOptions[
                        filterOptions?.find(
                          (option) => option.value === row.column
                        )?.field
                      ]
                    }
                    placeholder="Select"
                    onChange={(selectedOption) =>
                      handleFilterRowChange(
                        index,
                        "value",
                        selectedOption?.value,
                        setFilters,
                        filters
                      )
                    }
                  />
                </div>
              ) : (
                <div
                  style={{ maxWidth: "15rem", height: "2.6rem" }}
                  className={`w-full flex items-center rounded-md input_border`}
                >
                  <input
                    onWheel={(e) => e.target.blur()}
                    min={0}
                    placeholder="Enter Values"
                    className="table_search_input w-full rounded-md text-xs h-full placeholder:text-text-xs placeholder:text-787878 px-3"
                    type={
                      filterOptions?.find((opt) => opt?.value === row?.column)
                        ?.valueField
                    }
                    value={row.value}
                    onChange={(e) =>
                      handleFilterRowChange(
                        index,
                        "value",
                        e.target.value,
                        setFilters,
                        filters
                      )
                    }
                    onKeyDown={(e) => {
                      if (
                        e.key === "Subtract" &&
                        filterOptions?.find((opt) => opt?.value === row?.column)
                          ?.valueField === "number"
                      ) {
                        e.preventDefault(); // Prevent minus key
                      }
                    }}
                  />
                </div>
              )}

              {index === filters?.filters.length - 1 ? (
                <PlusIcon
                  onClick={() => {
                    handleAddFilterRow(setFilters, filters);
                  }}
                  className={`w-5 text-primary cursor-pointer`}
                />
              ) : (
                <div
                  className="mt-3"
                  onClick={() =>
                    handleRemoveFilterRow(index, setFilters, filters)
                  }
                >
                  <DeleteIcon className="cursor-pointer" />
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {openMoreFilter ? (
        <div
          className="mt-6 w-full flex bg-[#0000005a] relative z-2"
          style={{ height: overlayHeight }}
          onClick={() => {
            setOpenMoreFilter(!openMoreFilter);
          }}
        ></div>
      ) : (
        <>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div
              className="overflow-x-auto mt-5"
              style={{
                maxWidth: "100%",
                maxHeight: `calc(100% - ${heightOfFirstDiv + 20}px)`,
                height: `calc(100% - ${heightOfFirstDiv + 20}px)`,
              }}
            >
              <table
                className={`${tableData?.length === 0 ? "h-full" : ""} w-full`}
              >
                {tableData.length > 0 && (
                  <thead className="bg-F2F4F5 top-0 z-10">
                    <tr>
                      {/* Header for Serial Number */}
                      <th className="tab-header-right-not-fixed whitespace-nowrap text-xs fw_500 text-475467 bg-F2F4F5 text-start">
                        Sr No
                      </th>
                      {/* Dynamic Table Headings */}
                      {tableData &&
                        tableData.length > 0 &&
                        Object.keys(tableData[0]).map((key, index) => (
                          <th
                            key={index}
                            className="tab-header-right-not-fixed whitespace-nowrap text-xs fw_500 text-475467 bg-F2F4F5 text-start"
                          >
                            {key.replace(/_/g, " ").toUpperCase()}{" "}
                            {/*Changes made today to to show and hide columns in saved reports*/}
                          </th>
                        ))}
                    </tr>
                  </thead>
                )}
                <tbody>
                  {tableData && tableData.length > 0 ? (
                    tableData.map((item, rowIndex) => (
                      <tr key={rowIndex}>
                        {/* Serial Number Column */}
                        <td className="tab-cell-right-not-fixed text-sm fw_400 text-secondary">
                          {rowIndex + 1}
                        </td>
                        {/* Dynamic Row Data */}
                        {Object.values(item).map((value, colIndex) => (
                          <td
                            key={colIndex}
                            className="tab-cell-right-not-fixed text-sm fw_400 text-secondary text-nowrap"
                          >
                            {value}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="100%"
                        className="text-center text-sm fw_400 text-primary h-full"
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </ReportLayout>
  );
};

const filterOptions = [
  // {
  //   label: "Item Code",
  //   value: "counts.item_code", // Updated to match the database column
  //   operators: [
  //       "equal_to",
  //   ],
  //   valueField: "search",
  //   action: productListAction,
  //   field: "item_code",
  // },
  // {
  //   label: "Item Name",
  //   value: "counts.item_generic_name", // Updated to match the database column
  //   operators: ["equal_to"],
  //   valueField: "search",
  //   field: "item_name",
  //   action: productListAction,
  // },
  // {
  //   label: "Item Category",
  //   value: "counts.item_category", // Updated to match the database column
  //   operators: ["equal_to"],
  //   valueField: "search",
  //   field: "item_category",
  //   action: itemTypeListAction,
  // },
  // {
  //   label: "Item Manufacturer",
  //   value: "counts.item_manufacturer", // Updated to match the database column
  //   operators: ["equal_to"],
  //   valueField: "search",
  //   field: "item_manufacturer",
  //   action: manufacturesListAction,
  // },
  // {
  //   label: "Item Status",
  //   value: "counts.is_active", // Updated to match the database column
  //   operators: ["equal_to"],
  //   valueField: "text",
  // },
  // {
  //   label: "No.of Batches",
  //   value: "counts.\"No. of Batches\"", // Corrected to handle the space in the column name
  //   operators: ["equal_to"],
  //   valueField: "text",
  // },
  // ...(isAdmin
  //   ? [
  //       {
  //         label: "Store Area",
  //         value: "siat.shop_area", 
  //         operators: [
  //           "equal_to",
  //         ],
  //         valueField: "text",
  //       },
  //       {
  //         label: "Store Landmark",
  //         value: "siat.shop_landmark", 
  //         operators: [
  //           "equal_to",
  //         ],
  //         valueField: "text",
  //       },
  //       {
  //         label: "Store Town/Village",
  //         value: "siat.shop_town_or_village", 
  //         operators: [
  //           "equal_to",
  //         ],
  //         valueField: "text",
  //       },
  //       {
  //         label: "Store City",
  //         value: "siat.shop_city", 
  //         operators: [
  //           "equal_to",
  //         ],
  //         valueField: "text",
  //       },
  //       {
  //         label: "Store State",
  //         value: "siat.shop_state", 
  //         operators: [
  //           "equal_to",
  //         ],
  //         valueField: "text",
  //       },
  //       {
  //         label: "Store Pincode",
  //         value: "siat.shop_pin_code", 
  //         operators: [
  //           "equal_to",
  //         ],
  //         valueField: "text",
  //       },
  //     ]
  //   : []),
];

export default VendorMaster;
