import React, { useEffect, useState } from "react";
import FilterRadioButtons from "../../forms/filter-radio-buttons";
import { RolesListAction } from "../../../redux/actions/general-action";
import { useDispatch } from "react-redux";

const UserFilter = ({ setFilterData, filterData, setCurrentPage }) => {
  const [rolesList, setRolesList] = useState([]);
  const dispatch = useDispatch();
  const handleRoleChange = (e) => {
    setFilterData({
      ...filterData,
      role: e.target.value,
    });
    setCurrentPage(1);
  };

  useEffect(() => {
    dispatch(RolesListAction(onSuccessRoles, onErrorRoles));
  }, []);
  const onSuccessRoles = (data) => {
    setRolesList(
      data?.data?.map((item) => ({
        label: item?.role_name,
        value: item?.role_name,
      }))
    );
  };
  const onErrorRoles = (data) => {};

  return (
    <div
      menuPortalTarget={document.body}
      style={{
        minWidth: "13rem",
        zIndex: 999,
        top: "40px",
        right: "-5px",
        boxShadow: `0px 1px 3px 0px var(--popover-shadow-color)`,
      }}
      className="absolute  border border-[var(--popover-border-color)] border-solid border-1   py-3 px-2  bg-[var(--popover-bg-color)]  rounded  z-10 flex flex-col"
    >
      <span className=" text-[length:--popover-heading-font-size] font-[var(--popover-heading-font-weight)] text-[var(--popover-heading-fg-color)] whitespace-nowrap cursor-pointer border-radius px-[8px] py-[12px]">
        FILTER BY
      </span>
      <hr className="border border-EAECF0 " />
      <div className="px-5 ">
        <FilterRadioButtons
          name="role"
          label={"Filter Roles"}
          options={rolesList}
          handleChange={handleRoleChange}
          value={filterData?.role}
        />
      </div>
    </div>
  );
};

export default UserFilter;
