import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  productDisCreateAction,
  productPlanningCreateAction,
} from "../../redux/actions/product-action";
import AddButton from "../buttons/button";
import InputField from "../forms/input-field";
import successToast from "../helper-components/success-toast";
import SelectField from "../forms/select-field";

const DiscountTab = ({
  productId,
  setFormValues,
  formValues,
  setCurrentTab,
  product,
  disabled = false,
  itemGeneralData = {},
  uomGroupData = [],
}) => {
  const dispatch = useDispatch();
  const initialValues = {
    item_id: product?.item_id ?? productId,
    item_min_discount: product?.item_min_discount ?? 0,
    item_max_discount: product?.item_max_discount ?? 0,
    item_on_purchasing_qauntity: product?.item_on_purchasing_qauntity ?? 0,
    item_on_purchasing_unit_id: product?.item_on_purchasing_unit_id ?? "",
    item_customer_reward_qauntity: product?.item_customer_reward_qauntity ?? 0,
    item_customer_reward_uom_id: product?.item_customer_reward_uom_id ?? "",
  };
  const validationSchema = Yup.object().shape({});
  const innerOnSubmit = (values) => {
    setFormValues({
      ...formValues,
      discount: values,
    });
    dispatch(
      productDisCreateAction(
        {
          itemGeneralData: itemGeneralData,
          itemDiscountAndschemaData: values,
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    successToast(data?.message);
    setCurrentTab("Restrictions");
  };
  const onError = (data) => {};

  return (
    <>     
      <Formik
        enableReinitialize={true}
        initialValues={formValues?.discount ?? initialValues}
        validationSchema={validationSchema}
        onSubmit={innerOnSubmit}
      >
        {({ values, handleSubmit, errors, setFieldValue }) => {
          return (
            <>
              <h2 className="text-000000 fw_500 text-sm mb-8 px-5 xl:px-10 mt-10">
                Discounts
              </h2>
              <div className="flex flex-wrap">
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10">
                  <InputField
                    handleChange={(event) => {
                      let value = parseFloat(event.target.value);
                      if (value > 100) {
                        event.target.value = 100;
                      }
                    }}
                    max="100"
                    isDisabled={disabled}
                    type="number"
                    name="item_min_discount"
                    placeholder=""
                    label="Min Dis(%)"
                    isRequired={false}
                    labelWidth="9rem"
                  />
                </div>
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10 mt-10 lg:mt-0">
                  <InputField
                    handleChange={(event) => {
                      let value = parseFloat(event.target.value);
                      if (value > 100) {
                        event.target.value = 100;
                      }
                    }}
                    max="100"
                    isDisabled={disabled}
                    type="number"
                    name="item_max_discount"
                    placeholder=""
                    label="Max Dis (%)"
                    isRequired={false}
                    labelWidth="9rem"
                  />
                </div>
              </div>
              <h2 className="text-000000 fw_500 text-sm mb-8 px-5 xl:px-10 mt-10">
                Scheme
              </h2>
              <div className="flex flex-wrap">
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10 flex gap-5">
                  <div className="w-[50%]">
                    <InputField
                      defaultInputClass="inputField focus_outline__none border-0 text-value-color "
                      handleChange={(event) => {
                        let value = parseFloat(event.target.value);
                        if (value > 100) {
                          event.target.value = 100;
                        }
                      }}
                      max="100"
                      isDisabled={disabled}
                      type="number"
                      name="item_on_purchasing_qauntity"
                      placeholder=""
                      label="On purchasing of "
                      isRequired={false}
                      labelWidth="9rem"
                    />
                  </div>
                  <div className="w-[50%]">
                    <SelectField
                      isDisabled={disabled}
                      placeholder="Select uom"
                      labelClass="hidden"
                      options={uomGroupData
                        ?.find(
                          (grp) => grp?.group_id === itemGeneralData?.item_uom
                        )
                        ?.group_items?.map((uom) => ({
                          label: uom?.alt_uom,
                          value: uom?.item_id,
                        }))}
                      label="Purchasing UOM Name"
                      id="item_on_purchasing_unit_id"
                      name="item_on_purchasing_unit_id"
                      isSearchable={true}
                      errMsgContainerClass="ps-2"
                      isRequired={true}
                    />
                  </div>
                </div>
                <div className="w-full 2xl:w-[35%] lg:w-1/2 px-5 xl:px-10 mt-10 lg:mt-0 flex gap-5">
                  <div className="w-[50%]">
                    <InputField
                      handleChange={(event) => {
                        let value = parseFloat(event.target.value);
                        if (value > 100) {
                          event.target.value = 100;
                        }
                      }}
                      max="100"
                      isDisabled={disabled}
                      type="number"
                      name="item_customer_reward_qauntity"
                      placeholder=""
                      label="Customer will get"
                      isRequired={false}
                      labelWidth="9rem"
                    />
                  </div>
                  <div className="w-[50%]">
                    <SelectField
                      isDisabled={disabled}
                      placeholder="Select uom"
                      labelClass="hidden"
                      // options={uomList}
                      options={uomGroupData
                        ?.find(
                          (grp) => grp?.group_id === itemGeneralData?.item_uom
                        )
                        ?.group_items?.map((uom) => ({
                          label: uom?.alt_uom,
                          value: uom?.item_id,
                        }))}
                      label="Purchasing UOM Name"
                      id="item_customer_reward_uom_id"
                      name="item_customer_reward_uom_id"
                      isSearchable={true}
                      errMsgContainerClass="ps-2"
                      isRequired={true}
                    />
                  </div>
                </div>
              </div>
              {!disabled && (
                <div
                  style={{
                    bottom: "0",
                  }}
                  className="flex justify-end px-10 mb-5x sticky bg-white py-4 invoice-bottom-container"
                >
                  <AddButton
                    onclkFn={handleSubmit}
                    prefixIcon={false}
                    text="Save"
                    type="submit"
                  />
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default DiscountTab;
