import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import CloseIcon from "../../../../assets/icons/close-icon";
import RadioButton from "../../../forms/radio-button";
import AddButton from "../../../buttons/button";
import { useFormikContext } from "formik";
import FilterRadioButtons from "../../../forms/filter-radio-buttons";

const TemplateAddOptModal = ({ isAddOptOpen = false, setIsAddOptOpen }) => {
  const { values, setFieldValue, setValues } = useFormikContext();

  console.log(values, "sadadadasdadad");
  return (
    <Transition show={isAddOptOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => {
          setIsAddOptOpen(false);
        }}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-0"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
          </Transition.Child>

          {/* This is your AddOpt container */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-0"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div
              className={`mx-auto w-[35rem]    p-5  text-left align-middle transition-all transform bg-white shadow-xl rounded-xl`}
            >
              <div className="flex justify-between items-start h-[3rem]">
                <span className="text-000000 text-lg fw_600">
                  Additional Options
                </span>{" "}
                <button
                  type="button"
                  className="close-button bg-transparent border-none p-0 hover:bg-787878"
                  onClick={() => {
                    setIsAddOptOpen(false);
                  }}
                >
                  <CloseIcon />
                </button>
              </div>

              <div className="flex gap-10 flex-col">
                <div>
                  <RadioButton
                    onChange={(sel) => {
                      const newGstFormat = sel?.target?.value;
                      setFieldValue("gst_format", newGstFormat);

                      let updatedGrnColumns = values.grnColumns.filter(
                        (col) =>
                          ![
                            "item_gst",
                            "item_cgst_percentage",
                            "item_sgst_percentage",
                          ].includes(col.name)
                      );

                      if (newGstFormat === "gst") {
                        updatedGrnColumns = [...updatedGrnColumns, ...gst];
                      } else if (newGstFormat === "cgst_sgst") {
                        updatedGrnColumns = [
                          ...updatedGrnColumns,
                          ...cgst_sgst,
                        ];
                      }

                      setFieldValue("grnColumns", updatedGrnColumns);
                    }}
                    mainContainerClass="flex gap-5"
                    inputLabelClass="ms-1"
                    labelClass="color_9E9E9E d-flex text-sm mb-4 flex"
                    name={`gst_format`}
                    options={gstFormatOptns}
                    label="GST Format"
                  />
                </div>
                <div>
                  <RadioButton
                    onChange={(sel) => {
                      const newExpiryFormat = sel?.target?.value;
                      setFieldValue("exp_format", newExpiryFormat);

                      let updatedGrnColumns = values.grnColumns.filter(
                        (col) =>
                          ![
                            "item_exp_date",
                            "item_exp_month",
                            "item_exp_year",
                          ].includes(col.name)
                      );

                      if (newExpiryFormat === "single") {
                        updatedGrnColumns = [...updatedGrnColumns, ...single];
                      } else if (newExpiryFormat === "multiple") {
                        updatedGrnColumns = [...updatedGrnColumns, ...mutiple];
                      }

                      setFieldValue("grnColumns", updatedGrnColumns);
                    }}
                    mainContainerClass="flex gap-5"
                    inputLabelClass="ms-1"
                    labelClass="color_9E9E9E d-flex text-sm mb-4 flex"
                    name={`exp_format`}
                    options={expiryFormatOptns}
                    label="Expiry Format"
                  />
                </div>
              </div>
              <div className="flex justify-between">
                <span></span>{" "}
                <AddButton
                  onclkFn={() => {
                    setIsAddOptOpen(false);
                  }}
                  prefixIcon={false}
                  text="Save"
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default TemplateAddOptModal;

export const gstFormatOptns = [
  { label: "GST", value: "gst" },
  { label: "CGST/SGST", value: "cgst_sgst" },
];
export const expiryFormatOptns = [
  { label: "Single column", value: "single" },
  { label: "Multi column", value: "multiple" },
];

const cgst_sgst = [
  {
    label: "Item CGST %",
    name: "item_cgst_percentage",
    value: "",
    required: true,
  },
  {
    label: "Item SGST %",
    name: "item_sgst_percentage",
    value: "",
    required: true,
  },
];

const gst = [
  { label: "Item GST %", name: "item_gst", value: "", required: true },
];

const single = [
  {
    label: "Item Expiry Date",
    name: "item_exp_date",
    value: "",
    required: true,
  },
];

const mutiple = [
  {
    label: "Expiry Month",
    name: "item_exp_month",
    value: "",
    required: true,
  },
  {
    label: "Expiry year",
    name: "item_exp_year",
    value: "",
    required: true,
  },
];
