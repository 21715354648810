import React from "react";

const DeleteIcon = ({
  onClickFn = () => {},
  width = "14",
  height = "16",
  fill = "#F24E1E",
}) => {
  return (
    <svg
      className="cursor-pointer"
      onClick={onClickFn}
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.615 16C2.16833 16 1.78733 15.8426 1.472 15.528C1.15733 15.2133 1 14.8323 1 14.385V1.99998H0V0.99998H4V0.22998H10V0.99998H14V1.99998H13V14.385C13 14.845 12.846 15.229 12.538 15.537C12.2293 15.8456 11.845 16 11.385 16H2.615ZM12 1.99998H2V14.385C2 14.5643 2.05767 14.7116 2.173 14.827C2.28833 14.9423 2.43567 15 2.615 15H11.385C11.5383 15 11.6793 14.936 11.808 14.808C11.936 14.6793 12 14.5383 12 14.385V1.99998ZM4.808 13H5.808V3.99998H4.808V13ZM8.192 13H9.192V3.99998H8.192V13Z"
        fill={fill}
      />
    </svg>
  );
};

export default DeleteIcon;
