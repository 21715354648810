import React from "react";

const ChevronDown = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 4.5C0.5 2.567 2.067 1 4 1H10C11.933 1 13.5 2.567 13.5 4.5V10.5C13.5 12.433 11.933 14 10 14H4C2.067 14 0.5 12.433 0.5 10.5V4.5Z"
          fill="white"
        />
        <path
          d="M0.5 4.5C0.5 2.567 2.067 1 4 1H10C11.933 1 13.5 2.567 13.5 4.5V10.5C13.5 12.433 11.933 14 10 14H4C2.067 14 0.5 12.433 0.5 10.5V4.5Z"
          stroke="#D0D5DD"
        />
        <path
          d="M4.5 6.75L7 9.25L9.5 6.75"
          stroke="#7F7F7F"
          stroke-width="1.002"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default ChevronDown;

export const ChevronRight = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 14C2.067 14 0.5 12.433 0.5 10.5L0.5 4.5C0.5 2.567 2.067 1 4 1H10C11.933 1 13.5 2.567 13.5 4.5V10.5C13.5 12.433 11.933 14 10 14H4Z"
        fill="white"
      />
      <path
        d="M4 14C2.067 14 0.5 12.433 0.5 10.5L0.5 4.5C0.5 2.567 2.067 1 4 1H10C11.933 1 13.5 2.567 13.5 4.5V10.5C13.5 12.433 11.933 14 10 14H4Z"
        stroke="#D0D5DD"
      />
      <path
        d="M6.25 10L8.75 7.5L6.25 5"
        stroke="#7F7F7F"
        stroke-width="1.002"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
