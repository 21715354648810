import React from "react";
import PagRightIcon, {
  PagLeftIcon,
} from "../../assets/icons/arrow-pagination-icons";

const Pagination = ({ paginationProps }) => {
  const { pageSize, dataCount, setCurrentPage, currentPage } = paginationProps;

  const totalPages = Math.ceil(dataCount / pageSize);
  const maxVisiblePages = 5; // You can adjust this number based on your design preference

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const generatePages = () => {
    const pages = [];
    // Calculate the start and end based on the current page
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

    // Adjust the start page if we are nearing the end
    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          type="button"
          key={i}
          onClick={() => handlePageChange(i)}
          className={`mx-2 px-3 py-2 text-sm  rounded-md  ${
            currentPage === i
              ? "text-pageination-color bg-D5E6Ff fw_500"
              : "bg-white text-pageination-color fw_500"
          }`}
        >
          {i}
        </button>
      );
    }
    // Add ellipsis if there are more pages before the current visible range
    if (startPage > 1) {
      pages.unshift(
        <span key="ellipsis-start" className="mx-2">
          ...
        </span>
      );
    }

    // Add ellipsis if there are more pages after the current visible range
    if (endPage < totalPages) {
      pages.push(
        <span key="ellipsis-end" className="mx-2">
          ...
        </span>
      );
    }

    return pages;
  };

  return (
    <div
      style={{
        bottom: "0",
        maxHeight: "80px",
      }}
      className="flex justify-between px-10  sticky bg-white py-4 invoice-bottom-container"
    >
      <button
        type="button"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        style={{
          boxShadow: "0px 1px 2px 0px #1018280D",
        }}
        className="flex border-D0D5DD border gap-2 items-center rounded-md px-3 py-2 text-sm"
      >
        <PagLeftIcon /> Previous
      </button>

      <div>{generatePages()}</div>

      <button
        type="button"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        style={{
          boxShadow: "0px 1px 2px 0px #1018280D",
        }}
        className="flex border-D0D5DD border text-sm gap-2 items-center rounded-md px-3 py-2"
      >
        Next <PagRightIcon />
      </button>
    </div>
  );
};

export default Pagination;
