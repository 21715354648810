import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendorIcon from "../../assets/icons/calendor-icon";

const DatePickerInput = ({ placeholder = "", onChange = () => {}, value }) => {
  return (
    <div
      style={{
        height: "32px",
        width: "150px",
      }}
      className="flex items-center border border-[var(--head-input-border-color)] rounded-md gap-[8px] px-2 z-20"
    >
      <CalendorIcon width="14px" height="14px" />
      <DatePicker
        // menuPortalTarget={document.body}
        isClearable
        selected={value ? new Date(value) : null} // Ensure value is a Date object
        onChange={
          (date) => onChange(date ? date.toISOString().split("T")[0] : "") // 👈 Return empty string instead of null
        }
        dateFormat="yyyy-MM-dd"
        placeholderText={placeholder}
        onKeyDown={(e) => e.preventDefault()} // 👈 Prevent manual typing
        className="w-full bg-transparent focus:outline-none text-[var(--head-input-fg-color)] text-[length:var(--head-input-font-size)] font-[var(--head-input-font-weight)] placeholder:text-[var(--head-input-placeholder-color)] placeholder:text-[length:var(--head-input-font-size)] placeholder:font-[var(--head-input-place-font-weight)]"
      />
    </div>
  );
};

export default DatePickerInput;
